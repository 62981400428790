import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Button } from "components/button";

import { useSnackbarContext } from "components/snackbar";
import { useModalContext } from "components/modal";
import { TCategory } from "./TCategory";
import Switch from "rc-switch";
import { useTranslation } from 'react-i18next';

type Props = {
  id: number | "new";
  onSave?: () => void;
};

type FormValues = {
  category_name: string;
  root_category: boolean;
  parent_category_id: number;
  active: boolean;
  is_parent_category: boolean;
  category_order: number;
  visible_in_field: boolean;
  visible_in_po: boolean;
  is_service: boolean;
  is_primary_root: boolean;
};

const CategoryModal = ({ id, onSave }: Props) => {
  const { t } = useTranslation();
  const tBase = "views.inventories.categories.CategoryModal";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const schema = Yup.object().shape({
    category_name: Yup.string().required(tr("Category name is required")),
  });

  const [isParentCategory, setIsParentCategory] = useState(false);
  const snackbar = useSnackbarContext();
  const modal = useModalContext();
  const queryClient = useQueryClient();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    register,
    setValue,
    reset,
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(schema) as any,
    mode: "all",
  });

  const { data: categories } = useQuery("categories", async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/company/products/categories`
    );
    return data;
  });

  const findValue = (value: string) => {
    return categories?.find(
      (category: TCategory) => category.category_id === id
    )?.[value] || "";
  };

  useEffect(() => {
    if (categories && id !== "new") {
      reset({
        category_name: findValue("category_name"),
        root_category: findValue("root_category"),
        parent_category_id: findValue("parent_category_id"),
        active: findValue("active"),
        is_parent_category: findValue("is_parent_category"),
        category_order: findValue("category_order"),
        visible_in_field: findValue("visible_in_field"),
        visible_in_po: findValue("visible_in_po"),
        is_service: findValue("is_service"),
        is_primary_root: findValue("is_primary_root"),
      });
    } else {
      reset({
        category_name: "",
        root_category: false,
        parent_category_id: 0,
        active: true,
        is_parent_category: false,
        category_order: 0,
        visible_in_field: true,
        visible_in_po: true,
        is_service: true,
        is_primary_root: false,
      });
    }
  }, [categories, id, reset]);

  const mutation = useMutation(
    async (data: FormValues) => {
      const formData = new FormData();
      formData.append("category_name", data.category_name);
      formData.append("root_category", String(data.root_category));
      formData.append("parent_category_id", String(data.parent_category_id));
      formData.append("active", String(data.active));
      formData.append("is_parent_category", String(data.is_parent_category));
      formData.append("category_order", String(data.category_order));
      formData.append("visible_in_field", String(data.visible_in_field));
      formData.append("visible_in_po", String(data.visible_in_po));
      formData.append("is_service", String(data.is_service));
      formData.append("is_primary_root", String(data.is_primary_root));

      if (id && id === "new") {
        return await axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/company/products/category`,
            formData
          )
          .then((res) => {
            queryClient.invalidateQueries("categories");
            return res.data;
          });
      } else {
        return await axios
          .put(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/company/products/category/${id}`,
            formData
          )
          .then((res) => {
            queryClient.invalidateQueries("categories");
            return res.data;
          });
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("categories");
        snackbar.showSnackbar(tr("Category saved!"));
        modal.setModal();

        if (onSave) onSave();
      },
      onError: () => {
        queryClient.invalidateQueries("categories");
        snackbar.showSnackbar(tr("Failed to save category"));
      },
    }
  );

  const onSubmit = (data: FormValues) => {
    mutation.mutate(data);
  };
  
  const watchIsParentCategory = watch("is_parent_category");

  useEffect(() => {
    setIsParentCategory(watchIsParentCategory);
  }, [watchIsParentCategory]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block mb-2" htmlFor="active">
            {tr("Active")}
          </label>
          <Controller
            control={control}
            name="active"
            render={({ field }) => (
              <Switch
                onChange={(checked) => field.onChange(checked)}
                checked={field.value}
              />
            )}
          />
          {errors.active && (
            <p className="text-red-500">{errors.active.message}</p>
          )}
        </div>
        <div>
          <label className="block mb-2" htmlFor="category_name">
            {tr("Category Name")}
          </label>
          <input
            className="w-full p-2 border border-gray-300 rounded"
            type="text"
            id="category_name"
            {...register("category_name")}
          />
          {errors.category_name && (
            <p className="text-red-500">{errors.category_name.message}</p>
          )}
        </div>
        <div>
          <label className="block mb-2" htmlFor="is_parent_category">
            {tr("Is Parent Category")}
          </label>
          <Controller
            control={control}
            name="is_parent_category"
            render={({ field }) => (
              <Switch
                onChange={(checked) => {
                  field.onChange(checked);
                  setIsParentCategory(checked);
                  if (checked) {
                    setValue("parent_category_id", 0);  
                  }
                }}
                checked={field.value}
              />
            )}
          />
          {errors.is_parent_category && (
            <p className="text-red-500">{errors.is_parent_category.message}</p>
          )}
        </div>
        <div>
          <label className="block mb-2" htmlFor="parent_category_id">
            {tr("Parent Category")}
          </label>
          <select
            className="w-full p-2 border border-gray-300 rounded"
            id="parent_category_id"
            {...register("parent_category_id")}
            disabled={isParentCategory}
          >
            <option value="">{tr("Select a parent category")}</option>
            {categories
              ?.filter((category: TCategory) => category.is_parent_category)
              ?.map((category: TCategory) => (
                <option key={category.category_id} value={category.category_id}>
                  {category.category_name}
                </option>
              ))}
          </select>
          {errors.parent_category_id && (
            <p className="text-red-500">{errors.parent_category_id.message}</p>
          )}
        </div>
        <div>
          <label className="block mb-2" htmlFor="visible_in_field">
            {tr("Visible in Field")}
          </label>
          <Controller
            control={control}
            name="visible_in_field"
            render={({ field }) => (
              <Switch
                onChange={(checked) => field.onChange(checked)}
                checked={field.value}
              />
            )}
          />
          {errors.visible_in_field && (
            <p className="text-red-500">{errors.visible_in_field.message}</p>
          )}
        </div>
        <div>
          <label className="block mb-2" htmlFor="visible_in_po">
            {tr("Visible in PO")}
          </label>
          <Controller
            control={control}
            name="visible_in_po"
            render={({ field }) => (
              <Switch
                onChange={(checked) => field.onChange(checked)}
                checked={field.value}
              />
            )}
          />
          {errors.visible_in_po && (
            <p className="text-red-500">{errors.visible_in_po.message}</p>
          )}
        </div>
      </div>
      <div className="flex justify-end mt-4">
        <Button primary={isValid} type="submit" disabled={!isValid}>
          {tr("Save")}
        </Button>
      </div>
    </form>
  );
};

export { CategoryModal };
