import React, { useState } from "react";
import { cloneDeep } from "lodash";
import { TItemsContainer } from "types/items-container";
import { TTargetType } from "types/target-type";
import {
	calculateGrandTotal,
	calculateTaxTotal,
	calculateTotal,
} from "./utils";
import { SubtleIconButton } from "components/button";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import ProductsSelect from "components/react-select/products";
import Taxes from "./taxes";
import { dollarFormatter, priceCheck } from "utils";
import GroupHeader from "./components/groupHeader";
import { useTranslation } from 'react-i18next';
import ProductRow from "./components/ProductRow";

type Product = {
	id: number;
	fk_ingroup_id: number | null;
	group_name: string;
	root_id: number;
	fk_product_id: number;
	new: boolean;
	quantity: number;
	unit_price: number;
	list_price: number;
	install_price: number;
	root_name: string;
};

export default function EditLayout(props: {
	data?: TItemsContainer;
	formik: any;
	id: string;
	type: TTargetType;
}) {
	const { t } = useTranslation();
	const tBase = "containers.lineItems.editLayout";
	const tr = (key: string) => t(`${tBase}.${key}`);

	const [isShowing, setIsShowing] = useState<{ [key: number]: boolean }>({});
	const priceType: "sale" | "install" = props.formik?.values?.price_type || "list";
	const rateLabel = priceType === "sale" ? "unit_price" : "install_price";
	const products = props.formik.values && props.formik.values.products.length > 0 && props.formik.values.products;

	const deleteProduct = (i: number) => {
		props.formik.setValues((v: any) => {
			let temp = cloneDeep(v);
			temp.products.splice(i, 1);
			return temp;
		});
	};

	const deleteGroup = (id: number) => {
		props.formik.setValues((v: any) => {
			let temp = cloneDeep(v);
			temp.products = temp.products.filter(
				(product: any) => product.fk_ingroup_id !== id
			);
			return temp;
		});
	};

	const moveProduct = (dragIndex: number, hoverIndex: number) => {
    const updatedProducts = [...props.formik.values.products];
    const draggedProduct = updatedProducts[dragIndex];

    // If dragging an entire group (i.e., first product of the group), move all products in that group
    if (draggedProduct.fk_ingroup_id) {
			const groupId = draggedProduct.fk_ingroup_id;
			
			const groupProducts = updatedProducts.filter(
					(p) => p.fk_ingroup_id === groupId
			);

			const groupStartIndex = updatedProducts.findIndex(
					(p) => p.fk_ingroup_id === groupId
			);
			const groupEndIndex = groupStartIndex + groupProducts.length - 1;

			// If the hover index is within the group boundary, do nothing
			if (hoverIndex >= groupStartIndex && hoverIndex <= groupEndIndex) {
				const relativeDragIndex = groupProducts.findIndex(
					(p) => p.id === draggedProduct.id
				);
				const productToMove = groupProducts[relativeDragIndex];
	
				groupProducts.splice(relativeDragIndex, 1);
	
				const relativeHoverIndex = hoverIndex - groupStartIndex;
	
				groupProducts.splice(relativeHoverIndex, 0, productToMove);
				updatedProducts.splice(groupStartIndex, groupProducts.length, ...groupProducts);
			} else {
				// Remove all group products from their original positions
				updatedProducts.splice(groupStartIndex, groupProducts.length);
				updatedProducts.splice(hoverIndex, 0, ...groupProducts);
			}


    } else {
			// If it's a standalone product or the user is dragging a product outside a group
			const hoverProduct = updatedProducts[hoverIndex];

			if (
					draggedProduct.fk_ingroup_id === hoverProduct.fk_ingroup_id ||
					(!draggedProduct.fk_ingroup_id && !hoverProduct.fk_ingroup_id)
			) {
					updatedProducts.splice(dragIndex, 1);
					updatedProducts.splice(hoverIndex, 0, draggedProduct);
			}
    }

    // Perform recalculation of ordering
    const recalculatedProducts = updatedProducts.map((product, index) => ({
			...product,
			ordering: index + 1, // Update ordering to be consistent
		}));

		console.log("Recalculated products", recalculatedProducts);

		// Update the products list
    props.formik.setFieldValue('products', recalculatedProducts);
	};


	const handleToggleGroup = (groupId: number) => {
    setIsShowing((prevState) => ({
        ...prevState,
        [groupId]: !prevState[groupId],
    }));
	};
	
	const populateProducts = products && products.map((product: Product, i: number, f: Product[]) => {
		const groupId = product?.fk_ingroup_id;
	
		// Logic to show the header (category) based on conditions
		const showHeader =
			i === 0 ||
			product.fk_ingroup_id !== f[i - 1].fk_ingroup_id || // Check if product belongs to a different group
			(!product.fk_ingroup_id &&
				product.root_id !== f[i - 1].root_id &&
				product.fk_product_id !== f[i - 1].fk_product_id) ||
			(product.new === true && !f[i - 1].new);
	
		// Determine the category name
		const cat =
			showHeader &&
			(product.root_name ||
				(product.new && tr("Just Added")) ||
				(groupId && tr("Group")) ||
				tr("Service"));
	
		// Check if the current product is part of a group
		if (groupId) {

			const groupProducts = products.filter((p: { fk_ingroup_id: number; }) => p.fk_ingroup_id === groupId);
	
			// Find the first occurrence of this group in the list to render the GroupHeader
			if (groupProducts[0].id === product.id) {
				return (
					<React.Fragment key={`group-${groupId}`}>
						{/* Show category if applicable */}	
						{cat && (
							<div className="col-span-full flex items-center">
								<h3 className="pt-2 pb-1 text-sm font-bold uppercase col-span-full text-slate-500">
									{cat}{" "}
								</h3>
								{product.fk_ingroup_id && (
									<SubtleIconButton
										size="small"
										onClick={() => handleToggleGroup(groupId)}
									>
										{isShowing[groupId] ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
									</SubtleIconButton>
								)}
							</div>
						)}
	
						{/* Render GroupHeader for the group */}
						<GroupHeader
							data={{
								id: groupId,
								group: true,
								group_name: product.group_name,
								group_products: groupProducts,
							}}
							formik={props.formik}
							rateLabel={rateLabel}
							handleDelete={() => deleteGroup(groupId)}
							moveProduct={moveProduct}	
							index={products.findIndex((p: { id: number; }) => p.id === groupProducts[0].id)}
						/>
	
						{/* Render the products inside the group */}
						{isShowing[groupId] && groupProducts.map((groupProduct: Product, index: number) => (
							<ProductRow
								key={`group-product-${groupProduct.id}`} 
								product={groupProduct}
								products={products}
								index={products.findIndex((p: { id: number; }) => p.id === groupProduct.id)} // Maintain original order
								hideEmpty={props.formik.values.hide_empty}
								deleteProduct={() => deleteProduct(index)}
								formik={props.formik}
								priceType={priceType}
								rateLabel={rateLabel}
								moveProduct={moveProduct}
								isGroupedProduct={true}
							/>
					))}
					</React.Fragment>
				);
			} else {
				// If this product is part of a group, but the GroupHeader has already been rendered, skip it
				return null;
			}
		} else {
			// This is a standalone product, render it directly
			return (
				// <React.Fragment key={`product-${product.id}-${i}`}>
				<React.Fragment key={`product-${product.id}`}>
					{/* Show category if applicable */}
					{cat && (
						<div className="col-span-full flex items-center">
							<h3 className="pt-2 pb-1 text-sm font-bold uppercase col-span-full text-slate-500">
								{cat}{" "}
							</h3>
						</div>
					)}
	
					<ProductRow
						key={`product-${product.id}`}
						product={product}
						products={products}
						index={i}
						hideEmpty={props.formik.values.hide_empty}
						deleteProduct={() => deleteProduct(i)}
						formik={props.formik}
						priceType={priceType}
						rateLabel={rateLabel}
						moveProduct={moveProduct}
						isGroupedProduct={false}
					/>
				</React.Fragment>
			);
		}
	});
	

	let total = calculateTotal(props.formik?.values?.products);

	total = {
		list: total.list * 100,
		sale: total.sale * 100,
		install: total.install * 100,
	};

	const afterTaxTotal = calculateTaxTotal(total, props.data?.taxes);

	const grandTotal = calculateGrandTotal(total, afterTaxTotal);

	// const addProduct = (e: any) => {
	// 	props.formik.setValues((v: any) => {
	// 		return {
	// 			...v,
	// 			products: e.children
	// 				? [
	// 						...v.products,
	// 						...e.children.map((child: any) => ({
	// 							...child,
	// 							fk_ingroup_id: e.id,
	// 							group_name: e.label,
	// 							new: true,
	// 							unit_price: priceCheck(child.unit_price),
	// 							list_price: priceCheck(child.list_price),
	// 							install_price: priceCheck(child.install_price),
	// 						})),
	// 				  ]
	// 				: [
	// 						...v.products,
	// 						{
	// 							...e,
	// 							fk_ingroup_id: null,
	// 							new: true,
	// 							quantity: 1,
	// 							unit_price: priceCheck(e.unit_price),
	// 							list_price: priceCheck(e.list_price),
	// 							install_price: priceCheck(e.install_price),
	// 						},
	// 				  ],
	// 		};
	// 	});
	// };

	const addProduct = (e: any) => {
    // console.log("Adding product", e);

    // Prepare new products from the input
    const newProducts = e.children
        ? e.children.map((child: any) => ({
            ...child,
            fk_ingroup_id: e.id,
            group_name: e.label,
            new: true,
            unit_price: priceCheck(child.unit_price),
            list_price: priceCheck(child.list_price),
            install_price: priceCheck(child.install_price),
        }))
        : [{
            ...e,
            fk_ingroup_id: null,
            new: true,
            quantity: 1,
            unit_price: priceCheck(e.unit_price),
            list_price: priceCheck(e.list_price),
            install_price: priceCheck(e.install_price),
        }];

    // Avoid adding the same product multiple times
    props.formik.setValues((v: any) => {
        return {
            ...v,
            products: [...v.products, ...newProducts], // Add new products to the existing list
        };
    });
};

	const array: ("list" | "install" | "sale")[] = ["list", "sale", "install"];

	const restOfPriceType = array
		.filter((f) => f !== priceType)
		.reduce(
			(prev, f, i, all) =>
				prev +
				`${tr(f.toLocaleUpperCase())}: ${dollarFormatter(grandTotal[f])} ${
					i !== all.length - 1 ? " | " : ""
				}`,
			""
		);

	return (
		<div className="mb-24">
			<div className="grid grid-cols-[0.3fr_0.3fr_100px_2fr_5fr_1fr_1fr] gap-3 px-1 border-b border-gray-300 pb-4">
				<div className="col-start-3">{tr("Qty")}</div>
				<div>{tr("Label")}</div>
				<div >{tr("Long Description")}</div>
				<div>{tr("Rate")}</div>
				<div>Total</div>
				{populateProducts}
			</div>
			<div className="flex space-x-2 py-4 border-b-[2px] border-black w-100 items-center">
				<div className="pt-2 pb-1 text-sm font-bold uppercase col-span-full text-slate-500">
					{tr("Add Items")}:
				</div>
				<div className="flex flex-1 max-w-[775px]">
					<ProductsSelect onChange={addProduct} />
				</div>
			</div>
			<div className="flex justify-end mt-2">
				{props.type !== "appointment" && (
					<Taxes
						total={total}
						view="edit"
						data={props.data?.taxes}
						id={props.id}
						type={props.type}
						priceType={priceType}
					/>
				)}
			</div>
			<div className="flex justify-end">
				<div>
					<div style={{ fontSize: "0.8rem" }}>
						{restOfPriceType}
						</div>
					<div className="text-[1.5em] text-right">
						Total: {dollarFormatter(grandTotal[priceType])}
					</div>
				</div>
			</div>
		</div>
	);
}
