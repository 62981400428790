import React from "react";
import axios from "axios";
import { useModalContext } from "components/modal";
import { useAreYouSure } from "components/areYouSure";
import { useSnackbarContext } from "components/snackbar";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ITags } from "./interfaces/ITags";
import { EditTag } from "./EditTag";
import TooltippedElement from "components/TooltippedElement";
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from 'react-i18next';

import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { FaUndo } from "react-icons/fa";
import { IconButton } from "components/button";

type Props = {
  tag: ITags;
  archived?: boolean;
};

export const Tag = ({ tag, archived }: Props) => {
  const { id, label, color, type } = tag;

  const queryClient = useQueryClient();
  const areYouSure = useAreYouSure();
  const { showSnackbar } = useSnackbarContext();
  const { setModal } = useModalContext();
  const { t } = useTranslation();
  const tBase = "views.admin.tags.components.Tag";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const handleClickEdit = () => {
    setModal({
      label: tr("Edit Tag Type"),
      component: <EditTag tag={tag} />,
    });
  };

  const handleClickArchive= async (categoryId: number) => {
    areYouSure.activate(
      () => {
        axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/company/tags/archive/${categoryId}`
          )
          .then(() => {
            queryClient.invalidateQueries();
            showSnackbar(tr("Category archived successfully."));
          })
          .catch(() => {
            showSnackbar(tr("Failed to archive category."));
          });
      },
      { message: tr("Are you sure you want to perform this action?") }
    );
  };

  const handleClickRestore = async (categoryId: number) => {
    areYouSure.activate(
      () => {
        axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/company/tags/restore/${categoryId}`
          )
          .then(() => {
            queryClient.invalidateQueries();
            showSnackbar(tr("Category restored successfully."));
          })
          .catch(() => {
            showSnackbar(tr("Failed to restore category."));
          });
      },
      { message: tr("Are you sure you want to perform this action?") }
    );
  };

  const iconCircleStyle = " cursor-pointer py-2 h-10 w-10 rounded-full bg-[#edf1f4] flex items-center justify-center hover:bg-[#bec1c3] select-none";

  return (
    <div className={`
      p-2 pl-4 pr-4 mt-2 mb-2 flex items-center justify-between border rounded-md shadow-md 
      ${archived ? "hover:bg-slate-100" : "hover:bg-slate-100"}
    `}>
      <div className="flex items-center">

        <h4 className="mr-4 pr-2 pt-2 pb-2 border-r-2 border-slate-300 group-hover:border-slate-500 text-center w-[60px] align-middle">
          {id}
        </h4>

        <div className="mr-4 pr-4 pt-2 pb-2 border-r-2 border-slate-300 text-center align-middle">
          {color ? (
            <div style={{ backgroundColor: color }} className="w-5 h-5 rounded-full"></div>   
          ) : (
            <div className="relative bg-white h-5 w-5 rounded-full overflow-hidden">
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rotate-45 w-px h-7 bg-red-500"></div>
            </div>
          )}
        </div>

        <h4 className="mr-3">
          {label}
        </h4>
        <div className="italic text-gray-400 text-[12px]">
          {type ? 
           type === "inventory" ? `(${tr("Inventory")})` : `(${tr("Customer / Property")})`
           : ""}
        </div>
      </div>
      
      <div className="flex items-center space-x-3">
        {archived ? (
          <TooltippedElement 
            placement="top"
            message={tr("Restore")}
            className="inline-flex"
            element={
              <IconButton
                onClick={() => handleClickRestore(id)}
              >
                <FaUndo size={24} className="text-green-500 p-0.5 cursor-pointer" />
              </IconButton>
            }
          />
        ) : (
          <TooltippedElement 
            placement="top"
            message={tr("Archive")}
            className="inline-flex"
            element={
              <IconButton
                onClick={() => handleClickArchive(id)}
              >
                <AiFillDelete size={24} className="text-red-500 cursor-pointer" />
              </IconButton>
            }
          />
        )}
        <TooltippedElement
          placement="top"
          message={tr("Edit")}
          element={
            <AiFillEdit
              size={24}
              className={"text-blue-500" + iconCircleStyle}
              onClick={handleClickEdit}
            />
          }
        />
      </div>
    </div>
  );
};
