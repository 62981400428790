import * as React from "react";
// import { Count, Category, Query } from "./index";
import { useTranslation } from 'react-i18next';
import Field from "components/field";
import { Button } from "components/button";
import { Con, List, ListCategory, AmountRange, BalanceRange, Props } from "./style-and-types";


export function PaymentFilter({
  query,
  count,
  handleStatusClick,
  selected,
  search
}: Props) {
  const { t } = useTranslation();
  const tBase = "views.payments.paymentFilter";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const statuses = ["Voided", "Deposit", "Payment"];

  const [amountMin, setAmountMin] = React.useState("");
  const [amountMax, setAmountMax] = React.useState("");
  const [balanceMin, setBalanceMin] = React.useState("");
  const [balanceMax, setBalanceMax] = React.useState("");

  React.useEffect(() => {
    setAmountMin(query?.amountMin?.[0] || "");
    setAmountMax(query?.amountMax?.[0] || "");
    setBalanceMin(query?.balanceMin?.[0] || "");
    setBalanceMax(query?.balanceMax?.[0] || "");
  }, [query]);

  const populateStatus = React.useMemo(() => {
    return statuses.map((status) => {
      const findCount = count?.find((count) => count.status === status) || { count: 0 };
  
      return (
        <List
          key={status}
          clicked={status === selected}
          onClick={() => {
            handleStatusClick(status);
          }}
        >
          <div>{tr(status)}</div>
          <div>{findCount.count}</div>
        </List>
      );
    });
  }, [statuses, count, selected, handleStatusClick, tr]);
  
  

  return (
    <Con className="border-r">
      <ListCategory>
        <p>
          {tr("Status")}
        </p>
        <p onClick={() => handleStatusClick("showAll")} style={{ color: "#FF4F00", cursor: "pointer" }}>
          {tr("Show All")}
        </p>     
      </ListCategory>
      {populateStatus}

    <p style={{ textAlign: "center" }}>
      {tr("Amount Range")}
    </p>

    {/* Min and Max fields */}
    <AmountRange>
      <Field
        backgroundColorFocus="white"
        placeholder={tr("Min")}
        // className={errState ? styles.inputFailed : styles.inputNormal}
        value={amountMin}
        setValue={setAmountMin}
        // onBlur={}
        style={{ width: "5rem" }}
        advancedNumericalInputHandling
        allowLeaders
      />
      <p style={{ margin: "0 0.5rem", fontSize: "1.2rem", textAlign: "center" }}>-</p>
      <Field
        backgroundColorFocus="white"
        placeholder={tr("Max")}
        // className={errState ? styles.inputFailed : styles.inputNormal}
        value={amountMax}
        setValue={setAmountMax}
        style={{ width: "5rem" }}
        // onBlur={}
        advancedNumericalInputHandling
        allowLeaders
      />
    </AmountRange>

    <p style={{ textAlign: "center" }}>
      {tr("Balance Range")}
    </p>

    <BalanceRange>
      <Field
        backgroundColorFocus="white"
        placeholder={tr("Min")}
        // className={errState ? styles.inputFailed : styles.inputNormal}
        value={balanceMin}
        setValue={setBalanceMin}
        // onBlur={}
        style={{ width: "5rem" }}
        advancedNumericalInputHandling
        allowLeaders
      />
      <p style={{ margin: "0 0.5rem", fontSize: "1.2rem", textAlign: "center" }}>-</p>
      <Field
        backgroundColorFocus="white"
        placeholder={tr("Max")}
        // className={errState ? styles.inputFailed : styles.inputNormal}
        value={balanceMax}
        setValue={setBalanceMax}
        style={{ width: "5rem" }}
        // onBlur={}
        advancedNumericalInputHandling
        allowLeaders
      />
    </BalanceRange>

    {/* button to apply ranges */}

    <Button 
      style={{ margin: "1rem auto" }}
      onClick={() => {
        search.update({ ...search.search, ...{ amountMin, amountMax, balanceMin, balanceMax}, page: 1 });
      }}
      primary
    >
      {tr("Apply Ranges")}
    </Button>

    </Con>
  );
}

export default React.memo(PaymentFilter);
