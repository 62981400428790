import { AxiosError, AxiosResponse } from "axios";
import { businessClient } from "./api";

export const getInventoryItems = async (
  categoryId: number | string | null | undefined
) => {
  if (categoryId) {
    const onSuccess = (response: AxiosResponse) => {
      return response.data.items;
    };

    const onError = (error: AxiosError) => {
      return console.error(error);
    };

    //! Warning: this will get only those items NOT linked to a vendor, for the VendorItemLinkModalBody
    return businessClient({
      method: "GET",
      url: "/item",
      params: { category: categoryId, type: "ALLITEMS", linkedItemModal: true },
    })
      .then(onSuccess)
      .catch(onError);
  } else {
    return null;
  }
};

export const getInventoryCategories = async () => {
  const onSuccess = (response: AxiosResponse) => {
    return response.data;
  };

  const onError = (error: AxiosError) => {
    return console.error(error);
  };

  return businessClient({
    method: "GET",
    url: "/inventory/category",
  })
    .then(onSuccess)
    .catch(onError);
};
