import React, { useState } from "react";
import axios from "axios";
import { useMutation } from "react-query";
import { Input } from "components/input";
import { Button } from "components/button";

const StripeAPICalls = () => {
    const [email, setEmail] = useState("");
    const [accountId, setAccountId] = useState("");

    const [userId, setUserId] = useState("");
    const [companyId, setCompanyId] = useState("");

    const updateAccountEmail = useMutation(
        async(email) => {
            await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/stripe/update-stripe-account-email`,
                {
                    email: email,
                    account_id: accountId,
                }
            ).then((res) => {
                setEmail("");
                setAccountId("");
                console.log(res);
            }).catch((err) => {
                console.log(err);
            })
    });

    const provisionAccount = useMutation(
        async() => {
            await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/provisionAccount`,
                {
                    userId: userId,
                    companyId: companyId
                }
            ).then((res) => {
                console.log(res);
            }).catch((err) => {
                console.log(err);
            })
    });

    const deleteStripeAccount = useMutation(
        async() => {
            await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/stripe/delete-stripe-account`,
                {
                    account_id: accountId,
                }
            ).then((res) => {
                setEmail("");
                setAccountId("");
                console.log(res);
            }).catch((err) => {
                console.log(err);
            })
    });



    return (
        <div>
            <h1>Stripe API Calls</h1>

            <Input
                type="text"
                placeholder="Enter new email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
            />

            <Input
                type="text"
                placeholder="Enter account id"
                onChange={(e) => setAccountId(e.target.value)}
                value={accountId}
            />

            <Button
                onClick={() => {
                    updateAccountEmail.mutate(email);
                }}
            > 
                Update Account Email
            </Button>

            <div style={{ marginTop: "20px" }}>
                {/* make axios call to ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/provisionAccount */}


                <Input
                    type="text"
                    placeholder="Enter user id"
                    onChange={(e) => setUserId(e.target.value)}
                    value={userId}
                />

                <Input
                    type="text"
                    placeholder="Enter company id"
                    onChange={(e) => setCompanyId(e.target.value)}
                    value={companyId}
                />

                
                <Button
                    onClick={() => {
                        provisionAccount.mutate();
                    }}
                >
                    Provision Account
                </Button>
            </div>

            <div>
                {/* delete stripe account */}
                <Input
                    type="text"
                    placeholder="Enter account id"
                    onChange={(e) => setAccountId(e.target.value)}
                    value={accountId}
                />

                <Button
                    onClick={() => {
                        deleteStripeAccount.mutate();
                    }}
                >
                    Delete Stripe Account
                </Button>


            </div>
            
        </div>
    );
}

export default StripeAPICalls;