/* Shape of the data

[
    {
        "assignee": "Bodi  Krmpotic",
        "id": "234",
        "data": []
    },
    {
        "assignee": "Commercial  Service",
        "id": "89",
        "data": []
    },
    {
        "assignee": "Ernesto Valdovinos",
        "id": "75",
        "data": [
            [
                {
                    "id": 2650,
                    "value": 75,
                    "duration": 540,
                    "start_time": null,
                    "end_time": null,
                    "start_time_utc": "2024-12-12T14:00:00+00:00",
                    "end_time_utc": "2024-12-12T23:00:00+00:00",
                    "block_type": "block",
                    "status": null,
                    "appointment_category": null,
                    "type": null,
                    "name": "WORK IN SHOP",
                    "note": null,
                    "address_obj": null,
                    "latitude": null,
                    "longitude": null,
                    "attention": false,
                    "start": "2024-12-12T14:00:00.000Z",
                    "end": "2024-12-12T23:00:00.000Z"
                }
            ]
        ]
    },
    {
        "assignee": "Ezequiel Zuniga",
        "id": "462",
        "data": []
    },
    {
        "assignee": "Gary Lange",
        "id": "83",
        "data": [
            [
                {
                    "id": 65586,
                    "value": 83,
                    "duration": 60,
                    "start_time": null,
                    "end_time": null,
                    "start_time_utc": "2024-12-12T13:00:00+00:00",
                    "end_time_utc": "2024-12-12T13:00:00+00:00",
                    "block_type": "appointment",
                    "status": {
                        "id": 10,
                        "label": "Scheduled",
                        "color": "142,68,173",
                        "type": "appointment",
                        "order": 3
                    },
                    "appointment_category": "Flagging",
                    "type": "estimate",
                    "name": "Woodland Homes ",
                    "note": "FLAGGING (Sprinkler heads out 3 feet all the way around the house.)",
                    "address_obj": {
                        "id": 149892,
                        "fk_bcustomer_id": 209507,
                        "address": "19055 Camelback Ave",
                        "city": "Omaha",
                        "state": "NE",
                        "zipcode": "68136",
                        "imported": null,
                        "name": "",
                        "fk_billed_customer_id": 66769,
                        "fk_business_id": 1,
                        "fk_customer_address_id": null,
                        "default_tax": null,
                        "latitude": 41.15755,
                        "longitude": -96.212656,
                        "created_at": "2024-04-09T21:05:09.926486+00:00",
                        "updated_at": null,
                        "import_id": null,
                        "is_standardized_address": false,
                        "full_address": "19055 Camelback Ave, Omaha, NE 68136"
                    },
                    "latitude": 41.15755,
                    "longitude": -96.212656,
                    "attention": false,
                    "start": "2024-12-12T13:00:00.000Z",
                    "end": "2024-12-12T14:00:00.000Z"
                },
                {
                    "id": 2644,
                    "value": 83,
                    "duration": 45,
                    "start_time": null,
                    "end_time": null,
                    "start_time_utc": "2024-12-12T14:15:00+00:00",
                    "end_time_utc": "2024-12-12T15:00:00+00:00",
                    "block_type": "block",
                    "status": null,
                    "appointment_category": null,
                    "type": null,
                    "name": "Pick up Popcorn from Just Pop'd 9839 S 96th street ",
                    "note": null,
                    "address_obj": null,
                    "latitude": null,
                    "longitude": null,
                    "attention": false,
                    "start": "2024-12-12T14:15:00.000Z",
                    "end": "2024-12-12T15:00:00.000Z"
                },
                {
                    "id": 65531,
                    "value": 83,
                    "duration": 60,
                    "start_time": null,
                    "end_time": null,
                    "start_time_utc": "2024-12-12T16:00:00+00:00",
                    "end_time_utc": "2024-12-12T16:00:00+00:00",
                    "block_type": "appointment",
                    "status": {
                        "id": 14,
                        "label": "Completed",
                        "color": "19,141,117",
                        "type": "appointment",
                        "order": 8
                    },
                    "appointment_category": "Flagging",
                    "type": "estimate",
                    "name": "Edward Bedel Construction ",
                    "note": "FLAGGING (Meet everyone out there at 10am) ",
                    "address_obj": {
                        "id": 154701,
                        "fk_bcustomer_id": 20052,
                        "address": "23608 Hampton Road",
                        "city": "Elkhorn",
                        "state": "NE",
                        "zipcode": "68022",
                        "imported": null,
                        "name": "",
                        "fk_billed_customer_id": 20052,
                        "fk_business_id": 1,
                        "fk_customer_address_id": null,
                        "default_tax": null,
                        "latitude": 41.208645,
                        "longitude": -96.285999,
                        "created_at": "2024-09-26T20:55:09.393924+00:00",
                        "updated_at": null,
                        "import_id": null,
                        "is_standardized_address": false,
                        "full_address": "23608 Hampton Road, Elkhorn, NE 68022"
                    },
                    "latitude": 41.208645,
                    "longitude": -96.285999,
                    "attention": false,
                    "start": "2024-12-12T16:00:00.000Z",
                    "end": "2024-12-12T17:00:00.000Z"
                }
            ],
            [
                {
                    "id": 65593,
                    "value": 83,
                    "duration": 60,
                    "start_time": null,
                    "end_time": null,
                    "start_time_utc": "2024-12-12T13:00:00+00:00",
                    "end_time_utc": "2024-12-12T13:00:00+00:00",
                    "block_type": "appointment",
                    "status": {
                        "id": 10,
                        "label": "Scheduled",
                        "color": "142,68,173",
                        "type": "appointment",
                        "order": 3
                    },
                    "appointment_category": "Flagging",
                    "type": "estimate",
                    "name": "Richland Homes ",
                    "note": "FLAGGING ",
                    "address_obj": {
                        "id": 151908,
                        "fk_bcustomer_id": 66660,
                        "address": "10426 S 109th St",
                        "city": "Papillion ",
                        "state": "NE",
                        "zipcode": "68046",
                        "imported": null,
                        "name": "",
                        "fk_billed_customer_id": 66660,
                        "fk_business_id": 1,
                        "fk_customer_address_id": null,
                        "default_tax": null,
                        "latitude": 41.156629,
                        "longitude": -96.081349,
                        "created_at": "2024-07-12T20:33:13.008508+00:00",
                        "updated_at": null,
                        "import_id": null,
                        "is_standardized_address": false,
                        "full_address": "10426 S 109th St, Papillion , NE 68046"
                    },
                    "latitude": 41.156629,
                    "longitude": -96.081349,
                    "attention": false,
                    "start": "2024-12-12T13:00:00.000Z",
                    "end": "2024-12-12T14:00:00.000Z"
                }
            ],
            [
                {
                    "id": 65624,
                    "value": 83,
                    "duration": 60,
                    "start_time": null,
                    "end_time": null,
                    "start_time_utc": "2024-12-12T13:00:00+00:00",
                    "end_time_utc": "2024-12-12T13:00:00+00:00",
                    "block_type": "appointment",
                    "status": {
                        "id": 10,
                        "label": "Scheduled",
                        "color": "142,68,173",
                        "type": "appointment",
                        "order": 3
                    },
                    "appointment_category": "Estimate",
                    "type": "estimate",
                    "name": "Welcome Homes",
                    "note": "THE BUILDER WOULD LIKE AN ESTIMATE ON PROPERTY",
                    "address_obj": {
                        "id": 167932,
                        "fk_bcustomer_id": 209504,
                        "address": "Lot 267 Whispering Oaks ",
                        "city": "Council Bluffs",
                        "state": "IA",
                        "zipcode": "51503",
                        "imported": null,
                        "name": "",
                        "fk_billed_customer_id": 209504,
                        "fk_business_id": 1,
                        "fk_customer_address_id": null,
                        "default_tax": null,
                        "latitude": 41.257009,
                        "longitude": -95.85126,
                        "created_at": "2024-12-12T13:39:30.405538+00:00",
                        "updated_at": null,
                        "import_id": null,
                        "is_standardized_address": false,
                        "full_address": "Lot 267 Whispering Oaks , Council Bluffs, IA 51503"
                    },
                    "latitude": 41.257009,
                    "longitude": -95.85126,
                    "attention": false,
                    "start": "2024-12-12T13:00:00.000Z",
                    "end": "2024-12-12T14:00:00.000Z"
                }
            ]
        ]
    },
    {
        "assignee": "Gavin Dolbee",
        "id": "133",
        "data": []
    },
    {
        "assignee": "Ian Stanley",
        "id": "531",
        "data": []
    },
    {
        "assignee": "Ian Stanley",
        "id": "81",
        "data": []
    },
    {
        "assignee": "Jamie Wolff",
        "id": "17",
        "data": []
    },
    {
        "assignee": "Jorge Hernandez",
        "id": "92",
        "data": []
    },
    {
        "assignee": "Justin Neville",
        "id": "545",
        "data": []
    },
    {
        "assignee": "Mario Ibarra",
        "id": "80",
        "data": []
    },
    {
        "assignee": "New  Sod",
        "id": "226",
        "data": []
    },
    {
        "assignee": "Person Admin",
        "id": "5",
        "data": []
    },
    {
        "assignee": "Plan R",
        "id": "216",
        "data": []
    },
    {
        "assignee": "Quinn Swalberg",
        "id": "73",
        "data": [
            [
                {
                    "id": 2651,
                    "value": 73,
                    "duration": -180,
                    "start_time": null,
                    "end_time": null,
                    "start_time_utc": "2024-12-12T14:00:00+00:00",
                    "end_time_utc": "2024-12-12T11:00:00+00:00",
                    "block_type": "block",
                    "status": null,
                    "appointment_category": null,
                    "type": null,
                    "name": "QUINN WORK IN SHOP",
                    "note": null,
                    "address_obj": null,
                    "latitude": null,
                    "longitude": null,
                    "attention": false,
                    "start": "2024-12-12T14:00:00.000Z",
                    "end": "2024-12-12T11:00:00.000Z"
                }
            ]
        ]
    },
    {
        "assignee": "Service Small Repairs",
        "id": "596",
        "data": []
    },
    {
        "assignee": "Seth Kerrey",
        "id": "407",
        "data": []
    },
    {
        "assignee": "Terell Turner",
        "id": "155",
        "data": []
    },
    {
        "assignee": "Terry Hulsey",
        "id": "72",
        "data": [
            [
                {
                    "id": 2652,
                    "value": 72,
                    "duration": 540,
                    "start_time": null,
                    "end_time": null,
                    "start_time_utc": "2024-12-12T14:00:00+00:00",
                    "end_time_utc": "2024-12-12T23:00:00+00:00",
                    "block_type": "block",
                    "status": null,
                    "appointment_category": null,
                    "type": null,
                    "name": "TERRYW WORK IN SHOP",
                    "note": null,
                    "address_obj": null,
                    "latitude": null,
                    "longitude": null,
                    "attention": false,
                    "start": "2024-12-12T14:00:00.000Z",
                    "end": "2024-12-12T23:00:00.000Z"
                }
            ]
        ]
    },
    {
        "assignee": "Tim Welch",
        "id": "85",
        "data": []
    },
    {
        "assignee": "Todd Rannals",
        "id": "119",
        "data": []
    },
    {
        "assignee": "Todd Swalberg",
        "id": "100",
        "data": [
            [
                {
                    "id": 65625,
                    "value": 100,
                    "duration": 60,
                    "start_time": null,
                    "end_time": null,
                    "start_time_utc": "2024-12-12T13:00:00+00:00",
                    "end_time_utc": "2024-12-12T13:00:00+00:00",
                    "block_type": "appointment",
                    "status": {
                        "id": 10,
                        "label": "Scheduled",
                        "color": "142,68,173",
                        "type": "appointment",
                        "order": 3
                    },
                    "appointment_category": "Flagging",
                    "type": "estimate",
                    "name": "Tackett Homes ",
                    "note": "FLAGGING ",
                    "address_obj": {
                        "id": 115322,
                        "fk_bcustomer_id": 195749,
                        "address": "1717 S 151st Ave ",
                        "city": "Omaha",
                        "state": "NE",
                        "zipcode": "68144",
                        "imported": null,
                        "name": "Builder",
                        "fk_billed_customer_id": 66722,
                        "fk_business_id": 1,
                        "fk_customer_address_id": null,
                        "default_tax": null,
                        "latitude": null,
                        "longitude": null,
                        "created_at": "2023-12-04T15:30:34.747506+00:00",
                        "updated_at": null,
                        "import_id": null,
                        "is_standardized_address": false,
                        "full_address": "1717 S 151st Ave , Omaha, NE 68144"
                    },
                    "latitude": null,
                    "longitude": null,
                    "attention": false,
                    "start": "2024-12-12T13:00:00.000Z",
                    "end": "2024-12-12T14:00:00.000Z"
                },
                {
                    "id": 65595,
                    "value": 100,
                    "duration": 60,
                    "start_time": null,
                    "end_time": null,
                    "start_time_utc": "2024-12-12T14:00:00+00:00",
                    "end_time_utc": "2024-12-12T19:00:00+00:00",
                    "block_type": "appointment",
                    "status": {
                        "id": 10,
                        "label": "Scheduled",
                        "color": "142,68,173",
                        "type": "appointment",
                        "order": 3
                    },
                    "appointment_category": null,
                    "type": "job",
                    "name": "Curt Hofer Construction",
                    "note": "AR CALL total due $3,422.95",
                    "address_obj": {
                        "id": 152640,
                        "fk_bcustomer_id": 107658,
                        "address": "17608 Erskine St",
                        "city": "Omaha",
                        "state": "NE",
                        "zipcode": "68116",
                        "imported": null,
                        "name": "Becky Bartak",
                        "fk_billed_customer_id": 107658,
                        "fk_business_id": 1,
                        "fk_customer_address_id": null,
                        "default_tax": null,
                        "latitude": 41.280956,
                        "longitude": -96.189953,
                        "created_at": "2024-08-19T18:02:06.421479+00:00",
                        "updated_at": null,
                        "import_id": null,
                        "is_standardized_address": false,
                        "full_address": "17608 Erskine St, Omaha, NE 68116"
                    },
                    "latitude": 41.280956,
                    "longitude": -96.189953,
                    "attention": false,
                    "start": "2024-12-12T14:00:00.000Z",
                    "end": "2024-12-12T15:00:00.000Z"
                }
            ],
            [
                {
                    "id": 65598,
                    "value": 100,
                    "duration": 60,
                    "start_time": null,
                    "end_time": null,
                    "start_time_utc": "2024-12-12T14:00:00+00:00",
                    "end_time_utc": "2024-12-12T19:00:00+00:00",
                    "block_type": "appointment",
                    "status": {
                        "id": 10,
                        "label": "Scheduled",
                        "color": "142,68,173",
                        "type": "appointment",
                        "order": 3
                    },
                    "appointment_category": null,
                    "type": "job",
                    "name": "Roberts Nursery Lawn & Landscaping",
                    "note": "AR CALL total $290",
                    "address_obj": {
                        "id": 153293,
                        "fk_bcustomer_id": 26452,
                        "address": "19706 Hansen Avenue",
                        "city": "Omaha",
                        "state": "NE",
                        "zipcode": "68130",
                        "imported": null,
                        "name": "",
                        "fk_billed_customer_id": 1519,
                        "fk_business_id": 1,
                        "fk_customer_address_id": null,
                        "default_tax": null,
                        "latitude": 41.225131,
                        "longitude": -96.226328,
                        "created_at": "2024-09-16T12:45:37.115777+00:00",
                        "updated_at": null,
                        "import_id": null,
                        "is_standardized_address": false,
                        "full_address": "19706 Hansen Avenue, Omaha, NE 68130"
                    },
                    "latitude": 41.225131,
                    "longitude": -96.226328,
                    "attention": false,
                    "start": "2024-12-12T14:00:00.000Z",
                    "end": "2024-12-12T15:00:00.000Z"
                }
            ],
            [
                {
                    "id": 65603,
                    "value": 100,
                    "duration": 60,
                    "start_time": null,
                    "end_time": null,
                    "start_time_utc": "2024-12-12T14:00:00+00:00",
                    "end_time_utc": "2024-12-12T19:00:00+00:00",
                    "block_type": "appointment",
                    "status": {
                        "id": 10,
                        "label": "Scheduled",
                        "color": "142,68,173",
                        "type": "appointment",
                        "order": 3
                    },
                    "appointment_category": null,
                    "type": "job",
                    "name": "Kinnanscaping ",
                    "note": "AR CALL-TOTAL DUE $2,626.66",
                    "address_obj": {
                        "id": 4502,
                        "fk_bcustomer_id": 20058,
                        "address": "P.O. Box 250",
                        "city": "Gretna",
                        "state": "NE",
                        "zipcode": "68028",
                        "imported": true,
                        "name": null,
                        "fk_billed_customer_id": 20058,
                        "fk_business_id": 1,
                        "fk_customer_address_id": null,
                        "default_tax": null,
                        "latitude": null,
                        "longitude": null,
                        "created_at": "2023-02-04T20:12:42.773502+00:00",
                        "updated_at": null,
                        "import_id": null,
                        "is_standardized_address": false,
                        "full_address": "P.O. Box 250, Gretna, NE 68028"
                    },
                    "latitude": null,
                    "longitude": null,
                    "attention": false,
                    "start": "2024-12-12T14:00:00.000Z",
                    "end": "2024-12-12T15:00:00.000Z"
                }
            ],
            [
                {
                    "id": 65605,
                    "value": 100,
                    "duration": 60,
                    "start_time": null,
                    "end_time": null,
                    "start_time_utc": "2024-12-12T14:00:00+00:00",
                    "end_time_utc": "2024-12-12T19:00:00+00:00",
                    "block_type": "appointment",
                    "status": {
                        "id": 10,
                        "label": "Scheduled",
                        "color": "142,68,173",
                        "type": "appointment",
                        "order": 3
                    },
                    "appointment_category": null,
                    "type": "job",
                    "name": "Silverstone Custom Homes",
                    "note": "AR CALL -Total due $5,293.00",
                    "address_obj": {
                        "id": 150203,
                        "fk_bcustomer_id": 66696,
                        "address": "20632 Woodridge Dr",
                        "city": "Gretna",
                        "state": "NE",
                        "zipcode": "68028",
                        "imported": null,
                        "name": "",
                        "fk_billed_customer_id": 66696,
                        "fk_business_id": 1,
                        "fk_customer_address_id": null,
                        "default_tax": null,
                        "latitude": 41.12852,
                        "longitude": -96.23908,
                        "created_at": "2024-04-22T18:29:00.321922+00:00",
                        "updated_at": null,
                        "import_id": null,
                        "is_standardized_address": false,
                        "full_address": "20632 Woodridge Dr, Gretna, NE 68028"
                    },
                    "latitude": 41.12852,
                    "longitude": -96.23908,
                    "attention": false,
                    "start": "2024-12-12T14:00:00.000Z",
                    "end": "2024-12-12T15:00:00.000Z"
                }
            ],
            [
                {
                    "id": 65602,
                    "value": 100,
                    "duration": 60,
                    "start_time": null,
                    "end_time": null,
                    "start_time_utc": "2024-12-12T14:00:00+00:00",
                    "end_time_utc": "2024-12-12T19:00:00+00:00",
                    "block_type": "appointment",
                    "status": {
                        "id": 10,
                        "label": "Scheduled",
                        "color": "142,68,173",
                        "type": "appointment",
                        "order": 3
                    },
                    "appointment_category": null,
                    "type": "job",
                    "name": "Tackett Homes ",
                    "note": "AR CALL-total due $4,570.00",
                    "address_obj": {
                        "id": 152426,
                        "fk_bcustomer_id": 66722,
                        "address": "5512 North 290th Circle",
                        "city": "Valley",
                        "state": "NE",
                        "zipcode": "68064",
                        "imported": null,
                        "name": "",
                        "fk_billed_customer_id": 66722,
                        "fk_business_id": 1,
                        "fk_customer_address_id": null,
                        "default_tax": null,
                        "latitude": 41.308574,
                        "longitude": -96.37158,
                        "created_at": "2024-08-06T20:50:12.699289+00:00",
                        "updated_at": null,
                        "import_id": null,
                        "is_standardized_address": true,
                        "full_address": "5512 North 290th Circle, Valley, NE 68064"
                    },
                    "latitude": 41.308574,
                    "longitude": -96.37158,
                    "attention": false,
                    "start": "2024-12-12T14:00:00.000Z",
                    "end": "2024-12-12T15:00:00.000Z"
                }
            ],
            [
                {
                    "id": 65600,
                    "value": 100,
                    "duration": 60,
                    "start_time": null,
                    "end_time": null,
                    "start_time_utc": "2024-12-12T14:00:00+00:00",
                    "end_time_utc": "2024-12-12T19:00:00+00:00",
                    "block_type": "appointment",
                    "status": {
                        "id": 10,
                        "label": "Scheduled",
                        "color": "142,68,173",
                        "type": "appointment",
                        "order": 3
                    },
                    "appointment_category": null,
                    "type": "job",
                    "name": "Jensen Gardens",
                    "note": "AR CALL Total Due $394.94",
                    "address_obj": {
                        "id": 154676,
                        "fk_bcustomer_id": 157442,
                        "address": "5823 South 119th Plaza",
                        "city": "Omaha",
                        "state": "NE",
                        "zipcode": "68137",
                        "imported": null,
                        "name": "",
                        "fk_billed_customer_id": 157442,
                        "fk_business_id": 1,
                        "fk_customer_address_id": null,
                        "default_tax": null,
                        "latitude": 41.199435,
                        "longitude": -96.098665,
                        "created_at": "2024-09-26T13:22:55.290757+00:00",
                        "updated_at": null,
                        "import_id": null,
                        "is_standardized_address": false,
                        "full_address": "5823 South 119th Plaza, Omaha, NE 68137"
                    },
                    "latitude": 41.199435,
                    "longitude": -96.098665,
                    "attention": false,
                    "start": "2024-12-12T14:00:00.000Z",
                    "end": "2024-12-12T15:00:00.000Z"
                }
            ],
            [
                {
                    "id": 65596,
                    "value": 100,
                    "duration": 60,
                    "start_time": null,
                    "end_time": null,
                    "start_time_utc": "2024-12-12T14:00:00+00:00",
                    "end_time_utc": "2024-12-12T19:00:00+00:00",
                    "block_type": "appointment",
                    "status": {
                        "id": 14,
                        "label": "Completed",
                        "color": "19,141,117",
                        "type": "appointment",
                        "order": 8
                    },
                    "appointment_category": null,
                    "type": "job",
                    "name": "Executive Outdoor Living",
                    "note": "AR CALL TOTAL  DUE $13,457.00",
                    "address_obj": {
                        "id": 48181,
                        "fk_bcustomer_id": 66300,
                        "address": "P.O. Box 34688",
                        "city": "Omaha",
                        "state": "NE",
                        "zipcode": "68134",
                        "imported": true,
                        "name": "Billing",
                        "fk_billed_customer_id": 66300,
                        "fk_business_id": 1,
                        "fk_customer_address_id": null,
                        "default_tax": null,
                        "latitude": 41.224694882367345,
                        "longitude": -96.08714580847688,
                        "created_at": "2023-02-04T20:12:42.773502+00:00",
                        "updated_at": null,
                        "import_id": null,
                        "is_standardized_address": false,
                        "full_address": "P.O. Box 34688, Omaha, NE 68134"
                    },
                    "latitude": 41.224694882367345,
                    "longitude": -96.08714580847688,
                    "attention": false,
                    "start": "2024-12-12T14:00:00.000Z",
                    "end": "2024-12-12T15:00:00.000Z"
                }
            ],
            [
                {
                    "id": 65601,
                    "value": 100,
                    "duration": 60,
                    "start_time": null,
                    "end_time": null,
                    "start_time_utc": "2024-12-12T14:00:00+00:00",
                    "end_time_utc": "2024-12-12T19:00:00+00:00",
                    "block_type": "appointment",
                    "status": {
                        "id": 10,
                        "label": "Scheduled",
                        "color": "142,68,173",
                        "type": "appointment",
                        "order": 3
                    },
                    "appointment_category": null,
                    "type": "job",
                    "name": "Style Craft Homes",
                    "note": "AR CALL TOTAL DUE $4,188.79",
                    "address_obj": {
                        "id": 155317,
                        "fk_bcustomer_id": 20066,
                        "address": "5100 South 82nd Street",
                        "city": "Omaha",
                        "state": "NE",
                        "zipcode": "68127",
                        "imported": null,
                        "name": "",
                        "fk_billed_customer_id": 20066,
                        "fk_business_id": 1,
                        "fk_customer_address_id": null,
                        "default_tax": null,
                        "latitude": 41.20686,
                        "longitude": -96.041138,
                        "created_at": "2024-10-03T14:01:28.650156+00:00",
                        "updated_at": null,
                        "import_id": null,
                        "is_standardized_address": false,
                        "full_address": "5100 South 82nd Street, Omaha, NE 68127"
                    },
                    "latitude": 41.20686,
                    "longitude": -96.041138,
                    "attention": false,
                    "start": "2024-12-12T14:00:00.000Z",
                    "end": "2024-12-12T15:00:00.000Z"
                }
            ],
            [
                {
                    "id": 65604,
                    "value": 100,
                    "duration": 60,
                    "start_time": null,
                    "end_time": null,
                    "start_time_utc": "2024-12-12T14:00:00+00:00",
                    "end_time_utc": "2024-12-12T19:00:00+00:00",
                    "block_type": "appointment",
                    "status": {
                        "id": 10,
                        "label": "Scheduled",
                        "color": "142,68,173",
                        "type": "appointment",
                        "order": 3
                    },
                    "appointment_category": null,
                    "type": "job",
                    "name": "Mike Agostino",
                    "note": "AR CALL",
                    "address_obj": {
                        "id": 167372,
                        "fk_bcustomer_id": 209163,
                        "address": "4109 South 184th Street",
                        "city": "Omaha",
                        "state": "NE",
                        "zipcode": "68135",
                        "imported": null,
                        "name": "",
                        "fk_billed_customer_id": 209163,
                        "fk_business_id": 1,
                        "fk_customer_address_id": null,
                        "default_tax": null,
                        "latitude": 41.21858,
                        "longitude": -96.20247,
                        "created_at": "2024-11-05T16:23:24.07915+00:00",
                        "updated_at": null,
                        "import_id": null,
                        "is_standardized_address": true,
                        "full_address": "4109 South 184th Street, Omaha, NE 68135"
                    },
                    "latitude": 41.21858,
                    "longitude": -96.20247,
                    "attention": false,
                    "start": "2024-12-12T14:00:00.000Z",
                    "end": "2024-12-12T15:00:00.000Z"
                }
            ]
        ]
    },
    {
        "assignee": "Uriel Torres",
        "id": "86",
        "data": [
            [
                {
                    "id": 65364,
                    "value": 86,
                    "duration": 210,
                    "start_time": null,
                    "end_time": null,
                    "start_time_utc": "2024-12-12T14:00:00+00:00",
                    "end_time_utc": "2024-12-12T18:00:00+00:00",
                    "block_type": "appointment",
                    "status": {
                        "id": 13,
                        "label": "In Progress",
                        "color": "36,113,163",
                        "type": "appointment",
                        "order": 5
                    },
                    "appointment_category": null,
                    "type": "job",
                    "name": "Michael Manzer",
                    "note": "NEW SYSTEM INSTALL ",
                    "address_obj": {
                        "id": 167858,
                        "fk_bcustomer_id": 209482,
                        "address": "5167 S 147th Cir",
                        "city": "Omaha",
                        "state": "NE",
                        "zipcode": "68137",
                        "imported": null,
                        "name": "",
                        "fk_billed_customer_id": 209482,
                        "fk_business_id": 1,
                        "fk_customer_address_id": null,
                        "default_tax": null,
                        "latitude": 41.20617,
                        "longitude": -96.143579,
                        "created_at": "2024-12-03T22:07:54.676168+00:00",
                        "updated_at": null,
                        "import_id": null,
                        "is_standardized_address": false,
                        "full_address": "5167 S 147th Cir, Omaha, NE 68137"
                    },
                    "latitude": 41.20617,
                    "longitude": -96.143579,
                    "attention": false,
                    "start": "2024-12-12T14:00:00.000Z",
                    "end": "2024-12-12T17:30:00.000Z"
                }
            ]
        ]
    },
    {
        "assignee": "Wyatt Pratt",
        "id": "101",
        "data": [
            [
                {
                    "id": 2649,
                    "value": 101,
                    "duration": 540,
                    "start_time": null,
                    "end_time": null,
                    "start_time_utc": "2024-12-12T14:00:00+00:00",
                    "end_time_utc": "2024-12-12T23:00:00+00:00",
                    "block_type": "block",
                    "status": null,
                    "appointment_category": null,
                    "type": null,
                    "name": "WYATT WORK ON BIDS",
                    "note": null,
                    "address_obj": null,
                    "latitude": null,
                    "longitude": null,
                    "attention": false,
                    "start": "2024-12-12T14:00:00.000Z",
                    "end": "2024-12-12T23:00:00.000Z"
                }
            ]
        ]
    }
]

*/

import { ILocation } from "views/calendar/types/ILocation";

const extractLocationsDaily = (rows: any) => {
  // console.log("extractLocationsDaily", rows);

  const locations: ILocation[] = [];

  // push lat/long to array

  rows.forEach((row: any) => {
    // console.log("row", row.data);

    row.data.forEach((data: any) => {
      // console.log("data", data);

      data.forEach((item: any) => {
        // console.log("item", item);

        if (item.latitude && item.longitude) {
          locations.push({
            latitude: item.latitude,
            longitude: item.longitude,
          });
        }
      });
    });
  });

  console.log("locations", locations);

  return locations;
};

export default extractLocationsDaily;
