import * as React from "react";
import styled, { keyframes } from "styled-components";
import { RiLoader5Line } from "react-icons/ri";

type Props = {
  size?: number;
  color?: string;
  className?: string;
};
export default function Spinner({ size, className, color }: Props) {
  return (
    <SpinnerCon className={className}>
      <Loader {...{ size, color }} />
    </SpinnerCon>
  );
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled(RiLoader5Line)<{ size?: number }>`
  animation: ${rotate} 0.5s linear infinite;
  font-size: ${(props) => props.size || 16}px;
  color: ${(props) => props.color || "#000"};
`;

const SpinnerCon = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;
