import React, { useState } from "react";
import Assignee from "components/react-select/assignees";
import { Button } from "components/button";
import { Input } from "components/input";
import axios from "axios";
import { useModalContext } from "components/modal";
import { format } from "date-fns";
import { useSnackbarContext } from "components/snackbar";
import { useQueryClient } from "react-query"; 
import { useTranslation } from 'react-i18next';
import { convertFromUTC, convertToUTC } from "utils/utcConversion";
import { useAuthContext } from "contexts/auth";

const AddBlock = () => {
  const { t } = useTranslation();
  const tBase = "views.calendar.addBlock";
  const tr = (key) => t(`${tBase}.${key}`);
  const { timezone } = useAuthContext();
  
  const { setModal } = useModalContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [assignees, setAssignees] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [description, setDescription] = useState(null);
  const { showSnackbar } = useSnackbarContext();
  const queryClient = useQueryClient();

  const addTimeBlock = async (values) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/time_block/addTimeBlock`,
        { values }
      );

      if (response.status === 200) {
        setIsSubmitting(false);
        setModal(null);
        showSnackbar(tr("Successfully added time block."));

        // Invalidate and refetch appointment queries (this will update the calendar)
				await queryClient.invalidateQueries();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = () => {
    setErrorMessage("");
    if (
      assignees &&
      assignees.length > 0 &&
      startDate &&
      endDate &&
      startTime &&
      endTime &&
      description
    ) {
      setIsSubmitting(true);

      const values = {
        assignees,
        startDate,
        endDate,
        // startTime: format(new Date(startDate + " " + startTime), "HH:mmXXX"),
        // endTime: format(new Date(endDate + " " + endTime), "HH:mmXXX"),
        startTime: convertToUTC(startDate, startTime, timezone),
        endTime: convertToUTC(startDate, endTime, timezone),
        description,
      };

      addTimeBlock(values);
      showSnackbar(tr("Successfully added time block."));
    } else {
      setErrorMessage(tr("All fields are required."));
    }
  };

  const ifFilledOut = assignees && assignees.length > 0 && startDate && endDate && startTime && endTime && description;
  const backgroundColor = ifFilledOut ? '#ff4f00' : '';
  const textColor = "white";

  return (
    <div>
      <Assignee
        value={assignees}
        onChange={setAssignees}
        assigneeType="appointment"
        label={tr("Assignee")}
      />

      <Input
        value={startDate}
        onChange={(e) => {
          setStartDate(e.target.value);
        }}
        label={tr("Start Date")}
        max="9999-12-31"
        type="date"
      />

      <Input
        value={endDate}
        onChange={(e) => {
          setEndDate(e.target.value);
        }}
        label={tr("End Date")}
        max="9999-12-31"
        type="date"
      />

      <Input
        value={startTime}
        onChange={(e) => {
          setStartTime(e.target.value);
        }}
        label={tr("Start Time")}
        type="time"
      />

      <Input
        value={endTime}
        onChange={(e) => {
          setEndTime(e.target.value);
        }}
        label={tr("End Time")}
        type="time"
      />

      <Input
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
        label={tr("Description")}
        type="text"
      />

      <div
        className="flex flex-1 flex-row justify-end"
      >
        <div className="flex space-x-4">
          {errorMessage && (
            <div className="text-red-500 mt-5">{errorMessage}</div>
          )}
          <Button
            style={{ 
              marginTop: 10,
              backgroundColor: backgroundColor,
              color: textColor,
              }}
            primary
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {tr("Submit")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddBlock;