import React, { useEffect, useState } from "react";
import { Button, SubtleButton } from "components/button";
import Popper from "components/react-popper";
import TextArea from "components/textarea";
import styled from "styled-components";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { useAuthContext } from "contexts/auth";
import { convertFromUTC } from "utils/utcConversion";

function ReminderDialog(props) {
  const { t } = useTranslation();
  const tBase = "containers.appointmentCard.reminderDialog";
  const tr = (key) => t(`${tBase}.${key}`);
  const { timezone } = useAuthContext();

  const appointmentId = props.appointmentId;

  const [to, setTo] = useState("");
  const [phone, setPhone] = useState("");
  const [contacts, setContacts] = useState([]);
  const [details, setDetails] = useState({});
  const [body, setBody] = useState(``);

  const getAppointmentReminderDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/reminderDetails/${appointmentId}`
      );

      const startTime = response.data.details.start_time_utc
      ? convertFromUTC(response.data.details.start_time_utc, timezone)
      : response.data.details.start_time
      ? response.data.details.start_time.slice(0, 5)
      : "";

      setContacts(response.data.contacts);
      setDetails(response.data.details);
      setBody(
        `Reminder: Appointment with ${
          response.data.details.business
        } - ${new Date(
          response.data.details.date
        ).toLocaleDateString()} at ${startTime}. 
        - Sent by EServ`
      );
    } catch (error) {
      console.error(error);
    }
  };

  const sendText = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/sendReminder`,
        { phone, message: body }
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAppointmentReminderDetails();
  }, []);

  return (
    <div style={{ minWidth: 600 }}>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Popper clickable reference={<Button>{tr("To")}</Button>}>
            <MenuToolTip>
              {contacts &&
                contacts.length > 0 &&
                contacts?.map((contact) => {
                  return (
                    <SubtleButton
                      onClick={() => {
                        setTo(`${contact.name} (${contact.type})`);
                        setPhone(contact.phone);
                      }}
                      style={{ color: "black" }}
                    >
                      {contact.name} ({contact.type}): {contact.phone}
                    </SubtleButton>
                  );
                })}
            </MenuToolTip>
          </Popper>

          <div style={{ marginLeft: 20 }}>{to}</div>
        </div>

        <TextArea
          style={{ marginLeft: 20, alignSelf: "right" }}
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
          rows={1}
        />
      </div>

      <TextArea
        style={{ width: "100%" }}
        component="textarea"
        minRows={6}
        value={body}
        onChange={(e) => {
          setBody(e.target.value);
        }}
      />
      <Button
        style={{ marginTop: 5 }}
        onClick={() => {
          sendText();
        }}
      >
        {tr("Send")}
      </Button>
    </div>
  );
}

export default ReminderDialog;

const MenuToolTip = styled.div`
  min-width: 200px;
  display: flex;
  text-align: left;
  flex-direction: column;
`;