import React from "react";
import axios from "axios";
import { useMutation } from "react-query";
import Spinner from "components/spinner";
import "./styles.css";
import { useTranslation } from 'react-i18next';

const StripeInfo = () => {
  const [stripePayoutData, setStripePayoutData] = React.useState<object[]>([]);
  const [updatedPayoutData, setUpdatedPayoutData] = React.useState<object[]>(
    []
  );
  const [nullStripeId, setNullStripeId] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const tBase = "views.admin.stripe-information.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const getLink = useMutation(
    async () =>
      await axios
        .get(
          `
      ${process.env.REACT_APP_SERVER_URL}/api/v1/company/stripe/account_url
      `
        )
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        window.location.replace(data.url);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  React.useEffect(() => {
    setIsLoading(true);
    try {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/payments/stripe-information`
        )
        .then((res) => {
          setStripePayoutData(res.data);
          setIsLoading(false);
        });
    } catch (e) {
      console.error(e);
    }
  }, []);

  React.useEffect(() => {
    if (!stripePayoutData) {
      setNullStripeId(true);
    } else {
      setNullStripeId(false);
      stripePayoutData.map((record: any) => {
        setUpdatedPayoutData((prevState) => [
          ...prevState,
          {
            ...record,
            customShowButton: "btnShowButton_" + record.id,
            customHideButton: "btnHideButton_" + record.id,
            customTransactionDiv: "transactionDiv_" + record.id,
          },
        ]);
      });
    }
  }, [stripePayoutData]);

  // currency.format([amount]), from utils/currency, is what is typically used to format dollar amounts, however, it doesn't preserve the negative sign
  // nor does it divide the amount by 100, which is needed for amounts gotten from Stripe,
  // so I am using formatDollarAmount instead
  const formatDollarAmount = (amount: number) => {
    const formattedAmount = (amount / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    return formattedAmount;
  };

  const showTransactions = (
    transactionDiv: any,
    showButton: any,
    hideButton: any
  ) => {
    // TODO: devise a more efficient toggle
    let ele = document.getElementById(transactionDiv)!.style.display;

    if (ele === "none") {
      document.getElementById(transactionDiv)!.style.display = "block";
      document.getElementById(hideButton)!.style.display = "block";
      document.getElementById(showButton)!.style.display = "none";
    } else {
      document.getElementById(transactionDiv)!.style.display = "none";
      document.getElementById(hideButton)!.style.display = "none";
      document.getElementById(showButton)!.style.display = "block";
    }
  };

  let timer: any;

  const fadeInBubble = (message: any, location: any, elementId: any) => {
    clearTimeout(timer);

    const element = document.getElementById(elementId);

    if (element!.scrollWidth > element!.clientWidth) {
      let bubble = document.getElementById("descriptionBubble");
      bubble!.style.opacity = "1";

      let bodyLoc = document.body.getBoundingClientRect();

      bubble!.innerHTML = message;
      bubble!.style.display = "block";
      bubble!.style.top = location.clientY - bodyLoc.top + "px";
      bubble!.style.left = location.clientX - bodyLoc.left + "px";
    }
  };

  const fadeOutBubble = () => {
    document.getElementById("descriptionBubble")!.style.opacity = "0";

    timer = setTimeout(() => {
      let bubble = document.getElementById("descriptionBubble");
      bubble!.style.display = "none";
    }, 2000);
  };

  let lightGrey = false;

  const getBalanceTransactionData = (data: any, type: any) => {
    let stringAmt = null;
    let numberAmt = null;

    lightGrey = false;
    if (data) {
      let arr = [];
      let total: number = 0;
      for (let t = 0; t < data.length; t++) {
        switch (type) {
          case "amount":
            stringAmt = formatDollarAmount(data[t].amount);
            numberAmt = data[t].amount;
            break;
          case "fee":
            stringAmt = formatDollarAmount(data[t].fee);
            numberAmt = data[t].fee;
            break;
          case "net":
            stringAmt = formatDollarAmount(data[t].net);
            numberAmt = data[t].net;
            break;
          case "type":
            stringAmt = data[t].type;
            break;
          case "date":
            stringAmt = new Date(data[t].created * 1000).toLocaleDateString();
            break;
          case "minus":
            stringAmt = "-";
            break;
          case "equal":
            stringAmt = "=";
            break;
        }

        if (type !== "description") {
          if (lightGrey) {
            lightGrey = false;
            arr.push(
              <p style={{ fontSize: "15px", backgroundColor: "#e6e6e6" }}>
                {stringAmt}
              </p>
            );
          } else {
            lightGrey = true;
            arr.push(
              <p style={{ fontSize: "15px", backgroundColor: "#cacaca" }}>
                {stringAmt}
              </p>
            );
          }
        } else {
          if (data[t].description === null) {
            if (lightGrey) {
              lightGrey = false;
              arr.push(
                <p
                  id={data[t].id}
                  style={{
                    fontSize: "15px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    backgroundColor: "#e6e6e6",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                >
                  {tr("None")}
                </p>
              );
            } else {
              lightGrey = true;
              arr.push(
                <p
                  id={data[t].id}
                  style={{
                    fontSize: "15px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    backgroundColor: "#cacaca",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                >
                  {tr("None")}
                </p>
              );
            }
          } else {
            if (lightGrey) {
              lightGrey = false;
              arr.push(
                <p
                  id={data[t].id}
                  style={{
                    fontSize: "15px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    backgroundColor: "#e6e6e6",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                  onMouseOver={(e) =>
                    fadeInBubble(data[t].description, e, data[t].id)
                  }
                  onMouseOut={() => fadeOutBubble()}
                >
                  {data[t].description}
                </p>
              );
            } else {
              lightGrey = true;
              arr.push(
                <p
                  id={data[t].id}
                  style={{
                    fontSize: "15px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    backgroundColor: "#cacaca",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                  onMouseOver={(e) =>
                    fadeInBubble(data[t].description, e, data[t].id)
                  }
                  onMouseOut={() => fadeOutBubble()}
                >
                  {data[t].description}
                </p>
              );
            }
          }
        }

        total += numberAmt;
      }

      // render one more <p> for totals
      if (["amount", "fee", "net"].includes(type)) {
        arr.push(
          <p
            style={{
              fontSize: "15px",
              borderTopWidth: "1px",
              borderTopColor: "black",
            }}
          >
            {formatDollarAmount(total)}
          </p>
        );
      } else if (type === "equal") {
        arr.push(
          <p
            style={{
              fontSize: "15px",
              borderTopWidth: "1px",
              borderTopColor: "black",
            }}
          >
            =
          </p>
        );
      } else if (type === "minus") {
        arr.push(
          <p
            style={{
              fontSize: "15px",
              borderTopWidth: "1px",
              borderTopColor: "black",
            }}
          >
            -
          </p>
        );
      }

      return arr;
    }
  };

  const exportData = async (payoutId: any) => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/payments/stripe-information/export`,
          {
            responseType: "blob",
            params: {
              payoutId: payoutId,
            },
          }
        )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `StripePayout_${new Date()}.xlsx`);
          document.body.appendChild(link);
          link.click();
        });
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoading) {
    return <Spinner size={45} />;
  }

  if (nullStripeId) {
    return (
      <div>
        <h1>{tr("Most Recent Stripe Payouts")}</h1>
        <p style={{ marginTop: "25px" }}>{tr("No Stripe Account ID Found")}</p>
      </div>
    );
  }

  return (
    <div>
      <h1>{tr("Most Recent Stripe Payouts")}</h1>
      <button style={{ marginBottom: "20px" }} onClick={() => getLink.mutate()}>
        {tr("Connect to Stripe")}
      </button>
      {updatedPayoutData[0] ? (
        updatedPayoutData.map((record: any) => (
          record.type === "break" ?
            <>
              <div style={{ border: "1px solid black", borderRadius: "10px", padding: "10px", margin: "20px", backgroundColor: "#FF4F00", width: "65em", minWidth: "fit-content" }}>
                <p style={{ fontSize: "20px", fontWeight: "bold", margin: "20px", textAlign: "center", color: "white" }}>
                  {record.message}
                </p>
              </div>
            </>
          :
          <>
            <div
              style={{
                margin: "20px",
                padding: "10px",
                backgroundColor: "lightgrey",
                width: "65em",
                borderRadius: "10px",
                minWidth: "fit-content",
              }}
            >
              <div
                style={{
                  backgroundColor: "#ffa57c",
                  padding: "5px",
                  borderRadius: "10px",
                }}
              >
                <p style={{ display: "inline-block" }}>Arrival Date</p>
                <p style={{ display: "inline-block", float: "right" }}>
                  {new Date(record.arrival_date * 1000).toLocaleDateString()}
                </p>
              </div>

              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
              >
                {/* <p style={{fontSize:"30px", fontWeight:"bold", marginBottom:"10px"}}>Total Amount</p> */}
                <div
                  id="transactionCount"
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "center",
                    borderRight: "4px",
                    padding: "5px",
                  }}
                >
                  <p>{tr("Transaction Count")}</p>
                  <p>{record.balance_transactions.data.length}</p>
                </div>
                <div
                  style={{
                    border: "1px solid black",
                    height: "84px",
                    marginTop: "-3px",
                  }}
                ></div>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  <p>{tr("Gross")}</p>
                  <p>{formatDollarAmount(record.amount + record.total_fee)}</p>
                </div>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  <br></br>
                  <p>-</p>
                </div>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  <p>{tr("Fees")}</p>
                  <p>{formatDollarAmount(record.total_fee)}</p>
                </div>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  <br></br>
                  <p>=</p>
                </div>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  <p>{tr("Total")}</p>
                  <p>{formatDollarAmount(record.amount)}</p>
                </div>
              </div>

              <div>
                <div style={{ display: "inline-block" }}>
                  <p>{tr("Date Paid")}:</p>
                  <p>{tr("Description")}:</p>
                  <p>{tr("Delivery method")}:</p>
                  <p>{tr("Stripe payout ID")}:</p>
                </div>
                <div style={{ display: "inline-block", marginLeft: "7em" }}>
                  <p>
                    {new Date(record.arrival_date * 1000).toLocaleDateString()}
                  </p>
                  <p>{record.description}</p>
                  <p>{record.method}</p>
                  <p>{record.id}</p>
                </div>
                <div style={{ display: "flex" }}>
                  <button
                    id={record.customShowButton}
                    style={{
                      display: "block",
                      fontWeight: "bold",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      marginRight: "5px",
                      marginTop: "5px",
                    }}
                    type="button"
                    onClick={() =>
                      showTransactions(
                        record.customTransactionDiv,
                        record.customShowButton,
                        record.customHideButton
                      )
                    }
                  >
                    {tr("More")}...
                  </button>
                  <button
                    id={record.customHideButton}
                    style={{
                      display: "none",
                      fontWeight: "bold",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      marginRight: "5px",
                      marginTop: "5px",
                    }}
                    type="button"
                    onClick={() =>
                      showTransactions(
                        record.customTransactionDiv,
                        record.customShowButton,
                        record.customHideButton
                      )
                    }
                  >
                    {tr("Less")}...
                  </button>
                  <button
                    style={{
                      display: "block",
                      fontWeight: "bold",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      marginTop: "5px",
                    }}
                    type="button"
                    onClick={() => exportData(record.id)}
                  >
                    {tr("Export")}
                  </button>
                </div>
                <div
                  id={record.customTransactionDiv}
                  style={{ display: "none" }}
                >
                  <div
                    style={{
                      margin: "10px",
                      padding: "10px",
                      backgroundColor: "#efefef",
                      borderRadius: "10px",
                    }}
                  >
                    <h2
                      className="transactionTitle"
                      style={{ marginBottom: "10px", textAlign: "center" }}
                    >
                      {tr("Transactions")}
                    </h2>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(7, 100px) 415px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        <p style={{ textAlign: "center" }}>{tr("Date")}</p>
                        {(() => {
                          return getBalanceTransactionData(
                            record.balance_transactions.data,
                            "date"
                          );
                        })()}
                      </div>
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        <p style={{ textAlign: "center" }}>{tr("Type")}</p>
                        {(() => {
                          return getBalanceTransactionData(
                            record.balance_transactions.data,
                            "type"
                          );
                        })()}
                      </div>
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        <p>{tr("Amount")}</p>
                        {(() => {
                          return getBalanceTransactionData(
                            record.balance_transactions.data,
                            "amount"
                          );
                        })()}
                      </div>
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        <br></br>
                        {(() => {
                          return getBalanceTransactionData(
                            record.balance_transactions.data,
                            "minus"
                          );
                        })()}
                      </div>
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        <p style={{ textAlign: "center" }}>{tr("Fee")}</p>
                        {(() => {
                          return getBalanceTransactionData(
                            record.balance_transactions.data,
                            "fee"
                          );
                        })()}
                      </div>
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        <br></br>
                        {(() => {
                          return getBalanceTransactionData(
                            record.balance_transactions.data,
                            "equal"
                          );
                        })()}
                      </div>
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        <p style={{ textAlign: "center" }}>{tr("Net")}</p>
                        {(() => {
                          return getBalanceTransactionData(
                            record.balance_transactions.data,
                            "net"
                          );
                        })()}
                      </div>
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          textAlign: "left",
                          maxWidth: "fit-content",
                        }}
                      >
                        <p style={{ textAlign: "center" }}>{tr("Description")}</p>
                        {(() => {
                          return getBalanceTransactionData(
                            record.balance_transactions.data,
                            "description"
                          );
                        })()}
                      </div>
                    </div>
                  </div>
                </div>
                <p id="descriptionBubble"></p>
              </div>
            </div>
          </>
        ))
      ) : (
        <p style={{ marginTop: "25px" }}>{tr("No Payment Order Data to Show")}</p>
      )}
    </div>
  );
};

export default StripeInfo;
