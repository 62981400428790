import React, { useState } from "react";
import axios from "api/axios";
import { A, OutlineButton } from "components/button";
import FlatContainer from "components/container/flat";
import SafeArea from "components/safe-area";
import { RiAddFill } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { TInventory } from "types/inventory";
import queryString from "query-string";
import Pagination from "components/table/pagination";
import Field from "components/field";
import { useAreYouSure } from "components/areYouSure";
import { useSnackbarContext } from "components/snackbar";
import Tab from "components/tab";
import Product from "./product";
import { TProduct } from "types/product";
import { TItemGroup } from "types/item_group";
import { TCategory } from "./categories/TCategory";
import { useModalContext } from "components/modal";
import EditItemGroupModal from "views/item_group/edit";
import { Categories } from "./categories";
import { CategoryModal } from "./categories/CategoryModal";
import Switch from "rc-switch";
import { useTranslation } from 'react-i18next';
import { Button } from "components/buttonV2";
import TagFilterModal from "views/customers/tag-filter-modal";
import ViewBySelect from "components/viewBySelect";

type TCategoryPicker = {
  value: number;
  label: string;
  is_parent_category: boolean;
  parent_category_id: number | null;
};

export default function InventoriesView() {
  const { t } = useTranslation();
  const tBase = "views.inventories.index2";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const location = useLocation<{ page: string }>(),
    history = useHistory(),
    areYouSure = useAreYouSure(),
    snackBar = useSnackbarContext(),
    modal = useModalContext();
  const [showArchived, setShowArchived] = useState(false);
  const [showModal, setShowModal] = useState(false);
  interface Tag {
    id: string;
    label: string;
    value: string;
  }
  
  const [filterTags, setFilterTags] = useState<Tag[]>([]);
  const [filterExclusive, setFilterExclusive] = useState<boolean>(false);
  const [archiver, setArchiver] = React.useState< {archived_by:string, archived_at:Date} | null >(null);
  const [itemsPerPage, setItemsPerPage] = useState<number>(20);

  const archivedMsg = `${tr("Archived By")}: ` + archiver?.archived_by + tr(" on ") + (archiver && new Date(archiver.archived_at).toLocaleString("default", {
    weekday: "long",
    month: "short",
    day: "numeric",
    year: "numeric",
  }));

  const getArchiver = async(data: any, type: string) => {
    let id;
    if (type === "part") {
      id = data.item_id;
    } else if (type === "service") {
      id = data.id;
    } else {
      id = data.id;
    }

    await 
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/item?type=ARCHIVER&item_id=${id}&item_type=${type}`)
        .then((res) => setArchiver(res.data[0]))
  }

  const search = queryString.parse(location.search);

  const toggleModal = () => {
    showModal ? setShowModal(false) : setShowModal(true);
  };
  
  const handleSave = (data: any) => {
    setFilterTags(data.selectedTags);
    setFilterExclusive(data.filterExclusive);
  };
  
  const query = useQuery<{
    items: (TInventory | TProduct | TItemGroup)[];
    page_count: number;
    item_count: number;
    services_count: number;
    groups_count: number;
    categories: TCategory[] | null;
    categories_count: number;
  }>(
    ["items", [search, showArchived, filterTags, filterExclusive, itemsPerPage]],
    async () => {
      const params = {
        page: search.page || 1,
        category: search.category,
        search: search.search,
        tab: search.tab,
        includeArchived: showArchived ? 1 : 0,
        filterTags: filterTags.length > 0 ? filterTags.map(tag => tag.id).join(',') : undefined,
        filterExclusive: filterExclusive ? 1 : 0,
        itemsPerPage: itemsPerPage,
      };
      

      const result = await axios

      // .get(
      //   `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/item?type=ALLITEMS`,
      //   { params }
      // )

      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/inventory_item/ALLITEMSv2`,
        { params }
      )

      .then((res) => {
        return res.data;
      });

      return result;
    },
    {
      keepPreviousData: true,
    }
  );

  const archiveItem = useMutation(
    async (e: { id: string; type: string }) =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/item/${e.id}/${e.type}
  `
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        query.refetch();
        snackBar.showSnackbar(tr("Item archived!"));
      },
    }
  );

  const unarchiveItem = useMutation(
    async (e: { id: string; type: string }) =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/item/${e.id}/${e.type}/undo
  `
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        query.refetch();
        snackBar.showSnackbar(tr("Item restored!"));
      },
    }
  );

  const ProductMemoized = React.memo(Product);

  const populateItems = React.useMemo(() => 
    query.data?.items?.map((item) => (
      <ProductMemoized
        key={"item_id" in item ? item.item_id : item.id}
        data={item}
        archive={(id) =>
          areYouSure.activate(() =>
            archiveItem.mutate({
              id: id,
              type: "item_id" in item ? "part" : "product_label" in item ? "service" : "group",
            })
          )
        }
        unarchive={(id) =>
          areYouSure.activate(() =>
            unarchiveItem.mutate({
              id: id,
              type: "item_id" in item ? "part" : "product_label" in item ? "service" : "group",
            })
          )
        }
        onClick={() => getArchiver(item, "part")}
        archivedMsg={archivedMsg}
      />
    )), 
  [query.data?.items, archivedMsg]);

  const handleSearch = (e: any) => {
    history.push({
      search: queryString.stringify({
        ...search,
        search: e.target.value,
        page: 1,
      }),
    });
  };

  const handleCategorySelect = (selectedCategory: { value: number; label: string } | null) => {
    history.push({
      search: queryString.stringify({
        ...search,
        page: 1,
        // category: e?.value || null,
        category: selectedCategory ? selectedCategory.value : undefined,
      }),
    });
  };

  let sortedCategories = query.data?.categories?.sort((a, b) =>
    (a.category_name || "").localeCompare(b.category_name || "")
  );

  // Add "--" to subcategories
  let modifiedCategories = sortedCategories?.map((category) => {
    // If the category is a parent category, leave the label as it is.
    if (category.is_parent_category) {
      return category;
    }

    return {
      ...category,
      label: "-- " + category.category_name,
    };
  });

  // Organize categories into parent and subcategories both alphabetically
  const organizeCategories = (categories: TCategory[]) => {
    const parentCategories = categories.filter(cat => cat.is_parent_category);
    const subCategories = categories.filter(cat => !cat.is_parent_category);
  
    const organizedCategories: TCategory[] = [];
  
    parentCategories.forEach(parent => {
      organizedCategories.push(parent); // Add the parent category
      // Find and add subcategories of the current parent, sorted alphabetically
      const children = subCategories
        .filter(sub => sub.parent_category_id === parent.category_id)
        .sort((a, b) => a.category_name.localeCompare(b.category_name));
      organizedCategories.push(...children);
    });
  
    return organizedCategories;
  };

  modifiedCategories = organizeCategories(modifiedCategories || []);

  const showOnTabParts = search.tab !== "services" && search.tab !== "groups" && search.tab !== "categories";

  return (
    <SafeArea>
      <FlatContainer
        style={{ header: { borderBottom: 0, paddingBottom: 10, position: "relative" } }}
        footer={
          <Pagination
            pageIndex={
              search.page && !Array.isArray(search.page) ? +search.page : 1
            }
            pageCount={query.data?.page_count}
            handleButtonClick={(e) =>
              history.push({
                search: queryString.stringify({ ...search, page: e }),
              })
            }
          />
        }
        title={tr("Products")}
        buttons={[
          <A primary to="/inventory/new">
            <RiAddFill /> {tr("Add Part")}
          </A>,
          <OutlineButton
            onClick={() =>
              modal.setModal({
                component: <EditItemGroupModal id={"new"} />,
                label: tr("Item Group"),
              })
            }
            primary
          >
            <RiAddFill /> {tr("Add Group")}
          </OutlineButton>,
          <OutlineButton
            onClick={() =>
              modal.setModal({
                component: <CategoryModal id={"new"} onSave={() => query.refetch()} />,
                label: tr("Category"),
              })
            }
            primary
          >
            <RiAddFill /> {tr("Add Category")}
          </OutlineButton>,
        ]}
      >

        <div className="flex items-end justify-between -mt-5 -mb-3">

          {/* Switch button for showing archived */}
          <div className="flex items-end mb-2">
            <div>
              <Switch checked={showArchived} onChange={setShowArchived} />
            </div>
            <div className="ml-2">
              {tr("Show Archived")} 
              {
                search.tab === "parts" ? 
              tr(" Parts")
              : search.tab === "services" ?
              tr(" Services")
              : search.tab === "groups" ?
              tr(" Groups")
              : 
              tr(" Categories")
              }
            </div>
          </div>

          {/* Filter by tags And View By */}
          <div className="flex space-x-2 my-1.5">
            {showOnTabParts && (
              <Button className="ml-auto " type="secondary" onClick={toggleModal}>
                {tr("Filter By Tags")}
              </Button>
            )}
            <ViewBySelect
                itemsPerPage={itemsPerPage}  
                setItemsPerPage={setItemsPerPage}  
                updateItemsPerPage={(count) => {
                  setItemsPerPage(count);
                  history.push({
                    search: queryString.stringify({ ...search, page: 1 }), // Reset page to 1 when itemsPerPage changes
                  });
                }}
                width="143px"
              />
            <TagFilterModal
              showModal={showModal}
              toggleModal={toggleModal}
              onSave={handleSave}
              type={"inventory"}
              className="z-[9999]"
            />
          </div>
          
        </div>
        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Field
            type="input"
            isLoading={query.isLoading}
            value={
              search.search && !Array.isArray(search.search)
                ? search.search
                : ""
            }
            onChange={handleSearch}
            placeholder={tr("Search")}
            style={{ minWidth: "58%" }}
          />
          {showOnTabParts && (
            <Field
              type="select"
              isClearable
              // value={modifiedCategories?.find(cat => cat.category_id === (search.category && +search.category))}
              value={modifiedCategories?.find(
                (cat) => cat.category_id === (search.category ? +search.category : null)
              )}
              onChange={(selectedCategory: { value: number; label: string } | null) => {
                handleCategorySelect(
                  selectedCategory
                    ? { value: selectedCategory.value, label: selectedCategory.label }
                    : null
                );
              }}
              options={modifiedCategories?.map((category) => ({
                value: category.category_id, // Ensure category_id is passed as the value
                label: `${category.is_parent_category ? '' : '-- '}${category.category_name}${!category.active ? ' (Archived)' : ''}`,
                style: { color: !category.active ? 'red' : 'initial' },
              }))}
              style={{ minWidth: "32%" }}
              placeholder={tr("Category")}
            />
          )}
        </div>

        <Tab
          data={{
            parts: {
              count: query.data?.item_count,
              tab: tr("Parts"),
            },
            services: {
              count: query.data?.services_count,
              tab: tr("Services"),
            },
            groups: {
              count: query.data?.groups_count,
              tab: tr("Groups"),
            },
            categories: {
              count: query.data?.categories_count,
              tab: tr("Categories"),
              content: (
                <Categories 
                  categories={query.data?.items as any[]} 
                  isLoading={query.isLoading} 
                  search={search.search}
                  onSave={() => query.refetch()}
                />
              ),
            },
          }}
          onClick={(e) => {
            history.push({
              search: queryString.stringify(
                { 
                  ...search, 
                  tab: e, 
                  page: 1,
                  category: undefined,
                }
              ),
            });
          }}
          content={search.tab === "categories" ? null : <>{populateItems}</>}
          defaultTab={
            search.tab && !Array.isArray(search.tab) ? search.tab : "parts"
          }
        />
      </FlatContainer>
    </SafeArea>
  );
}
