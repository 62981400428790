import React from "react";
import styled from "styled-components";
import {
  useQuestionBuilderDispatch,
  useQuestionBuilderState,
} from "../context";
import Switch from "@material-ui/core/Switch";
import { RiDeleteBinLine } from "react-icons/ri";
import { SubtleIconButton } from "components/button";
import { useTour } from "contexts/tour";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

const QuestionFooterCon = styled.div`
  border-top: 1px solid grey;
  margin-top: 0.5rem;
  padding: 0.5rem 0 0.5rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

type Props = {
  required: boolean;
  index: number;
  isGroupLinkageQuestion: boolean;
  renewDatePicker: (type:string) => void;
  type: string;
};

export default function QuestionFooter({
  required,
  index,
  isGroupLinkageQuestion,
  renewDatePicker,
  type,
}: Props) {
  const { t } = useTranslation();
  const tBase = "views.settings.questionBuilder.question.footer";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const dispatch = useQuestionBuilderDispatch();
  const { isPrimary, subquestions } = useQuestionBuilderState();
  const tour = useTour();


  let paramId = useParams<{ id: string }>();

  // removing subquestion linking to reduce complexity
  if (subquestions) {
    const isSubquestion = subquestions.some((sub) => sub.id === parseInt(paramId.id));
    if (isSubquestion) return null;
  }

  return (
    <QuestionFooterCon>
      {isPrimary && (
        <div ref={(ref) => tour?.setStep(ref, 4)}>
          <span>{tr("Link question to category")}</span>
          <Switch
            checked={isGroupLinkageQuestion}
            onChange={() => dispatch({ type: "handleLinkage" })}
          />
        </div>
      )}

      <div>
        <span>{tr("Required")}</span>
        <Switch
          checked={required}
          onChange={() => dispatch({ type: "handleRequired", index })}
        />
      </div>
      <SubtleIconButton
        onClick={(e) => {
          e.stopPropagation();
          dispatch({ type: "deleteQuestion", index });
          renewDatePicker(type);
        }}
      >
        <RiDeleteBinLine />
      </SubtleIconButton>
    </QuestionFooterCon>
  );
}
