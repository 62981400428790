/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AppointmentCard from "./appointmentCard";
import Axios from "axios";
import { findIndex, find } from "lodash";
import {
  isAfter,
  isBefore,
  isEqual,
  addHours,
  format,
  startOfToday,
  addMinutes,
  startOfDay,
} from "date-fns";
import { Avatar } from "components/avatar";
import { useQuery } from "react-query";
import useQueryString from "hooks/useQueryString";
import useWindowDimensions from "hooks/useWindowDimensions";
import DroppableCell from "./DailyCell";
import { useTranslation } from "react-i18next";
import { convertFromUTC, convertToUTC } from "utils/utcConversion";
import { useAuthContext } from "contexts/auth";
import { extractLocations } from "views/calendar/utils/extractLocations";
import { locationsToDistances } from "views/calendar/utils/locationsToDistances";
import { useLocationContext } from "views/calendar/hooks/useLocationContext";
import extractLocationsDaily from "./utils/extractLocationsDaily";

const CalendarCon = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  height: 100%;
  align-items: flex-start;
  align-self: flex-start;
  font-size: 0.9rem;
  width: 100%;
  box-shadow: 0 0 10px -5px black;
`;

const FirstRow = styled.div`
  background: white;
  height: 50px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  position: sticky;
  font-weight: bold;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid black;
  > div {
    border-top: 1px solid #eceff1;
    border-left: 1px solid #e5e7ec;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    &:nth-child(2n) {
      border-left: 1px solid #eceff1;
    }
  }
`;

const FirstCol = styled.div`
  background: white;
  position: sticky;
  width: 150px;
  flex: 0 0 auto;
  left: 0;
  text-align: left;
  padding: 5px;
  font-weight: bold;
  border-left: 1px solid #cfd8dc;
  border-right: 1px solid black;
  border-top: 1px solid #eceff1;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
`;

const Cell = styled.div`
  width: 40px;
  flex: 0 0 auto;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const AssigneeRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 69px;
  overflow: hidden;
  flex: 0 0 auto;
  border-top: 1px solid #eceff1;
  position: relative; /* This ensures that the pseudo-elements are positioned correctly */

  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-right: 1px solid #cfd8dc;
    z-index: 1;
  }
`;

export default function ({
  date,
  toolTipModal,
  handleToolTip,
  refreshStatus,
  refreshPage,
  appointmentMoved,
  filterProps,
}) {
  // const { t } = useTranslation();
  // const tBase = "views.calendar.containers.daily.dailyContainer";
  // const tr = (key) => t(`${tBase}.${key}`);
  const { timezone } = useAuthContext();
  const { setMaxDistance } = useLocationContext();

  let height = -19;

  const dimension = useWindowDimensions();

  const startOfDayTime = startOfDay(date ? new Date(date) : new Date());
  startOfDayTime.setHours(7, 0, 0, 0);

  let parseDate = date ? new Date(date) : new Date();
  let formatDate = format(parseDate, "yyyy-MM-dd");

  const queryString = useQueryString();

  const query = useQuery(
    ["appointment", "daily", formatDate, queryString.search],
    async () =>
      await Axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/all`,
        {
          params: {
            date: formatDate,
            grid: "daily",
            filters: queryString.search.division,
          },
        }
      ).then((res) => res.data),
    {
      keepPreviousData: true,
      refetchInterval: 60000,
    }
  );

  const groupAppointmentsByRow = (array) => {
    return array.reduce(
      (prev, current) => {
        if (!current) return prev;

        let {
          start_time: currentStart,
          end_time: currentEnd,
          start_time_utc: currentStartUtc,
          end_time_utc: currentEndUtc,
          duration,
        } = current;

        let startLocation;

        // if (currentStart) {
        //   startLocation = new Date(currentStart);
        // } else if (currentEnd) {
        //   startLocation = new Date(currentEnd);
        // } else {
        //   startLocation = new Date(addHours(startOfToday(), 7));
        // }

        if (currentStartUtc) {
          startLocation = new Date(currentStartUtc); // Prefer UTC start time
        } else if (currentEndUtc) {
          startLocation = new Date(currentEndUtc); // Use UTC end time as fallback
        } else if (currentStart) {
          startLocation = new Date(currentStart);
        } else if (currentEnd) {
          startLocation = new Date(currentEnd); // Use local end time as fallback
        } else {
          startLocation = new Date(addHours(startOfToday(), 7)); // Default if no times available
        }

        const endLocation = addMinutes(startLocation, duration || 120);

        let index = findIndex(prev, (list) => {
          return (
            findIndex(list, ({ start, end }) => {
              return !(
                isEqual(endLocation, start) ||
                isBefore(endLocation, start) ||
                isAfter(startLocation, end) ||
                isEqual(startLocation, end)
              );
            }) === -1
          );
        });

        index !== -1
          ? prev[index].push({
              ...current,
              start: startLocation,
              end: endLocation,
            })
          : prev.push([{ ...current, start: startLocation, end: endLocation }]);

        return prev;
      },
      [[]]
    );
  };

  const formattedData = query.data?.map(({ appointment, assignee, id }) => ({
    assignee,
    id,
    data: appointment
      ? groupAppointmentsByRow(
          appointment.sort(
            (a, b) => new Date(a.start_time_utc) - new Date(b.start_time_utc)
          )
        )
      : [],
  }));

  const cellWidth = (dimension.width - 185) / 24;

  const populateCalendar = formattedData?.map(
    ({ assignee, data, id }, assigneeIndex) => {
      if (data.length === 0) {
        height += 69;
      }

      if (formattedData) {
        // console.log("formattedData", formattedData);
        const distances = locationsToDistances(
          extractLocationsDaily(formattedData)
        );
        const max = Math.max(...distances);
        console.log("max", max);
        setMaxDistance(max);
      }
      return (
        <React.Fragment key={id}>
          <Row
            style={{
              background: !assignee
                ? "#FFEBEE"
                : assigneeIndex % 2 === 1
                ? "#F4F9FD"
                : "white",
            }}
          >
            <FirstCol
              style={{
                background: !assignee
                  ? "#FFEBEE"
                  : assigneeIndex % 2 === 1
                  ? "#F4F9FD"
                  : "white",
              }}
            >
              {assignee ? (
                <Avatar showLabel={true} size="small" user_id={id}>
                  {assignee}
                </Avatar>
              ) : (
                <>
                  {/* tr("Unassigned") */}
                  Unassigned
                </>
              )}
            </FirstCol>
            <Content>
              {new Array(data?.length || 1).fill(0).map((_, rowIdx) => {
                let border =
                  rowIdx === 0
                    ? "!border-slate-400 !border-t"
                    : rowIdx === data.length
                    ? "!border-b !border-slate-400"
                    : "";

                return (
                  <AssigneeRow className={border}>
                    {new Array(12).fill("").map(
                      (_, cellIdx) => (
                        <Cell
                          key={id + "-" + cellIdx}
                          style={{
                            width: cellWidth * 2 + "px",
                            borderRight:
                              cellIdx < 11 ? "1px solid #cfd8dc" : "none", // Add right border except for the last cell
                          }}
                        ></Cell>
                      ),
                      {
                        /*
                     UNCOMMENT THIS TO: start working on the daily drag and drop, make sure to remove comments as well and cell above
                     note: unscheduled/partially complete also don't work
                     */
                      },
                      {
                        /* (
                      <DroppableCell
                        key={id + "-" + cellIdx}
                        {...{ appointmentMoved }}
                        formatDate={formatDate}
                        newTime={addMinutes(startOfDayTime, cellIdx * 60)} 
                        crewId={id}
                        style={{
                          width: cellWidth * 2 + "px",
                          borderRight: cellIdx < 11 ? '1px solid #cfd8dc' : 'none', // Add right border except for the last cell
                        }}
                      >
                      </DroppableCell>
                    ) */
                      }
                    )}
                  </AssigneeRow>
                );
              })}
            </Content>
          </Row>

          {data?.map((row) => {
            height += 69;

            const appointmentCards = row.map((appointment) => {
              return (
                <AppointmentCard
                  latitude={appointment.latitude}
                  longitude={appointment.longitude}
                  key={id + " " + appointment.id}
                  handleToolTip={handleToolTip}
                  {...{ appointment, toolTipModal, height, cellWidth }}
                  selected={toolTipModal && toolTipModal.id === appointment.id}
                />
              );
            });

            return appointmentCards;
          })}
        </React.Fragment>
      );
    }
  );

  return (
    <CalendarCon>
      <FirstRow>
        <FirstCol></FirstCol>
        {new Array(12).fill("").map((e, f, i) => (
          <React.Fragment key={`first-row-${f}`}>
            <Cell style={{ width: cellWidth * 2 + "px" }}>
              {f + 7 > 12 ? f + 7 - 12 + "pm" : f + 7 + "am"}
            </Cell>
          </React.Fragment>
        ))}
      </FirstRow>
      {populateCalendar}
    </CalendarCon>
  );
}
