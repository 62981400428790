import * as React from "react";
import { ContainerWithSidebar } from "components/layout";
import styled from "styled-components";
import { useQuery } from "react-query";
import axios from "axios";
import Table2, { useTable } from "components/table/index";
import { Sidebar } from "views/home";
import { SearchInput } from "components/input";
import { useHistory } from "react-router-dom";
import { Button } from "components/button";
import { TCustomer } from "types/customer";
import SafeArea from "components/safe-area";
import FlatContainer from "components/container/flat";
import useQueryString from "hooks/useQueryString";
import { MdVerifiedUser } from "react-icons/md";
import { useModalContext } from "components/modal";
import CreateModal from "views/home/components/createModal";
import TagFilterModal from "./tag-filter-modal";
import { exclude } from "query-string";
import CheckedBoxButton from "components/checkBoxButton";
import { filter } from "lodash";
import FindDuplicatesButton from "./find_duplicates_button";
import Field from "components/field";
import { useTranslation } from 'react-i18next';
import { getContrastTextColorHEX, checkForColor } from "utils/getContrastTextColor";

export default function Customers() {
  const { t } = useTranslation();
  const tBase = "views.customers.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const history = useHistory();
  const queryString = useQueryString<{ search?: string; page?: string, status?: string }>();

  // const [storedSort, setStoredSort] = React.useState<string>("")
  const [storedSearch, setStoredSearch] = React.useState<string>("");

  // passes column and order values to be logged into history and sorts table upon return
  const onSort = (e: any) => {
    queryString.update({ ...e, search: storedSearch });
    // setStoredSort(e)
  };

  const onModalButtonClicked = () => {
    // console.log("modal button clicked");
  };

  const sort = {
    column:
      queryString.search.column && !Array.isArray(queryString.search.column)
        ? queryString.search.column
        : null,
    order:
      queryString.search.order && !Array.isArray(queryString.search.order)
        ? queryString.search.order
        : null,
  };

  const tableProps = useTable({ onSort, sort, url: true });

  const [showModal, setShowModal] = React.useState(false);
  const [filterTags, setFilterTags] = React.useState<object[]>([]);
  const [filterExclusive, setFilterExclusive] = React.useState<boolean>(false);
  const [emptyStrings, setEmptyStrings] = React.useState<boolean>(false);
  const [chosenTagType, setChosenTagType] = React.useState<string>("customer");
  const [existingFilter, setExistingFilter] = React.useState<any>(null);

  const toggleModal = () => {
    showModal ? setShowModal(false) : setShowModal(true);
  };

  const handleSave = (data: any) => {
    setFilterTags(data.selectedTags);
    setFilterExclusive(data.filterExclusive);
    setChosenTagType(data.tagType);
    setExistingFilter({ selectedTags: data.selectedTags, filterExclusive: data.filterExclusive, tagType: data.tagType });
  };

  const customers = useQuery<{ customers: TCustomer[]; totalCount: number }>(
    [
      "customers",
      tableProps.pageIndex,
      tableProps.sort,
      queryString.search,
      filterTags,
      filterExclusive,
      chosenTagType,
      emptyStrings,
      queryString.search.status
    ],
    async () =>
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers`, {
          params: {
            ...tableProps.sort,
            pageIndex:
              queryString.search.page && !Array.isArray(queryString.search.page)
                ? +queryString.search.page
                : 1,
            search: queryString.search.search,
            filterTags: filterTags,
            exclusive: filterExclusive,
            tag_type: chosenTagType,
            emptyStrings: emptyStrings,
            status: queryString.search.status
          },
        })
        .then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );

  let data = customers.data?.customers;

  React.useEffect(() => {
    customers.data?.totalCount &&
      tableProps.setPageCount(Math.ceil(customers.data?.totalCount / 50));
  }, [customers.data]);

  const columns = {
    name: {
      label: tr("Name"),
      // width: "50%",
      style: {
        fontWeight: "600",
        textTransform: "capitalize",
      },
      cell: (row: any) => {
        return row.hasOwnProperty("name") && row.name ? (
          <span className="flex space-x-1 items-center">
            <span>{row.name.toString().toLowerCase()}</span>
            {row.fk_customer_id && (
              <MdVerifiedUser className="text-blue-500 text-[0.9rem]" />
            )}
          </span>
        ) : (
          ""
        );
      },
    },
    tags: {
      label: chosenTagType === "customer" ? tr("Tags (Customer)") : tr("Tags (Property)"),
      cell: (row: any) => (
        <div className="space-x-1">
          {(chosenTagType === "customer" && row.customer_tags) ? (
            row.customer_tags.map((tag: any) => (
              <span
                key={tag.id}
                className="rounded p-1 text-xs"
                style={{ 
                  background: tag.color,
                  color: getContrastTextColorHEX(tag.color),
                  border: `1px solid ${checkForColor(tag.color)}`,
                }}
              >
                {tag.label}
              </span>
            ))
          ) : (chosenTagType === "property" && row.property_tags) ? (
            row.property_tags.map((tag: any) => (
              <span
                key={tag.id}
                className="rounded p-1 text-white text-xs"
                style={{ background: tag.color }}
              >
                {tag.label}
              </span>
            ))
          ) : (
            ""
          )}
        </div>
      ),
    },
    email: {
      label: tr("Email"),
      width: "50%",
      style: {
        textTransform: "lowercase",
      },
      cell: (row: any) => {
        return row.hasOwnProperty("email") && row.email ? (
          <span className="space-x-1 items-center">
            <span>{row.email.toString().toLowerCase()}</span>
          </span>
        ) : (
          ""
        );
      },
    },
    address: {
      label: tr("Address"),
      width: "50%",
      cell: (row: any) => {
        return row.hasOwnProperty("addresses") && row.addresses ? (
          <span className="space-x-1 items-center">
            <span>
              {row.addresses[0].address.toString().toLowerCase() +
                ", " +
                row.addresses[0].city.toString().toLowerCase() +
                ", " +
                row.addresses[0].state.toString().toUpperCase() +
                ", " +
                row.addresses[0].zipcode.toString().toLowerCase()}
            </span>
          </span>
        ) : (
          ""
        );
      },
    },
    phone: {
      label: tr("Phone #"),
    },
  };

  const to = (row: any) => `/customers/${row.id}`;

  const handlePage = (page: number) => {
    queryString.update({ ...queryString.search, page: page.toString() });
  };

  const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    queryString.update({
      ...queryString.search,
      search: target.value,
      page: "1",
    });
    setStoredSearch(target.value);
  };

  const handleStatusChange = (e: { value: string; label: string } | null) => {
    queryString.update({ ...queryString.search, status:  e ? e.value : undefined });
  };
  const statusOptions = [
    { value: "active", label: tr("Active") },
    { value: "include_archived", label: tr("Active & Archived") },
    { value: "archived", label: tr("Archived") },
  ];

  return (
    <Main>
      <FlatContainer
        title={
          <HeaderCon>
            <h1 style={{ display: "inline-block", width: "5em" }}>{tr("Customers")}</h1>
            <SearchInput
              placeholder={tr("Search customer")}
              search
              value={
                queryString.search.search &&
                !Array.isArray(queryString.search.search)
                  ? queryString.search.search
                  : ""
              }
              isLoading={customers.isFetching}
              style={{
                width: "300px",
                marginLeft: "1rem",
                display: "inline-block",
              }}
              onChange={handleSearch}
            />
            <div style={{ display: "inline-block" }}>
              <CheckedBoxButton
                checkedValue={emptyStrings}
                setCheckedValue={setEmptyStrings}
              >
                {tr("Hide Empty")}
              </CheckedBoxButton>
            </div>
            <div style={{ display: "inline-block" }}>
            <Field
                type="select"
                isClearable
                value={statusOptions.find(
                  (option) => option.value === queryString.search.status
                )}
                onChange={handleStatusChange}
                options={statusOptions}
                style={{ minWidth: "190px" }}
                placeholder={tr("Customer Status")}
              />
            </div>
          </HeaderCon>
        }
        buttons={[
          <Button primary style={{ marginLeft: "auto" }} onClick={toggleModal}>
            {tr("Filter By Tags")}
          </Button>,
          <Button
            primary
            style={{ marginLeft: "auto" }}
            onClick={() => history.push("/customers/edit/new")}
          >
            + {tr("Create Customer")}
          </Button>,
          <FindDuplicatesButton />,
        ]}
      >
        <Table2
          rOrC="customers"
          columns={columns}
          data={data}
          isCustomerDashboard
          {...{
            ...tableProps,
            to,
            handlePage,
            pageIndex:
              queryString.search.page && !Array.isArray(queryString.search.page)
                ? +queryString.search.page
                : 1,
          }}
        />
        <TagFilterModal
          showModal={showModal}
          toggleModal={toggleModal}
          onSave={handleSave}
          type={"customer"} // can be "customer&property" (see tag-filter-modal.tsx)
          {...{ chosenTagType, existingFilter }}
        />
      </FlatContainer>
    </Main>
  );
}

const Main = styled.div`
  padding: 1rem;
`;

const HeaderCon = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
