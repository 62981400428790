import React, { useState } from "react";
import { useQuery } from "react-query";

import { getInventoryCategories, getInventoryItems } from "api/inventoryApi";
import { TInventory, TInventoryCategory } from "../../../../../types/inventory";
import Spinner from "components/spinner";

import styles from "./VendorItemLinkModalBody.module.scss";
import { Input } from "components/input";
import { Button } from "components/button";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import Field from "components/field";
import { useSnackbarContext } from "components/snackbar";

const VendorItemLinkModalBody = ({vendorId}: any) => {
  const { t } = useTranslation();
  const tBase = "views.vendors.VendorBody.VendorItems.VendorItemLinkModal.VendorItemLinkModalBody";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { showSnackbar } = useSnackbarContext();

  const [selectedCategory, setSelectedCategory] =
    useState<TInventoryCategory | null>(null);
  const [selectedItem, setSelectedItem] = useState<TInventory | null>(null);
  const [price, setPrice] = useState<string>("");
  const [leadTime, setLeadTime] = useState<string>("");
  const [notes, setNotes] = useState<string>("");

  const categoryQuery = useQuery("categories", getInventoryCategories);

  const itemsQuery = useQuery(
    ["vendor_link_items", selectedCategory?.category_id],
    () => getInventoryItems(selectedCategory?.category_id || null)
  );

  const handleChangeCategory = (category: TInventoryCategory) => {
    setSelectedCategory(category);
    setSelectedItem(null);
  };

  const handleChangeItem = (item: TInventory) => {
    setSelectedItem(item);
  };

  const handleLinkItemSave = async() => {
    await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/company/vendors/addLinkedItem`,
      {
        vendorId: vendorId,
        itemId: selectedItem?.item_id,
        price: price,
        leadTime: leadTime,
        notes: notes
      }).then(() => {
        setPrice("");
        setLeadTime("");
        setNotes("");
        // remove the item from the itemsQuery list
        itemsQuery.refetch();
        showSnackbar(tr("Item Linked Successfully"));
      }).catch(() => {
        showSnackbar(tr("Error Linking Item"));
      }
    );
  }

  if (categoryQuery?.isLoading) return <Spinner />;

  if (categoryQuery?.isError) return <div>Error</div>;

  return (
		<div className={styles.container}>
			<div className={styles.pickers}>
				<div className={styles.categoryList}>
					{categoryQuery?.data?.map((category: TInventoryCategory) => (
						<div
							className={
								selectedCategory && category.category_id === selectedCategory.category_id
									? styles.selectedCategoryContainer
									: styles.categoryContainer
							}
							onClick={() => handleChangeCategory(category)}
						>
							{category.category_name}
						</div>
					))}
				</div>
				<div className={styles.itemList}>
					{itemsQuery?.isLoading ? <Spinner /> : null}
					{itemsQuery?.isError ? <div>{tr("Error")}</div> : null}
					{itemsQuery?.data?.map((item: TInventory) => {
						return (
							<div
								className={
									selectedItem && item.item_id === selectedItem.item_id
										? styles.selectedItemContainer
										: styles.itemContainer
								}
								onClick={() => handleChangeItem(item)}
							>
								{item.item_name}
							</div>
						);
					})}
				</div>
			</div>
			<div className={styles.chooseLabels}>
				{!selectedCategory ? (
					<div className={styles.chooseCategory}>{tr("Choose a Category")}</div>
				) : null}
				{selectedCategory && !selectedItem ? (
					<div className={styles.chooseItem}>{tr("Choose an Item")}</div>
				) : null}
			</div>
			{selectedItem ? (
				<>
					<div className={styles.itemDetailsContainer}>
						{selectedItem.hasOwnProperty("imageUrl") && selectedItem.imageUrl ? (
							<img
								alt={selectedItem.item_name}
								src={selectedItem?.imageUrl ? selectedItem.imageUrl : ""}
								className={styles.image}
							/>
						) : null}

						<div className={styles.detailGroup}>
							<div className={styles.itemName}>{selectedItem.item_name}</div>
							<div className={styles.description}>{selectedItem.long_description}</div>
						</div>
					</div>
					<div className={styles.formContainer}>
						<Field
							advancedNumericalInputHandling
							value={price}
							setValue={setPrice}
							placeholder={tr("Price")}
							style={{ marginBottom: "10px" }}
						/>
						<Input
							style={{ marginBottom: "10px" }}
							placeholder={tr("Lead Time")}
							value={leadTime}
							onChange={(e: any) => setLeadTime(e.target.value)}
						/>
						<Input
							style={{ marginBottom: "10px" }}
							placeholder={tr("Notes")}
							value={notes}
							onChange={(e: any) => setNotes(e.target.value)}
						/>
						<div className={styles.footerRow}>
							<Button
								className={styles.saveButton}
								onClick={handleLinkItemSave}
								primary
								disabled={!price}
							>
								{tr("Save")}
							</Button>
						</div>
					</div>
				</>
			) : null}
		</div>
	);
};

export default VendorItemLinkModalBody;
