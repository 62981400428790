import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from 'react-i18next';

interface IViewBySelectProps {
  itemsPerPage: number;
  setItemsPerPage: React.Dispatch<React.SetStateAction<number>>;
  updateItemsPerPage: (count: number) => void;
  width?: string;
};

const ViewBySelect = ({ itemsPerPage, setItemsPerPage, updateItemsPerPage, width = "150px" }: IViewBySelectProps) => {
  const { t } = useTranslation();
  const tBase = "views.duplicates.customers.components.ViewBySelect";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  // Toggle dropdown visibility
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  // Close the dropdown when clicking outside of it
  useEffect(() => {
    function handleClickOutside(event: { target: any; }) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false); // Close the dropdown
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]); 

  const options = [
    { id: 1, count: 10 },
    { id: 2, count: 20 },
    { id: 3, count: 50 },
  ];

  return (
    <div ref={dropdownRef}>
      <div className={`relative inline-block  w-[${width}]`}>
        <div
          className="flex items-center appearance-none w-full bg-white border border-[#dedede] hover:border-[#e2e2e2] px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline cursor-pointer select-none h-10"
          onClick={toggleDropdown}
        >
          {tr("View By")} {itemsPerPage}
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <IoIosArrowDown />
          </div>
        </div>
        {showDropdown && (
          <div className="absolute z-10 top-11 w-full bg-white border border-gray-400 rounded shadow">
            {options.map((option) => (
              <div
                key={option.id}
                className="px-2 py-1 cursor-pointer hover:bg-gray-200"
                onClick={() => {
                  updateItemsPerPage(option.count);
                  setShowDropdown(false);
                }}
              >
                {option.count}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewBySelect;
