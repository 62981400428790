import React, { useRef } from "react";
import { IconButton, SubtleIconButton } from "components/button";
import { Input } from "components/input";
import { RiCloseFill } from "react-icons/ri";
import { dollarFormatter } from "utils";
import { useDrag, useDrop } from "react-dnd";
import { GrDrag } from "react-icons/gr";

type Props = {
  data: any;
  rateLabel: string;
  onChange?: () => void;
  formik: any;
  handleDelete: () => void;
  index?: number;
  moveProduct: (dragIndex: number, hoverIndex: number) => void;
};

export default function GroupHeader({
  data,
  rateLabel,
  formik,
  handleDelete,
  index,
  moveProduct,
}: Props) {
  const ITEM_TYPE = "PRODUCT";
  const ref = useRef<HTMLButtonElement>(null);

  const [{ isDragging }, drag] = useDrag({
    type: ITEM_TYPE,
    item: { index }, // Item contains its index
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ITEM_TYPE,
    hover: (draggedItem: { index: number }) => {
      const dragIndex = draggedItem.index;
      const hoverIndex = index ?? 0;
      if (dragIndex === hoverIndex) return;
      moveProduct(dragIndex, hoverIndex);
      draggedItem.index = hoverIndex;
    },
  });

  drag(drop(ref));

  const price = data.group_products.reduce((prev: number, current: any) => {
    return prev + current[rateLabel] * current.quantity;
  }, 0);

  return (
    <>
      <IconButton size="small" onClick={handleDelete}>
        <RiCloseFill />
      </IconButton>
      <SubtleIconButton
        size="small"
        className={`hover:cursor-grab hover:bg-blue-100 active:cursor-grabbing`}
        ref={ref}
      >
        <GrDrag />
      </SubtleIconButton>
      <Input size="small" value={1} disabled style={{ width: "100px" }} />
      <Input size="small" value={data.group_name} disabled />
      <Input size="small" value="- - -" disabled />
      <Input size="small" value={"- - -"} disabled />
      <Input size="small" value={dollarFormatter(price * 100)} disabled />
    </>
  );
}
