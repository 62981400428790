import React, { useState, createElement } from "react";

// page import
import Login from "./views/login/index";
import TimeClock from "views/time-clock";
// import fakeAuth  from './auth'
import Home from "./views/home";
import Dashboard from "./views/dashboard";
import UserDashboard from "./views/dashboard/userpage";
//import SpecialOrder from "./views/dashboard/specialOrder";
import Navbar from "./views/navigation";
import Order from "./views/order";
import Cart from "./views/cart";

import EditTeam from "views/team/edit";
import NewUser from "views/team/edit/newuser";
import ViewUser from "views/team/viewUser";
import EditUser from "views/team/viewUser/edit";
import LeaveRequest from "views/team/sick/leaveform";
import Team from "views/team";
import Absent from "views/team/sick";

import Admin from "./views/admin";
import NewAccount from "./views/admin/container/newuser";
import UserPermission from "./views/admin/user";
import { AppointmentTypes } from "./views/admin/appointment-types";
import Profile from "./views/profile";
import Inventory from "./views/inventories/inventory";
import Warehouse from "./views/warehouse";
import Parts from "./views/parts";
import CategoryManagement from "./views/category_management";
import PublicRepairRequestForm from "./views/repair_request/public_form";
import RepairRequestForm from "./views/repair_request/form";
import RepairRequestManagement from "./views/repair_request";
import VehicleInspection from "./views/vehicle_inspection";
import InspectionDashboard from "./views/vehicle_inspection/dashboard/";
import PublicVehicleInspection from "./views/vehicle_inspection/public";

import WarehouseManagement from "./views/warehouse_management";
import Services from "./views/services";

import HistoricalTeam from "./views/historicalTeam";
// import NoPermission from "./views/nopermission";

import AppContainer from "./styles/app";

// CONTEXT
import Modal from "./components/modal";
import Snackbar from "./components/snackbar";
import VehicleCheckout from "./views/vehicleCheckout";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { useAuthContext } from "contexts/auth.js";
import Customers from "views/customers";
import DuplicateCustomers from "./views/duplicates/customers";
import Customer from "views/customer";
import Calendar from "views/calendar";
import RequestParent from "views/requests";
import QuestionBuilder from "views/settings/questionBuilder";
import Invoices from "views/invoices";
import Projects from "views/projects";
import Quotes from "views/quotes";
import Project from "views/projects/views/project";
import Purchasing from "views/purchasing";
import Welcome from "views/welcome";
import ValidateAccount from "views/validate_account";
import ProjectSettings from "views/admin/projects";
import NewCustomer from "views/customers/new-customer";
import CustomDocument from "views/custom_document/index2";
import Winterization from "views/winterization";
import InventoriesView from "views/inventories/index2";
import ItemGroups from "views/item_groups";
import ItemGroup from "views/item_group";
import PropertyDetail from "views/property";
import Request from "views/request";
import Estimate from "views/estimate";
import Estimates from "views/estimates";
import Invoice from "views/invoice";
import Signup from "views/signup";
import Appointments from "views/appointments";
import Properties from "views/properties";
import Payment from "views/payment";
import Payments from "views/payments";
import Production from "views/production-3";
import Reports from "views/reports";
import VendorsIndex from "./views/vendors/VendorsIndex/VendorsIndex";
import Equipment from "pages/equipment/equipment.page";
import SignupConfirmationSplash from "views/signup/signupConfirmationSplash";
import emailVerificationSplash from "views/signup/emailVerificationSplash";
import Privacy from "views/signup/privacy";
import NotFound from "components/404";

import {
  EssentialPlan,
  EnhancedPlan,
  ExclusivePlan,
} from "views/subscription/plans";
import PlanSelection from "views/subscription/planSelection";
import MassScheduler from "features/mass-scheduler/MassScheduler";
import { Production as ProductionTest } from "features/production";
import EservMap from "features/map";
import OnboardingWizard from "features/onboarding-wizard";
import { NoPermission } from "features/no-permission";
import { Button } from "components/buttonV2";
import { useTranslation } from "react-i18next";
import Email from "views/outreach/email";

const NotAllowed = () => {
  const { t, i18n } = useTranslation();
  const tBase = "routes";
  const tr = (key) => t(`${tBase}.${key}`);
  const isES = i18n.language === "es" ? true : false;

  return (
    <div className="w-100 h-100 border flex flex-1 justify-center items-center -mt-[200px]">
      <div className="text-center mx-auto">
        <div className="border-2 border-red-500 px-5 py-3 rounded-md bg-slate-100">
          <h1>{tr("Unauthorized")}</h1>
          <p>{tr("You do not have permission to view this page")}</p>
        </div>
        <Button
          link={true}
          to="/"
          className={`mx-auto mt-4 ${isES ? "w-[140px] h-12" : "w-[120px]"}`}
        >
          {tr("Return Home")}
        </Button>
      </div>
    </div>
  );
};

const PrivateRoute = ({ navBar, path, exact, component }) => {
  const { isLoading, isAuth, logout, isSubscriptionActive } = useAuthContext();
  if (!isAuth) {
    return <Redirect to="/login" />;
  }

  return (
    <Route
      {...{ exact, path }}
      render={(props) => {
        const element = createElement(component, props);

        if (navBar) {
          return (
            <>
              {/* <Sidebar /> */}
              <div
                style={{
                  display: "flex",
                  minHeight: "100vh",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Navbar
                  // themeChanged={() => {
                  //   setTheme((theme) => !theme);
                  // }}
                  logout={logout}
                />
                {/* <NavigationTab /> */}
                {element}
              </div>
            </>
          );
        }
        return element;
      }}
    ></Route>
  );
};

export const history = createBrowserHistory();

export default function App() {
  const { isLoading, isAuth, logout, isSubscriptionActive, permissions } =
    useAuthContext();
  const authPermissions = permissions.pages;
  const [theme, setTheme] = useState(false);
  if (isLoading) {
    return null;
  }

  const isAllowed = (page) => {
    if (authPermissions && !authPermissions.includes(`/${page}`)) {
      return false;
    } else {
      return true;
    }
  };

  const routesData = {
    general: {
      public: true,
      hideNavBar: true,
      routes: [
        {
          path: "/login",
          render: () =>
            // isAuth ? <Redirect to="/" /> : <Login path="/login" />,
            isAuth ? (
              isSubscriptionActive ? (
                <Redirect to="/" />
              ) : (
                <Redirect to="/subscription/planSelection" />
              )
            ) : (
              <Login path="/login" />
            ),
        },
        {
          path: "/signup",
          // render: () => (isAuth ? <Redirect to="/" /> : <Signup />),
          render: () =>
            isAuth ? (
              isSubscriptionActive ? (
                <Redirect to="/" />
              ) : (
                <Redirect to="/subscription/planSelection" />
              )
            ) : (
              <Signup />
            ),
        },
        {
          path: "/privacy",
          component: Privacy,
        },
        {
          path: "/forgetpassword",
          render: () =>
            isAuth ? <Redirect to="/" /> : <Login path="/forgetpassword" />,
        },
        {
          path: "/validate-account/:id",
          render: () => (isAuth ? <Redirect to="/" /> : <ValidateAccount />),
        },
        {
          path: "/time-clock",
          component: isAllowed("time-clock") ? TimeClock : NotAllowed,
        },
        {
          path: "/map",
          component: EservMap,
        },
      ],
    },
    private_general: {
      routes: [
        {
          path: "/nopermission",
          component: NoPermission,
        },
        {
          path: "/vehicleCheckout",
          component: isAllowed("vehicleCheckout")
            ? VehicleCheckout
            : NotAllowed,
        },
        {
          path: "/dashboard/:id/:employee_id/:employee_name/:transfer_date",
          component: UserDashboard,
        },
        {
          path: "/order",
          component: isAllowed("order") ? Order : NotAllowed,
        },
        {
          path: "/historicalteam",
          component: isAllowed("historicalteam") ? HistoricalTeam : NotAllowed,
        },
        {
          path: "/warehouse",
          component: isAllowed("warehouse") ? Warehouse : NotAllowed,
        },
        {
          path: "/parts",
          component: isAllowed("parts") ? Parts : NotAllowed,
        },
        {
          path: "/warehouseManagement",
          component: WarehouseManagement,
        },
        {
          path: "/profile/:page?",
          component: Profile,
        },
        {
          path: "/category_management",
          component: isAllowed("category_management")
            ? CategoryManagement
            : NotAllowed,
        },
        {
          path: "/cart",
          component: Cart,
        },
        {
          path: "/purchasing",
          component: isAllowed("purchasing") ? Purchasing : NotAllowed,
        },
        {
          path: "/winterization",
          component: Winterization,
        },
      ],
    },
    production: {
      routes: [
        {
          hideNavBar: true,
          path: "/production_test",
          component: ProductionTest,
        },
        {
          hideNavBar: true,
          path: "/production",
          component: isAllowed("production") ? Production : NotAllowed,
        },
      ],
    },
    appointments: {
      routes: [
        {
          path: "/appointments",
          component: isAllowed("appointments") ? Appointments : NotAllowed,
        },
      ],
    },
    invoices: {
      routes: [
        {
          path: "/invoices",
          component: isAllowed("invoices") ? Invoices : NotAllowed,
        },
        {
          path: "/invoices/:id",
          component: Invoice,
        },
      ],
    },
    item_group: {
      routes: [
        {
          path: "/item_groups",
          component: ItemGroups,
        },
        {
          path: "/item_groups/:id/:type?",
          component: ItemGroup,
        },
      ],
    },
    equipment: {
      routes: [
        {
          path: "/equipment",
          component: isAllowed("equipment") ? Equipment : NotAllowed,
        },
      ],
    },
    inventory: {
      routes: [
        // {
        //   path: "/inventory",
        //   component: Inventories,
        // },
        {
          path: "/inventory/:id/:type?",
          component: Inventory,
          hideNavBar: true,
        },
        {
          path: "/products",
          component: isAllowed("products") ? InventoriesView : NotAllowed,
        },
        {
          path: "/vendors",
          component: isAllowed("vendors") ? VendorsIndex : NotAllowed,
        },
      ],
    },
    payments: {
      routes: [
        {
          path: "/payments",
          component: isAllowed("payments") ? Payments : NotAllowed,
        },
        { path: "/payments/:id", component: Payment },
      ],
    },
    property: {
      routes: [
        {
          path: "/property",
          component: isAllowed("property") ? Properties : NotAllowed,
        },
        {
          path: "/property/:id",
          component: PropertyDetail,
          hideNavBar: false,
        },
      ],
    },
    welcome: {
      routes: [
        {
          path: "/welcome",
          component: Welcome,
        },
      ],
    },
    customers: {
      routes: [
        {
          path: "/customers",
          component: isAllowed("customers") ? Customers : NotAllowed,
        },
        {
          path: "/customers/edit/:id",
          component: NewCustomer,
        },
        {
          path: "/customers/:id",
          component: Customer,
        },
      ],
    },
    outreach: {
      routes: [
        {
          path: "/outreach/email",
          component: Email,
        },
      ],
    },
    duplicates: {
      routes: [
        {
          path: "/duplicates/customers",
          component: DuplicateCustomers,
        },
      ],
    },
    custom_doc: {
      routes: [
        {
          hideNavBar: true,
          path: "/custom-docs/:id",
          component: CustomDocument,
        },
      ],
    },
    team: {
      routes: [
        {
          path: "/team",
          component: isAllowed("team") ? Team : NotAllowed,
        },
        {
          path: "/team/edit",
          component: EditTeam,
        },
        {
          path: "/team/newuser",
          component: NewUser,
        },
        {
          path: "/team/viewuser/:id",
          component: ViewUser,
        },
        {
          path: "/team/viewuser/:id/edit",
          component: EditUser,
        },
      ],
    },
    home: {
      routes: [
        {
          path: "/",
          component: Home,
        },
        {
          path: "/dashboard",
          component: isAllowed("dashboard") ? Dashboard : NotAllowed,
        },
      ],
    },
    admin: {
      routes: [
        {
          path: "/admin/employees/:id/:page?/:pageId?",
          exact: false,
          component: UserPermission,
        },
        {
          path: "/admin/project",
          component: ProjectSettings,
        },
        {
          path: "/admin/:page?/:id?/:category?",
          component: Admin,
        },
        {
          path: "/admin/newaccount",
          component: NewAccount,
        },
        {
          path: "/admin/appointment-types",
          component: AppointmentTypes,
        },
      ],
    },
    repair_request: {
      routes: [
        {
          path: "/repair_request/form",
          component: RepairRequestForm,
        },
        {
          path: "/repair_request",
          component: isAllowed("repair_request")
            ? RepairRequestManagement
            : NotAllowed,
        },
        {
          path: "/repair_request/public_form",
          public: true,
          component: PublicRepairRequestForm,
        },
      ],
    },
    absent: {
      routes: [
        {
          path: "/absent",
          component: isAllowed("absent") ? Absent : NotAllowed,
        },
        {
          path: "/absent/new",
          component: LeaveRequest,
        },
      ],
    },
    vehicle_inspection: {
      routes: [
        {
          path: "/vehicle_inspection",
          component: isAllowed("vehicle_inspection")
            ? VehicleInspection
            : NotAllowed,
        },
        {
          path: "/vehicle_inspection/dashboard",
          component: InspectionDashboard,
        },
        {
          path: "/vehicle_inspection/public",
          public: true,
          component: PublicVehicleInspection,
        },
      ],
    },
    projects: {
      routes: [
        {
          path: "/projects",
          component: isAllowed("projects") ? Projects : NotAllowed,
        },
        {
          path: "/projects/:id/:cat?/:catId?",
          component: Project,
        },
      ],
    },
    estimates: {
      routes: [
        {
          path: "/estimates",
          component: isAllowed("estimates") ? Estimates : NotAllowed,
        },
        {
          path: "/estimates/:id",
          component: Estimate,
        },
      ],
    },
    requests: {
      routes: [
        {
          path: "/requests/mass-schedule",
          component: MassScheduler,
        },
        {
          path: "/requests",
          component: isAllowed("requests") ? RequestParent : NotAllowed,
        },
        {
          path: "/requests/:id/:category?/:categoryId?",
          component: Request,
        },
      ],
    },
    services: {
      routes: [
        {
          path: "/services",
          component: Services,
        },
      ],
    },
    quotes: {
      routes: [
        {
          path: "/quotes",
          component: isAllowed("quotes") ? Quotes : NotAllowed,
        },
      ],
    },
    calendar: {
      routes: [
        {
          path: "/calendar",
          component: isAllowed("calendar") ? Calendar : NotAllowed,
        },
      ],
    },
    reports: {
      routes: [
        {
          path: "/reports",
          component: isAllowed("reports") ? Reports : NotAllowed,
        },
      ],
    },
    settings: {
      routes: [
        {
          path: "/settings/questions/:id?",
          component: isAllowed("settings") ? QuestionBuilder : NotAllowed,
        },
      ],
    },
  };

  const routes = Object.entries(routesData).map(([key, value]) =>
    value.routes.map((route, i) => {
      const exact = route.exact || true,
        navBar = !route.hideNavBar && !value.hideNavBar ? true : false,
        privateRoute = !route.public && !value.public ? true : false;

      const component = privateRoute ? (
        <PrivateRoute navBar={navBar} exact={exact} key={i} {...route} />
      ) : (
        <Route exact={exact} key={i} {...route} />
      );

      return component;
    })
  );

  return (
    <AppContainer theme={theme}>
      <Router history={history}>
        <Switch>
          {routes}
          <Route
            path="/subscription/planSelection"
            exact
            component={PlanSelection}
          />
          <Route
            path="/subscription/plan/essential"
            exact
            component={EssentialPlan}
          />
          <Route
            path="/subscription/plan/enhanced"
            exact
            component={EnhancedPlan}
          />
          <Route
            path="/subscription/plan/exclusive"
            exact
            component={ExclusivePlan}
          />
          <Route
            path="/signupConfirmationSplash"
            exact
            component={SignupConfirmationSplash}
          />
          <Route
            path="/emailVerificationSplash"
            exact
            component={emailVerificationSplash}
          />
          <Route component={NotFound} />
        </Switch>
        <Modal />
        <Snackbar />
        {isAuth && <OnboardingWizard />}
      </Router>
    </AppContainer>
  );
}
