import React from "react";
import { useAuthContext } from "contexts/auth";
import { Button } from "components/buttonV2";

export const NoPermission = (page: string) => {
  const authContext = useAuthContext();
  const authPermissions = authContext.permissions.pages;

  // console.log("isAuth", authContext.permissions.pages);

  if (authPermissions && !authPermissions.includes(`/${page}`)) {
    return (
      <div className="w-100 h-100 border flex flex-1 justify-center items-center -mt-[200px]">
        <div className="text-center mx-auto">
          <div className="border-2 border-red-500 px-5 py-3 rounded-md bg-slate-100">
            <h1>Unauthorized</h1>
            <p>You do not have permission to view this page</p>
          </div>
          <Button link={true} to="/" className="mx-auto mt-4 w-[120px]">
            Return Home
          </Button>
        </div>
      </div>
    );
  }

  // Return null if the user has permission
  return null;
}