import React, { useEffect, useState, useRef } from "react";
import { useUpload } from "../context/UploadContext";
import { PiMicrosoftExcelLogo } from "react-icons/pi";
import { BsThreeDotsVertical } from "react-icons/bs";
import axios from "axios";
import { useModalContext } from "components/modal";
import Import from "./Import";
import Pagination from "components/paginationV2";
import { AiFillDelete } from "react-icons/ai";
import { BsDownload } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
import { Button } from "components/buttonV2";
import { useSnackbarContext } from "components/snackbar";
import { useTranslation } from 'react-i18next';

interface ImportItem {
  id: string;
  import_name: string;
  import_type: "customers" | "inventory" | "services";
  created_at: string;
};

interface GroupedImports {
  [type: string]: ImportItem[];
};

interface ContentProps {
  className: string;
  type: string;
  imports: ImportItem[];
  currentPage: number;
  itemsPerPage: number;
  setImports: React.Dispatch<React.SetStateAction<GroupedImports>>;
  setFireGet?: React.Dispatch<React.SetStateAction<boolean>>;
};

const Content: React.FC<ContentProps> = ({ type, imports, className, currentPage, itemsPerPage, setImports, setFireGet }) => {
  const { t } = useTranslation();
  const tBase = "views.admin.uploadV2.components.ViewImports";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const { setModal } = useModalContext();
  const {  
    inventory,
    services,
    customers,
  } = useUpload();
  const { showSnackbar } = useSnackbarContext();
  const [activeDropdownId, setActiveDropdownId] = useState<string | null>(null);
  const [loadingGradient, setLoadingGradient] = useState<{ [key: string]: boolean }>({});
  const dropdownRef = useRef(null);

  useEffect(() => {
    setFireGet && setFireGet(prev => !prev);
  }, []);
  
  const getImport = async (id: string, type: string) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/import/getImportById/${type}/${id}`);
      return res.data; // Return the fetched import data
    } catch (error) {
      console.error("Error fetching imports", error);
      return null; // Return null in case of an error
    }
  };

  const viewImport = async (id: string, name: string, type: string) => {
    const importData = await getImport(id, type);
    setModal({
      label: "",
      component: <Import data={importData} type={type} name={name} {...{inventory, customers, services}} />,
    });
  };

  const handleDropdownToggle = (id: string) => {
    setActiveDropdownId(prevId => (prevId === id ? null : id));
  };

  const handleClickOutside = (event: MouseEvent) => {
    if ((dropdownRef.current as unknown as HTMLElement) && !(dropdownRef.current as unknown as HTMLElement).contains(event.target as Node)) {
      setActiveDropdownId(null);
    }
  };
  
  const filteredImports = imports.filter(importItem => importItem.import_type === type);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentImports = filteredImports.slice(indexOfFirstItem, indexOfLastItem);

  const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const deleteImport = async (id: string, type: string) => {
    try {
      setModal(undefined);
      setLoadingGradient(prev => ({ ...prev, [id]: true }));
      const res = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/import/deleteImportById/${type}/${id}`);
      await wait(700);
      if (res.status === 200) {
        setLoadingGradient(prev => ({ ...prev, [id]: false }));
        setImports(prevImports => ({
          ...prevImports,
          [type]: prevImports[type].filter((importItem: ImportItem) => importItem.id !== id),
        }));
      }
      showSnackbar(tr("Import deleted successfully"));
    } catch (error) {
      console.error("Error deleting import", error);
      setLoadingGradient(prev => ({ ...prev, [id]: false }));
      showSnackbar(tr("Error deleting import"));
    }
  };

  const downloadImport = async (id: string, name: string, type: string) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/import/downloadImport/${type}/${id}`, {
        responseType: 'blob'
      });
  
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove(); // Clean up the DOM
    } catch (error) {
      console.error("Error downloading import", error);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={className}>
      <div>
        {/* Headers */}
        <div className="grid grid-cols-8 gap-4 text-[#000000B3] text-sm font-bold mt-6 mb-2">
          <div className="col-span-1 pl-4">ID</div>
          <div className="col-span-4">{tr("Name")}</div>
          <div className="col-span-2">{tr("Date")}</div>
        </div>
        <div className="border-t border-primary " />
        {currentImports.map((item) => (
          <div key={item.id} className="relative">
            <div className="grid grid-cols-8 gap-4 hover:bg-[#f6fafe] py-2 relative">
              <div className="col-span-1 pl-4">
                <button className="inline-block" onClick={() => viewImport(item.id, item.import_name, item.import_type)}>{item.id}</button>
              </div>
              <div className="col-span-4">
                <button className="inline-block" onClick={() => viewImport(item.id, item.import_name, item.import_type)}>{item.import_name}</button>
              </div>
              <div className="col-span-2">
                <button className="inline-block" onClick={() => viewImport(item.id, item.import_name, item.import_type)}>{new Date(item.created_at).toLocaleDateString()}</button>
              </div>
              <div className="col-span-1 relative flex items-center">
                <button 
                  className={`hover:bg-gray-200 h-[32px] px-1 -my-2 ${activeDropdownId === item.id ? "bg-gray-200" : ""}`}
                  onClick={() => handleDropdownToggle(item.id)}
                >
                  <BsThreeDotsVertical />
                </button>
                {activeDropdownId === item.id && (
                  <div ref={dropdownRef} className="absolute top-[24px] left-[-100px] bg-white z-20 border shadow-sm">
                    <button 
                      className="flex items-center border w-full py-1.5 px-2.5 space-x-2 hover:bg-slate-100"
                      onClick={() => viewImport(item.id, item.import_name, item.import_type)}
                    >
                      <div>{tr("View")}</div>
                      <FaEye size={20} className="text-secondary cursor-pointer" />
                    </button>
                    <button 
                      className="flex items-center border w-full py-1.5 px-2.5 space-x-2 hover:bg-slate-100"
                      onClick={() => downloadImport(item.id, item.import_name, item.import_type)}
                    >
                      <div>{tr("Download")}</div>
                      <BsDownload size={20} className="text-green-500 cursor-pointer" />
                    </button>
                    <button 
                      className="flex items-center border w-full py-1.5 px-2.5 space-x-2 hover:bg-slate-100"
                      onClick={() => {    
                        setModal({
                        label: tr("Delete Import"),
                        component: 
                        <div>
                          <div>{tr("Are you sure you would like to delete this import?")}</div>
                          <div className="text-sm">
                            {tr("This action will")} <span className="italic">{tr("permanently delete")}</span> {tr("the import")} 
                            <br/>
                            {tr("and all associated data")}:
                            <div className="mt-1">
                              <ul className="list-disc list-inside text-gray-500">
                                {
                                  item.import_type === "customers" ? (
                                    <>
                                      <li>{tr("Customers")}</li>
                                      <li>{tr("Customer Addresses")}</li>
                                      <li>{tr("Additional Addresses")}</li>
                                      <li>{tr("Additional Contacts")}</li>
                                      {/* <li>{tr("Custom Fields")}</li> */}
                                      <li>{tr("Customer Tags")}</li>
                                      <li>{tr("Address Tags")}</li>
                                      <li>{tr("Notes")}</li>
                                    </>
                                  ) : item.import_type === "inventory" ? (
                                    <>
                                      <li>{tr("Inventory")}</li>
                                      <li>{tr("Categories")}</li>
                                      {/* <li>{tr("Product Tags")}</li> */}
                                    </>
                                  ) : item.import_type === "services" ? (
                                    <>
                                      <li>{tr("Services")}</li>
                                    </>
                                  ) : null
                                }
                              </ul>
                            </div>
                          </div>
                          <div className="my-2 text-secondary">{item.import_name}</div> 
                          <div className="flex flex-row-reverse w-full mt-4">
                            <Button onClick={() => deleteImport(item.id, item.import_type)}>{tr("Confirm")}</Button>
                            <Button className="mr-2" type="secondary" onClick={() => {setModal(undefined)}}>{tr("Cancel")}</Button>
                          </div>
                        </div>,
                        })
                      }}
                    >
                      <div>{tr("Delete")}</div>
                      <AiFillDelete size={20} className="text-red-500 cursor-pointer" />
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="border-t border-primary " />
            {loadingGradient[item.id] && (
              <div className="absolute top-0 right-0 h-full bg-gradient-to-l from-[#358ad1] from-10% via-secondary via-30% to-[#a2d4ff] to-90% animate-expandLeft" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

const ViewImports = () => {
  const { t } = useTranslation();
  const tBase = "views.admin.uploadV2.components.ViewImports";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { 
    selected, 
    viewImports, 
    setViewImports, 
    selectedImportType, 
    setSelectedImportType,
    pages,
    setPages,
  } = useUpload();
  const [imports, setImports] = useState<GroupedImports>({});
  const itemsPerPage = 10;
  const [fireGet, setFireGet] = useState(false);

  const groupImportsByType = (imports: ImportItem[]): GroupedImports => {
    const grouped: GroupedImports = {
      customers: [],
      inventory: [],
      services: []
    };

    imports.forEach(importItem => {
      if (grouped[importItem.import_type]) {
        grouped[importItem.import_type].push(importItem);
      }
    });

    return grouped;
  };
  
  const getImports = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/import/getImportIds/`);
      const groupedImports = groupImportsByType(res.data);
      setImports(groupedImports);
    } catch (error) {
      console.error("Error fetching imports", error);
    }
  };

  useEffect(() => {
    getImports();
  }, [fireGet]);
  	
  const handlePageChange = (pageNumber: number) => {
    setPages(prevPages => ({ ...prevPages, [selectedImportType]: pageNumber }));
  };  

  const handleImportTypeChange = (type: string) => {
    setSelectedImportType(type);
    setPages(prevPages => ({ ...prevPages, [type]: prevPages[type] || 1 }));
  };  

  const types = ["customers", "inventory", "services"];

  return (
    <div className="absolute w-full h-full z-[2]">
      {!viewImports && !selected && (
        <button
          className="mt-1.5 flex items-center hover:underline decoration-2 underline-offset-4 decoration-primary"
          onClick={() => setViewImports(true)}
        >
          {tr("View Imports")}{" "}
          <PiMicrosoftExcelLogo className="text-2xl ml-1.5 text-green-500" />
        </button>
      )}
      {viewImports && (
        <div 
          className="border border-gray-400 mt-[40px] rounded-xl w-full h-[calc(100%-56px)] overflow-y-scroll relative" 
          style={{
            WebkitMaskImage: '-webkit-radial-gradient(white, black)', // Hides corners
          }}
        >
          <div className="flex items-center justify-between sticky top-0 left-0 text-gray-500 text-[14px] bg-white z-[1] overflow-hidden border border-b">
            <div className="flex">
              {types.map(type => (
                <div key={type}>
                  <button 
                    onClick={() => handleImportTypeChange(type)}
                    className={`py-3 relative
                      ${selectedImportType === type 
                        ? "text-primary hover:text-primary hover:bg-white" 
                        : " hover:text-gray-800 hover:bg-[#edf1f4]"
                      }
                    `}
                  >
                    <span className="px-3">{tr(type.charAt(0).toUpperCase() + type.slice(1))}</span>
                    <div 
                      className={`${selectedImportType === type ? "absolute bottom-0 w-full h-[3px] bg-primary" : ""}`}
                    />
                  </button>
                </div>
              ))}
            </div>
          </div>
          <Content 
            type={selectedImportType} 
            imports={imports[selectedImportType] || []} 
            className="mt-5"
            currentPage={pages[selectedImportType] || 1}
            itemsPerPage={itemsPerPage}
            setImports={setImports}
            setFireGet={setFireGet}
          />
          <Pagination 
            itemsPerPage={itemsPerPage}
            currentPage={pages[selectedImportType] || 1} 
            totalPages={Math.ceil((imports[selectedImportType]?.length || 0) / itemsPerPage)}
            onPageChange={handlePageChange}
            className="mt-5"
          />
        </div>
      )}
    </div>
  );
};

export default ViewImports;