import react, { useState, useEffect } from "react";
import { Avatar } from "components/avatar";
import { SubtleButton, SubtleIconButton } from "components/button";
import format from "date-fns/format";
import * as React from "react";
import {
  RiArrowDownSLine,
  RiArrowRightSLine,
  RiCloseLine,
  RiPencilLine,
} from "react-icons/ri";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import { theme } from "styles/theme";
import { dollarFormatter } from "utils";
import AddAppointment from "../../appointmentCard/appointment/appointmentModal";
import StatusSelect from "components/react-select/status";
import LineItemsContainer from "containers/lineItems";
import CompletionNote from "../../appointmentCard/completionNote";

import AppointmentStatuses from "./AppointmentStatuses";
import ReminderDialog from "../../appointmentCard/reminderDialog";
import { parseDuration } from "utils/duration";
import { useAreYouSure } from "components/areYouSure";
import AppointmentProduction from "../../appointmentCard/appointmentProduction";
import Switch from "rc-switch";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";
import { convertFromUTC } from "utils/utcConversion";
import { useAuthContext } from "contexts/auth";

type Props = any;

export default function Appointment({
  appointment,
  handleStatusChange,
  handleDelete,
  setModal,
  parentId,
  i,
  length,
  acceptedBid,
  type,
  isBookit = false,
  responses,
  selectedAppts,
  setSelectedAppts,
  ...props
}: Props) {
  const {
    duration,
    id: appointmentId,
    fk_job_id,
    note,
    assignee,
    completion_note,
    actual_total,
    status,
  } = appointment;
  const { t, i18n } = useTranslation();
  const tBase = "containers.appointmentCardMini.components.Appointment";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const locale = i18n.language === "es" ? es : enUS;
  const { timezone } = useAuthContext();

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    (props.expand === true || props.expand === false) &&
      setExpanded(props.expand);
  }, [props.expand]);

  const queryClient = useQueryClient(),
    areYouSure = useAreYouSure();
  const getdateintervalinfo = () => {
    if (appointment.enddate !== null && appointment.enddate !== undefined) {
      return (
        appointment.date &&
        format(new Date(appointment.date + "T00:00"), "eee, MMM dd, yyyy", {
          locale: locale,
        }) +
          format(new Date(appointment.enddate + "T00:00"), "eee, MMM dd, yyyy", {
            locale: locale,
          })
      );
    } else {
      return (
        appointment.date &&
        format(new Date(appointment.date + "T00:00"), "eee, MMM dd, yyyy", {
          locale: locale,
        })
      );
    }
  };
  const date = getdateintervalinfo();

  const start_time = appointment.start_time_utc && convertFromUTC(appointment.start_time_utc, timezone);
  const end_time = appointment.end_time_utc && convertFromUTC(appointment.end_time_utc, timezone);

  const interval = start_time;

  const populateAssignee = assignee
    ? assignee.map(({ value, label }: any) => (
        <div key={value} className="flex">
          <Avatar showLabel size="small" user_id={value}>
            {label}
          </Avatar>
        </div>
      ))
    : "";

  const intAppointmentId = parseInt(appointmentId);

  const handleToggleSelection = (id: number) => {
    setSelectedAppts((prev: any) => {
      if (prev.includes(id)) {
        return prev.filter((item: any) => item !== id); // Remove the id if it's already selected
      } else {
        return [...prev, id]; // Add the id if it's not selected
      }
    });
  };

  return (
    <div className="flex flex-col">
      <div className="flex [&>*]:mr-4 py-2">
        <div
          className="text-2xl text-gray-600"
          onClick={() => setExpanded((s) => !s)}
        >
          {expanded ? <RiArrowDownSLine /> : <RiArrowRightSLine />}
        </div>
        <div className="flex justify-center items-center w-10 h-10 rounded-[5px] text-2xl p-2 bg-[#edf1f4]">
          {length - i}
        </div>
        <div className="flex flex-1 flex-col justify-between">
          <div className="flex flex-wrap text-sm font-bold text-slate-500">
            <span>#{appointment.id}</span>
            {note && (
              <>
                <span className="mx-1">|</span>
                <span>{appointment.note}</span>
              </>
            )}

            {appointment.appointment_category && (
              <>
                <span className="mx-1">|</span>
                <span>{appointment.appointment_category}</span>
              </>
            )}
            <span className="flex items-center px-2 mx-1 text-xs font-bold capitalize rounded bg-slate-100 text-slate-700">
              {appointment.type}
            </span>
            <span>{dollarFormatter(actual_total)}</span>
          </div>

          <div className="flex [&>*]:mr-0.5">
            <div className="font-semibold">
              {date || (
                <span style={{ color: "red", fontWeight: "bold" }}>
                  {tr("No Date")}
                </span>
              )}
            </div>
            <div className="ml-2 text-gray-500">
              {start_time && start_time}
              {start_time && end_time && "-"}
              {end_time && end_time}
              {duration
                ? " | " +
                  parseDuration(duration).hr +
                  "h " +
                  parseDuration(duration).mins +
                  "m"
                : ""}
            </div>
          </div>
          <div className="flex [&>*]:mr-2">{populateAssignee}</div>
        </div>
        <div className="-mt-1">
          <AppointmentStatuses appointmentId={appointmentId} />
        </div>
        <div className="-mt-1">
          <Switch
            onChange={() => handleToggleSelection(appointment.id)}
            checked={selectedAppts.includes(appointment.id)}
          />
        </div>
      </div>
      {expanded && (
        <div className="p-4 border-t bg-slate-100/40">
          <CompletionNote completion_note={completion_note} />
          <LineItemsContainer
            type="appointment"
            id={appointmentId}
            parentId={parentId}
            parentType={type}
            readOnly={true}
          />
          <AppointmentProduction appointmentId={appointmentId} />
        </div>
      )}
    </div>
  );
}