import styled from "styled-components";
import { theme } from "styles/theme";

const size = (size) => {
  switch (size) {
    case "large":
      return `
            font-size: 1rem;
            // padding: 0.5rem;
            `;
    case "small":
      return `
            font-size: 0.7rem;
            // padding: 0.25rem 0.5rem;
            `;
    default:
      return `
            font-size: 0.8rem;
            // padding: 0.25rem 1rem;
            `;
  }
};
export const StyledStatusTag = styled.div`
  text-transform: capitalize;
  border-radius: 25px;
  font-size: 0.8rem;
  font-weight: bold;
  display: inline-block;
  padding: 0.25em 0.75em;
  text-align: center;
  color: ${(props) => props.color || "black"};
  background: ${(props) => props.background || "#f0f0f0"};
  ${(props) => size(props.size)}
`;

const color = (status) => {
  switch (status) {
    case "new":
      return `
        color: #4A235A;
        background: #E8DAEF;
        `;
    case "in progress":
      return `
        color: brown;
        background: #FAE5D3;
        `;
    case "completed":
      return `
        color: green;
        background: #D4EFDF;
        `;
    case "canceled":
      return `
        color: red;
        background: #FDEDEC; 
      `;
    case "nuevo":
      return `
        color: #4A235A;
        background: #E8DAEF;
        `;
    case "en progreso":
      return `
        color: brown;
        background: #FAE5D3;
        `;
    case "completado":
      return `
        color: green;
        background: #D4EFDF;
        `;
    case "cancelado":
      return `
        color: red;
        background: #FDEDEC; 
      `;
    // Appointment Status
    case "Unscheduled":
      return `
        color: #e94b40;
        background: #f8efea;
        `;
    case "Unassigned":
      return `
        color: #d25400;
        background: #f8f1dd;
        `;
    case "Scheduled":
      return `
        color: #4A235A;
        background: #E8DAEF;
        `;
    case "In Progress":
      return `
        color: brown;
        background: #FAE5D3;
        `;
    case "Completed":
      return `
        color: green;
        background: #D4EFDF;
        `;
    case "Canceled":
      return `
        color: red;
        background: #FDEDEC; 
      `;
    case "Paused":
      return `
        color: black;
        background: #DCDCDC;
        `;
    case "On the Way":
      return `
        color: #4e4d00;
        background: #e1e0b3;
        `;
    case "Partially Complete":
      return `
        color: #e15901;
        background: #feede3;
        `;
    case "Programado":
      return `
        color: #4A235A;
        background: #E8DAEF;
        `;
    case "No Asignado":
      return `
        color: #d25400;
        background: #f8f1dd;
        `;
    case "En Progreso":
      return `
        color: brown;
        background: #FAE5D3;
        `;
    case "Completado":
      return `
        color: green;
        background: #D4EFDF;
        `;
    case "Cancelado":
      return `
        color: red;
        background: #FDEDEC; 
      `;
    case "Pausado":
      return `
        color: black;
        background: #DCDCDC;
        `;
    case "En Camino":
      return `
        color: #4e4d00;
        background: #e1e0b3;
        `;
    case "Parcialmente Completo":
      return `
        color: #e15901;
        background: #feede3;
        `;
    case "Sin Asignar":
      return `
        color: #d25400;
        background: #f8f1dd;
        `;
    case "Sin Programar":
      return `
        color: #e94b40;
        background: #f8efea;
        `;
    default:
      return `
        color: grey;
        background: #EBEDEF;
        `;
  }
};

export const StyledRequestStatusTag = styled(StyledStatusTag)`
  ${(props) => color(props.children)}
`;

export const StyledAppointmentStatusTag = styled(StyledStatusTag)`
  ${(props) => color(props.children)}
`;

export const StyledCategoryTag = styled.div`
  color: black;
  line-height: 1;
  font-size: 0.8rem;
  padding: 0.5em 0.5em;
  border-radius: 5px;
  background: #ebedef;
`;

export const SOwnerTag = styled.div`
  background: ${theme.bg.lightorange};
  font-weight: bold;
  color: ${theme.color.main};
  border-radius: 5px;
  line-height: 1;
  margin-left: 0.5rem;
  padding: 0.25em;
  font-size: 0.8rem;
`;
