import { ResponsiveContainer } from "recharts";
import { PieChart as RCPieChart, Pie, Cell } from "recharts";

const PieChart = () => {
  const COLORS = ["#00C49F", "#FFBB28", "#FF8042", "#0088FE"];
  const yearToDate = 2366192.75;
  const data = [
    { name: "Sales", value: 25 },
    { name: "Marketing", value: 25 },
    { name: "Development", value: 25 },
    { name: "Other", value: 25 },
  ];

  return (
    <div className="relative flex flex-col items-center text-sm">
      <ResponsiveContainer width="100%" height={200}>
        <RCPieChart>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            paddingAngle={5}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </RCPieChart>
      </ResponsiveContainer>
      <div className="absolute text-white font-bold text-lg top-[50%] translate-y-[-50%]">
        ${Math.round(yearToDate).toLocaleString()}
      </div>
      <div className="mt-4 flex justify-center space-x-4">
        {data.map((entry, index) => (
          <div
            key={entry.name}
            className="text-xs"
            style={{ color: COLORS[index % COLORS.length] }}
          >
            {entry.name} - {entry.value}%
          </div>
        ))}
      </div>
    </div>
  );
};

export default PieChart;
