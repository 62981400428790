import axios from "axios";
import * as React from "react";
import { useQuery } from "react-query";
import { TAppointment } from "types/appointment";
import { AppointmentCard } from "../components/AppointmentCard";

export default function PartiallyCompleted({
  toolTipModal,
  handleToolTip,
}: any) {
  const query = useQuery<TAppointment[] | null>(
    ["appointments", "partially completed"],
    async () =>
      await axios
        .get(
          `
                ${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/partially_completed
                `
        )
        .then((res) => res.data)
  );

  const populateAppts = query.data?.map((appt) => {
    const { start_time, start_time_utc, end_time, end_time_utc } = appt;
    const start = start_time_utc ? start_time_utc : start_time;
    const end = end_time_utc ? end_time_utc : end_time;
    return (
      <AppointmentCard
        key={appt.id}
        {...appt}
        canDrag={true}
        note={appt.date}
        {...{ toolTipModal, handleToolTip }}
        start={start}
        end={end}
      />
    );
  });
  return <div>{populateAppts}</div>;
}