import * as React from "react";
import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import Switch from "rc-switch";
import async from "react-select/async";
import { ReactReduxContext } from "react-redux";
import {
  RiAlarmWarningFill,
  RiFileForbidFill,
  RiTaskLine,
  RiFileListLine,
  RiFileCloudLine,
  RiFileTransferLine,
  RiMailSendFill,
  RiEditLine,
  RiMore2Fill,
} from "react-icons/ri";
import TooltippedElement from "components/TooltippedElement";
import { useModalContext } from "components/modal";
import { Button, OutlineButton } from "components/button";
import Dropdown from "components/dropdown";
import AreYouSureModal from "components/areYouSureModal";
import MultiInput from "components/input/multi";
import { useTranslation } from "react-i18next";

const Con = styled.div`
  padding: 1rem;
`;
const Rowcell = styled.div`
  margin-top: 5px;
  padding: 4px;
  display: block;
  text-align: center;
`;
const Title = styled.div`
  text-align: left;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    width: 45%;
  }
`;

export default function ServicePlansOnCustomer(props) {
  const { t } = useTranslation();
  const tBase = "views.customer.serviceplanDropdown";
  const tr = (key) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext();
  const cusId = props.id;
  const id = { id: cusId };
  const cusemail = props.email;
  const queryClient = useQueryClient();
  const plansData = useQuery(
    "plans",
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers?type=PLANS`
        )
        .then((res) => {
          // return array of plans sorted alphabetically by name
          return res.data?.sort((a, b) => {
            const nameA = a.name?.toLowerCase() || "";
            const nameB = b.name?.toLowerCase() || "";
            return nameA.localeCompare(nameB);
          })
        })
  );

  const customerplans = useQuery(
    ["customerplans", id.id, "plans"],
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers?type=CUSPLANS`,
          { params: { id: id.id } }
        )
        .then((res) =>
          res.data?.map((x) => {
            return x;
          })
        )
  );
  //console.log(customerplans.data?.map((a)=>{return a.service_planid}))
  const properties = useQuery(
    ["properties", id.id, "properties"],
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/${id.id}/property`,
          { params: { id: id.id } }
        )
        .then((res) =>
          res.data?.map((x) => {
            return x;
          })
        )
  );
  const addplantocustomer = useMutation((info) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/`, {
        type: "ADDPLAN",
        data: info,
      })
      .then(() => {
        queryClient.invalidateQueries();
      });
  });
  const removeplanfromcustomer = useMutation((info) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/`, {
        type: "DELETEPLAN",
        data: info,
      })
      .then(() => {
        queryClient.invalidateQueries();
      });
  });
  const sendContract = useMutation((info) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/`, {
        type: "SENDCONTRACT",
        data: info,
      })
      .then(() => {
        queryClient.invalidateQueries();
      });
  });
  const getPDFcontract = useMutation((info) => {
    return axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/?Cid=${cusId}&id=${info.id}&Aid=${info.property}&type=PDF`,
        {
          responseType: "URL",
        }
      )
      .then((res) => {
        return res.data;
      });
  });
  const reducelength = (string) => {
    if (string?.length > 40) {
      return string.substring(0, 40) + "...";
    } else {
      return string;
    }
  };
  const ConfirmContractsend = (props) => {
    const Pid = props.Pid;
    const property = props.property;

    const [email, setEmail] = React.useState([cusemail]);

    return (
      <div
        style={{
          width: "500px",
          padding: "10px",
        }}
      >
        <p style={{ margin: "auto", marginBottom: "10px" }}>
          {tr("Enter the email(s) to send this contract to")}:
        </p>
        <MultiInput
          style={{ margin: "auto", marginBottom: "10px" }}
          values={email}
          onChange={(e) => setEmail(e)}
        />
        <div
          style={{
            margin: "auto",
            marginTop: "50px",
            marginBottom: "10px",
            width: "100%",
          }}
        >
          <Button
            primary
            style={{ float: "right" }}
            onClick={() => {
              sendContract.mutateAsync({
                Pid: Pid,
                Cid: id.id,
                property: property,
                emailList: email,
              });
              setModal();
            }}
          >
            {tr("Send")}
          </Button>

          <Button
            secondary
            onClick={() => {
              setModal();
            }}
          >
            {tr("Cancel")}
          </Button>
        </div>
      </div>
    );
  };

  const EditPlanModal = (props) => {
    const [plan, setPlan] = React.useState(props.plan);
    const [property, setProperty] = React.useState(props.property.id);
    const [message, setMessage] = React.useState();
    const [action, setAction] = React.useState();
    return (
      <div>
        <Row style={{ marginTop: "10px" }}>
          <Button
            primary
            hidden={props.plan.is_contract === false}
            style={{ margin: "auto" }}
            onClick={() => {
              setModal({
                label: tr("Send Contract"),
                component: (
                  <ConfirmContractsend Pid={plan.id} property={property} />
                ),
              });
            }}
          >
            {tr("Resend contract email")}
          </Button>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Button
            primary
            style={{ margin: "auto" }}
            onClick={() => {
              setAction("remove");
              setMessage(
                "Are you sure you want to remove this plan from this customer?"
              );
            }}
          >
            {tr("Remove Plan")}
          </Button>
        </Row>
        <Row style={{ marginTop: "10px", width: "30rem" }}>{tr(message)}</Row>
        <Row style={{ marginTop: "10px" }}>
          <Button
            secondary
            onClick={() => {
              setModal();
            }}
          >
            {tr("Cancel")}
          </Button>
          <Button
            style={{ float: "right" }}
            primary
            onClick={() => {
              if (action === "sendcontract") {
                sendContract.mutateAsync({
                  Pid: props.plan.id,
                  Cid: id.id,
                  property: property,
                });
              } else if (action === "remove") {
                removeplanfromcustomer.mutateAsync({
                  Pid: props.plan.id,
                  Cid: id.id,
                  property: property,
                });
              }
              setModal();
            }}
          >
            {tr("Confirm")}
          </Button>
        </Row>
      </div>
    );
  };
  const AddPlanModal = () => {
    const [plan, setPlan] = React.useState();
    const [property, setProperty] = React.useState();
    const [contractchosen, setContractchosen] = React.useState(false);
    return (
      <div>
        <p>{tr("Select a plan to add to the customer")}</p>
        <Dropdown
          reference={
            <OutlineButton>
              {plan
                ? plansData.data?.find((x) => {
                    if (x.id?.toString() === plan?.toString()) {
                      return true;
                    } else {
                      return false;
                    }
                  })?.name
                : tr("Select Plan")}
            </OutlineButton>
          }
          data={[
            {
              button: plansData.data?.map((x) => {
                if (x.is_contract === true) {
                  return {
                    label: x.name + " (contract)",
                    onClick: () => {
                      setPlan(x.id);
                      setContractchosen(true);
                    },
                    style: {
                      cursor: "pointer",
                    },
                  };
                }
                return {
                  label: x.name,
                  onClick: () => {
                    setPlan(x.id);
                  },
                  style: {
                    cursor: "pointer",
                  },
                };
              }),
            },
          ]}
        />
        <p>{tr("Select a property for this plan to apply to")}:</p>
        <Dropdown
          reference={
            <OutlineButton>
              {property
                ? properties.data?.find((x) => {
                    if (x.id?.toString() === property?.toString()) {
                      return true;
                    } else {
                      return false;
                    }
                  })?.address +
                  " " +
                  properties.data?.find((x) => {
                    if (x.id?.toString() === property?.toString()) {
                      return true;
                    } else {
                      return false;
                    }
                  })?.city +
                  ", " +
                  properties.data?.find((x) => {
                    if (x.id?.toString() === property?.toString()) {
                      return true;
                    } else {
                      return false;
                    }
                  })?.state +
                  " " +
                  properties.data?.find((x) => {
                    if (x.id?.toString() === property?.toString()) {
                      return true;
                    } else {
                      return false;
                    }
                  })?.zipcode
                : tr("Select Property")}
            </OutlineButton>
          }
          data={[
            {
              button: properties.data?.map((x) => {
                let name =
                  x.address + " " + x.city + ", " + x.state + " " + x.zipcode;
                return {
                  onClick: () => {
                    setProperty(x.id);
                  },
                  label: name,
                  value: x.id,
                };
              }),
            },
          ]}
        />
        <Row style={{ marginTop: "10px" }}>
          <Button
            secondary
            onClick={() => {
              setModal();
            }}
          >
            {tr("Cancel")}
          </Button>
          <Button
            style={{ float: "right" }}
            primary
            onClick={() => {
              if (contractchosen === true) {
                setModal({
                  label: "Send Contract",
                  component: (
                    <ConfirmContractsend Pid={plan} property={property} />
                  ),
                });
              } else {
                addplantocustomer.mutateAsync({
                  Pid: plan,
                  Cid: id.id,
                  property: property,
                });
                setModal();
              }
            }}
          >
            {tr("Confirm")}
          </Button>
        </Row>
      </div>
    );
  };



	const PlanList = () => {
		const Contractcell =(props) => {
			if (props.plan?.is_contract === true) {
				if(customerplans.data?.map((a)=>{return a.service_planid})?.includes(props.plan.id)){
					if(customerplans.data?.map((a)=>{return a.service_planid}).find((x)=>{if(x.service_planid?.toString()===props.plan.id?.toString()){return true}else{return false}})?.signed){
						return(<TooltippedElement message={tr("View approved contract")} element={<RiTaskLine style={{color:'green',cursor:'pointer', marginRight:'5px', display:'inline'}}
							onClick={async ()=>{getPDFcontract.mutateAsync({id:props.plan.id,property:props.property}).then((res)=>{window.open(res)})}}
						/>}/>)
					}else{
						return(<TooltippedElement message={tr("View unapproved contract")} element={<RiFileCloudLine style={{color:'green',cursor:'pointer', marginRight:'5px', display:'inline'}}
							onClick={async ()=>{getPDFcontract.mutateAsync({id:props.plan.id,property:props.property}).then((res)=>{window.open(res)})}}
						/>}/>)
					}
				}else{
					return(<TooltippedElement message={tr("View unsent contract")} element={<RiFileListLine style={{color:'green',cursor:'pointer', marginRight:'5px', display:'inline'}}
						onClick={async ()=>{getPDFcontract.mutateAsync({id:props.plan.id,property:props.property}).then((res)=>{window.open(res)})}}
					/>}/>)
				}
			} else{
			return(<TooltippedElement message={tr("Contract not required")} element={<RiFileForbidFill style={{color:'red' , marginRight:'5px', display:'inline'}}/>
			}/>)}
		};
		
		return (
			<div style={{cursor:'default'}}>
				<Row
					style={{
						margin: "auto",
						width: "100%",
						textAlign: "left",
						border: "none",
						borderBottom: "solid",
						borderColor: "##fff",
						borderWidth: "1.5px",
						padding: "1px",
					}}
				>
					<Rowcell
						style={{
							margin: "5px",
							width: "25%",
							textAlign: "left",
						}}
					>
						{tr("Plan Name")}
					</Rowcell>
					<Rowcell
						style={{
							margin: "5px",
							width: "20%",
							textAlign: "center",
						}}
					>
						{tr("Plan Description")}
					</Rowcell>
					<Rowcell
						style={{
							margin: "5px",
							width: "20%",
							textAlign: "center",
						}}
					>
						{tr("Property")}
					</Rowcell>
					<Rowcell
						style={{
							margin: "5px",
							width: "15%",
							textAlign: "center",
						}}
					>
						{tr("Approval Date")}
					</Rowcell>
					<Rowcell
						style={{
							margin: "5px",
							width: "15%",
							textAlign: "center",
						}}
					>
						{tr("Duration (Months)")}
					</Rowcell>
					<Rowcell
						style={{
							margin: "5px",
							width: "5%",
							textAlign: "right",
						}}
					>
						{tr("Options")}
					</Rowcell>
				</Row>
			{
				customerplans.data
					?.map((cusplanrow) => {
						const plan = plansData.data?.find((x) => 
							x.id?.toString() === cusplanrow.service_planid?.toString()
						);
						return { cusplanrow, plan };
					})
					.sort((a, b) => {
						// sorts alphabetically, by plan name
						const nameA = a.plan?.name?.toLowerCase() || "";
						const nameB = b.plan?.name?.toLowerCase() || "";
						return nameA.localeCompare(nameB);
					})
					.map(({ cusplanrow, plan }) => (
						<Row
							style={{
								margin: "auto",
								width: "100%",
								textAlign: "left",
								border: "none",
								borderBottom: "solid",
								borderColor: "#aaa",
								borderWidth: "1px",
								opacity: cusplanrow.is_active ? "1" : "0.5",
							}}
						>
							<Rowcell
								style={{
									margin: "5px",
									width: "25%",
									textAlign: "left",
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								<Contractcell
									plan={plan}
									property={cusplanrow.customer_address.id}
								/>
								<p style={{ display: "inline" }}>{plan?.name}</p>
							</Rowcell>
							<Rowcell
								style={{
									margin: "5px",
									width: "20%",
									textAlign: "center",
									alignItems: "center",
								}}
							>
								<div
									style={{ margin: "auto", width: "fit-content" }}
								>
									<TooltippedElement
										message={plan?.description}
                    placement="bottom"
										element={
											<p style={{ display: "block", margin: "auto", maxWidth: "200px" }}>
												{reducelength(plan?.description)}
											</p>
										}
									/>
								</div>
							</Rowcell>
							<Rowcell
								style={{
									margin: "5px",
									width: "20%",
									textAlign: "center",
									alignItems: "center",
								}}
							>
								{cusplanrow.customer_address?.address +
									" " +
									cusplanrow.customer_address?.city +
									", " +
									cusplanrow.customer_address?.state +
									" " +
									cusplanrow.customer_address?.zipcode || tr("No Property")}
							</Rowcell>
							<Rowcell
								style={{
									margin: "5px",
									width: "15%",
									textAlign: "center",
									alignItems: "center",
								}}
							>
								{cusplanrow.date_signed?.toString().slice(0, 10) ||
									tr("Pending")}
							</Rowcell>
							<Rowcell
								style={{
									margin: "5px",
									width: "15%",
									textAlign: "center",
									alignItems: "center",
								}}
							>
								{plan?.duration}
							</Rowcell>
							<Rowcell
								style={{
									margin: "5px",
									width: "5%",
									textAlign: "right",
									display: "flex",
									flexDirection: "row",
									alignItems: "right",
									justifyContent: "flex-end",
									cursor: "pointer",
								}}
								onClick={() => {
									setModal({
										label: tr("Plan Options"),
										component: (
											<EditPlanModal
												plan={plan}
												property={cusplanrow.customer_address}
											/>
										),
									});
								}}
							>
								<RiMore2Fill />
							</Rowcell>
						</Row>
					))
			}
			</div>
		);
	};
	return (
		<div>
			<Row>
				<h1>{tr("Service Plans")}:</h1>
				<Button
					primary
					style={{ float: "right" }}
					onClick={() => {
						setModal({
							component: <AddPlanModal/>						});
					}}>
					{tr("Add Plan")} </Button>
			</Row>

      <PlanList></PlanList>
    </div>
  );
}
