import axios from "axios";
import { useModalContext } from "components/modal";
import { useQuery, useQueryClient } from "react-query";

type Props = {
  vendorId: number;
};

const getItems = async (vendorId: number) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/api/v1/company/heritage/all-items`
  );
  return response.data;
};

const VendorRemoteModal = ({ vendorId }: Props) => {
  const { setModal } = useModalContext();
  const queryClient = useQueryClient();
  const { data, isLoading, isError } = useQuery("remoteItems", () =>
    getItems(vendorId)
  );

  const onRefreshPrices = async () => {
    await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/company/heritage/update-prices`
    );
    queryClient.invalidateQueries("remoteItems");
  };

  const onClose = () => {
    setModal();
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error...</div>;
  }

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <table className="w-full border-collapse border border-gray-200">
        <thead>
          <tr className="bg-gray-100 text-left">
            <th className="p-2 border border-gray-200">Item ID</th>
            <th className="p-2 border border-gray-200">Name</th>
            <th className="p-2 border border-gray-200">Part Number</th>
            <th className="p-2 border border-gray-200 text-right">Price</th>
            <th className="p-2 border border-gray-200">Lead Time</th>
            <th className="p-2 border border-gray-200">Notes</th>
            <th className="p-2 border border-gray-200">Last Updated</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item: any) => (
            <tr key={item.id} className="odd:bg-white even:bg-gray-50">
              <td className="p-2 border border-gray-200">{item.item_id}</td>
              <td className="p-2 border border-gray-200">{item.item_name}</td>
              <td className="p-2 border border-gray-200">
                {item.vendor_part_number}
              </td>
              <td className="p-2 border border-gray-200 text-right font-semibold text-gray-800">
                ${(Number(item.price) / 100).toFixed(2)}
              </td>
              <td className="p-2 border border-gray-200">
                {item.lead_time || "N/A"}
              </td>
              <td className="p-2 border border-gray-200">
                {item.notes || "N/A"}
              </td>
              <td className="p-2 border border-gray-200">
                {new Date(item.last_update).toLocaleString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Buttons Section */}
      <div className="mt-4 flex justify-end gap-4">
        <button
          onClick={onRefreshPrices}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          Refresh Prices
        </button>
        <button
          onClick={onClose}
          className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default VendorRemoteModal;
