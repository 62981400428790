import * as React from "react";
import {
  IconButton,
  Button,
  SubtleIconButton,
  OutlineButton,
  NoStyleA,
} from "components/button";
import Axios, { AxiosResponse } from "axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Status } from "../request/component/status";
import { Link, useHistory, useParams } from "react-router-dom";
import { Info } from "./info";
import {
  RiAddFill,
  RiArrowDownSFill,
  RiArrowDownSLine,
  RiCheckboxCircleFill,
  RiCheckLine,
  RiMenuLine,
  RiEdit2Line,
  RiSave3Line
} from "react-icons/ri";
import Timeline from "../request/container/timeline";
import { useSnackbarContext } from "components/snackbar";
import { TEstimate } from "types/estimate";
import { theme } from "styles/theme";
import { TransparentInput } from "components/input";
import Dropdown from "components/dropdown";
import styled from "styled-components";
import { useModalContext } from "components/modal";
import MultiInput from "components/input/multi";
import { TCustomer } from "types/customer";
import { StyledLabel } from "components/input/style";
import MarkEstimateAsWonModal from "./markEstimateAsWonModal";
import LineItemsContainer from "containers/lineItems";
import CustomerContainer from "containers/customer";
import SafeArea from "components/safe-area";
import BreadCrumbs from "components/breadcrumbs";
import DataLabel from "components/dataLabel";
import Spinner from "components/spinner";
import SendEstimateModal from "./sendEstimateModal";
import { useAreYouSure } from "components/areYouSure";
import ApplyTemplateModal from "views/projects/views/project/views/quote/applyTemplate";
import Tab from "components/tab";
import Field from "components/field";
import { dollarFormatter } from "utils";
import { useTranslation } from "react-i18next";

export type Status = {
  id: number;
  color: string;
  label: string;
};
export type Details = {
  status: Status;
  entry_date: string;
  description: string;
  send_date: string | null;
  respond_date: string | null;
  converted_date: string | null;
  declined_note: string | null;
  products: any;
};

export default function Estimate() {
  const { t } = useTranslation();
  const tBase = "views.estimate.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const { id } = useParams<{ id: string }>();
  const { showSnackbar } = useSnackbarContext();
  const [selectedOption, setSelectedOption] = React.useState<string | null>(
    null
  );

  const [editingTab, setEditingTab] = React.useState<number | null>(null);
  const [editingName, setEditingName] = React.useState<boolean>(false);

  const history = useHistory();
  const { setModal } = useModalContext(),
    areYouSure = useAreYouSure();

  const query = useQuery<TEstimate>(
    ["estimate", id],
    async () =>
      id &&
      (await Axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/estimates/${id}`
      ).then((res) => res.data)),
    {
      refetchOnWindowFocus: false,
    }
  );

  const [editNameInput, setEditNameInput] = React.useState<string>(query.data?.description || "");

  const updateParts = useMutation(
    async (e: any) =>
      query.data?.options &&
      (await Axios.post(`
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/estimates/${
          selectedOption || query.data?.options[0].id
        }/updateEstimateProducts
`,
        e
      ).then((res) => res.data)),
    {
      onSuccess: () => {
        query.refetch();
        showSnackbar(tr("Saved!"));
      },
    }
  );

  const duplicateEstimate = useMutation(
    async () =>
      await Axios.post(
        `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/estimates/${id}/duplicate
  `
      ).then((res) => res.data),
    {
      onSuccess: (newId) => {
        window.location.replace(`/estimates/${newId}`);
        // history.push(`/estimates/${newId}`);
      },
    }
  );

  const newOptionMutation = useMutation(
    async () =>
      await Axios.post(
        `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/estimates/${id}/option/new
  `,
        {
          count: query.data?.options?.length || 0,
        }
      ).then((res) => res.data),
    {
      onSuccess: (newId) => {
        query.refetch();
        setSelectedOption(newId);
      },
    }
  );

  const editOptionMutation = useMutation(
    async ({ id, value }: { id: number; value: string }) =>
      await Axios.post(
        `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/estimates/${id}/option/edit
  `,
        { value }
      ).then((res) => res.data),
    {
      onSuccess: () => {
        query.refetch();
        setEditingTab(null);
      },
    }
  );

  const deleteOptionMutation = useMutation(
    async ({ id }: { id: number }) =>
      await Axios.delete(
        `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/estimates/${id}/option
  `
      ).then((res) => res.data),
    {
      onSuccess: () => {
        setSelectedOption(null);
        query.refetch();
      },
    }
  );

  const LostEstimate = useMutation(
    async () =>
      await Axios.post(
        `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/estimates/lost/${id}
  `
      ).then((res) => res.data),
    {
      onSuccess: () => {
        showSnackbar(tr("Estimate marked as Lost!"));
        query.refetch();
      },
    }
  );
  const deleteEstimate = useMutation(
    async () =>
      await Axios.delete(
        `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/estimates/${id}
  `
      ).then((res) => res.data),
    {
      onSuccess: () => {
        showSnackbar(tr("Estimate deleted!"));
        history.replace(
          query.data?.fk_project_id
            ? `/projects/${query.data.fk_project_id}`
            : `/requests/${query.data?.fk_request_parent_id}`
        );
      },
    }
  );

  const updateEstimateName = useMutation(
    async (e: any) =>
      await Axios.post(
        `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/estimates/${id}/updateEstimateName
  `,
        e
      ).then((res) => res.data),
    {
      onSuccess: () => {
        query.refetch();
        showSnackbar(tr("Saved estimate name"));
      },
    }
  );

  const handleDuplicate = () => {
    duplicateEstimate.mutate();
  };

  const handleDelete = () => areYouSure.activate(() => deleteEstimate.mutate());
  const handleLost = () => areYouSure.activate(() => LostEstimate.mutate());
  if (query.isLoading) return <Spinner />;
  if (!query.data) return null;

  const statusId = query.data.status.id;

  const handleResend = () => {
    setModal({
      component: <ResendModal customer={query.data.customer} id={id} />,
      label: tr("Resend Estimate"),
    });
  };

  const editable = query.data.status.id === 1;

  const isConvertable = query.data.status.id === 5;

  let selectedIndex =
    query.data.options?.findIndex(
      (option) => option.id.toString() === selectedOption
    ) || -1;

  selectedIndex = selectedIndex === -1 ? 0 : selectedIndex;

  const populateOptions = query.data.options
    ? query.data.options.reduce(
        (curr, tab) => ({
          ...curr,
          [tab.id]: {
            tab:
              tab.id === editingTab ? (
                <Field
                  defaultValue={tab.label}
                  autoFocus
                  onBlur={(e) => {
                    let val = e.target.value.trim();
                    val && val !== tab.label
                      ? editOptionMutation.mutate({ value: val, id: tab.id })
                      : setEditingTab(null);
                  }}
                />
              ) : (
                <div className={`divide-x ${tab.selected && "text-green-600"}`}>
                  <span className="px-1">{tab.label}</span>
                  <span className="px-1">
                    {dollarFormatter(tab.actual_total)}
                  </span>
                  {tab.selected && (
                    <span className="bg-green-600 font-bold uppercase text-xs text-white rounded px-1">
                      {tr("WON")}
                    </span>
                  )}
                </div>
              ),
            buttons: (
              <Dropdown
                reference={
                  <SubtleIconButton size="xxsmall">
                    <RiMenuLine />
                  </SubtleIconButton>
                }
                data={[
                  {
                    button: [
                      {
                        label: tr("Edit"),
                        onClick: () => {
                          setEditingTab(tab.id);
                        },
                      },
                      {
                        label: tr("Apply Template"),
                        onClick: () => {
                          setModal({
                            component: (
                              <ApplyTemplateModal
                                id={tab.id.toString()}
                                type="option"
                              />
                            ),
                            label: tr("Apply Template"),
                          });
                        },
                      },
                      {
                        label: tr("Delete"),
                        onClick: () =>
                          areYouSure.activate(() =>
                            deleteOptionMutation.mutate({
                              id: tab.id,
                            })
                          ),
                        isLoading: deleteOptionMutation.isLoading,
                      },
                    ],
                  },
                ]}
              />
            ),
          },
        }),
        {}
      )
    : null;

  return (
    <div className="divide-y">
      <SafeArea>
        <div className="flex justify-between items-start py-4">
          <div className="space-y-4">
            <div>
              <BreadCrumbs
                link={[{ label: tr("Estimate"), to: "/estimates" }]}
              />
              <div className="text-baseline">
                <span className="text-3xl">
                  {tr("Estimate")} #{id}
                </span>
                <span className="mx-1 text-xl font-light text-slate-600">
                  {tr("for")}
                </span>
                <NoStyleA to={`/customers/${query.data.customer.id}`}>
                  <span className="text-xl text-slate-800 font-medium">
                    {query.data.customer.name}
                  </span>
                </NoStyleA>
                <Status color={query.data.status.color}>
                  {tr(query.data.status.label)}
                </Status>
              </div>
            </div>
            <div className="flex items-center space-x-3">
                  {editingName ? (
                    <TransparentInput
                      value={editNameInput} // Controlled input
                      onChange={(e) => setEditNameInput((e.target as HTMLInputElement).value)} // Update state on input change
                    />
                  ) : (
                    <h2>{query.data.description}</h2>
                  )}

                  <IconButton
                    size="small"
                    onClick={() => {
                      if (editingName) {
                        // Save functionality
                        updateEstimateName.mutate({
                          description: editNameInput, // Use inputValue
                          id,
                        });
                        setEditingName(false); // Exit editing mode
                      } else {
                        // Edit functionality
                        setEditingName(true); // Enter editing mode
                        setEditNameInput(query.data.description); // Reset input value to current description
                      }
                    }}
                  >
                    {editingName ? <RiSave3Line color="#1e90ff" /> : <RiEdit2Line color="#6b7280" />}
                  </IconButton>
                </div>
          </div>
          <div className="ml-auto flex items-center space-x-4">
            {editable ? (
              <Button
                onClick={() =>
                  setModal({
                    component: (
                      <SendEstimateModal
                        estimateId={id}
                        emails={[
                          query.data.customer.email,
                          ...(query.data.customer.contacts
                            ?.filter((c) => c.email)
                            .map((c) => c.email) || []),
                        ]}
                      />
                    ),
                    label: tr("Send Estimate"),
                  })
                }
                primary
              >
                {tr("Send Estimate")}
              </Button>
            ) : (
              <>
                <Sent />
              </>
            )}
            {(statusId === 1 || statusId === 3) && (
              <Button
                onClick={() =>
                  setModal({
                    component: (
                      <MarkEstimateAsWonModal
                        id={id}
                        options={query.data.options}
                      />
                    ),
                    label: tr("Mark As Won"),
                  })
                }
                primary
              >
                {tr("Award Estimate")}
              </Button>
            )}
            <Dropdown
              reference={
                <Button>
                  <span>{tr("Action")}</span>
                  <RiArrowDownSLine />
                </Button>
              }
              data={[
                {
                  button: [
                    query.data.estimate_pdf
                      ? {
                          onClick: () =>
                            window.open(query.data.estimate_pdf, "_blank"),
                          label: tr("Print Estimate"),
                        }
                      : null,
                    query.data.contract_pdf
                      ? {
                          onClick: () =>
                            window.open(query.data.estimate_pdf, "_blank"),
                          label: tr("View Contract"),
                        }
                      : null,
                    {
                      onClick: handleDuplicate,
                      label: tr("Duplicate Estimate"),
                      isLoading: duplicateEstimate.isLoading,
                    },
                    {
                      hide: editable,
                      onClick: handleResend,
                      label: tr("Resend Estimate"),
                    },
                    {
                      onClick: handleLost,
                      label: tr("Mark Lost"),
                      isLoading: LostEstimate.isLoading,
                    },
                    {
                      className: "!text-red-600",
                      onClick: handleDelete,
                      label: tr("Delete Estimate"),
                      isLoading: deleteEstimate.isLoading,
                    },
                  ],
                },
              ]}
            />
          </div>
        </div>
      </SafeArea>
      <SafeArea>
        <div className="space-y-4">
          <CustomerContainer
            data={query.data.customer}
            selected_address={
              query.data.request?.fk_address_id.toString() || ""
            }
            size="sm"
          />
          <Timeline
            entry_date={query.data.entry_date}
            respond_date={query.data.respond_date}
            send_date={query.data.send_date}
            converted_date={query.data.converted_date}
            declined_note={query.data.declined_note}
            status={query.data.status}
            logs={query.data.logs}
          />
          <div className="divide-y">
            <div className="py-4">
              <h2>{tr("Summary")}</h2>
            </div>
            <div className="py-4">
              <Info details={query.data} />
            </div>
          </div>
        </div>
      </SafeArea>
      <SafeArea>
        <div className="min-h-[400px] py-4">
          <Tab
            data={populateOptions}
            defaultTab={selectedOption || undefined}
            onClick={(e) => setSelectedOption(e)}
            buttons={
              <IconButton
                primary
                size="small"
                onClick={() => newOptionMutation.mutate()}
                isLoading={newOptionMutation.isLoading}
              >
                <RiAddFill />
              </IconButton>
            }
            content={
              selectedOption ? (
                <LineItemsContainer id={selectedOption} type={"option"} />
              ) : (
                <span>hi</span>
              )
            }
          />
        </div>
      </SafeArea>
    </div>
  );
}

const SOption = styled(OutlineButton)<{ background: string; won: boolean }>`
  margin-right: 0.5rem;
  background: ${(props) => props.background};
  display: flex;
  border-color: ${(props) => props.won && "green"};
  position: relative;
`;

const Sent = () => (
  <div
    style={{
      color: "green",
      marginLeft: "auto",
      display: "flex",
    }}
  >
    <RiCheckLine style={{ fontSize: "1.2rem" }} />
    Sent
  </div>
);

const ResendModal = ({ customer, id }: { customer: TCustomer; id: string }) => {
  const { t } = useTranslation();
  const tBase = "views.estimate.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const snackbar = useSnackbarContext(),
    modal = useModalContext(),
    queryClient = useQueryClient();

  const [emails, setEmails] = React.useState<(string | null)[]>([]);

  const resendMutation = useMutation(
    async (body: { emails: (string | null)[] }) =>
      await Axios.post(
        `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/estimates/${id}/resend
  `,
        body
      ),
    {
      onSuccess: () => {
        snackbar.showSnackbar(tr("Estimate resent!"));
        modal.setModal();
        queryClient.invalidateQueries();
      },
    }
  );

  React.useEffect(() => {
    let tempEmails = customer.contacts?.map((contact) => contact.email) || [];
    tempEmails = [...tempEmails, customer.email];
    setEmails(tempEmails);
  }, [customer]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", maxWidth: "500px" }}
    >
      <StyledLabel>{tr("Emails")}</StyledLabel>
      <MultiInput values={emails} onChange={(e) => setEmails(e)} />
      <Button
        primary
        isLoading={resendMutation.isLoading}
        style={{ alignSelf: "flex-end", marginTop: "1rem" }}
        onClick={() => resendMutation.mutate({ emails })}
      >
        {tr("Resend")}
      </Button>
    </div>
  );
};
