import { Vendor } from "types/vendor";
import VendorDetailItem from "./VendorDetailItem/VendorDetailItem";
import { Button } from "components/button";
import styles from "./VendorDetails.module.scss";
import { useContext } from "react";
import { VendorContext } from "contexts/VendorContext";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useAreYouSure } from "components/areYouSure";
import { useTranslation } from "react-i18next";

type IProps = {
  vendor?: Vendor;
  refetch?: any;
};

const VendorDetails = ({ vendor, refetch }: IProps) => {
  const { t } = useTranslation();
  const tBase = "views.vendors.VendorBody.VendorDetails.VendorDetails";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { updateMode, toggleUpdateModeButton, deleteVendor } =
    useContext(VendorContext);
  const { activate } = useAreYouSure();

  if (!vendor) return null;

  const adjustheight = () => {
    var textarea = document.getElementById("vendorTextArea");
    var container = document.getElementById("vendorDetailContainer");
    // Adjust the container's height to match the textarea's scrollHeight
    if (textarea && container)
      container.style.height = textarea.scrollHeight + "px";
  };

  return (
    <div className={styles.container}>
      <div className={styles.details}>
        <div className={styles.title}>Details</div>
        <VendorDetailItem label="ID" vendorKey={"vendor_id"} />
        <VendorDetailItem
          label={tr("Address")}
          vendorKey={"street_address"}
          refetch={refetch}
          editable
        />
        <VendorDetailItem
          label={tr("City")}
          vendorKey={"city"}
          refetch={refetch}
          editable
        />
        <VendorDetailItem
          label={tr("State")}
          vendorKey={"vendor_state"}
          refetch={refetch}
          editable
          limit={2}
        />
        <VendorDetailItem
          label={tr("Zip")}
          vendorKey={"zip_code"}
          refetch={refetch}
          editable
          limit={5}
        />
        <VendorDetailItem
          label={tr("Notes")}
          vendorKey={"notes"}
          refetch={refetch}
          editable
          isNotes
          {...{ adjustheight }}
        />
        {updateMode ? (
          <Button
            onClick={() => toggleUpdateModeButton(true, refetch)}
            style={{
              position: "absolute",
              bottom: "0",
              right: "0",
              margin: "10px",
              width: "8em",
            }}
          >
            {tr("Save")}
          </Button>
        ) : (
          <Button
            size="small"
            onClick={() => toggleUpdateModeButton(false, refetch)}
            style={{
              position: "absolute",
              bottom: "0",
              right: "0",
              margin: "10px",
              width: "8em",
            }}
          >
            {tr("Update")}
          </Button>
        )}
      </div>

      <div className={styles.contact}>
        <div className={styles.title}>{tr("Contact")}</div>
        <VendorDetailItem
          label={tr("First")}
          vendorKey={"first_name"}
          refetch={refetch}
          editable
        />
        <VendorDetailItem
          label={tr("Last")}
          vendorKey={"last_name"}
          refetch={refetch}
          editable
        />
        <VendorDetailItem
          label={tr("Phone")}
          vendorKey={"phone_number"}
          refetch={refetch}
          editable
        />
        <VendorDetailItem
          label={tr("Email")}
          vendorKey={"email_address"}
          refetch={refetch}
          editable
        />
        <Button
          size="small"
          onClick={() =>
            activate(() => deleteVendor(refetch), {
              message: tr("Are you sure you want to delete this vendor?"),
            })
          }
          style={{
            position: "absolute",
            bottom: "0",
            right: "0",
            margin: "10px",
          }}
        >
          {tr("Delete Vendor")}
        </Button>
      </div>
    </div>
  );
};

export default VendorDetails;
