import React, { useState } from 'react';
import { getContrastTextColorHEX } from "utils/getContrastTextColor";

// creates each tag filter button
const ClickableTag = ({tag, setSelectedTags, setUnselectedTags, unselected}: any) => {
  const [tagData, setTagData] = useState(tag);

  const updateTags = () => {
    if (unselected) {
      // adds tag to setSelectedTags
      setSelectedTags((prevState:any) => [...prevState, tagData])

      // removes tag from setUnselectedTags
      setUnselectedTags((prevState:any) => prevState.filter((tag:any) => tag.id !== tagData.id))
    }
    else {
      // re-add tag in tagData, sort unselected tags by label, alphabetical order
      setUnselectedTags((prevState:any) => [...prevState, tagData])
      setUnselectedTags((prevState:any) => prevState.sort((a:any, b:any) => a.label.localeCompare(b.label)))
      
      // removes tag from setSelectedTags
      setSelectedTags((prevState:any) => prevState.filter((tag:any) => tag.id !== tagData.id))
    }
  }  

  const textColor = getContrastTextColorHEX(tag.color);

  return (
    <>
      {unselected
        ? (
            <button
              key={tag.id}
              onClick={updateTags}
              className={`rounded p-1 text-white`}
              style={{
                background: tag.color,
                display: "block",
                margin: "5px",
                textAlign: "center",
                color: textColor,
                border: "1px solid #e1e1e1",
              }}
            >
              {tag.label}
            </button>
          )
        : (
            <button
              key={tag.id}
              onClick={updateTags}
              className={`rounded p-1 text-white`}
              style={{
                background: tag.color,
                display: "block",
                margin: "5px",
                textAlign: "center",
                color: textColor,
                border: "1px solid #e1e1e1",
              }}
            >
              {tag.label}
            </button>
          )}
    </>
  ); 
}

export default ClickableTag;