import React, { useState } from "react";
import { ITags } from "./interfaces/ITags";
import { Tag } from "./Tag";
import {IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useTranslation } from 'react-i18next';

type Props = {
  tags: ITags[];
  custPropToggleOn: boolean;
  inventoryToggleOn: boolean;
};

const TagList = ({ tags, custPropToggleOn, inventoryToggleOn }: Props) => {
  const [showArchived, setShowArchived] = useState(false);
  const { t } = useTranslation();
  const tBase = "views.admin.tags.components.TagList";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const filteredTags = tags.filter((tag) => {
    if (custPropToggleOn && inventoryToggleOn) {
      return tag.type === "customer&property" || tag.type === "customer" || tag.type === "" || tag.type === null || tag.type === "inventory";
    } else if (custPropToggleOn) {
      return tag.type === "customer&property" || tag.type === "customer" || tag.type === "" || tag.type === null;
    } else if (inventoryToggleOn) {
      return tag.type === "inventory";
    } else {
      return false;
    }
  });

  const activeTags = filteredTags.filter(
    (tag) => tag.active
  );

  const archivedTags = filteredTags.filter(
    (tag) => !tag.active
  );

  return (
    <div>
      {/* Appointment Types */}
      <div>
        {activeTags.length > 0 ? 
          activeTags?.map((tag: ITags) => (
            <Tag
              key={tag.id}
              tag={tag}
            />
          ))
          : <div className="my-2 border p-4 rounded-md text-gray-400">No Tags Found</div>
        }
      </div>
      {/* Archived */}
      <div className="pt-3">
        <div className="flex items-center space-x-2">
          <h1>{tr("Archived")}</h1>
          <button
            onClick={() => setShowArchived(!showArchived)}
            className="flex items-center"
          >
            {showArchived 
              ? <IoIosArrowDown size={20} className="hover:text-primary" /> 
              : <IoIosArrowUp size={20} className="hover:text-primary" />
            }
          </button>
        </div>
        <div>
          {archivedTags.length > 0 ? 
            !showArchived && archivedTags?.map((tag: ITags) => (
              <Tag
                key={tag.id}
                tag={tag}
                archived={true}
              />
            ))
            : !showArchived && <div className="my-2 border p-4 rounded-md text-gray-400">No Archived Tags Found</div>
          }
        </div>
      </div>
      <div className="h-[100px]"></div>
    </div>
  );
};

export { TagList };
