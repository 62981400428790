import {
  StyledStatusTag,
  StyledRequestStatusTag,
  StyledAppointmentStatusTag,
  StyledCategoryTag,
  SOwnerTag,
} from "./style";
import { StyledComponent } from "styled-components";
import * as React from "react";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  color?: string;
  background?: string;
  size?: "large" | "small";
}

const Wrapper = (Component: StyledComponent<"div", any, {}, never>) => {
  return React.forwardRef(
    (props: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
      return (
        <Component {...props} ref={ref}>
          {props.children}
        </Component>
      );
    }
  );
};

export const StatusTag = Wrapper(StyledStatusTag);

export const RequestStatusTag = Wrapper(StyledRequestStatusTag);

export const AppointmentStatusTag = Wrapper(StyledAppointmentStatusTag);

export const CategoryTag = Wrapper(StyledCategoryTag);

export const OwnerTag = Wrapper(SOwnerTag);
