import React, { useRef } from "react";
import { IconButton, SubtleIconButton } from "components/button";
import { Input } from "components/input";
import DollarInputV2 from "components/input/dollarInputV2";
import { GrDrag } from "react-icons/gr";
import { RiCloseFill } from "react-icons/ri";
import { useDrag, useDrop } from 'react-dnd';

const ProductRow = (props: {
  product: any;
  products: any[];
  formik: any;
  index: number;
  deleteProduct: () => void;
  priceType: "list" | "sale" | "install";
  rateLabel: "list_price" | "unit_price" | "install_price";
  hideEmpty: boolean;
  moveProduct: (dragIndex: number, hoverIndex: number) => void;
  isGroupedProduct: boolean;
}) => {
  const { product, products, index, moveProduct } = props;
  const ITEM_TYPE = "PRODUCT";
  const ref = useRef<HTMLButtonElement>(null);

  const [{ isDragging }, drag] = useDrag({
    type: ITEM_TYPE,
    item: { index }, 
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ITEM_TYPE,
    hover: (draggedItem: { index: number }) => {
      const dragIndex = draggedItem.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;
      moveProduct(dragIndex, hoverIndex);
      draggedItem.index = hoverIndex;
    },
  });


  // const [, drop] = useDrop({
  //   accept: ITEM_TYPE,
  //   hover: (draggedItem: { index: number }) => {
  //     const dragIndex = draggedItem.index;
  //     const hoverIndex = index;
  //     if (dragIndex === hoverIndex) return;

  //     // Access the dragged and hover products from the full product list
  //     const draggedProduct = products[dragIndex];
  //     const hoverProduct = products[hoverIndex];

  //     // Check if the drag is valid (either both are standalone or both are in the same group)
  //     if (
  //       draggedProduct.fk_ingroup_id === hoverProduct.fk_ingroup_id ||
  //       (!draggedProduct.fk_ingroup_id && !hoverProduct.fk_ingroup_id)
  //     ) {
  //       moveProduct(dragIndex, hoverIndex);
  //       draggedItem.index = hoverIndex; // Update the dragged item index
  //     }
  //   },
  // });

  drag(drop(ref));

  let rate =
    props.priceType === "list"
      ? product.list_price
      : props.priceType === "sale"
      ? product.unit_price
      : product.install_price;
  
  if (!product.quantity && props.hideEmpty) return null;

  return (
    <div className="contents">
      <IconButton size="small" onClick={props.deleteProduct}>
        <RiCloseFill />
      </IconButton>
      <SubtleIconButton 
        size="small" 
        ref={ref}
        className={`
          hover:cursor-grab 
          hover:bg-blue-100
          active:cursor-grabbing 
        `}
      >
        <GrDrag />
      </SubtleIconButton>
      <Input
        size="small"
        name={`products[${props.index}].quantity`}
        onFocus={(e) => e.target.select()}
        formik={props.formik}
      />
      <Input
        size="small"
        name={`products[${props.index}].label`}
        formik={props.formik}
      />
      <Input
        size="small"
        name={`products[${props.index}].long_description`}
        formik={props.formik}
      />
      <DollarInputV2
        $size="small"
        onFocus={(e) => e.target.select()}
        name={`products[${props.index}].${props.rateLabel}`}
        formik={props.formik}
      />
      <DollarInputV2 disabled $size="small" value={product.quantity * rate} />
    </div>
  )
}

export default ProductRow;