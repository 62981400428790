import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../buttonV2";

const NotFound = () => {
  const { t } = useTranslation();
  const tBase = "components.404";
  const tr = (key: string) => t(`${tBase}.${key}`);

  return (
    <div className="w-screen h-screen bg-gradient-to-br from-[#ff4f00] to-[#1e90ff] flex items-center justify-center">
      <div className="relative text-center p-10 bg-white bg-opacity-90 rounded-lg shadow-lg max-w-lg mx-auto w-[400px]">
        <h1 className="text-7xl font-bold text-gray-800 mb-4">404</h1>
        <h2 className="text-2xl font-semibold text-gray-700 mb-2">
          {tr("Error")}
        </h2>
        <p className="text-lg text-gray-600 mb-6">{tr("Page Not Found")}</p>
        <Button
          link={true}
          to="/"
          className="px-6 py-3 bg-gradient-to-r from-[#ff4f00] to-[#1e90ff] text-white font-medium rounded-md shadow-md hover:shadow-lg transition duration-200 ease-in-out"
        >
          {tr("Return Home")}
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
