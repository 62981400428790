import { useEffect, useState, useRef } from "react";
import { ContainerWithSidebar } from "../../../components/layout/index";
import { SearchInput } from "../../../components/input";
import { MenuButton, Button, IconButton } from "../../../components/button";
import Spinner from "../../../components/spinner";
import axios from "axios";
import { FiPlus } from "react-icons/fi";
import { BiArrowToTop } from "react-icons/bi";
import { IoMdCart } from "react-icons/io";
import { AiOutlineCloseCircle, AiOutlineSearch } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { Modal } from "@material-ui/core";
import _ from "lodash";
import ParentCategory from "./ParentCategory";
import Category from "./Category";
import Item from "./Item";
import { useTranslation } from 'react-i18next';

import styles from "./index.module.css";
import CheckedBoxButton from "components/checkBoxButton";

const Order = (props) => {
  const { t } = useTranslation();
  const tBase = "views.purchasing.order.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const pickerRef = useRef(null);
  const [items, setItems] = useState([]);
  const [filter, setFilter] = useState("All Items");
  const [isLoading, setIsLoading] = useState(true);
  const [currentCart, setCurrentCart] = useState([]);
  const [currentCartIds, setCurrentCartIds] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [parentCategories, setParentCategories] = useState(null);
  const [categories, setCategories] = useState(null);
  const [addRecommendedToCart, setAddRecommendedToCart] = useState(false);

  const addToCart = async (itemId, quantity) => {
    await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/addToPurchasingCart`,
      {
        itemId,
        quantity,
      }
    );

    fetchCurrentCarts();
  };

  const fetchItems = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/purchasingItems`
    );

    function roundToTwoDecimals(value) {
      return Math.round(value * 100) / 100;
    }
  
    let data = response.data.map((item) => {
      item.in_stock = roundToTwoDecimals(item.in_stock);
      item.minimum = roundToTwoDecimals(item.minimum);
      item.maximum = roundToTwoDecimals(item.maximum);
      item.outstanding = roundToTwoDecimals(item.outstanding);
  
      return item;
    });

    console.log("Data: ", data);

    setItems(data);
    setFilteredItems(data);
    setIsLoading(false);
  };



  const fetchCurrentCarts = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/getCarts`
    );


    setCurrentCart(response.data.currentCart);
  };

  const handleScrollTo = (id) => {
    const element = document.getElementById(`pc-${id}`);

    const offset = 60;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const removeCartItem = async (id) => {
    await axios.delete(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/removeCartItem`,
      {
        data: {
          cartItemId: id,
        },
      }
    );

    fetchCurrentCarts();
  };

  const handleClose = () => {
    fetchCurrentCarts();
    setModalOpen(false);
  };

  useEffect(() => {
    fetchCurrentCarts();
    fetchItems();
  }, []);

  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  useEffect(() => {
    const roots = filteredItems.map((i) => {
      return {
        rootId: parseInt(i.root_id) || null,
        rootName: i.root_name || tr("Uncategorized"),
      };
    });
    const uniqueRoots = _.uniqBy(roots, "rootId");
    setParentCategories(uniqueRoots);

    const cats = filteredItems.map((i) => {
      return {
        categoryId: parseInt(i.category_id) || null,
        categoryName: i.category_name || tr("Uncategorized"),
        parentCategoryId: parseInt(i.parent_category_id) || null,
      };
    });
    const uniqueCats = _.uniqBy(cats, "categoryId");
    setCategories(uniqueCats);
  }, [filteredItems]);

  useEffect(() => {
    if (currentCart.length > 0) {
      const idList = currentCart?.map((item) => {
        return item.item_id;
      });

      setCurrentCartIds(idList);
    } else {
      setCurrentCartIds([]);
    }
  }, [currentCart]);

  useEffect(() => {
    // for every item in filtered items, if the item has a recommeneded amount (maximum - (in_stock - outstanding)), add it to the cart
    if (addRecommendedToCart) {
      filteredItems.forEach((item) => {
        const recommendedAmount = (item.in_stock + (item.outstanding || 0)) < item.minimum ? 
        item.maximum - (item.in_stock + (item.outstanding || 0))
        : 0  

        if (recommendedAmount > 0) {
          // consider batching these to one api call
          console.log("Adding to cart: ", item.item_id, recommendedAmount);
          addToCart(item.item_id, recommendedAmount);
        }
      });
    } else {
      // if addRecommendedToCart is false, remove all items from the cart
      currentCart.forEach((item) => {
        removeCartItem(item.cart_item_id);
      })
    }
  }, [addRecommendedToCart]);

  return (
    <>
      <div className="header-container">
        <div className="header-row">
          <div className="header">{filter && filter === "All Items" ? tr("All Items") : filter}</div>
          <SearchInput
            className="search"
            placeholder={tr("Search")}
            value={searchParam}
            onChange={(e) => {
              setSearchParam(e.target.value);
            }}
          />
          <AiOutlineSearch
            style={{ marginLeft: 10, cursor: "pointer" }}
            size={20}
          />
          <div
            onClick={() => {
              setModalOpen(true);
            }}
            className="cart"
          >
            <IoMdCart size={18} />
            <div className="cart-count">{currentCart.length}</div>
          </div>
        </div>
          <CheckedBoxButton 
            checkedValue={addRecommendedToCart}
            setCheckedValue={setAddRecommendedToCart}
            additionalStyling={{ marginRight: 0, marginLeft: "auto", marginTop: 10, marginBottom: 10, width: "auto" }}
          > 
            {tr("Add All Recommended Amounts to Cart")}
          </CheckedBoxButton>
      </div>

      <Modal open={modalOpen} onClose={handleClose}>
        <Cart
          removeCartItem={removeCartItem}
          fetchCounts={props.fetchCounts}
          handleClose={handleClose}
        />
      </Modal>
      {!isLoading ? (
        <div className={styles.layoutRow}>
          <div className={styles.navigator}></div>
          <div className={styles.floatingNavigator}>
            <div className={styles.floatingNavigatorContent}>
              <div className={styles.categoryHeader}>{tr("Categories")}</div>
              <hr />
              <div>
                {parentCategories &&
                  parentCategories.length > 0 &&
                  _.sortBy(parentCategories, ["rootId"])?.map((pc) => {
                    return (
                      <div
                        className={styles.rootButton}
                        onClick={() => handleScrollTo(pc.rootId)}
                      >
                        {pc.rootName}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          <div
            ref={pickerRef}
            className={styles.picker}
            style={{ paddingLeft: 20, paddingRight: 20 }}
          >
            {parentCategories &&
              parentCategories.length > 0 &&
              _.sortBy(parentCategories, ["rootId"])?.map((pc) => {
                return (
                  <ParentCategory
                    key={pc.rootId}
                    rootId={pc.rootId}
                    rootName={pc.rootName}
                  >
                    {categories &&
                      categories.length > 0 &&
                      _.sortBy(
                        categories.filter(
                          (c) => pc.rootId === c.parentCategoryId
                        ),
                        ["categoryId"]
                      )?.map((c) => (
                        <Category
                          key={c.categoryId}
                          categoryName={c.categoryName}
                        >
                          {filteredItems &&
                            filteredItems.length > 0 &&
                            _.sortBy(
                              filteredItems.filter(
                                (i) =>
                                  parseInt(i.category_id) ===
                                    parseInt(c.categoryId) ||
                                  (!c.categoryId && !i.category_id)
                              ),
                              ["item_id"]
                            )?.map((i) => {
                              return (
                                <Item
                                  itemId={i.item_id}
                                  itemName={i.item_name}
                                  imageUri={i.imageUri}
                                  model={i.model}
                                  description={i.long_description}
                                  inStock={i.in_stock}
                                  maximum={i.maximum}
                                  minimum={i.minimum}
                                  outstanding={i.outstanding}
                                  addToCart={addToCart}
                                  isInCart={ currentCartIds.filter((id) => id == i.item_id).length > 0 }
                                />
                              );
                            })}
                        </Category>
                      ))}
                  </ParentCategory>
                );
              })}
          </div>

          <div
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            className="to-top-btn"
          >
            <BiArrowToTop size={36} color="#ff4f00" />
          </div>
        </div>
      ) : (
        <div className="spinner-container">
          <Spinner />
        </div>
      )}
    </>
  );
};

const Cart = (props) => {
  const { t } = useTranslation();
  const tBase = "views.purchasing.order.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const [cart, setCart] = useState(null);

  const fetchCart = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/getCurrentCart`
    );

    // somewhat redundant since this rounding operation is also performed in the purchasingItems query
    function roundToTwoDecimals(value) {
      return Math.round(value * 100) / 100;
    }

    response.data.forEach((item) => {
      item.stock = roundToTwoDecimals(item.stock);
      item.minimum = roundToTwoDecimals(item.minimum);
      item.maximum = roundToTwoDecimals(item.maximum);
      item.outstanding = roundToTwoDecimals(item.outstanding);
    });

    setCart(response.data);
  };

  const submitCart = async () => {
    await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/submitCart`,
      {
        cart,
      }
    );

    props.handleClose();
    props.fetchCounts();
  };

  useEffect(() => {
    fetchCart();
  }, []);

  return (
    <div className="cart-modal">
      {cart ? (
        <>
          <div className="cart-modal-top-row">
            <div>{tr("Order")} {cart.length > 0 ? cart[0].id : null}</div>

            <AiOutlineCloseCircle
              className="modal-close-btn"
              color="red"
              size={28}
              onClick={props.handleClose}
            />
          </div>
          <div className="cart-modal-body">
            {cart.length > 0 &&
              cart?.map((item, index) => {
                return (
                  <div key={index} className="cart-row">
                    <div className="left-group">
                      <div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div>{item.pc_name}</div>
                          <div>{" -> "}</div>
                          <div>{item.category_name}</div>
                        </div>
                        <div>{item.item_name}</div>
                      </div>
                    </div>
                    <div className="middle-group">
                      <div className="group">
                        <div className="center">{tr("Minimum")}:</div>
                        <div className="center">{item.minimum}</div>
                      </div>
                      <div className="group">
                        <div className="center">{tr("Maximum")}:</div>
                        <div className="center">{item.maximum}</div>
                      </div>
                      <div className="group">
                        <div className="center">{tr("In Stock")}:</div>
                        <div className="center">{item.stock}</div>
                      </div>
                      <div className="group">
                        <div className="center">{tr("Recommended")}:</div>
                        <div className="center">
                          {item.maximum - (item.stock + item.outstanding)}
                        </div>
                      </div>

                      <div
                        className="group"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Input
                          className="qty-input"
                          item={item}
                          fetchCart={fetchCart}
                        />
                        <BsTrash
                          color="red"
                          size={16}
                          className="trash"
                          style={{ marginLeft: 20, marginTop: 5 }}
                          onClick={() => {
                            props.removeCartItem(item.cart_item_id);
                            fetchCart();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="cart-modal-bottom-row">
            <Button primary onClick={submitCart}>
              {tr("Submit")}
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
};

const Input = (props) => {
  const [quantity, setQuantity] = useState(props.item.quantity);

  const updateQuantity = async (cartItemId, itemId, quantity) => {
    if (quantity && quantity >= 0) {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/updateQuantity`,
        {
          cartItemId,
          itemId,
          quantity,
        }
      );

      if (response.status === 200) {
        setQuantity(quantity);
        props.fetchCart();
      }
    }
  };

  return (
    <>
      <input
        className="purchasing-quantity"
        value={quantity}
        onChange={(e) => {
          updateQuantity(
            props.item.cart_item_id,
            props.item.item_id,
            e.target.value
          );
        }}
      />
    </>
  );
};

const PurchasingInput = (props) => {
  const [quantity, setQuantity] = useState(
    props.max - props.quantity - props.outstanding < 0
      ? 0
      : props.max - props.quantity - props.outstanding
  );

  const [inCart, setInCart] = useState(false);

  const handleChange = (newQuantity) => {
    if (parseInt(newQuantity) && parseInt(newQuantity) >= 0) {
      setQuantity(newQuantity);
    } else {
      setQuantity(0);
    }
  };

  useEffect(() => {
    if (props.currentCartIds.filter((id) => id == props.itemId).length > 0) {
      setInCart(true);
    }
  }, []);

  return (
    <>
      <input
        className="purchasing-quantity"
        value={quantity}
        onChange={(e) => handleChange(e.target.value)}
      />
      <IconButton disabled={inCart} primary={true} size="large">
        <FiPlus
          onClick={() => {
            props.addToCart(props.itemId, quantity);
            setInCart(true);
          }}
        />
      </IconButton>
    </>
  );
};

export default Order;
