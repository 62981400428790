import { Avatar } from "components/avatar";
import { NoStyleA, OutlineButton } from "components/button";
import { useModalContext } from "components/modal";
import TableComponent from "components/table";
import { format, fromUnixTime, parseISO, isValid } from "date-fns";
import * as React from "react";
import { RiArrowRightLine } from "react-icons/ri";
import { TPayment } from "types/payment";
import { dollarFormatter } from "utils";
import Placeholder from "views/request/component/placeholder";
import ApplyDepositModal from "./applyDepositModal";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";

type Props = {
  data: (TPayment[] | null) | ((TPayment | null)[] | undefined);
  type: "request" | "invoice" | "project" | "all" | "customer";
  table?: any;
};

export default function PaymentsList(props: Props) {
  const { t, i18n } = useTranslation();
  const tBase = "containers.payments.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const modal = useModalContext();

  const locale = i18n.language === "es" ? es : enUS;

  const columns = {
    id: {
      label: "ID",
    },
    amount: {
      label: tr("Amount"),
      cell: (row: TPayment) => (
        <span className="font-bold">
          {dollarFormatter(
            props.type === "invoice" ? row.used_amount : row.amount
          )}
        </span>
      ),
    },
    is_deposit: {
      label: tr("Type"),
      cell: (row: TPayment) =>
        row.is_voided ? (
          <span className="bg-red-100 px-2 text-[0.8rem] uppercase rounded text-red-700 font-bold ">
            {tr("Voided")}
          </span>
        ) : row.is_deposit ? (
          <span className="bg-blue-100 px-2 text-[0.8rem] uppercase rounded text-blue-700 font-bold ">
            {tr("Deposit")}
          </span>
        ) : (
          <span className="px-2 text-[0.8rem] uppercase rounded font-bold bg-green-100 text-green-700">
            {tr("Payment")}
          </span>
        ),
    },
    customer: {
      label: tr("Customer"),
      cell: (row: TPayment) =>
        row.billed_to && (
          <Avatar size="xsmall" showLabel>
            {row.billed_to.name}
          </Avatar>
        ),
    },
    stripe_id: {
      label: tr("Method"),
      width: "50%",
      cell: (row: TPayment) =>
        row.stripe_id ? (
          <span className="flex text-blue-600">
            Stripe {row.payment_method === "ACH" ? "(ACH)" : tr("(card)")}
          </span>
        ) : (
          `Manual (${row.payment_method && tr(row.payment_method)})`
        ),
    },
    credit_ref: {
      label: tr("Credit To"),
      isClickable: true,
      cell: (row: TPayment) =>
        row.fk_request_id ? (
          <NoStyleA
            to={`/requests/${row.fk_request_id}`}
            className="!text-blue-600 hover:!underline"
          >
            {tr("Req")}#{row.fk_request_id}
          </NoStyleA>
        ) : row.fk_project_id ? (
          <NoStyleA
            to={`/projects/${row.fk_project_id}`}
            className="!text-blue-600 hover:!underline"
          >
            {tr("Proj")}#{row.fk_project_id}
          </NoStyleA>
        ) : (
          <NoStyleA
            to={`/customers/${row.fk_bcustomer_id}`}
            className="!text-blue-600 hover:!underline"
          >
            {tr("Cust")}#{row.fk_bcustomer_id}
          </NoStyleA>
        ),
    },
    time: {
      label: tr("Entry"),
      cell: (row: TPayment) => {
        try {
          if (row.received_on === null) {
            if (row.created === null) {
              return <span className="text-red-500">{tr("Unknown")}</span>;
            } else {
              const date = fromUnixTime(row.created);
              // console.log(`Parsed created date: ${date}`);
              return isValid(date)
                ? format(date, "MMM dd, yyyy", {
                  locale: locale,
                })
                : tr("Invalid Date");
            }
          } else {
            // Assuming received_on is in 'YYYY-MM-DD' format
            const [year, month, day] = row.received_on.split("-");
            const receivedDate = new Date(`${year}-${month}-${day}T00:00:00Z`);
            // console.log(`Parsed received date: ${receivedDate}`);
            return isValid(receivedDate)
              ? format(receivedDate, "MMM dd, yyyy", {
                locale: locale,
              })
              : tr("Invalid Date");
          }
        } catch (error) {
          console.error(
            `Error formatting date: ${row.received_on || row.created}`,
            error
          );
          return tr("Invalid Date");
        }
      },
    },
    invoice: {
      label: tr("Balance"),
      isClickable: true,
      cell: (row: TPayment) =>
        !row.is_voided &&
        row.remaining_total !== 0 &&
        props.type !== "invoice" &&
        (props.type === "request" ||
          props.type === "project" ||
          (!row.fk_request_id && !row.fk_project_id)) ? (
          <OutlineButton
            primary
            size="xsmall"
            onClick={() =>
              modal.setModal({
                component: (
                  <ApplyDepositModal
                    // @ts-ignore
                    type={props.type === "all" ? "customer" : props.type}
                    data={row}
                  />
                ),
                label: tr("Apply Deposit"),
              })
            }
          >
            {tr("Apply")} {dollarFormatter(row.remaining_total)}{" "}
            <RiArrowRightLine className="!text-xs" />
          </OutlineButton>
        ) : (
          row.is_voided ? dollarFormatter(0) : dollarFormatter(row.remaining_total)
        ),
    },
  };

  return (
    <div>
      {props.data ? (
        <TableComponent
          data={props.data}
          columns={columns}
          to={(row: any) => `/payments/${row.id}`}
          {...(props.table || {})}
        />
      ) : (
        <Placeholder
          type="payment"
          header={tr("No payment yet.")}
          label={tr("Payments will appear here when added.")}
        />
      )}
    </div>
  );
}
