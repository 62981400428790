import styled from 'styled-components';
import { Button } from "components/button";

const Container = styled.div`
  margin-top: 15px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* max-width: 400px; */
`;

const Title = styled.h1`
  text-align: center;
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
`;

const Select = styled.select`
  display: inline-block;
  width: 100%;
  height: 3rem;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* margin-top: 1rem; */
  font-size: 16px;
  background-color: #fff;
  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
  }
`;

const StyledButton = styled(Button)`
  /* margin-top: 15px; */
  float: right;
  font-size: 16px;
  /* padding: 10px 20px; */
`;

const WorkerList = styled.div`
  margin-top: 20px;
  max-height: 200px;
  overflow-y: auto;

  /* Custom Scrollbar Styles */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* Firefox scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;

  /* Scrollbar styles for Internet Explorer and Edge */
  -ms-overflow-style: -ms-autohiding-scrollbar;
`;


const RemoveButton = styled.button`
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #ff4f00;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #ff7043;
  }
`;

const CheckboxContainer = styled(Button)`
  display: flex;
  background-color: #FF4F00;
  align-items: center;
  /* margin-top: 10px; */
  height: 40px;
  width: 175px;
  border-radius: 5px;
  /* padding:10px; */
  color: #fff;

  &:disabled {
    width: 175px
  }
`;

const StyledCheckbox = styled.input`
  margin-left: 10px;
  accent-color: #007BFF; /* Customize the color */
  width: 20px;
  height: 20px;
`;

export {
    Container,
    Title,
    Select,
    StyledButton,
    WorkerList,
    RemoveButton,
    CheckboxContainer,
    StyledCheckbox
}