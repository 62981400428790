import * as React from "react";
import {
  addMultiple,
  addOption,
  handleSelectedQuestionChange,
  deleteQuestion,
  deleteOption,
  DeleteOption,
  handleLinkage,
  handleLinkageSelect,
  handleOptionEdit,
  changeQuestionTitle,
  ChangeQuestionTitle,
  changeQuestionType,
  populateInitial,
  handleRequired,
  setCategory,
  setGroupLinkage,
  HandleOptionEdit,
  HandleSelectedQuestionChange,
  DeleteQuestion,
  HandleLinkageSelect,
  PopulateInitial,
  ChangeQuestionType,
  HandleRequired,
  HandleClick,
  handleClick,
  SetCategory,
  SetGroupLinkage,
  SetIsPrimary,
  setIsPrimary,
  ChangeDivision,
  changeDivision,
  SetSubquestions,
  setSubquestions,
  handleOptionToSubQuestion,
  HandleOptionToSubQuestion,
  HandleLeadChange,
  handleLeadChange,
} from "./utils";
import Axios from "axios";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

export type Choices = {
  id: string;
  label: string;
  subquestion: string | null;
};

export type Questions = {
  title: string;
  id: any
  choices: Choices[];
  type: string;
  required: boolean;
};
export type GroupLinkage = {
  questionId?: any
  optionLinkage: {
    optionId?: string;
    groupId?: string | null;
  }[];
};
export type Category = {
  id: number;
  value: number;
  label: string;
  is_primary?: boolean;
  is_link?: boolean;
};

export type State = {
  questions: Questions[];
  snackBarUndoStatus: boolean;
  selectedQuestion: number;
  groupLinkage: GroupLinkage;
  initialQuestions: Questions[];
  isPrimary: boolean;
  category: Category[] | null;
  subquestions: Category[] | null;
  option: string;
  incompleteForm: boolean;
  division: number | null;
  leadTime: string | null;
};

export type Action =
  | ChangeQuestionTitle
  | HandleOptionEdit
  | HandleSelectedQuestionChange
  | DeleteQuestion
  | DeleteOption
  | HandleLinkageSelect
  | PopulateInitial
  | ChangeQuestionType
  | HandleRequired
  | SetCategory
  | SetSubquestions
  | SetGroupLinkage
  | HandleClick
  | SetIsPrimary
  | ChangeDivision
  | HandleLeadChange
  | {
      type: "handleLinkage";
    }
  | {
      type: "addOption";
    }
  | { type: "addMultiple" }
  | { type: "formCompleted" }
  | HandleOptionToSubQuestion;

const defaultState = {
  questions: [],
  snackBarUndoStatus: false,
  selectedQuestion: 0,
  groupLinkage: { questionId: undefined, optionLinkage: [] },
  initialQuestions: [],
  isPrimary: true,
  category: [],
  option: "",
  incompleteForm: false,
  division: null,
  subquestions: null,
  leadTime: null,
};

const QuestionBuilderStateContext = React.createContext<State>(defaultState);
const QuestionBuilderDispatchContext = React.createContext<
  React.Dispatch<Action>
>(() => null);

const runFunction = (state: State, action: Action): State => {
  switch (action.type) {
    // initialization
    case "initialLoad": {
      return populateInitial(state, action);
    }
    // category
    case "setCategory": {
      return setCategory(state, action);
    }
    case "setSubquestions": {
      return setSubquestions(state, action);
    }
    case "setIsPrimary": {
      return setIsPrimary(state, action);
    }
    // question
    case "deleteQuestion": {
      return deleteQuestion(state, action);
    }
    // Future implemenation: this will be turned into addQuestion and be used as the base for all question types
    case "addMultiple": {
      return addMultiple(state);
    }
    case "changeQuestionTitle": {
      return changeQuestionTitle(state, action);
    }
    case "changeQuestionType": {
      return changeQuestionType(state, action);
    }
    // option
    case "addOption": {
      return addOption(state);
    }
    case "deleteOption": {
      return deleteOption(state, action);
    }
    case "handleOptionEdit": {
      return handleOptionEdit(state, action);
    }
    case "handleOptionToSubQuestion": {
      return handleOptionToSubQuestion(state, action);
    }
    case "handleClick": {
      return handleClick(state, action);
    }
    // select
    case "changeSelectedQuestion": {
      return handleSelectedQuestionChange(state, action);
    }
    // linkage
    case "handleLinkage": {
      return handleLinkage(state);
    }
    case "handleLinkageSelect": {
      return handleLinkageSelect(state, action);
    }
    case "setGroupLinkage": {
      return setGroupLinkage(state, action);
    }
    // required
    case "handleRequired": {
      return handleRequired(state, action);
    }

    case "changeDivision": {
      return changeDivision(state, action);
    }
    case "handleLeadChange": {
      // console.log(state)
      return handleLeadChange(state, action);
    }

    // default
    default:
      return state;
  }
};

const noUpdateDispatchArray = [
  "initialLoad",
  "setCategory",
  "setIsPrimary",
  "handleClick",
  "changeSelectedQuestion",
  "setGroupLinkage",
  "formCompleted",
  "setSubquestions",
];

function questionBuilderReducer(state: State, action: Action): State {
  if (action.type === "formCompleted") {
    return { ...state, incompleteForm: false };
  }

  let updatedState = runFunction(state, action);
  if (!noUpdateDispatchArray.includes(action.type)) {
    updatedState = { ...updatedState, incompleteForm: true };
  }
  console.log(action.type)
  // console.log(updatedState)
  return updatedState;
}

function QuestionBuilderProvider({ children }: { children: React.ReactNode }) {
  const params = useParams<{ id: string }>();

  const fetchQuestions = useQuery(
    "questions",
    () =>
      params.id &&
      Axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/question/${params.id}`
      ).then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          dispatch({ type: "formCompleted" });
          dispatch({
            type: "initialLoad",
            payload: { questions: data.questions, division: data.division },
          });
          dispatch({ type: "setGroupLinkage", payload: data.group_link });
          dispatch({ type: "setIsPrimary", payload: data.is_primary });
        }
      },
    }
  );

  React.useEffect(() => {
    params?.id && fetchQuestions.refetch();
  }, [params.id]);

  const [state, dispatch] = React.useReducer(
    questionBuilderReducer,
    defaultState
  );

  return (
    <QuestionBuilderStateContext.Provider value={state}>
      <QuestionBuilderDispatchContext.Provider value={dispatch}>
        {children}
      </QuestionBuilderDispatchContext.Provider>
    </QuestionBuilderStateContext.Provider>
  );
}

function useQuestionBuilderState() {
  const context = React.useContext(QuestionBuilderStateContext);
  return context;
}

function useQuestionBuilderDispatch() {
  const context = React.useContext(QuestionBuilderDispatchContext);
  return context;
}

export {
  QuestionBuilderProvider,
  useQuestionBuilderDispatch,
  useQuestionBuilderState,
};
