import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { Button } from "components/button";
import styles from "./Item.module.scss";
import Field from "components/field";

const Item = ({
  itemId,
  itemName,
  imageUri,
  description,
  model,
  inStock,
  maximum,
  minimum,
  outstanding,
  addToCart,
  isInCart,
}) => {
  const { t } = useTranslation();
  const tBase = "views.purchasing.order.Item";
  const tr = (key) => t(`${tBase}.${key}`);

  const [added, setAdded] = useState(false);
  const [value, setValue] = useState(
    inStock + (outstanding|| 0) < minimum ?
    maximum - (inStock + (outstanding || 0)) < 0
      ? 0
      : maximum - (inStock + (outstanding || 0))
    : 0
  );
  const [failedToAdd, setFailedToAdd] = useState(false);
  const [errState, setErrState] = useState(null);

  const errorEnum = Object.freeze({
    err0: tr("Must Be A Number Greater Than Zero"),
    // no longer used since we don't want to restrict the user from adding more than the maximum stock
    err1: tr("Must Not Exceed Maximum Stock"),
  });

  const handleAdd = () => {
    setFailedToAdd(false);
    if (parseFloat(value) && parseFloat(value) === 0) {
      setErrState(errorEnum.err0);
    } 
    // else if (parseFloat(value) + inStock + (outstanding || 0) > maximum) {
    //   setErrState(errorEnum.err1);
    // }
    else {
      addToCart(itemId, value);
      setAdded(true);
    }
  };

  useEffect(() => {
    setErrState(null);
  }, [value]);

  useEffect(() => {
    if (isInCart) {
      setAdded(true);
    } else {
      setAdded(false);
    }
  }, [isInCart]);

  return (
    <div className={styles.container}>
      <div>
        {description && (
          <Tooltip title={description} placement="bottom-end">
            {itemName && <h5 className={styles.heading}>{itemName}</h5>}
          </Tooltip>
        )}
        {model && <div className={styles.model}>{model}</div>}
        {imageUri ? (
          <img
            loading="lazy"
            className={styles.image}
            src={imageUri}
            alt={`item-${itemId}`}
          />
        ) : (
          <div className={styles.defaultIcon}>
            {/* Insert your default icon SVG or any other placeholder image */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-12 h-12 text-gray-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 3h18v18H3V3z"
              />
            </svg>
          </div>
        )}
      </div>
      <div>
        <div className={styles.bodyText}>
          {tr("In Stock")}:{" "}
          <span className={inStock < minimum ? styles.redText : null}>
            {inStock}
          </span>
        </div>
        <div className={styles.bodyText}>{tr("Minimum")}: {minimum}</div>
        <div className={styles.bodyText}>{tr("Maximum")}: {maximum}</div>
        <div className={styles.bodyText}>{tr("Outstanding")}: {outstanding || 0}</div>
        <div>
          {tr("Recommended")}:{" "}
          { inStock + (outstanding || 0) < minimum ? 
            maximum - (inStock + (outstanding || 0)) < 0
            ? 0
            : maximum - (inStock + (outstanding || 0))
            : 0
          }

        </div>
        <div>
          <div className={styles.row}>
            <Field
              backgroundColorFocus="white"
              className={errState ? styles.inputFailed : styles.inputNormal}
              value={value} 
              setValue={setValue}
              onBlur={() => value === "" &&
                setValue(
                  inStock + (outstanding|| 0) < minimum ?
                  maximum - (inStock + (outstanding || 0)) < 0
                    ? 0
                    : maximum - (inStock + (outstanding || 0))
                  : 0
                )
              }
              advancedNumericalInputHandling 
            />
            <Button
              disabled={added}
              primary={!added}
              onClick={handleAdd}
              className={styles.addButton}
              style={{ paddingLeft: 20, paddingRight: 20, borderRadius: 10 }}
            >
              +
            </Button>
          </div>
          {errState && (
            <div className={styles.error}>
              {errState}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Item;
