import React from "react";
import styled from "styled-components";
import Employees from "./userlist";
import Logslist from "./activitylog";
import { Link } from "components/link";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import NotificationsSettings from "./notifications";
import Requests from "./requests";
import { ContainerWithSidebar } from "components/layout";
import Quickbooks from "./quickbooks";
import CompanySettings from "views/company_settings";
import ServicePlans from "views/service_plans";
import Categories from "./categories";
import Category from "./categories/category";
import CustomField from "./custom-fields/custom-field";
import CustomFields from "./custom-fields";
import QuoteTemplates from "./quote-templates";
import QuoteTemplate from "./quote-template";
import CustomDocuments from "views/custom_documents";
import TagManager from "../admin/tags/index";
import UploadIndex from "./uploadV2";
import StripeInfo from "./stripe-information";
import Warranties from "views/warranties";
import Warranty from "views/warranty";
import Geocoding from "./geocoding";
import StripeAPICalls from "./stripe-api-calls.js";

import Subscription from "views/subscription";
import SubscriptionAdmin from "views/subscription/subscriptionAdmin";
import UpgradeSub from "views/subscription/upgrade";
import {
  EssentialPlan,
  EnhancedPlan,
  ExclusivePlan,
} from "views/subscription/plans";
import UpdateSubscription from "views/subscription/admin/updateSubscription";
import Duck from "views/time_dashboard";
import { AppointmentTypes } from "./appointment-types";
import UnassignedRequestForm from "views/unassigned_request_form";
import ProductionCentral from "./production";
import { PermissionsIndex } from "./permissions";
import Reminders from "./reminders";

import { useTranslation } from "react-i18next";
import TaxManagement from "./tax";
import { useAuthContext } from "contexts/auth";

const Sidebar = styled.div`
  width: 270px;
  border-right: 2px solid black;
`;

const Content = styled.div`
  flex: 1;
  padding: 1rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const tabs = [
  {
    label: "Company Settings",
    link: "company-settings",
  },
  {
    label: "Service Plans",
    link: "service-plans",
  },
  {
    label: "Time Dashboard",
    link: "time-dashboard",
  },
  {
    label: "Divisions",
    link: "divisions",
  },
  {
    label: "Appointment Types",
    link: "appointment-types",
  },
  {
    label: "Custom Fields",
    link: "custom-field",
  },
  {
    label: "Custom Documents",
    link: "custom-docs",
  },
  {
    label: "Quote Templates",
    link: "quote-template",
  },
  {
    label: "Requests",
    link: "requests",
  },
  {
    label: "Employees",
    link: "employees",
  },
  {
    label: "Production Central",
    link: "production-central",
  },
  {
    label: "Activity Log",
    link: "activity-log",
  },
  {
    label: "Notifications",
    link: "notifications",
  },
  {
    label: "Permissions",
    link: "permissions",
  },
  {
    label: "Tags",
    link: "tags",
  },
  {
    label: "Quickbooks",
    link: "quickbooks",
  },
  {
    label: "Taxes",
    link: "tax",
  },
  {
    label: "Stripe Payouts",
    link: "stripeInfo",
  },
  {
    label: "Geocoding",
    link: "geocoding",
  },
  {
    label: "Import",
    link: "import",
  },
  {
    label: "Email Reminders",
    link: "reminders",
  },
  {
    label: "Subscription",
    link: "subscription",
  },
  {
    label: "Warranties",
    link: "warranty",
  },
  {
    label: "Stripe API Calls",
    link: "stripe-api-calls",
  },
  // this link is being hidden until the subscription admin page is ready and cooperating with permissions
  // {
  //   label: "Subscription Admin",
  //   link: "subscriptionAdmin",
  // },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  const { t } = useTranslation();
  const tBase = "views.admin.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const auth = useAuthContext();

  const { params } = useRouteMatch();

    const populateTabs = tabs
    .filter((tab) => tab.label !== "Stripe API Calls" || auth.pin === "KB255")
    .map((tab) => {
      return (
        <Link
          key={tab.label}
          to={`/admin/${tab.link}`}
          selected={params.page === tab.link}
        >
          {tr(tab.label)}
        </Link>
      );
    });

  return (
    <ContainerWithSidebar>
      <Sidebar>{populateTabs}</Sidebar>
      <Content>
        <Switch>
          <Route
            path="/admin/company-settings"
            exact
            component={CompanySettings}
          />
          <Route path="/admin/service-plans" exact component={ServicePlans} />
          <Route path="/admin/time-dashboard" exact component={Duck} />
          <Route path="/admin/divisions" exact component={Categories} />
          <Route
            path="/admin/appointment-types"
            exact
            component={AppointmentTypes}
          />
          <Route path="/admin/divisions/:id" exact component={Category} />
          <Route path="/admin/custom-field" exact component={CustomFields} />
          <Route path="/admin/custom-field/:id" exact component={CustomField} />
          <Route path="/admin/custom-docs" exact component={CustomDocuments} />
          <Route
            path="/admin/quote-template"
            exact
            component={QuoteTemplates}
          />
          <Route
            path="/admin/quote-template/:id"
            exact
            component={QuoteTemplate}
          />
          <Route path="/admin/employees" exact component={Employees} />
          <Route path="/admin/permissions" exact component={PermissionsIndex} />
          <Route path="/admin/activity-log" exact component={Logslist} />
          <Route
            path="/admin/production-central"
            exact
            component={ProductionCentral}
          />
          <Route
            path="/admin/notifications"
            exact
            component={NotificationsSettings}
          />
          <Route path="/admin/requests" exact component={Requests} />
          <Route path="/admin/tags" exact component={TagManager} />
          <Route path="/admin/geocoding" exact component={Geocoding} />
          <Route path="/admin/import" exact component={UploadIndex} />
          <Route path="/admin/quickbooks/:tab?" exact component={Quickbooks} />
          <Route path="/admin/tax" exact component={TaxManagement} />
          <Route path="/admin/stripeinfo" exact component={StripeInfo} />
          <Route path="/admin/warranty" exact component={Warranties} />
          <Route path="/admin/warranty/:id" exact component={Warranty} />
          <Route path="/admin/stripe-api-calls" exact component={StripeAPICalls} />
          <Route path="/admin/reminders" exact component={Reminders} />
          <Route path="/admin/subscription" exact component={Subscription} />
          <Route
            path="/admin/subscriptionAdmin"
            exact
            component={SubscriptionAdmin}
          />
          <Route
            path="/admin/subscription/admin/updateSubscription"
            exact
            component={UpdateSubscription}
          />
          <Route
            path="/admin/subscription/upgrade"
            exact
            component={UpgradeSub}
          />
          <Route
            path="/admin/subscription/upgrade/essential"
            exact
            component={EssentialPlan}
          />
          <Route
            path="/admin/subscription/upgrade/enhanced"
            exact
            component={EnhancedPlan}
          />
          <Route
            path="/admin/subscription/upgrade/exclusive"
            exact
            component={ExclusivePlan}
          />
          <Route
            path="/admin/unassignedRequestForm"
            exact
            component={UnassignedRequestForm}
          />
        </Switch>
      </Content>
    </ContainerWithSidebar>
  );
}
