import { useQuery } from "react-query";
import { getHeritageConnection } from "../api/getHeritageConnection";
import { IHeritageToken } from "../types/IHeritageConnection";

export const useHeritageConnection = () => {
  return useQuery<IHeritageToken>(
    ["heritage-connection"],
    () => getHeritageConnection(),
    {
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
    }
  );
};
