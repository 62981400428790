import { useState, useRef, useEffect, useMemo} from "react";
import axios from "axios";
import Field from "components/field";
import SafeArea from "components/safe-area";
import Spinner from "components/spinner";
import { useTable } from "components/table";
import PaymentsList from "containers/payments";
import useQueryString from "hooks/useQueryString";
import { useQuery } from "react-query";
import { TPayment } from "types/payment";
import Placeholder from "views/request/component/placeholder";
import { useTranslation } from 'react-i18next';
import { ContainerWithSidebar } from "components/layout";
import PaymentFilter from "./paymentFilter";
import { useHistory } from "react-router-dom";
import QueryString from "query-string";
import { Query, Count } from "./style-and-types";


export default function Payments(props: any) {
  const { t } = useTranslation();
  const tBase = "views.payments.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const [queryFilter, setQueryFilter] = useState<Query>({});
  const [initialLoad, setInitialLoad] = useState(true);
  const { location } = props;
  const [counts, setCounts] = useState<Count[] | null>(null); // Store counts locally


  const search = useQueryString();

  const table = useTable()

  const stringSearchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (table.sort)
      search.update({ ...search.search, ...table.sort})
  }, [table.sort]);

  const query = useQuery(
    ["payments", search.search, queryFilter],
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/payments`,
          {
            // ...queryFilter,
            params: search.search,
          }
        )
        .then((res) => {
          return res.data
        }),
    // {
    //   onSuccess: (data) => {
    //     table.setPageCount(Math.floor(data.count / 50) || 8);
    //   },
    // }
  );

  const history = useHistory()

  useEffect(() => {
    let rawString = QueryString.parse(location.search);
    let parsedString: { [key: string]: string[] } = {};
    Object.entries(rawString).forEach(([key, value]) => {
      if (typeof value === "string") {
        parsedString[key] = value.split("|");
      }
    });
    let queryPage = parsedString.page && +parsedString.page[0];
    if (queryPage !== table.pageIndex)
      table.handlePage((parsedString?.page && +parsedString?.page[0]) || 1);
    setQueryFilter(parsedString);
    setInitialLoad(false);
  }, [location.search]);

  useEffect(() => {
    !initialLoad &&
      updateUrl({ ...queryFilter, page: [table.pageIndex.toString()] });
  }, [initialLoad, table.pageIndex]);

  const updateUrl = (queryFilter: Query) => {
    let stringifyQuery: { [key: string]: string } = {};

    Object.entries(queryFilter).forEach(([key, value]) => {
      stringifyQuery[key] = value.join("|");
    });

    history.replace({
      search: QueryString.stringify(stringifyQuery),
    });
  };

  const selected = useMemo(() => queryFilter?.category?.[0], [queryFilter]);

  useEffect(() => {
    if (query.data?.count) {
      // Update local counts only after successful data fetch
      setCounts(query.data.count);
  
      const totalPage = Math.ceil(
        (selected
          ? query.data.count.find((count: any) => count.status === selected)
              ?.count || 1
          : query.data.page_count) / 50
      );
  
      table.setPageCount(totalPage);
    }
  }, [selected, query.data?.count]);    

  const handleStatusClick = (status: string) => {
    if (status === "showAll") {
      updateUrl({});
      stringSearchRef.current!.value = "";
      // resetDateFilter();
    } else {
      updateUrl({ ...queryFilter, category: [status], page: ["1"] });
    }
  };

  return (
    <ContainerWithSidebar>
      <PaymentFilter
        query={queryFilter}
        selected={selected}
        count={counts}
        categories={query.data?.categories}
        {...{ handleStatusClick, search }}
      />
      <div className="divide-y">
        <SafeArea>
          <div className="py-4">
            <h1>{tr("Payments")}</h1>
            <Field
              onKeyDown={(e: any) => e.key === "Enter" && e.target.blur()}
              onBlur={(e) =>
                search.update({ ...search.search, search: e.target.value })
              }
              ref={stringSearchRef}
            />
          </div>
        </SafeArea>
        <SafeArea>
          <div className="py-4">
            {query.isLoading ? (
              <Spinner />
            ) : query.data ? (
              <PaymentsList data={query.data.payments} type="all" table={table} />
            ) : (
              <Placeholder
                type="payment"
                header={tr("No payments yet")}
                label={tr("Payments will appear here when added.")}
              />
            )}
          </div>
        </SafeArea>
      </div>
    </ContainerWithSidebar>
  );
}
