import axios from "api/axios";
import { useAreYouSure } from "components/areYouSure";
import { Button, IconButton } from "components/button";
import Form from "components/form2";
import { Input } from "components/input";
import { SFlatContent, SFlatHeader } from "components/segment/flat";
import Select from "react-select";
import { useSnackbarContext } from "components/snackbar";
import { useFormik } from "formik";
import { cloneDeep } from "lodash";
import * as React from "react";
import { RiAddFill, RiDeleteBin2Fill } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { TDefaultCustomField } from "types/custom-field";
import { useTranslation } from 'react-i18next';

export default function CustomFields() {
  const { showSnackbar } = useSnackbarContext(),
    areYouSure = useAreYouSure();
  const [values, setValues] = React.useState<TDefaultCustomField[]>([]);
  
  const { t } = useTranslation();
  const tBase = "views.admin.custom-fields.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const options = [
    { value: "request", label: tr("Request") },
    { value: "property", label: tr("Property") },
    { value: "appointment", label: tr("Appointment") },
  ];

  const customFieldsQuery = useQuery<TDefaultCustomField[]>(
    "custom-fields",
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/custom-fields
    `
        )
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        // Organize custom field data by the order property.
        // Items with a null order property will be placed at the end of the list.
        data = data.sort((a, b) => {
          if (a.order === null && b.order === null) return 0;
          if (a.order === null) return 1;
          if (b.order === null) return -1;
          return a.order - b.order;
        });
          
        setValues(data);
        formik.resetForm();
      },
    }
  );

  const saveChanges = useMutation(
    async (e: { custom_fields: TDefaultCustomField[] }) =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/custom-fields
  `,
          e
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        showSnackbar(tr("Custom Field Updated!"));
        customFieldsQuery.refetch();
      },
    }
  );

  const formik = useFormik({
    initialValues: { custom_fields: values },
    onSubmit: (e) => saveChanges.mutate(e),
    enableReinitialize: true,
  });

  const populateCustomFields =
    formik.values.custom_fields.length > 0 &&
    formik.values.custom_fields.map((customField, i) => {
      const handleDelete = () => {
        formik.setValues((v) => {
          let temp = cloneDeep(v);
          temp.custom_fields.splice(i, 1);
          return temp;
        });
      };
      return (
        <SCustomCon key={i} style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <div className="text-[0.8rem] mb-1">{tr("Type")}</div>
            <Select
              options={options}
              onChange={(e: any) => {
                formik.setValues((v) => {
                  let temp = cloneDeep(v);
                  temp.custom_fields[i].type = e.value;
                  return temp;
                });
              }}
              nullValue="Select..."
              isClearable
              value={options.find((o) => o.value === customField.type)}
              selected={customField.custom_key}
              name={`custom_fields.${i}.custom_key`}
            />
          </div>
          <Input
            label={tr("Key")}
            onChange={formik.handleChange}
            value={customField.custom_key}
            name={`custom_fields.${i}.custom_key`}
            style={{ flex: 2 }}
          />
          <Input
            label={tr("Value")}
            value={customField.custom_value}
            name={`custom_fields.${i}.custom_value`}
            style={{ flex: 4 }}
          />
          <IconButton onClick={() => areYouSure.activate(handleDelete)}>
            <RiDeleteBin2Fill />
          </IconButton>
        </SCustomCon>
      );
    });

  const addCustomField = () => {
    formik.setValues((v) => {
      return {
        custom_fields: [
          ...v.custom_fields,
          { id: null, custom_key: "", custom_value: "", type: null, order: null },
        ],
      };
    });
  };

  return (
    <div>
      <Form formik={formik}>
        <SFlatHeader>
          <h1>{tr("Custom Fields")}</h1>
          <div className="flex w-[300] justify-between">
            <Button className="mr-2" type="submit">
              {tr("Save")}
            </Button>
            <Button primary onClick={addCustomField}>
              <RiAddFill /> {tr("Custom Field")}
            </Button>
          </div>
        </SFlatHeader>
        <SFlatContent>{populateCustomFields}</SFlatContent>
      </Form>
    </div>
  );
}

const SCustomCon = styled.div`
  display: flex;
  align-items: flex-end;
  > *:not(:last-child) {
    margin-right: 1rem;
  }
`;
