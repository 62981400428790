import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import CountUp from "react-countup";
import { FaRegBuilding } from "react-icons/fa";
import PieChart from "./components/PieChart";
import YtdDetails from "./components/YtdDetails";

const data = [
  { dayOfYear: "Jan", lastYTD: 1000, yearToDate: 1200, day_total: 50 },
  { dayOfYear: "Feb", lastYTD: 2000, yearToDate: 2300, day_total: 100 },
  { dayOfYear: "Mar", lastYTD: 3000, yearToDate: 3300, day_total: 150 },
];

const goal = 6500000;
const yearToDate = 2366192.75;
const lastYearToDate = 2105574.49;
const progressPercent = (yearToDate / goal) * 100;

const Production = () => {
  const currentTime = new Date().toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return (
    <div className="min-h-screen bg-[#25292E] text-white flex flex-col">
      {/* Header */}
      <div
        className="flex justify-between items-center p-4 bg-[#30343A]"
        style={{ height: 50 }}
      >
        <div className="flex items-center">
          <h1 className="text-lg font-bold">Production</h1>
          <div className="ml-2 bg-[#D55225] p-2 pl-4 pr-4 rounded-sm">1</div>
        </div>
        <div className="flex items-center">
          <FaRegBuilding />
          <h2 className="ml-2">Company</h2>
        </div>
        <div className="h-10 bg-gray-600 flex items-center justify-center rounded-full text-sm pl-4 pr-4">
          {currentTime}
        </div>
      </div>

      {/* Table Section */}
      <div className="bg-[#30343A] ml-2 mr-2 mt-2 p-4 rounded overflow-auto flex-1">
        <div className="grid grid-cols-6 font-semibold text-sm border-b border-gray-700 pb-2 text-[#23f0b6]">
          <div>Leader</div>
          <div>Yesterday Actual</div>
          <div>Year To Date</div>
          <div>Last Year To Date</div>
          <div>Median</div>
          <div>Goal</div>
        </div>
        <div className="mt-2 space-y-2 text-sm">
          <div className="grid grid-cols-6">
            <div>ServiceResidential</div>
            <div>$10,264</div>
            <div>$1,212,825.63</div>
            <div>$1,212,198</div>
            <div>$6,259</div>
            <div className="text-green-500">90.71%</div>
          </div>
          <div className="grid grid-cols-6">
            <div>Residential</div>
            <div>$4,499</div>
            <div>$2,439,962.79</div>
            <div>$2,361,622</div>
            <div>$15,430</div>
            <div className="text-green-500">92.07%</div>
          </div>
        </div>
      </div>

      {/* Goal and Progress Section */}
      <div className="grid grid-cols-3 gap-2 bg-[#25292E] m-2">
        <div className="bg-[#30343A] rounded flex items-center justify-center ">
          <div className="text-green-500 text-4xl">🏆</div>
          <div className="text-lg font-bold ml-4">
            Goal: $<CountUp end={goal} duration={1} separator="," />
          </div>
        </div>
        <div className="col-span-2 bg-[#30343A] p-4 rounded flex items-center">
          <div className="w-full bg-gray-700 rounded-full h-6">
            <div
              className="bg-green-500 h-6 rounded-full"
              style={{ width: `${progressPercent}%` }}
            ></div>
          </div>
          <div className="ml-4 text-lg font-bold">
            <CountUp end={progressPercent} duration={1} decimals={1} />%
          </div>
        </div>
      </div>

      {/* Main Section */}
      <div className="mr-2 ml-2 mb-2">
        <div className="grid grid-cols-3 gap-2">
          {/* Line Chart */}
          <div className="bg-[#30343A] p-4 rounded col-span-2 h-full">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="dayOfYear" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="lastYTD"
                  stroke="#EA4950"
                  name="Last Year to Date"
                />
                <Line
                  type="monotone"
                  dataKey="yearToDate"
                  stroke="#49A8EA"
                  name="Year to Date"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>

          {/* Pie Chart and YTD Details */}
          <div className="flex flex-row">
            <div className="bg-[#30343A] p-4 rounded mr-2 w-[50%]">
              <PieChart />
            </div>
            <div className="bg-[#30343A] p-4 rounded w-[50%]">
              <YtdDetails />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Production;
