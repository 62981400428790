import React, { useState } from 'react';
import { Button } from 'components/button';


type TProps = {
  children: string;
  checkedValue: boolean;
  setCheckedValue: React.Dispatch<React.SetStateAction<boolean>>;
  additionalStyling?: React.CSSProperties;
}

// I would like to refactor this a bit to be used as a reusable component
const CheckedBoxButton = ({children, checkedValue, setCheckedValue, additionalStyling} : TProps & React.HTMLProps<HTMLInputElement>) => {
  const [checked, setChecked] = useState(false);
  
  const handleClick = () => {
    setChecked(!checked);
    setCheckedValue(!checkedValue);
  }

  const CheckBoxButtonStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    border: 'none',
    padding: '5px 10px',
    fontSize: '16px',
    cursor: 'pointer',
    borderRadius: '5px',
    width: '130px',
    // textShadow: "0px 1px 4px #23430C",
    ...additionalStyling,
    // backgroundColor: hovered ? "#777777" : "#D2D4D6", 
    // transition: 'background-color 0.3s ease',
  };

  return (
    <Button style={CheckBoxButtonStyle} onClick={handleClick} primary>
      <input type="checkbox" checked={checkedValue} />
      <span>{children}</span>
    </Button>
  )
}

export default CheckedBoxButton;