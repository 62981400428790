import axios from "axios";
import Tab from "components/tab";
import { useQuery } from "react-query";
import RequestNotes from "views/request/container/notes";
import ProjectInfo from "./components/projectInfo";
import { Con, SSidebar, SContent } from "./style";
import ProjectDefault from "./views";
import Quote from "./views/quote";
import RequestReminders from "views/request/container/reminders";
import RequestFilesTab from "views/request/container/files";
import { Customer } from "views/requests/newRequestModal/customerForm";
import { Assignees } from "components/react-select/assignees";
import Segment from "components/segment";
import CustomFieldsTab from "containers/CustomFieldsTab";
import { TDivision } from "types/division";
import QuoteV2 from "./views/quote/indexv2";
import useWindowDimensions from "hooks/useWindowDimensions";
import { TProperty } from "types/property";
import { useTranslation } from 'react-i18next';

type Client = {
  id: number;
  first_name: string;
  last_name: string;
};
export type Project = {
  id: string;
  label: string;
  address: string | null;
  entry_date: string;
  fk_business_id: string;
  due_date: string | null;
  division: TDivision | null;
  start_date: string | null;
  billed: number | null;
  total_amount: number | null;
  actual_amount: number | null;
  assignees: Assignees[] | null;
  status: {
    id: number;
    label: string;
    color: string;
  };
  clients: (Customer & { id: number, company_name: string })[] | null;
  trip_count: number | null;
  manager: { value: string; label: string } | null;
  property: TProperty | null;
  warranty_count: number;
};
export default function Project(props: any) {
  const { t } = useTranslation();
  const tBase = "views.projects.views.project.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { id, cat, catId } = props.match.params;
  const dimensions = useWindowDimensions();

  const project = useQuery<Project>(
    ["project", id],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/projects/${id}
  `
        )
        .then((res) => res.data)
  );

  const tabData = {
    notes: {
      tab: <div>{tr("Notes")} </div>,
      content: <RequestNotes id={id} type="project" />,
    },
    reminders: {
      tab: tr("Reminders"),
      content: <RequestReminders id={id} type="project" />,
    },
    files: {
      tab: <div>{tr("Files")}</div>,
      content: <RequestFilesTab id={id} type="project" />,
    },
    custom_fields: {
      tab: <div>{tr("Custom Fields")}</div>,
      content: <CustomFieldsTab type="project" id={id} />,
    },
  };

  const renderView = () => {
    switch (cat) {
      case "quote":
        return <QuoteV2 />;
      case "quote2":
        return <Quote projectId={id} id={catId} />;
      default:
        return <ProjectDefault id={id} project={project} />;
    }
  };

  return (
    <div className="flex space-x-4 px-4">
      {dimensions.width >= 1200 && (
        <div className="flex-[0_0_25%] overflow-auto border p-4 m-4 rounded-md">
          <div className="divide-y">
            <ProjectInfo data={project.data} />
            <Tab data={tabData} defaultTab={"notes"} />
          </div>
        </div>
      )}

      <div className="flex-auto overflow-hidden">{renderView()}</div>
    </div>
  );
}
