import * as React from "react";
import styled from "styled-components";
import Tab from "components/tab";
import Estimates from "./containers/estimates";
import Invoices from "./containers/invoices";
import Requests from "./containers/requests";
import CustomerFiles from "./containers/customerFiles";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Spinner from "components/spinner";
import SafeArea from "components/safe-area";
import { theme } from "styles/theme";
import { Avatar } from "components/avatar";
import FlatContainer from "components/container/flat";
import { Button, OutlineButton } from "components/button";
import { TCustomer } from "types/customer";
import { useAreYouSure } from "components/areYouSure";
import { useSnackbarContext } from "components/snackbar";
import CustomerTagList from "./customer-tag-list";
import { TProperty } from "types/property";
import PropertyCon from "views/properties/propertyCon";
import NewPropertyModal from "views/properties/newPropertyModal";
import { RiAddLine, RiArrowDownSLine, RiDeleteBin7Line } from "react-icons/ri";
import { useModalContext } from "components/modal";
import Dropdown from "components/dropdown";
import RequestModal from "views/requests/newRequestModal";
import { MdVerifiedUser } from "react-icons/md";
import PaymentsList from "containers/payments";
import CustomerPaymentsTab from "./containers/payments";
import NewPaymentModal from "views/payment/newPaymentModal";
import { dollarFormatter } from "utils";
import RequestNotes from "views/request/container/notes";
import Projects from "./containers/projects";
import ServicePlansOnCustomer from "./serviceplanDropdown";
import Sync from "./containers/sync";
import PaymentMethodsWrapper from "./containers/PaymentMethods";
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from "utils/phoneNumberFormatter";

export default function Customer() {
  const { t } = useTranslation();
  const tBase = "views.customer.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const params = useParams<{ id: string }>(),
    history = useHistory(),
    { activate } = useAreYouSure(),
    { showSnackbar } = useSnackbarContext(),
    modal = useModalContext();

  const customer = useQuery<{
    info: TCustomer & { total_amount_due: number };
    counts: {
      estimates: string;
      invoices: string;
      requests: string;
      payments: string;
      notes: string;
      projects: string;
      files: string;
    };
  }>(
    ["customer", params.id],
    async () =>
      await axios
        .get(
          `
        ${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/${params.id}
        `
        )
        .then((res) => {
          return res.data;
        })
  );

  const deleteCustomer = useMutation(
    async () =>
      await axios
        .delete(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/${params.id}
  `
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        showSnackbar(tr("Customer deleted successfully!"));
        history.replace("/customers");
      },
    }
  );
  const customerplans = useQuery(
    "customerplans",
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers?type=CUSPLANS`,
          { params: { id: params.id } }
        )
        .then((res) =>
          res.data.map((x: any) => {
            return x.service_planid;
          })
        )
  );
  if (customer.isLoading) return <Spinner />;

  const data = {
    overview: {
      tab: tr("Overview"),
      content: <CustomerInfoTab data={customer.data?.info} />,
    },
    requests: {
      tab: tr("Requests"),
      count: customer.data?.counts.requests,
      content: <Requests id={params.id} />,
    },
    // projects: {
    //   tab: `Projects`,
    //   count: customer.data?.counts.projects,
    //   content: <Projects id={params.id} />,
    // },
    estimates: {
      tab: tr("Estimates"),
      count: customer.data?.counts.estimates,
      content: <Estimates id={params.id} />,
    },
    servicePlans: {
      tab: tr("Service Plans"),
      count: customerplans.data?.length,
      content: (
        <ServicePlansOnCustomer
          id={params.id}
          email={customer.data?.info.email}
        />
      ),
    },
    invoices: {
      tab: tr("Invoices"),
      count: customer.data?.counts.invoices,
      content: <Invoices id={params.id} />,
    },
    payments: {
      tab: tr("Payments"),
      count: customer.data?.counts.payments,
      content: <CustomerPaymentsTab id={params.id} />,
    },
    notes: {
      tab: tr("Notes"),
      count: customer.data?.counts.notes,
      content: <RequestNotes id={params.id} type="customer" />,
    },
    files: {
      tab: tr("Files"),
      count: customer.data?.counts.files,
      content: <CustomerFiles id={params.id} type="customerFiles" />,
    },
    sync: {
      tab: tr("Sync"),
      content: <Sync id={params.id} />,
    },
    payment_methods: {
      tab: tr("Payment Methods"),
      content: <PaymentMethodsWrapper id={params.id} customer={customer.data?.info} />,
    },
  };

  const phoneSimplify = (phone: any) => {
    if (!phone) return null;
    // if (phone.length >= 14 && !ext) return null;
    if (typeof phone !== "string") {
      phone = String(phone);  // Convert to string if not already
    }
    return phone.replace(/[^0-9]/g, "");
  };

  const phoneNumberPre = phoneSimplify(customer.data?.info.phone);
  const phoneNumber = formatPhoneNumber(phoneNumberPre);

  return (
    <div>
      <SafeArea
        style={{
          content: {
            padding: "1rem 0",
          },
          overlay: { borderBottom: `1px solid ${theme.border.light}` },
        }}
      >
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Avatar
                showLabel
                size="xlarge"
                verified={customer.data?.info.fk_customer_id}
              >
                {customer.data?.info.name}
              </Avatar>
              <CustomerTagList tags={customer.data?.info} />
            </div>
            <span style={{ color: theme.color.subtext3, lineHeight: "2rem" }}>
              {customer.data?.info.email} • {phoneNumber}{" "}
              {customer.data?.info?.extension &&
                ` *${customer.data?.info?.extension}`}
              {customer.data?.info?.title && (
                <span> • {customer.data?.info?.title}</span>
              )}
            </span>
            <span style={{ color: theme.color.subtext3, lineHeight: "2rem" }}>
              {customer.data?.info.extended_contacts?.map((entry, i) => {
                <span key={i}>
                  {entry.contact_type} : {entry.contact_value}
                </span>;
              })}
            </span>
          </div>
          <div
            style={{
              margin: "auto",
              color:
                customer.data?.info.total_amount_due === 0 ? "green" : "red",
              fontWeight: "bold",
              fontSize: "1.5rem",
            }}
          >
            {tr("Total Amount Due")}:{" "}
            {dollarFormatter(customer.data?.info.total_amount_due)}
          </div>
          <div className="flex items-center space-x-2">
            <Button
              onClick={() =>
                modal.setModal({
                  component: <RequestModal customerId={params.id} />,
                  label: tr("New Request"),
                })
              }
              primary
            >
              {tr("Create Request")}
            </Button>
            <Dropdown
              reference={
                <Button>
                  <RiArrowDownSLine /> {tr("Action")}
                </Button>
              }
              data={[
                {
                  button: [
                    {
                      label: tr("Apply Credit"),
                      onClick: () =>
                        modal.setModal({
                          component: (
                            <NewPaymentModal
                              customerId={params.id}
                              id={params.id}
                              type="customer"
                              is_deposit
                            />
                          ),
                          label: tr("Apply Credit"),
                        }),
                    },
                    {
                      label: tr("Edit"),
                      onClick: () =>
                        history.push(`/customers/edit/${params.id}`),
                    },
                    {
                      label: (
                        <span className="flex space-x-2 items-center !text-red-500">
                          <RiDeleteBin7Line />
                          <span>{tr("Delete")}</span>
                        </span>
                      ),
                      onClick: () => activate(() => deleteCustomer.mutate()),
                    },
                  ],
                },
              ]}
            />
          </div>
        </div>
      </SafeArea>
      <SafeArea>
        <Tab data={data} defaultTab={"overview"} />
      </SafeArea>
    </div>
  );
}

const CustomerInfoTab = ({ data }: { data?: TCustomer }) => {
  const { t } = useTranslation();
  const tBase = "views.customer.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  
  const modal = useModalContext();

  const propertyQuery = useQuery<TProperty[]>(
    ["property", "customer", data?.id],
    async () =>
      await axios
        .get(
          `
        ${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/${data?.id}/property
        `
        )
        .then((res) => res.data)
  );

  const customerCredit = useQuery<number | null>(
    ["customer credit", data?.id],
    async () =>
      data?.id
        ? await axios
            .get(
              `${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/${data?.id}/credit`
            )
            .then((res) => res.data)
        : null
  );

  const populateAddresses = (propertyQuery.data &&
    propertyQuery.data.map((property) => (
      <PropertyCon
        property={property}
        key={property.id}
        customerId={data?.id}
      />
    ))) || <div className="text-slate-500">{tr("No Address")}</div>;

  const populateContacts = (data &&
    data.contacts?.map((contact, i) => {
      return (
        <SContact key={i}>
          <div>
            <h3>{contact.name}</h3>
          </div>
          <div>
            <span>
              {`${contact.email} • ${contact.phone}`}
              {contact.extension && <span> *{contact.extension}</span>}
              {contact.title && <span> • {contact.title}</span>}
            </span>
          </div>
        </SContact>
      );
    })) || <div className="text-slate-500">{tr("No Additional Contact")}</div>;

  return (
    <div>
      {customerCredit.data !== 0 && (
        <div className="flex justify-between p-2 text-green-900 bg-green-100 rounded">
          <div className="flex flex-col">
            <span className="text-xs">{tr("Customer Credit")}</span>
            <span className="text-lg">
              {dollarFormatter(customerCredit.data)}
            </span>
          </div>
        </div>
      )}
      <FlatContainer
        title={tr("Service Properties")}
        buttons={[
          <OutlineButton
            primary
            onClick={() =>
              modal.setModal({
                component: (
                  <NewPropertyModal customerId={data?.id} customerInfo={data} />
                ),
                label: tr("New Property"),
              })
            }
          >
            <RiAddLine /> {tr("Add Property")}
          </OutlineButton>,
        ]}
      >
        <div className="divide-y">{populateAddresses}</div>
      </FlatContainer>
      <FlatContainer title={tr("Additional Contacts")}>
        {populateContacts}
      </FlatContainer>
    </div>
  );
};

const SAddress = styled.div`
  border-bottom: 1px solid ${theme.border.light};
  padding: 1rem 0;
`;

const SContact = styled.div`
  border-bottom: 1px solid ${theme.border.light};
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  > div:last-child {
    color: ${theme.color.subtext3};
  }
`;
