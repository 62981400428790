import { FormEvent, useContext, useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useMutation } from "react-query";

import Spinner from "components/spinner";
import { VendorContext } from "contexts/VendorContext";
import { VendorItemDetails } from "types/vendor";
import { getVendorItemDetails } from "api/vendorsApi";
import VendorItemDetail from "./VendorItemDetail/VendorItemDetail";

import styles from "./VendorItems.module.scss";
import { SearchInput } from "components/input";
import { Button } from "components/button";
import { useModalContext } from "components/modal";
import VendorItemLinkModalBody from "./VendorItemLinkModal/VendorItemLinkModalBody";
import { useTranslation } from "react-i18next";
import VendorRemoteModal from "./VendorRemoteModal";
import axios from "axios";
import { useAuthContext } from "contexts/auth";
import VendorConnectionModal from "./VendorConnectionModal";

const VendorItems = () => {
  const { t } = useTranslation();
  const tBase = "views.vendors.VendorBody.VendorItems.VendorItems";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext();
  const { currentVendor } = useContext(VendorContext);
  const [updateInProgress, setUpdateInProgress] = useState<boolean>(false);
  const auth = useAuthContext();
  // const [search, setSearch] = useState<string>("");

  const getUpdateVendorPricingStatus = useMutation(async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/company/siteone-vendor-update/update-prices/getSiteoneUpdatePricesStatus`,
      {
        params: {
          vendorId: currentVendor?.vendor_id,
        },
      }
    );
    setUpdateInProgress(response.data.updateInProgress);
  });

  useEffect(() => {
    getUpdateVendorPricingStatus.mutate();
  }, []);

  const vendorItemDetailsQuery = useQuery(
    ["vendors_items", currentVendor?.vendor_id],
    () => getVendorItemDetails(currentVendor?.vendor_id || null)
  );

  if (vendorItemDetailsQuery?.isLoading)
    return (
      <div className={styles.container}>
        <Spinner />
      </div>
    );

  if (vendorItemDetailsQuery?.isError) return <div>{tr("Error")}</div>;

  const SITEONE_DEV_CLIENT_ID = "0oa21e94cnlLcZl2M0h8";

  const handleUpdatePrices = async () => {
    const clientId = SITEONE_DEV_CLIENT_ID;
    const redirectUri = `${process.env.REACT_APP_SERVER_URL}/api/v1/company/siteone-vendor-update/siteone-authentication-callback`;
    // const redirectUri = `https://api.eserv.com/api/v1/company/siteone-vendor-update/siteone-authentication-callback`;
    const authorizationEndpoint =
      "https://siteone.oktapreview.com/oauth2/aus10m6iotqiJ1QTR0h8/v1/authorize";

    const token = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/siteone-vendor-update/update-prices/generate-token`, {
      // add a header to the request
      headers: {
        'siteone-authorization-token': '189273u4t890j238f02j348f28340j9va',
        'Content-Type': 'application/json',
      },
    });
  
    const stateData = {
      token: token.data.token,
      companyId: auth?.company_id,
      userId: auth?.user_id,
      vendorId: currentVendor?.vendor_id,
    };

    const params = new URLSearchParams({
      client_id: clientId,
      response_type: "code",
      scope: "openid profile",
      // scope: 'openid offline_access',
      redirect_uri: redirectUri,
      state: JSON.stringify(stateData),
    });

    // console.log("params", params.toString());

    window.location.href = `${authorizationEndpoint}?${params.toString()}`;
  };

  // create update prices confirm modal

  const updatePricesModal = () => {
    setModal({
      component: (
        <div>
          <p>{tr("Are you sure you want to update prices?")}</p>
          <p>{tr("This action will take at least one day to complete.")}</p>
          <Button primary onClick={handleUpdatePrices}>
            {tr("Confirm")}
          </Button>
          <Button onClick={() => setModal()}>{tr("Cancel")}</Button>
        </div>
      ),
      label: tr("Update Prices"),
    });
  };

  const handleAuthorize = async () => {
    await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/company/vendors/heritage/authorize`,
      {
        vendorId: currentVendor?.vendor_id,
      }
    );
  };

  const handleClickLinkItem = () => {
    setModal({
      component: (
        <VendorItemLinkModalBody vendorId={currentVendor?.vendor_id} />
      ),
      label: tr("Link Item"),
    });
  };

  const handleOpenRemote = () => {
    const vendorId = currentVendor?.vendor_id;
    if (vendorId) {
      setModal({
        component: <VendorRemoteModal vendorId={vendorId} />,
        label: "Refresh Prices",
      });
    } else {
      console.error("Vendor ID is not defined");
    }
  };

  const handleConnect = () => {
    setModal({
      component: <VendorConnectionModal />,
      label: "Vendor Connection",
    });
  };

  return (
    <div className={styles.container} style={{ borderLeft: "none" }}>
      <div className={styles.topRow}>
        <div className={styles.title}>
          <p style={{ display: "inline" }}>{tr("Items")}</p>
        </div>
        <div className="flex-row">
          {auth?.user_id === "255" && (
            <Button
              size="small"
              onClick={updatePricesModal}
              className="mr-2"
              disabled={updateInProgress}
            >
              {updateInProgress
                ? tr("Updating Prices...")
                : tr("Update Prices")}
            </Button>
          )}
          <Button size="small" onClick={handleOpenRemote} className="mr-2">
            Pricing
          </Button>
          <Button size="small" onClick={handleClickLinkItem}>
            {tr("Link")}
          </Button>
        </div>
      </div>
      {/* <SearchInput
        search
        placeholder="Search..."
        style={{ width: "100%" }}
        value={search}
        onChange={handleChangeSearch}
      /> */}

      <div className={styles.listContainer}>
        {vendorItemDetailsQuery?.data?.map((i: VendorItemDetails) => {
          return <VendorItemDetail key={i.id} item={i} />;
        })}
      </div>
    </div>
  );
};

export default VendorItems;
