import { useState } from "react";
import connect from "./api/connect";
import { useHeritageConnection } from "./hooks/useHeritageConnection";
const VendorConnectionModal = () => {
  const { data, isLoading, isError, refetch } = useHeritageConnection();
  const [feedback, setFeedback] = useState<string | null>(null);

  if (isLoading) return <p>Loading connection details...</p>;
  if (isError) return <p>Failed to fetch connection details.</p>;

  console.log("Connection Data:", data);

  const isConnected = Boolean(
    data?.access_token &&
      data?.expires_in &&
      Date.now() <
        new Date(data.created_at).getTime() +
          parseInt(data.expires_in.toString(), 10) * 1000
  );

  console.log("Is Connected:", isConnected);

  const handleConnect = async () => {
    setFeedback(null);
    try {
      console.log("Connecting...");
      await connect();
      await refetch();
      setFeedback("Successfully connected to Heritage!");
    } catch (error) {
      setFeedback("Failed to connect to Heritage.");
      console.error(error);
    }
  };

  const handleDisconnect = async () => {
    setFeedback(null);
    try {
      console.log("Disconnecting...");
      await refetch(); // Assuming disconnect updates the state
      setFeedback("Disconnected from Heritage.");
    } catch (error) {
      setFeedback("Failed to disconnect from Heritage.");
      console.error(error);
    }
  };

  return (
    <div className="p-4">
      <p className="mb-4 text-lg font-medium">
        Status: {isConnected ? "Connected" : "Connected"}
      </p>
      {feedback && <p className="text-sm text-gray-500">{feedback}</p>}
      <div className="flex justify-center gap-2 mt-4">
        {!isConnected ? (
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded"
            onClick={handleConnect}
          >
            Re-Connect
          </button>
        ) : (
          <button
            className="px-4 py-2 bg-red-500 text-white rounded"
            onClick={handleDisconnect}
          >
            Disconnect
          </button>
        )}
      </div>
    </div>
  );
};

export default VendorConnectionModal;
