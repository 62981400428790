import axios from "axios";

const connect = async () => {
  console.log("Connecting...");

  try {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/v1/company/heritage/connect`;

    return await axios.post(url);
  } catch (error) {
    console.error("Failed to connect:", error);
  }
};

export default connect;
