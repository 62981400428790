/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import { format } from "date-fns";
import Modal from "../../components/popup";
import ToolTip from "./toolTip";
import Unscheduled from "./unscheduledModal";
import WeeklyGrid from "./containers/weekly/weeklyContainer";
import DailyGrid from "./containers/daily/dailyContainer";
import MonthlyGrid from "./containers/monthly/monthlyContainer";

import Tab from "./tab";
import Sidebar from "./sidebar";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { useSnackbarContext } from "components/snackbar";
import Legend from "./legend";
import { theme } from "styles/theme";
import { useFilter } from "components/filter";
import useQueryString from "hooks/useQueryString";
import { IconButton } from "components/button";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { LocationProvider } from "./context/LocationContext";
import { ViewByTypeProvider } from "./context/ViewByTypeContext";
import { useTranslation } from 'react-i18next';

export default function CalendarIndex(props) {
  const { t } = useTranslation();
  const tBase = "views.calendar.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const queryString = useQueryString();

  let {
    date,
    grid = "weekly", // CHANGE THIS FOR DEFAULT WHEN "/calendar" LOADS
    sidebar,
  } = queryString.search;

  sidebar =
    (sidebar && sidebar === "false") || sidebar === "true" ? sidebar : "true";

  let parseDate = date ? new Date(date) : new Date();
  let formatDate = format(parseDate, "MM/dd/yyyy");

  // if (grid === "weekly" || "monthly") {
  // 	formatDate = format(parseDate, "MM/dd/yyyy");
  // } else {
  // 	formatDate = format(parseDate, "yyyy-MM-dd");
  // }
  const queryClient = useQueryClient();

  const { showSnackbar } = useSnackbarContext();

  const [unscheduledModal, setUnscheduledModal] = useState(false);
  const [toolTipModal, setToolTipModal] = useState(false);
  const [refreshStatus, setRefresh] = useState(true);

  const handleDateChange = (date) => {
    let formatDate = format(new Date(date), "MM/dd/yyyy");
    queryString.update({ ...queryString.search, date: formatDate });
  };

  const handleGridChange = (grid) => {
    queryString.update({ ...queryString.search, grid });
  };

  const refreshPage = () => setRefresh((status) => !status);

  const filtersQuery = useQuery(
    "appointment filter",
    async () =>
      await axios
        .get(
          `
          ${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/filter
          `
        )
        .then((res) => res.data)
  );

  const filterProps = useFilter(
    {
      data: {
        division: {
          label: tr("Division"),
          type: "multiple",
          options: filtersQuery.data?.divisions,
          key: "id",
          optionLabel: "label",
        },
      },
      defaultFilter: { division: queryString.search.division },
    },
    {
      setUrl: true,
    }
  );

  const appointmentMoved = useMutation(
    async ({ id, ...body }) => {
      return await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/${id}
    `,
        { ...body, grid }
      );
    },
    {
      onSuccess: async (data) => {
        showSnackbar(tr("Appointment updated!"));

        // invalidate all queries to have the new data (shows calendar change instantly)

        // not sure if needing await here (wasn't here before) or if needs specifics except for "invalidateQueries()""
        await queryClient.invalidateQueries(["appointments", "unscheduled"]);
        await queryClient.invalidateQueries([
          "appointments",
          "daily",
          formatDate,
        ]);
        await queryClient.invalidateQueries([
          "appointments",
          "weekly",
          formatDate,
        ]);
        await queryClient.invalidateQueries([
          "appointments",
          "monthly",
          formatDate,
        ]);
        await queryClient.invalidateQueries(["appointments"]);
        await queryClient.invalidateQueries();
      },
      onMutate: async (a) => {
        const interval =
          grid === "daily" ? "daily" : grid === "weekly" ? "weekly" : "monthly";
        // console.log("a.date", a.date)
        // console.log("a.prevDate", a.prevDate)
        // console.log("a.prevUserId", a.prevUserId)
        // console.log("a.userId", a.userId)

        let appts = queryClient.getQueryData([
          "appointments",
          interval,
          formatDate,
          filterProps.filters,
        ]);

        let unscheduled = queryClient.getQueryData([
          "appointments",
          "unscheduled",
        ]);

        if (!unscheduled) {
          unscheduled = {};
        }

        if (!appts) {
          appts = {};
        }

        if (!a.prevDate) {
          // handles moving unscheduled
          // console.log("!a.prevDate");
          Object.entries(unscheduled).forEach(([userId, userProps]) => {
            if (
              userProps &&
              userProps.appointments &&
              a.id in userProps.appointments
            ) {
              let spliced = userProps.appointments[a.id];

              delete userProps.appointments[a.id];

              const targetUserId = userId === a.prevUserId ? a.userId : userId;

              if (Object.keys(userProps.appointments).length === 0) {
                delete unscheduled[userId];
              }

              spliced = {
                ...spliced,
                date: format(new Date(a.date), "yyyy-MM-dd"),
              };
              appts[targetUserId].appointments[a.id] = spliced;
            }
          });
        } else if (!a.date) {
          Object.entries(appts).forEach(([userId, userProps]) => {
            if (
              userProps &&
              userProps.appointments &&
              a.id in userProps.appointments
            ) {
              let spliced = userProps.appointments[a.id];
              delete userProps.appointments[a.id];

              spliced = {
                ...spliced,
                date: null,
              };

              if (unscheduled[userId]) {
                unscheduled[userId].appointments[a.id] = spliced;
                unscheduled[userId].assignee = appts[userId].assignee;
              } else {
                unscheduled[userId] = {
                  assignee: appts[userId].assignee,
                  appointments: {
                    [a.id]: spliced,
                  },
                };
              }
            }
          });
        } else {
          // move any elements that is the same appt id
          Object.entries(appts).forEach(([userId, userProps]) => {
            if (
              userProps &&
              userProps.appointments &&
              a.id in userProps.appointments
            ) {
              userProps.appointments[a.id].date = format(
                new Date(a.date),
                "yyyy-MM-dd"
              );
            }
          });

          // copy appointment
          let spliced;
          if (
            appts &&
            appts[a.prevUserId] &&
            appts[a.prevUserId].appointments
          ) {
            spliced = appts[a.prevUserId].appointments[a.id];
          }

          // delete
          if (appts[a.prevUserId] && appts[a.prevUserId].appointments) {
            delete appts[a.prevUserId].appointments[a.id];
          }
          // move
          if (!appts[a.userId]) {
            appts[a.userId] = { appointments: {} };
          }

          if (appts[a.userId] && !appts[a.userId].appointments) {
            appts[a.userId].appointments = {};
          }

          appts[a.userId].appointments[a.id] = spliced;
        }

        queryClient.setQueryData(
          ["appointments", "monthly", formatDate],
          appts
        );

        queryClient.setQueryData(["appointments", "weekly", formatDate], appts);

        queryClient.setQueryData(["appointments", "daily", formatDate], appts);

        queryClient.setQueryData(["appointments", "unscheduled"], unscheduled);

        return;
      },
    }
  );

  return (
    <LocationProvider>
      <ViewByTypeProvider>
        <div
          className="flex overflow-hidden"
          style={{ height: `calc(100vh - ${theme.height.navigation})` }}
        >
          {sidebar === "true" && (
            <Sidebar
              {...{
                appointmentMoved,
                date,
                toolTipModal,
                refreshStatus,
                refreshPage,
              }}
              handleToolTip={setToolTipModal}
              className="relative"
            />
          )}
          <div className="overflow-auto space-y-2 flex-auto flex flex-col">
            <Tab
              handleDateChange={(date) => handleDateChange(date)}
              handleGridChange={(grid) => handleGridChange(grid)}
              {...{ date, grid, refreshStatus, filterProps }}
              handleUnscheduledClick={() => {
                setUnscheduledModal(true);
              }}
              sidebar={sidebar}
              className=""
            />
            <div className="flex flex-auto overflow-hidden">
              <div className="flex-auto overflow-hidden">
                <div style={{ height: `calc(100vh - ${142}px)` }}>
                  {grid === "daily" ? (
                    <DailyGrid
                      handleToolTip={setToolTipModal}
                      {...{
                        appointmentMoved,
                        date,
                        toolTipModal,
                        refreshStatus,
                        refreshPage,
                        filterProps,
                      }}
                    />
                  ) : grid === "weekly" ? (
                    <WeeklyGrid
                      handleToolTip={setToolTipModal}
                      {...{
                        appointmentMoved,
                        date,
                        toolTipModal,
                        refreshStatus,
                        refreshPage,
                        filterProps,
                      }}
                    />
                  ) : grid === "monthly" ? (
                    <MonthlyGrid
                      handleToolTip={setToolTipModal}
                      {...{
                        appointmentMoved,
                        date,
                        toolTipModal,
                        refreshStatus,
                        refreshPage,
                        filterProps,
                      }}
                    />
                  ) : null}
                </div>
              </div>

              {toolTipModal && (
                <ToolTip
                  {...{ toolTipModal, refreshPage }}
                  cancelDisplay={() => setToolTipModal(false)}
                />
              )}
            </div>

            <Legend grid={grid} date={formatDate} />
          </div>

          {unscheduledModal && (
            <div
              className="w-4/5 h-4/5 flex p-4"
              cancelDisplay={() => {
                refreshPage();
                setUnscheduledModal(false);
              }}
            >
              <Unscheduled />
            </div>
          )}

          <IconButton
            // className={`!fixed !bottom-2 !left-2 z-10`}
            className="absolute top-[58px] left-[10px] z-10"
            primary
            onClick={() =>
              queryString.update({
                ...queryString.search,
                sidebar: sidebar === "true" ? "false" : "true",
              })
            }
          >
            {sidebar === "true" ? <RiArrowLeftLine /> : <RiArrowRightLine />}
          </IconButton>
        </div>
      </ViewByTypeProvider>
    </LocationProvider>
  );
}
