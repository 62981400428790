import React, { useEffect } from "react";
import { useState } from "react";
import { useMutation } from "react-query";
import axios from "axios";
import { Button } from "components/button";
import ClickableTag from "./clickable-tag";
import CheckedBoxButton from "components/checkBoxButton";
import { useTranslation } from 'react-i18next';

type TProps = {
  showModal: boolean;
  toggleModal: () => void;
  onSave: (data: any) => void;
  type?: "customer" | "customer&property" | "inventory";
  className?: string;
  existingFilter?: any;
};

const TagFilterModal = ({ showModal, toggleModal, onSave, className, type, existingFilter } : TProps) => {
  const [unselectedTags, setUnselectedTags] = useState<any[]>([]);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [filterExclusive, setFilterExclusive] = useState<boolean>(false);
  const [tagsFetched, setTagsFetched] = useState<boolean>(false);
  const [tagType, setTagType] = useState<string>("customer");

  const [isApplied, setIsApplied] = useState(false);

  useEffect(() => {
    if (existingFilter) {
      const { selectedTags: existingSelectedTags, filterExclusive: existingFilterExclusive, tagType: existingTagType } = existingFilter;
  
      const existingFilterObj = {
        selectedTags: existingSelectedTags,
        filterExclusive: existingFilterExclusive,
        tagType: existingTagType,
      };
  
      const currentFilterObj = {
        selectedTags,
        filterExclusive,
        tagType,
      };
  
      setIsApplied(JSON.stringify(existingFilterObj) === JSON.stringify(currentFilterObj));
    } else {
      setIsApplied(false);
    }
  }, [selectedTags, filterExclusive, tagType, existingFilter]);

  const { t } = useTranslation();
  const tBase = "views.customers.tag-filter-modal";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const handleClose = () => {
    toggleModal();
  }

  const handleSave = () => {
    onSave({selectedTags, filterExclusive, tagType});
    toggleModal();
  };

  const getAllCustomerTags = useMutation(async () => {
    const tagType = type === "inventory" ? "allInventoryTags" : "allCustomer&PropertyTags";
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers`, {
        params: {
          type: tagType,
        },
      })
      .then((res) => {
        // prevents duplicate tags being rendered, may need in future
        var uniqueTags = new Set(res.data)
        var combinedTags = [...selectedTags, ...unselectedTags]
        for (const sourceObj of combinedTags) {
          const sourceKey = sourceObj["id"];
          if (!uniqueTags.has(sourceKey)) {
            uniqueTags.add(sourceObj)
          }
        }
        setUnselectedTags([...uniqueTags]);
      });
  });

  // React.useEffect(() => {
  //   getAllCustomerTags.mutate();
  // }, []);

  useEffect(() => {
    if (showModal && !tagsFetched) {
      getAllCustomerTags.mutate();
      setTagsFetched(true); 
    }
  }, [showModal, tagsFetched]);

  const modalOverlayStyles: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const modalContentStyles = {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
    minHeight: "400px",
    maxWidth: "400px",
    minWidth: "350px",
    border: "5px solid black",
  };

  const modalTitleStyles: React.CSSProperties = {
    marginTop: "5px",
    marginBottom: "5px",
    textAlign: "center",
  };

  const closeButtonStyles = {
    // position: 'absolute',
    display: "block",
    marginLeft: "auto",
    fontSize: "20px",
    cursor: "pointer",
    color: "#333",
  };

  const tagColumnsStyles: React.CSSProperties = {
    width: "50%",
    display: "flex",
    flexDirection: "column",
  };

  const tagTitleStyle: React.CSSProperties = {
    textAlign: "center",
    position: "sticky",
    top: "0",
    backgroundColor: "white",
  };

  const handleSelectAllTags = () => {
    setSelectedTags([...selectedTags, ...unselectedTags]);
    setUnselectedTags([]);
  }

  const handleClearAllTags = () => {
    setUnselectedTags([...unselectedTags, ...selectedTags]);
    setSelectedTags([]);
  }

  if (showModal) {
    return (
      <div style={modalOverlayStyles} className={className}>
        <div style={modalContentStyles}>
          <Button onClick={handleClose} style={closeButtonStyles}>
            &times;
          </Button>
          <h2 style={modalTitleStyles}>{tr("Tags")}</h2>
          <div style={{minHeight: "300px", maxHeight:"300px", overflow:"auto"}}>
            <div style={{display:"flex", flexDirection:"row"}}>
              <div style={tagColumnsStyles}>
                <p style={tagTitleStyle}>{tr("Unselected")}</p>
                {
                  unselectedTags.map((tag: any) => (
                    <ClickableTag key={tag.id} tag={tag} setSelectedTags={setSelectedTags} setUnselectedTags={setUnselectedTags} unselected={true}/>
                  ))
                }
              </div>
              <div style={tagColumnsStyles}>
                <p style={tagTitleStyle}>{tr("Selected")}</p>
                {
                  selectedTags.map((tag: any) => (
                    <ClickableTag key={tag.id} tag={tag} setSelectedTags={setSelectedTags} setUnselectedTags={setUnselectedTags} unselected={false}/>
                  ))
                }
              </div>
            </div>
          </div>


          <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignContent:"middle"}}>
            <Button style={{margin:"10px"}} onClick={handleSelectAllTags}>{tr("Select All")}</Button>
            <Button style={{margin:"10px"}} onClick={handleClearAllTags}>{tr("Clear All")}</Button>
          </div>

          <div style={{display:"flex", flexDirection:"column", justifyContent:"space-around", alignItems:"center", margin:"10px"}}>
            { setTagType &&
                <div style={{display:"inline-flex", justifyContent:"space-between", alignItems:"center", margin:"10px"}}>
                  <Button
                    onClick={() => {
                      setTagType("customer");
                      // getAllCustomerTags.mutate();
                    }}
                    style={{ fontSize: "16px", width: "45%" }}
                    {...(tagType === "customer" ? { primary: true } : {})}
                  >
                    Customer Tags
                  </Button>
                  <Button
                    onClick={() => {
                      setTagType("property");
                      // getAllCustomerTags.mutate();
                    }}
                    style={{ fontSize: "16px", width: "45%" }}
                    {...(tagType === "property" ? { primary: true } : {})}
                  >
                    Property Tags
                  </Button>
                </div>
            }
           <CheckedBoxButton 
              checkedValue={filterExclusive} 
              setCheckedValue={setFilterExclusive}
            >
              {tr("Exact Match")}
            </CheckedBoxButton>
          </div>
          
          <div style={{display:"flex", alignItems:"center", justifyContent:"flex-end", gap:"10px"}}>
            <p>
              {isApplied ?
                <span style={{color:"green"}}>{tr("Applied")}</span>
                :
                <span style={{color:"red" , fontStyle:"italic"}}>{tr("Not Applied")}</span>
              }
            </p>
            <Button onClick={handleSave} style={{ float: "right", marginTop:"5px" }} primary>
              {tr("Save")}
            </Button>
          </div>
        </div>
      </div>
    );
  }
  else {
    return null;
  }
};

export default TagFilterModal;
