import React from "react";

import { IconButton } from "components/button";
import { TTag } from "types/tag";
import { getContrastTextColorHEX } from "utils/getContrastTextColor";

import styles from "./Tag.module.scss";

type Props = {
  customerId: number | string;
  tag: TTag;
  type: string;
  handleUpdateTag: (
    customerId: number | string,
    tagId: number | string,
    type: string
  ) => void;
};

const Tag: React.FC<Props> = ({ customerId, tag, type, handleUpdateTag }) => {
  return (
    <div className={styles.container}>
      <p
        className={styles.label}
        style={{
          backgroundColor: tag.color,
          color: getContrastTextColorHEX(tag.color),
          border: tag.color === "#ffffff" 
            || tag.color === "#fff"
            || tag.color === "white"
            ? "1px solid #ccc" : "none",
        }}
      >
        {tag.label}
      </p>
      <IconButton
        onClick={() => {
          if (type === "add") {
            handleUpdateTag(customerId, tag.id, "add");
          } else {
            handleUpdateTag(customerId, tag.id, "remove");
          }
        }}
      >
        {type === "add" ? "+" : "-"}
      </IconButton>
    </div>
  );
};

export default Tag;
