import React from "react";
import { RiArrowGoBackLine } from "react-icons/ri";
import { FaUsers, FaClipboardCheck, FaTools } from "react-icons/fa";
import { PiMicrosoftExcelLogo } from "react-icons/pi";
import { useUpload } from '../context/UploadContext';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { 
    selected, 
    animEnd, 
    backButton,
    hoverBackButton,
    setHoverBackButton,
    viewImports
  } = useUpload();
  const { t } = useTranslation();
  const tBase = "views.admin.uploadV2.components.Header";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const handleMouseEnter = () => setHoverBackButton(true);
  const handleMouseLeave = () => setHoverBackButton(false);

  return (
    <div className="flex justify-center items-center relative mb-6">
      {(selected || viewImports) && (
        <div className="absolute left-0 top-1.5 z-[3]">
          <button 
            onClick={backButton} 
            className="flex items-center hover:underline decoration-2 underline-offset-4 decoration-primary"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <span>{tr("Back")}</span>
            <RiArrowGoBackLine className={`text-lg ml-1.5 ${hoverBackButton ? "text-primary" : "text-black"}`}/>
          </button>
        </div>
      )}

      {/* Rolling text (div is row reverse) */}
      <div className="absolute mt-1 w-full pointer-events-none">
        <div className="text-center text-lg pt-1 flex flex-col-reverse h-10 overflow-hidden w-full">
          <div className="lg:w-1/2 md:w-[300px] sm:w-[50%] border-b-2 border-gray-300 z-[2] mx-auto" />

          {/* First text */}
          <div className={` h-10 font-bold ${(selected && animEnd) || viewImports ? "animate-rollOut" : ""}`}>
            {tr("Please select which type of data you would like to import")}
          </div>


          {/* Second text */}
          <div className={`h-10 font-bold ${(selected && animEnd) || viewImports ? "animate-rollIn" : "translate-y-[-40px]"}`}>
            {viewImports && !selected ? tr("Previous Imports") : ""}
            {selected ? `${tr("Import")} ${selected.charAt(0).toUpperCase() + selected.slice(1)}` : ""}
            {selected === "customers" ? 
              <FaUsers className="text-2xl text-primary inline-block ml-2 mb-1" /> :
              selected === "inventory" ?
              <FaClipboardCheck className="text-2xl text-primary inline-block ml-2 mb-1" /> :
              selected === "services" ?
              <FaTools className="text-2xl text-primary inline-block ml-2 mb-1" /> :
              <PiMicrosoftExcelLogo className="text-2xl text-green-500 inline-block ml-2 mb-1" />
            }
          </div>

        </div>
      </div>

    </div>
  )
}

export default Header