// Converts the given date from UTC to local time
import { zonedTimeToUtc } from "date-fns-tz";

const convertFromUTC = (time: any, timeZone: string, showAmPm: boolean = true) => {
  if (!time) return "";
  
  const date = new Date(time);

  const options: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: showAmPm, 
    timeZone: timeZone,
  }; 

  const localTime = date.toLocaleTimeString([], options);

  return localTime;
};

const convertToUTC = (date: any, time: string, timeZone: any) => {
  const dateTime = `${date}T${time}:00`;
  const utcDate = zonedTimeToUtc(dateTime, timeZone); 
  return utcDate.toISOString(); // Return the UTC time in ISO format
};

export { convertFromUTC, convertToUTC };