import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Table from "components/table";
import { RequestStatusTag, CategoryTag } from "components/tag/index";
import { format } from "date-fns";
import Placeholder from "views/request/component/placeholder";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";

export default function AppointmentsList(props) {
  const { t, i18n } = useTranslation();
  const tBase = "views.property.appointment-list";
  const tr = (key) => t(`${tBase}.${key}`);
  const locale = i18n.language === "es" ? es : enUS;

  const [appointments, setAppointments] = useState(props.data);
  const history = useHistory();

  const handleClick = (row) => {
    history.push(`/requests/${row.id}`);
  };

  const columns = {
    id: {
      label: tr("Request #"),
    },
    date: {
      label: tr("Date"),
      cell: (row) => row.date ? format(new Date(row.date), "MMM dd, yyyy", { locale: locale }) : "N/A",
      minWidth: "80px",
    },
    status: {
      label: tr("Status"),
      cell: (row) => (
        <RequestStatusTag>{tr(row.appointment_status)}</RequestStatusTag>
      ),
    },
    link: {
      label: tr("Category"),
      cell: (row) => <CategoryTag>{row.link}</CategoryTag>,
    },
    note: {
      label: tr("Description"),
    },
    completion_note: {
      label: tr("Completion Note"),
      width: "100%",
    },
    // link: {
    //   label: "Category",
    //   cell: (row) => <CategoryTag>{"hi"}</CategoryTag>,
    // },
    // address: {
    //   label: "Address",
    //   width: "100%",
    // },
  };

  return (
    <div>
      {appointments && appointments.length > 0 ? (
        <Table
          columns={columns}
          data={appointments}
          handleClick={handleClick}
        />
      ) : (
        <Placeholder
          type="appointment"
          header={tr("No appointments.")}
          label={tr("Appointments will appear here when added.")}
        />
      )}
    </div>
  );
}

const Con = styled.div``;
