import * as React from "react";
import { Table, TrBody } from "./style";
import { RiArrowDownLine, RiArrowUpLine } from "react-icons/ri";
import Pagination from "./pagination";
import { NoStyleA } from "components/button";
import { isEqual } from "lodash";
import { useTranslation } from 'react-i18next';

type Sorting = {
  column: string | null;
  order: string | null;
};

type Options = {
  onPaginate?: (page: number) => void;
  onSort?: (sort: Sorting) => void;
  sort?: Sorting;
  // isIndexState?: boolean;
  pageIndex?: number;
  total?: number;
  url?: boolean;
};

export const useTable = (options?: Options) => {
  const [sort, setSort] = React.useState<Sorting>({
    column: null,
    order: null,
  });

  const [pageIndex, setPageIndex] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);

  let actualSort = options?.url ? options?.sort! : sort;
  let actualPageIndex = options?.url ? options?.pageIndex! : pageIndex;

  React.useEffect(() => {
    if (!options?.url && options?.sort) {
      setSort(options.sort);
    }
  }, [options?.url, options?.sort]);

  React.useEffect(() => {
    setPageCount(options?.total || 1);
  }, [options?.total]);

  React.useEffect(() => {
    if (!options?.url && options?.pageIndex) {
      setPageIndex(options.pageIndex);
    }
  }, [options]);

  const handleSort = (key: string) => {
    let resss;

    if (actualSort.column === key) {
      if (actualSort.order === "DESC") {
        resss = { ...actualSort, order: "ASC" };
      } else {
        resss = { ...actualSort, order: "DESC" };
      }
    } else {
      resss = {
        column: key,
        order: "ASC",
      };
    }

    if (options?.url) {
      options?.onSort && options.onSort(resss);
    } else {
      setSort(resss);
      handlePage(1);
    }
  };

  const handlePage = (page: number) => {
    if (options?.url) {
      options.onPaginate && options.onPaginate(page);
    } else {
      setPageIndex(page);
    }

    window.scrollTo(0, 0);
  };

  return {
    sort: options?.url ? options?.sort : sort,
    handleSort,
    pageIndex: actualPageIndex,
    handlePage,
    pageCount,
    setPageCount,
  };
};

type ColumnsProps<T> = {
  label: string;
  disabled?: boolean;
  isClickable?: boolean;
  headerStyle?: {};
  style?: {};
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  cell?: (row: T, groupPunchKey?: string) => React.ReactNode;
  className?: string;
};

type Columns<T> = {
  [e: string]: ColumnsProps<T>;
};

type Props<T> = {
  data: T[] | undefined | null;
  columns: Columns<T>;
  pageCount?: number;
  pageIndex?: number;
  handlePage?: (page: number, groupPunchKey?: string) => void;
  handleClick?: (row: T) => void;
  to?: (row: T) => string;
  sort?: Sorting;
  handleSort?: (e: string) => void;
  type?: "a" | "button";
  bubbleId?: string | undefined;
  isCustomerDashboard?: boolean | false; 
  isTimedashboard?: boolean | false;
  groupPunchKey?: string | undefined;
  timesToColor?: string[];
  rOrC?: string; // "requests" or "customers"
};

export default function TableComponent<T>(props: Props<T>) {
  const { t } = useTranslation();
  const tBase = "components.table";
  const tr = (key: string) => t(`${tBase}.${key}`);
  
    let timer: any
    //consider making the fade in / fade out bubble a component because it is used elsewhere
    const fadeInBubble = (message:any, location:any) => {
      clearTimeout(timer)
      if (message !== null) {
        if (message.length > 20) {
          const bubble = document.getElementById(props.bubbleId!)
          if (bubble) {
          bubble!.style.opacity = "1"

          let elementLoc = location.target.getBoundingClientRect()
          let bodyLoc = document.body.getBoundingClientRect()

          bubble!.innerHTML = message
          bubble!.style.display = "block"
          bubble!.style.top = (elementLoc.y - bodyLoc.y) - 30 + "px" 
          bubble!.style.left = (elementLoc.x - bodyLoc.x) + 200 + "px"
          }
        }
      }
    }

    const fadeOutBubble = () => {
        const bubble = document.getElementById(props.bubbleId!)
        if (bubble) {
        bubble!.style.opacity = "0"
        timer = setTimeout(() => {
            let bubble = document.getElementById(props.bubbleId!)
            bubble!.style.display = "none";
        }, 2000)
      }
    }

  let colCount = 0;
  const populateHeaders = Object.entries(props.columns).map(([key, value]) => {
    colCount++;
    const isSorted = props.sort?.column === key;
    return (
      <th
        style={{
          ...value.headerStyle,
          // color: isSorted ? theme.color.main : "black",
          width: value.width,
          minWidth: value.minWidth,
          maxWidth: value.maxWidth,
        }}
        key={key}
        onClick={() =>
          !value.disabled &&
          props.handleSort !== undefined &&
          props.handleSort(key)
        }
        className="border-b border-[#ff4f00] sticky top-0 !py-2"
      >
        <div className="px-2">
          {value.label}
          {isSorted &&
            (props.sort?.order === "ASC" ? (
              <RiArrowUpLine />
            ) : (
              <RiArrowDownLine />
            ))}
        </div>
      </th>
    );
  });

  const populateRows =
    props.data !== undefined ? (
      props.data && props.data.length > 0 ? (
        props.data.map((row: any, index) => {
          const isLast = props.data && index === props.data.length - 1;
          
          const to = props.to && props.to(row);

          const populateCols = Object.entries(props.columns).map(
            ([key, value]) => {
              const child = (
                key === "note" ?
                  <div
                    style={{
                      ...value.style,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    className="p-2 block"
                    onMouseOver={(e) => fadeInBubble(row.note, e)}
                    onMouseOut={(e) => fadeOutBubble()}
                  >
                    {row.note}
                  </div>
                :
                  <div
                    style={{
                      ...value.style,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    className="p-2 block"
                  >
                    {props.groupPunchKey ? 
                    value.cell ? value.cell(row, props.groupPunchKey) : row[key]
                    :
                    value.cell ? value.cell(row) : row[key]
                    }
                  </div>
              );

              return (
                <td
                  key={key}
                  style={
                    props.isCustomerDashboard ?
                    {
                      ...value.headerStyle,
                      // color: isSorted ? theme.color.main : "black",
                      minWidth: "20em",
                      maxWidth: "20em",
                    } : {
                      ...value.headerStyle,
                    }
                  }
                  className={`text-[#334155] border-b border-[#ff4f00] ${!row.active && props.rOrC === "customers" ? "bg-red-100 cursor-not-allowed" : "bg-white cursor-pointer"}`}
                >
                  {(
                    to && !value.isClickable && !row.active && props.rOrC === "customers"
                   ) ? (
                    child
                  ) : ( 
                    to && !value.isClickable 
                  ) ? (                    
                    <NoStyleA
                      style={{ ...value.style }}
                      to={to}
                      className="w-full block"
                    >
                      {child}
                    </NoStyleA>
                  ) : 
                    child
                  }
                </td>
              );
            }
          );
          

          // console.log("times to color ", props.timesToColor)
          const backgroundColor = row.time && props.timesToColor?.includes(new Date(row.time).toLocaleString()) ? "#fdd9c8" : "white";
          return (
            <TrBody
              style={{ backgroundColor: backgroundColor }}
              clickable={props.handleClick || props.to ? true : false}
              key={index}
              onClick={() => props.handleClick && props.handleClick(row)}
            >
              {populateCols}
            </TrBody>
          );
        })
      ) : (
        <TrBody>
          <td>
            <div className="text-slate-500 py-4">{tr("No data at the moment.")}</div>
          </td>
        </TrBody>
      )
    ) : (
      <>
        {new Array(3).fill(0).map((_, rowInd) => (
          <TrBody className="animate-pulse" key={rowInd}>
            {new Array(colCount).fill(0).map((_, index) => (
              <td key={index}>
                <div className="bg-slate-200 h-2 block  w-[90%] my-4 rounded" />
              </td>
            ))}
          </TrBody>
        ))}
      </>
    );

  const handleButtonClick = (index: number) => {
    if (props.groupPunchKey) {
      props.handlePage!(index, props.groupPunchKey);
    } else {
      props.handlePage!(index);
    }
  };

  // since TableComponent is used in multiple places, 
  // I've conditionally rendered this instance to prevent any unintended issues
  if (props.isTimedashboard) {
    return (
      <div className="w-full flex flex-col align-center">
        <div className="overflow-auto" style={props.isTimedashboard ? { height: "fit-content" } : {}}>
          <Table className="table-auto overflow-auto">
            <thead>
              <tr style={{position:"sticky", top:"0", backgroundColor:"white"}}>{populateHeaders}</tr>
            </thead>
            <tbody>{populateRows}</tbody>
          </Table>
        </div>
        {props.pageCount && props.pageCount > 1 ? (
          <Pagination
            {...{ handleButtonClick }}
            pageCount={props.pageCount!}
            pageIndex={props.pageIndex!}
          />
        ) : null}
      </div>
    )
  }

  return (
    <div className="w-full align-center">
      <div className="">
        <Table className="table-auto overflow-auto">
          <thead>
            <tr>{populateHeaders}</tr>
          </thead>
          <tbody>{populateRows}</tbody>
        </Table>
      </div>
      {props.pageCount && props.pageCount > 1 ? (
        <Pagination
          {...{ handleButtonClick }}
          pageCount={props.pageCount!}
          pageIndex={props.pageIndex!}
        />
      ) : null}
    </div>
  );
}
