import * as React from "react";
import styled from "styled-components";
import { Button } from "components/button";
import { Input } from "components/input";
import Axios from "axios";
import { useMutation } from "react-query";
import { useTranslation } from 'react-i18next';

export default function AddChildModal({
  id,
  type,
}: {
  id: number;
  type: "jobs" | "estimates";
}) {
  const { t } = useTranslation();
  const tBase = "views.request.container.addToRequest";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [label, setLabel] = React.useState("");

  const addNewChildRequest = useMutation(
    async () => {
      return await Axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/request/addChildRequest`, //addRequestChild
        {
          type,
          label,
          requestId: id,
        }
      ).then((res) => res.data);
    },
    {
      onSuccess: (childId) => {
        window.location.replace(`/${type}/${childId}`);
      },
    }
  );

  const handleLabelChange = (e: any) => {
    let { value } = e.target;
    setLabel(value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    addNewChildRequest.mutate();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Input
        label={tr("Label")}
        required
        autoFocus
        onChange={handleLabelChange}
        placeholder={tr("etc. Front Yard")}
      />
      <Button
        disabled={!label}
        isLoading={addNewChildRequest.isLoading}
        style={{ width: "100%", padding: "0.5rem 0", marginTop: "1rem" }}
        type="submit"
        primary
      >
        {tr("Submit")}
      </Button>
    </form>
  );
}
