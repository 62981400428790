import axios from "axios";
import { TCategory } from "./TCategory";
import { useQuery, useQueryClient } from "react-query";
import {
  AiFillDelete,
  AiFillEdit,
  AiFillPicture,
  AiFillSetting,
  AiOutlineArrowUp,
} from "react-icons/ai";
import { useState } from "react";
import { useSnackbarContext } from "components/snackbar";
import { useModalContext } from "components/modal";
import MoveCategory from "./MoveCategory";
import { useAreYouSure } from "components/areYouSure";
import { CategoryModal } from "./CategoryModal";
import { FaAngleDown, FaAngleUp, FaBoxes, FaUndo } from "react-icons/fa";
import CategoryParts from "./CategoryParts";
import TooltippedElement from "components/TooltippedElement";
import { useTranslation } from "react-i18next";

interface ICategoriesProps {
  categories: TCategory[];
  isLoading: boolean;
  search?: string | string[] | null;
  onSave: () => void;
}

const Categories = (props: ICategoriesProps) => {
  console.log("Categories -> props", props);
  const { t } = useTranslation();
  const tBase = "views.inventories.categories.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const { categories, isLoading, search, onSave } = props;
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbarContext();
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null
  );

  const moveCategory = async (draggedId: number, targetId: number) => {
    const moveResponse = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/company/products/move-subcategory`,
      {
        subCatId: draggedId,
        toParentId: targetId,
      }
    );

    if (moveResponse.status !== 200) {
      queryClient.invalidateQueries("categories");
      showSnackbar(tr("Failed to move category"));
      return;
    } else {
      showSnackbar("Category moved successfully");
      queryClient.invalidateQueries("categories");
      onSave();
    }
  };

  if (isLoading) {
    return <div>{tr("Loading")}...</div>;
  }

  // if (isError) {
  //   return <div>Error fetching categories</div>;
  // }

  let filteredCategories: TCategory[] = [];

  if (search && typeof search === "string" && search.length > 0) {
    const lowerSearch = search.toLowerCase();

    // Filter parent categories or subcategories based on the search term
    filteredCategories = categories.filter(
      (category) =>
        category.category_name?.toLowerCase().includes(lowerSearch) ||
        category.subcategories?.some((subCat) =>
          subCat.category_name?.toLowerCase().includes(lowerSearch)
        )
    );
  } else {
    // Show only parent categories if no search term is provided
    filteredCategories = categories?.filter(
      (category) => category.is_parent_category
    );
  }

  return (
    <div>
      {filteredCategories?.map((category: TCategory) => (
        <CategoryItem
          key={category.category_id}
          category={category}
          categories={categories}
          moveCategory={moveCategory}
          selectedCategoryId={selectedCategoryId}
          setSelectedCategoryId={setSelectedCategoryId}
          onSave={onSave}
        />
      ))}
    </div>
  );
};

const CategoryItem: React.FC<{
  category: TCategory;
  categories: TCategory[];
  selectedCategoryId: number | null;
  setSelectedCategoryId: (id: number | null) => void;
  moveCategory: (draggedId: number, targetId: number) => void;
  onSave: () => void;
}> = ({ category, categories, onSave }) => {
  const { t } = useTranslation();
  const tBase = "views.inventories.categories.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showParts, setShowParts] = useState(false);
  const { showSnackbar } = useSnackbarContext();
  const { setModal } = useModalContext();
  const queryClient = useQueryClient();
  const areYouSure = useAreYouSure();

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClickMoveCategory = (subCategoryId: number) => {
    const categoryOptions = categories
      .filter((cat) => {
        return cat.category_id !== subCategoryId && cat.is_parent_category;
      })
      .map((cat) => ({
        label: cat.category_name,
        value: cat.category_id,
      }));

    setModal({
      component: (
        <MoveCategory
          categories={categoryOptions}
          subCategoryId={subCategoryId}
          onSave={onSave}
        />
      ),
      label: tr("Move Category"),
    });
  };

  const handleClickEditCategory = (categoryId: number) => {
    setModal({
      component: <CategoryModal id={categoryId} onSave={onSave} />,
      label: tr("Edit Category"),
    });
  };

  const handleClickArchiveCategory = async (categoryId: number) => {
    areYouSure.activate(
      () => {
        axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/company/products/category/deactivate/${categoryId}`
          )
          .then(() => {
            queryClient.invalidateQueries("items");
            showSnackbar(tr("Category archived successfully."));
          })
          .catch(() => {
            showSnackbar("Failed to archive category.");
          });
      },
      { message: tr("Are you sure you want to perform this action?") }
    );
  };

  const handleClickUnArchiveCategory = async (categoryId: number) => {
    areYouSure.activate(
      () => {
        axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/company/products/category/activate/${categoryId}`
          )
          .then(() => {
            queryClient.invalidateQueries("items");
            showSnackbar(tr("Category restored successfully."));
          })
          .catch(() => {
            showSnackbar(tr("Failed to restore category."));
          });
      },
      { message: tr("Are you sure you want to perform this action?") }
    );
  };

  const archivedMsg =
    tr("Archived By") +
    ": " +
    category.archived_by +
    tr(" on ") +
    (category &&
      category.archived_at &&
      new Date(category.archived_at).toLocaleString("default", {
        weekday: "long",
        month: "short",
        day: "numeric",
        year: "numeric",
      }));

  const iconCircleStyle =
    " cursor-pointer py-2 h-10 w-10 rounded-full bg-[#edf1f4] flex items-center justify-center hover:bg-[#bec1c3] select-none";

  // const iconCircleStyle = " cursor-pointer hover:text-opacity-50";

  return (
    <>
      <div
        className={`flex items-center p-4 mt-2 mb-2 hover:bg-[#f6fafe] hover:bg-opacity-50 ${
          category.is_parent_category ? "cursor-pointer" : "cursor-auto"
        }`}
      >
        <div
          className="flex flex-row items-center flex-grow"
          onClick={() => {
            if (category.is_parent_category) {
              handleExpandClick();
            }
          }}
        >
          <div className="flex items-center justify-center flex-none w-20 h-20 mr-4 text-xs text-gray-700 bg-gray-200">
            <AiFillPicture size="50px" />
          </div>
          <div className="flex-grow">
            <div className="text-lg font-bold flex items-center">
              {category.category_name}
              {!category.active && (
                <TooltippedElement
                  placement="right"
                  message={archivedMsg}
                  element={
                    <span className="font-bold text-xs bg-red-600 rounded text-white px-1 uppercase ml-2">
                      {tr("Archived")}
                    </span>
                  }
                />
              )}
            </div>

            {category.is_parent_category && (
              <button>
                {isExpanded ? tr("Collapse") : tr("Expand")}{" "}
                {tr("Sub-Categories")}
              </button>
            )}

            {isExpanded ? (
              <div className="flex flex-row items-center justify-center w-10 h-4 text-blue-500">
                <FaAngleDown size={12} />
                <FaAngleDown size={12} />
                <FaAngleDown size={12} />
              </div>
            ) : category.is_parent_category ? (
              <div className="flex flex-row items-center justify-center w-10 h-4 text-blue-500">
                <FaAngleUp size={12} />
                <FaAngleUp size={12} />
                <FaAngleUp size={12} />
              </div>
            ) : (
              <div className="text-gray-400 text-[12px]">
                ({tr("Sub-category")})
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row items-center [&>*]:ml-6">
          <TooltippedElement
            placement="top"
            message={tr("Edit")}
            element={
              <AiFillEdit
                size={24}
                className={"text-blue-500" + iconCircleStyle}
                onClick={() =>
                  setModal({
                    component: (
                      <CategoryModal
                        id={category.category_id}
                        onSave={onSave}
                      />
                    ),
                    label: tr("Edit Category"),
                  })
                }
              />
            }
          />
          {category && category.active ? (
            <TooltippedElement
              placement="top"
              message={tr("Archive")}
              className="inline-flex"
              element={
                <AiFillDelete
                  size={24}
                  className={"text-red-500 cursor-pointer" + iconCircleStyle}
                  onClick={() => {
                    handleClickArchiveCategory(category.category_id);
                  }}
                />
              }
            />
          ) : (
            <TooltippedElement
              placement="top"
              message={tr("Restore")}
              className="inline-flex"
              element={
                <FaUndo
                  size={24}
                  className={
                    "text-green-500 cursor-pointer py-2.5 h-10 w-10 rounded-full bg-[#edf1f4] flex items-center justify-center hover:bg-[#bec1c3] select-none"
                  }
                  onClick={() => {
                    handleClickUnArchiveCategory(category.category_id);
                  }}
                />
              }
            />
          )}
        </div>
      </div>
      {isExpanded && (
        <div className="pl-8 font-bold">
          {tr("Sub-categories")}
          {category.subcategories.map((subCategory: TCategory) => (
            <div
              key={subCategory.category_id}
              className="flex flex-row items-center justify-between p-4 border-b border-gray-200 text-secondary hover:bg-blue-200 hover:bg-opacity-25"
            >
              <div className="flex items-center">
                <span>{subCategory.category_name}</span>
                {!subCategory.active && (
                  <TooltippedElement
                    placement="right"
                    message={archivedMsg}
                    element={
                      <span className="font-bold text-xs bg-red-600 rounded text-white px-1 uppercase ml-2">
                        {tr("Archived")}
                      </span>
                    }
                  />
                )}
              </div>
              {showParts ? (
                <CategoryParts subCategoryId={category.category_id} />
              ) : null}
              <div className="flex flex-row items-center [&>*]:ml-6">
                {/* TODO: Either delete settings button to add stuff or delete */}
                {/* <AiFillSetting
                    size={24}
                    className="mr-6 text-gray-500 cursor-pointer"
                    onClick={() => setShowParts(!showParts)}
                  /> */}
                <TooltippedElement
                  message={tr("Move Category")}
                  placement="top"
                  element={
                    <AiOutlineArrowUp
                      size={24}
                      className={
                        "text-green-500 cursor-pointer" + iconCircleStyle
                      }
                      onClick={() =>
                        handleClickMoveCategory(subCategory.category_id)
                      }
                    />
                  }
                />
                <TooltippedElement
                  placement="top"
                  message={tr("Edit")}
                  element={
                    <AiFillEdit
                      size={24}
                      className={
                        "text-blue-500 cursor-pointer" + iconCircleStyle
                      }
                      onClick={() =>
                        handleClickEditCategory(subCategory.category_id)
                      }
                    />
                  }
                />
                {subCategory && subCategory.active ? (
                  <TooltippedElement
                    placement="top"
                    message={tr("Archive")}
                    className="inline-flex"
                    element={
                      <AiFillDelete
                        size={24}
                        className={
                          "text-red-500 cursor-pointer" + iconCircleStyle
                        }
                        onClick={() => {
                          handleClickArchiveCategory(subCategory.category_id);
                        }}
                      />
                    }
                  />
                ) : (
                  <TooltippedElement
                    placement="top"
                    message={tr("Restore")}
                    className="inline-flex"
                    element={
                      <FaUndo
                        size={24}
                        className={
                          "text-green-500 cursor-pointer py-2.5 h-10 w-10 rounded-full bg-[#edf1f4] flex items-center justify-center hover:bg-[#bec1c3] select-none"
                        }
                        onClick={() => {
                          handleClickUnArchiveCategory(subCategory.category_id);
                        }}
                      />
                    }
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="h-2" />
      <hr />
    </>
  );
};

export { Categories, CategoryItem };
