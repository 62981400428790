import React, { useState, useEffect } from "react";
import { Button, MenuButton } from "components/button";
import { Input } from "components/input";
import { ContainerWithSidebar } from "../../../components/layout/index";
import axios from "axios";
import FileSaver from "file-saver";
import { useTranslation } from "react-i18next";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import "./index.scss";
import Switch from "rc-switch";

const Send = (props) => {
  const { t } = useTranslation();
  const tBase = "views.purchasing.send.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const [sendList, setSendList] = useState([]);
  const [currentPo, setCurrentPo] = useState(null);
  const [emailTo, setEmailTo] = useState("");
  const [open, setOpen] = useState(false);
  const [companyDetails, setCompanyDetails] = useState(null);

  const fetchSend = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/fetchSend`
    );

    setSendList(response.data.sendResult);
    setCompanyDetails(response.data.companyDetails[0]);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getOrderPdf = async (id) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/getOrderPdf/${id}`,
      { responseType: "blob" }
    );

    FileSaver.saveAs(
      response.data,
      `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}_${
        currentPo.po
      }_${currentPo.vendor_name || currentPo.vendor}`
    );
  };

  const sendEmail = async () => {
    let po = currentPo;
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/emailSend`,
      {
        emailTo,
        currentPo,
      }
    );

    setCurrentPo(null);
    if (response.status === 200) {
      po.email_timestamp = new Date().toLocaleDateString();
    }
    setCurrentPo(po);
  };

  const updateSent = async (id) => {
    await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/updateSent`,
      {
        poId: id,
      }
    );

    setCurrentPo(null);
    props.fetchCounts();
    fetchSend();
  };

  const handleChangePo = (po) => {
    sendList.forEach((item) => {
      if (item.id === po) {
        setCurrentPo(item);
      }
    });
  };

  const sumItemsPrices = (items) => {
    let sum = 0;

    if (items) {
      items.forEach((item) => {
        sum += item.price * item.quantity;
      });
    }

    return sum;
  };

  useEffect(() => {
    fetchSend();
  }, []);

  useEffect(() => {
    if (
      currentPo &&
      currentPo.hasOwnProperty("email_address") &&
      currentPo.email_address
    ) {
      setEmailTo(currentPo.email_address);
    } else {
      setEmailTo("");
    }
  }, [currentPo]);

  if (!companyDetails) return null;

  return (
    <ContainerWithSidebar>
      <div>
        <div className=" ml-3">{tr("Ready to Send")}</div>
        {sendList &&
          sendList.map((po, index) => {
            return (
              <MenuButton
                style={{
                  textAlign: "left",
                  marginTop: 3,
                  marginBottom: 3,
                  fontSize: "90%",
                }}
                key={index}
                onClick={() => handleChangePo(po.id)}
              >
                <div>
                  {po.po}
                  {" ("}
                  {po.hasOwnProperty("vendor_name")
                    ? po.vendor_name
                    : po.vendor}
                  {")"}
                </div>
              </MenuButton>
            );
          })}
      </div>
      <div>
        {currentPo ? (
          <>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {currentPo.email_timestamp ? (
                  <div style={{ color: "red" }}>
                    {tr("Email sent on")}{" "}
                    {new Date(currentPo.email_timestamp).toLocaleDateString()}
                  </div>
                ) : (
                  <div>Confirm Send</div>
                )}
                <div className="text-sm">
                  <div>
                    Send Email
                    <Switch checked={true} />
                  </div>
                  <div>
                    Submit Online
                    <Switch checked={true} />
                  </div>
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {currentPo.email_timestamp ? (
                    <div>
                      {tr("Send again to")} {emailTo}?
                    </div>
                  ) : (
                    <div>
                      {tr("Send to")} {emailTo}?
                    </div>
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  {tr("Cancel")}
                </Button>
                <Button
                  onClick={() => {
                    sendEmail();
                    handleClose();
                  }}
                  color="primary"
                  autoFocus
                >
                  {tr("Send")}
                </Button>
              </DialogActions>
            </Dialog>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: 45,
                marginTop: 10,
                justifyContent: "flex-end",
              }}
            >
              {currentPo.email_timestamp ? (
                <div className="email-label">
                  <div>
                    {tr("Email Sent")}:{" "}
                    {new Date(currentPo.email_timestamp).toLocaleDateString()}
                  </div>
                </div>
              ) : null}
              <Input
                style={{ width: 300 }}
                placeholder={tr("email")}
                value={emailTo}
                onChange={(e) => {
                  setEmailTo(e.target.value);
                }}
              />
              <Button
                primary={currentPo.email_timestamp ? false : true}
                onClick={handleClickOpen}
                style={{ marginLeft: 10 }}
              >
                {tr("Send")}
              </Button>

              <Button
                onClick={() => getOrderPdf(currentPo.id)}
                style={{ marginLeft: 10 }}
              >
                {tr("Download")}
              </Button>

              <Button
                onClick={() => {
                  updateSent(currentPo.id);
                }}
                style={{ marginLeft: 10 }}
                primary={currentPo.email_timestamp ? true : false}
              >
                {tr("Move to Receiving")}
              </Button>
            </div>
            <div
              style={{
                border: "1px solid black",
                width: 768,
                minHeight: 960,
                margin: "10px auto",
              }}
            >
              <div
                id="po"
                className="po-body"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: 960,
                  width: 768,
                  margin: "10px auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <img style={{ width: 50, height: 50 }} alt="company-logo" />
                    <div style={{ fontSize: "70%" }}>
                      {companyDetails.name && <div>{companyDetails.name}</div>}
                      {companyDetails.address && (
                        <div>{companyDetails.address}</div>
                      )}
                      <div>{`${companyDetails.city}, ${companyDetails.state} ${companyDetails.zipcode}`}</div>
                      {companyDetails.phone && (
                        <div>{companyDetails.phone}</div>
                      )}
                      {companyDetails.email && (
                        <div>{companyDetails.email}</div>
                      )}
                    </div>
                  </div>

                  <div className="po-info-box">
                    <div style={{ fontWeight: "bold", fontSize: "150%" }}>
                      {tr("Purchase Order")}
                    </div>
                    <div style={{ marginTop: 10 }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>{tr("DATE")}</div>
                        <div
                          style={{
                            border: "1px solid black",
                            padding: "2px 10px",
                            width: 100,
                            textAlign: "center",
                          }}
                        >
                          {new Date(currentPo.timestamp).toLocaleDateString()}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>PO #</div>
                        <div
                          style={{
                            border: "1px solid black",
                            padding: "2px 10px",
                            width: 100,
                            textAlign: "center",
                            borderTop: 0,
                          }}
                        >
                          {currentPo.po}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    marginTop: 20,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="vendor-box">
                    <div>
                      <div className="vendor-header">{tr("Vendor")}</div>
                      <div className="vendor-details">
                        <div>
                          {currentPo.vendor_name
                            ? currentPo.vendor_name
                            : currentPo.vendor}
                        </div>
                        {currentPo.street_address ? (
                          <>
                            <div>{currentPo.street_address}</div>
                            <div>{currentPo.address_line_2}</div>
                            <div>
                              {currentPo.city}, {currentPo.vendor_state}{" "}
                              {currentPo.zip_code}
                            </div>
                          </>
                        ) : null}
                        <div>
                          {currentPo.phone_number
                            ? currentPo.phone_number
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="ship-to-box">
                    <div className="ship-to-header">{tr("Ship To")}</div>
                    <div className="ship-to-details">
                      {companyDetails.name && <div>{companyDetails.name}</div>}
                      {companyDetails.address && (
                        <div>{companyDetails.address}</div>
                      )}
                      <div>{`${companyDetails.city}, ${companyDetails.state} ${companyDetails.zipcode}`}</div>
                    </div>
                  </div>
                </div>

                <div className="shipping-box">
                  <div className="shipping-header">Shipping Method</div>
                  <div className="shipping-details">
                    {currentPo.shipping_method}
                  </div>
                </div>

                <div className="products-box">
                  <div className="products-header">
                    <div className="products-header-col-1">
                      {tr("Product Name/Description")}
                    </div>
                    <div className="products-header-col-2">
                      {tr("Quantity")}
                    </div>
                    <div className="products-header-col-3">
                      {tr("Unit Cost")}
                    </div>
                    <div className="products-header-col-4">Total</div>
                  </div>

                  {currentPo.items?.map((item) => {
                    return (
                      <div className="products-details">
                        <div className="products-body-col-1">
                          <div>
                            {`${item.pc_name} -> ${item.category_name} -> ${item.item_name}`}
                          </div>
                          <div>
                            {tr("Vendor")} #:{" "}
                            {item.vendor_part_number ||
                              item.model ||
                              item.sku ||
                              ""}
                          </div>
                        </div>
                        <div className="products-body-col-2">
                          {item.quantity}
                        </div>

                        <div className="products-body-col-3">
                          {item.price && item.price > 0
                            ? (item.price / 100).toLocaleString("en-us", {
                                style: "currency",
                                currency: "usd",
                              })
                            : 0}
                        </div>
                        <div className="products-body-col-4">
                          {item.price && item.price > 0
                            ? (
                                (item.price * item.quantity) /
                                100
                              ).toLocaleString("en-us", {
                                style: "currency",
                                currency: "usd",
                              })
                            : 0}
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div
                  style={{
                    marginTop: 20,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="notes-box">
                    <div className="notes-header">
                      {tr("Notes/Instructions")}
                    </div>
                    <div className="notes-details"></div>
                  </div>

                  <div className="totals-box">
                    <div className="totals-row">
                      <div>{tr("Subtotal")}</div>
                      <div>
                        {(sumItemsPrices(currentPo.items) / 100).toLocaleString(
                          "en-us",
                          {
                            style: "currency",
                            currency: "usd",
                          }
                        )}
                      </div>
                    </div>
                    <div className="totals-row">
                      <div>{tr("Discount Received")} (-)</div>
                      <div>
                        {currentPo.discount > 0
                          ? (currentPo.discount / 100).toLocaleString("en-us", {
                              style: "currency",
                              currency: "usd",
                            })
                          : 0}
                      </div>
                    </div>
                    <div className="totals-row">
                      <div>{tr("Sales Tax Paid")}</div>
                      <div>0</div>
                    </div>
                    <div className="totals-row">
                      <div>{tr("Shipping/Freight Paid")}</div>
                      <div>
                        {currentPo.shipping_cost > 0
                          ? (currentPo.shipping_cost / 100).toLocaleString(
                              "en-us",
                              {
                                style: "currency",
                                currency: "usd",
                              }
                            )
                          : 0}
                      </div>
                    </div>
                    <div
                      className="totals-row"
                      style={{ fontWeight: "bolder" }}
                    >
                      <div>{tr("Grand Total")}</div>
                      <div>
                        {(
                          (sumItemsPrices(currentPo.items) -
                            parseInt(currentPo.discount || 0) +
                            parseInt(currentPo.shipping_cost || 0)) /
                          100
                        ).toLocaleString("en-us", {
                          style: "currency",
                          currency: "usd",
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </ContainerWithSidebar>
  );
};

export default Send;
