import React from "react";
import { ButtonProps, OutlineButton } from "components/button";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import "./reports.scss";
import SafeArea from "components/safe-area";
import {
  FiArchive,
  FiCalendar,
  FiFileText,
  FiGrid,
  FiLayers,
  FiPercent,
} from "react-icons/fi";
import { useMutation } from "react-query";
import { useModalContext } from "components/modal";
import NewReportModal from "./newReportModal";
import { useSnackbarContext } from "components/snackbar";
import ReportParametersModal from "./components/ReportParametersModal";

const Reports = () => {
  const { t } = useTranslation();
  const tBase = "views.reports.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const modal = useModalContext(),
    snackbar = useSnackbarContext();

  // const downloadFile = useMutation(
  //   async () =>
  //     await axios
  //       .post(
  //         `
  // ${process.env.REACT_APP_SERVER_URL}/api/v1/company/reports/test
  // `
  //       )
  //       .then((res) => res.data),
  //   {
  //     onSuccess: (url) => {
  //       window.location.assign(url);
  //     },
  //   }
  // );

  const downloadDailyInstall = async () => {
    try {
      axios({
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/company/reports/dailyInstall`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `DailyInstall_${new Date()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      console.error(error);
    }
  };

  const downloadStockLevels = async () => {
    try {
      snackbar.showSnackbar(tr("Downloading...."));
      axios({
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/company/reports/stockLevels`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `StockLevels_${new Date()}.xlsx`);
        document.body.appendChild(link);
        link.click();
        snackbar.showSnackbar();
      });
    } catch (error) {
      console.error(error);
    }
  };

  const downloadBelowMinimum = async () => {
    try {
      snackbar.showSnackbar(tr("Downloading...."));
      axios({
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/company/reports/belowMinimum`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `BelowMin_${new Date()}.xlsx`);
        document.body.appendChild(link);
        link.click();
        snackbar.showSnackbar();
      });
    } catch (error) {
      console.error(error);
    }
  };

  // const downloadPropertyList = async () => {
  //   try {
  //     snackbar.showSnackbar(tr("Downloading...."));
  //     axios({
  //       url: `${process.env.REACT_APP_SERVER_URL}/api/v1/company/reports/propertyList`,
  //       method: "GET",
  //       responseType: "blob",
  //     }).then((response) => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", `PropertyList_${new Date()}.xlsx`);
  //       document.body.appendChild(link);
  //       link.click();
  //       snackbar.showSnackbar();
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // const downloadPropertyList = useMutation(
  //   async () =>
  //     await axios
  //       .get(
  //         `
  //           ${process.env.REACT_APP_SERVER_URL}/api/v1/company/reports/propertyList
  //         `
  //       )
  //       .then((res) => res.data),
  //   {
  //     onSuccess: (url) => {
  //       window.location.assign(url);
  //     }
  //   }
  // );


  // const downloadAppointmentList = async () => {
  //   try {
  //     snackbar.showSnackbar(tr("Downloading...."));
  //     axios({
  //       url: `${process.env.REACT_APP_SERVER_URL}/api/v1/company/reports/appointmentList`,
  //       method: "GET",
  //       responseType: "blob",
  //     }).then((response) => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", `AppointmentList_${new Date()}.xlsx`);
  //       document.body.appendChild(link);
  //       link.click();
  //       snackbar.showSnackbar();
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // const downloadAppointmentList = useMutation(
  //   async () =>
  //     await axios
  //       .get(
  //         `
  //           ${process.env.REACT_APP_SERVER_URL}/api/v1/company/reports/appointmentList
  //         `
  //       )
  //       .then((res) => res.data),
  //   {
  //     onSuccess: (url) => {
  //       window.location.assign(url);
  //     }
  //   }
  // );

  // const downloadInvoiceList = async () => {
  //   try {
  //     snackbar.showSnackbar(tr("Downloading...."));
  //     axios({
  //       url: `${process.env.REACT_APP_SERVER_URL}/api/v1/company/reports/invoiceList`,
  //       method: "GET",
  //       responseType: "blob",
  //     }).then((response) => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", `InvoiceList_${new Date()}.xlsx`);
  //       document.body.appendChild(link);
  //       link.click();
  //       snackbar.showSnackbar();
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }



  // const downloadInvoiceList = useMutation(
  //   async () =>
  //     await axios
  //       .get(
  //         `
  //           ${process.env.REACT_APP_SERVER_URL}/api/v1/company/reports/invoiceList
  //         `
  //       )
  //       .then((res) => res.data),
  //   {
  //     onSuccess: (url) => {
  //       window.location.assign(url);
  //     }
  //   }
  // );

  const downloadInventoryList = useMutation(
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/reports/inventory-list
  `
        )
        .then((res) => res.data),
    {
      onSuccess: (url) => {
        window.location.assign(url);
      },
    }
  );

  const downloadOnOrder = async () => {
    try {
      snackbar.showSnackbar(tr("Downloading...."));
      axios({
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/company/reports/onOrder`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `OnOrder_${new Date()}.xlsx`);
        document.body.appendChild(link);
        link.click();
        snackbar.showSnackbar();
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <SafeArea classNames={{ content: "py-4 space-y-6" }}>
      <div>
        <h1>{tr("Reports")}</h1>
      </div>
      <div className="space-y-2">
        <div>
          <h2 className="text-slate-500 uppercase text-sm">{tr("Customer")}</h2>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <ContainerButton
            icon={<FiCalendar />}
            label={tr("Customer List")}
            description={tr("Pull a list of all customers created between two dates")}
            action={() => modal.setModal({
              component: <NewReportModal type="customerList" />,
              label: tr("Customer List")
            })}
          />
          <ContainerButton
            icon={<FiCalendar />}
            label={tr("Property List")}
            // disabled
            description={tr("Download a list of your customers' properties and addresses")}
            action={() => modal.setModal({
              component: <NewReportModal type="propertyList" />,
              label: tr("Property List")
            })}
          />
          <ContainerButton
            icon={<FiCalendar />}
            label={tr("Request List")}
            description={tr("Request Status and Details")}
            action={() => modal.setModal({
              component: <NewReportModal type="requestList" />,
              label: tr("Customer List")
            })}          
          />
        </div>
      </div>
      <div className="space-y-2">
        <div>
          <h2 className="text-slate-500 uppercase text-sm">{tr("Scheduling")}</h2>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <ContainerButton
            icon={<FiCalendar />}
            label={tr("Daily Install")}
            description={tr("Review your daily install schedule appointment.")}
            action={() => downloadDailyInstall()}
          />
          <ContainerButton
            icon={<FiCalendar />}
            label={tr("Appointments")}
            description={tr("Download a list of all appointments created between two dates")}
            action={() => modal.setModal({
              component: <NewReportModal type="appointmentList" />,
              label: tr("Appointments")
            })}
          />
        </div>
      </div>
      <div className="space-y-2">
        <div>
          <h2 className="text-slate-500 uppercase text-sm">{tr("Finance")}</h2>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <ContainerButton
            icon={<FiPercent />}
            label={tr("Aging Report")}
            description={tr("Review invoices past due.")}
            action={() =>
              modal.setModal({
                component: <NewReportModal type="aging" />,
                label: tr("New Report"),
              })
            }
          />
          <ContainerButton
            icon={<FiFileText />}
            label={tr("Invoices")}
            description={tr("Download list of invoices.")}
            action={() => modal.setModal({
              component: <NewReportModal type="invoiceList" />,
              label: tr("Invoices")
            })}
          />
        </div>
      </div>

      <div className="space-y-2">
        <div>
          <h2 className="text-slate-500 uppercase text-sm">{tr("Inventory")}</h2>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <ContainerButton
            icon={<FiLayers />}
            label={tr("Inventory List")}
            description={tr("Review inventory list.")}
            action={() => downloadInventoryList.mutate()}
          />
          <ContainerButton
            icon={<FiLayers />}
            label={tr("Stock Levels")}
            description={tr("Review stock levels in the business inventory.")}
            action={() => downloadStockLevels()}
          />
          <ContainerButton
            icon={<FiArchive />}
            label={tr("Below Minimum")}
            description={tr("Review inventory levels.")}
            action={() => downloadBelowMinimum()}
          />
          <ContainerButton
            icon={<FiGrid />}
            label={tr("On Order")}
            description={tr("Review historical purchase orders.")}
            action={() => downloadOnOrder()}
          />
          <ContainerButton
            icon={<FiGrid />}
            label={tr("Inventory Usage")}
            description={tr("Review historical inventory usage.")}
            action={() => {
              modal.setModal({
                component: <ReportParametersModal type="inventoryUsage" />,
                label: tr("New Inventory Usage Report"),
              });
            }}
          />
        </div>
      </div>

      <div className="space-y-2">
        <div>
          <h2 className="text-slate-500 uppercase text-sm">{tr("Sales")}</h2>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <ContainerButton
            icon={<FiLayers />}
            label={tr("Sales Record Report")}
            description={tr("Review individual or division sales record.")}
            action={() =>
              modal.setModal({
                component: <NewReportModal type="sales" />,
                label: tr("Generate Sales Report"),
              })
            }
          />
        </div>
      </div>
    </SafeArea>
    // <div className="reports-container">
    //   <h1 className="reports-title">Reports</h1>

    //   <h2 className="report-header">Scheduling</h2>

    //   <div className="report-row">
    //     <div className="report-group">
    //       <h3 className="report-name">Daily Install</h3>
    //       <Button
    //         className="request-download-button"
    //         onClick={() => downloadDailyInstall()}
    //       >
    //         Download
    //       </Button>
    //     </div>
    //   </div>

    //   <h2 className="report-header">Inventory</h2>

    //   <div className="report-row">
    //     <div className="report-group">
    //       <h3 className="report-name">Stock Levels</h3>
    //       <Button
    //         className="request-download-button"
    //         onClick={() => downloadStockLevels()}
    //       >
    //         Download
    //       </Button>
    //     </div>

    //     <div className="report-group">
    //       <h3 className="report-name">Below Minimum</h3>
    //       <Button
    //         className="request-download-button"
    //         onClick={() => downloadBelowMinimum()}
    //       >
    //         Download
    //       </Button>
    //     </div>

    //     <div className="report-group">
    //       <h3 className="report-name">On Order</h3>
    //       <Button
    //         className="request-download-button"
    //         onClick={() => downloadOnOrder()}
    //       >
    //         Download
    //       </Button>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Reports;

const ContainerButton = ({
  label,
  description,
  icon,
  action,
  ...props
}: Omit<ButtonProps, "children"> & {
  label: string;
  description: string;
  icon: React.ReactNode;
  action: () => void;
}) => {
  return (
    <OutlineButton
      className="!h-[150px] !items-start !justify-start !flex-col !p-4"
      onClick={action}
      {...props}
    >
      <div className="text-[1rem] !flex !items-center !space-x-2">
        {icon}
        <span>{label}</span>
      </div>
      <p className="text-slate-500 font-medium">{description}</p>
    </OutlineButton>
  );
};
