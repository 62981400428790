import React, { useState, useEffect, useRef } from "react";
import axios from "api/axios";
import { Button, IconButton } from "components/button";
import { Input } from "components/input";
import PhoneNumberInput from "components/input/phoneNumberInput";
import { StyledLabel } from "components/input/style";
import SafeArea from "components/safe-area";
import { useSnackbarContext } from "components/snackbar";
import Spinner from "components/spinner";
import { useFormik } from "formik";
import _, { cloneDeep, result } from "lodash";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { theme } from "styles/theme";
import { CustomerEdit, TCustomer } from "types/customer";
import { TTag } from "types/tag";
import * as Yup from "yup";
import TagManagement from "./tags";
import TagManager from "./TagManager";
import StandardizedAddressInput from "components/standardizedAddressInput";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';

export default function NewCustomer() {
  const { t } = useTranslation();
  const tBase = "views.customers.new-customer.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [addressObj, setAddressObj] = useState<any>({
    address: "",
    isStandardizedAddress: false,
    selectedOption: {
      description: "",
      city: "",
      state: "",
      zipcode: "",
    },
  });

  const params = useParams<{ id: string }>(),
    history = useHistory(),
    { showSnackbar } = useSnackbarContext();

  const isEditing = params.id !== "new";

  const tagsQuery = useQuery<Array<TTag>>("tags", async () =>
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/tags/customer&property`)
      .then((res) => res.data)
  );

  const query = useQuery<{ info: TCustomer }>(
    ["customer", "editing", params.id],
    async () =>
      params.id !== "new"
        ? axios
            .get(
              `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/${params.id}
    `
            )
            .then((res) => res.data)
        : null
  );

  const submitChanges = useMutation(
    async (e: object) =>
      await axios
        .post(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/${params.id}
    `,
          {
            ...e,
            address: addressObj.address,
            isStandardizedAddress: addressObj.isStandardizedAddress,
          }
        )
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        params.id === "new"
          ? showSnackbar(tr("Customer created!"))
          : showSnackbar(tr("Customer updated!"));
        history.push(`/customers/${res}`);
      },
    }
  );

  const contact = {
    is_new: true,
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    extension: "",
    title: "",
  };

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const initialValues = {
    first_name: query.data?.info.first_name || "",
    last_name: query.data?.info.last_name || "",
    company_name: query.data?.info.company_name || "",
    title: query.data?.info.title || "",
    email: query.data?.info.email || "",
    phone: query.data?.info.phone || "",
    extension: query.data?.info.extension || "",
    address: "",
    city: "",
    state: "",
    name: "",
    zipcode: "",
    contacts: query.data?.info.contacts || [],
    customer_tags: query.data?.info.customer_tags || [],
  };

  const onSubmit = (e: any) => {
    submitChanges.mutate(e);
  };

  // addresses: Yup.array().of(
  //   Yup.object().shape({
  //     address: Yup.string().required(),
  //     city: Yup.string().required(),
  //     zipcode: Yup.string().min(5).max(5).required(),
  //     state: Yup.string().min(2).max(2).required(),
  //     name: Yup.string().nullable(),
  //   })
  // ),

  const validationSchema = Yup.object({
    first_name: Yup.string().test({
      name: "first_name",
      message:
        tr("Please enter either First/Last Name or Company Name"),
      test: function (val) {
        return this.parent.company_name || val;
      },
    }),
    company_name: Yup.string(),
    last_name: Yup.string().test({
      name: "first_name",
      message:
        tr("Please enter either First/Last Name or Company Name"),
      test: function (val) {
        return this.parent.company_name || val;
      },
    }),
    // email: Yup.string().nullable(),
    email: Yup.string().nullable(),
    phone: Yup.string().nullable(),
    extension: Yup.string().nullable(),
    title: Yup.string().nullable(),

    contacts: Yup.array().of(
      Yup.object().shape({
        first_name: Yup.string().required(tr("Please enter a first name")),
        last_name: Yup.string().required(tr("Please enter a last name")),
        email: Yup.string().nullable(),
        phone: Yup.string().nullable(),
        extension: Yup.string().nullable(),
        title: Yup.string().nullable(),
      })
    ),
    // address: Yup.string().required(),
    // city: Yup.string().required(),
    // state: Yup.string().length(2).required(),
    // zipcode: Yup.string().length(5).required(),
    name: Yup.string(),

    customer_tags: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().nullable(),
        label: Yup.string(),
        color: Yup.string(),
      })
    ),
  });

  const formik = useFormik<CustomerEdit>({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  React.useEffect(() => {
    formik.setValues((v) => ({
      ...v,
      address: addressObj.address,
      // address: addressObj.selectedOption.description,
      city: addressObj.selectedOption.city,
      state: addressObj.selectedOption.state,
      zipcode: addressObj.selectedOption.zipcode,
    }));
  }, [addressObj]);


  const handleUpdateTag = (
    customerId: number | string,
    tagId: number | string,
    type: string
  ) => {
    formik.setValues((v: any) => {
      const tempTags = v.customer_tags;
      const newTag = tagsQuery.data?.filter((t: any) => t.id == tagId)[0];
      const filteredTags = [...tempTags.filter((t: any) => t && t.id != tagId)];

      return {
        ...v,
        customer_tags:
          type === "add" ? [...filteredTags, newTag] : [...filteredTags],
      };
    });
  };

  const updateFormikTags = (updatedTags: any) => {
    formik.setFieldValue("customer_tags", updatedTags, true);
    formik.setFieldTouched("customer_tags", true, true); // Mark the field as touched
    formik.validateForm(); // Trigger validation
  };

  if (query.isLoading) return <Spinner />;

  const populateContacts = formik.values.contacts?.map((contact, i) => {
    const deleteContact = () => {
      formik.setValues((v) => {
        let tempContacts = cloneDeep(v.contacts);
        tempContacts.splice(i, 1);
        return {
          ...v,
          contacts: tempContacts,
        };
      });
    };

    if (!contact) return null;

    return (
      <SList key={i}>
        <STitle>
          <h4>{`Contact #${i + 1}`}</h4>
          <Button size="small" onClick={deleteContact}>
            {tr("Delete")}
          </Button>
        </STitle>
        <div className="flex justify-between">
          <Input
            style={{ width: "32%" }}
            label={tr("First Name")}
            formik={formik}
            name={`contacts.${i}.first_name`}
          />
          <Input
            style={{ width: "32%" }}
            label={tr("Last Name")}
            formik={formik}
            name={`contacts.${i}.last_name`}
          />
          <Input
            label={tr("Title")}
            style={{ width: "32%"}}
            formik={formik}
            name={`contacts.${i}.title`}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Input
            label={tr("Email")}
            style={{ width: "49%" }}
            formik={formik}
            name={`contacts.${i}.email`}
          />
          <PhoneNumberInput
            label={tr("Phone")}
            style={{ width: "39%" }}
            formik={formik}
            name={`contacts.${i}.phone`}
          />
          <Input
            label="Ext"
            style={{ width: "9%" }}
            formik={formik}
            name={`contacts.${i}.extension`}
          />
        </div>
      </SList>
    );
  });

  if (query.isLoading) return <Spinner />;

  return (
    <SafeArea style={{ content: { maxWidth: "600px" } }}>
      <SCon onSubmit={formik.handleSubmit}>
        <STitle>
          <h2>{isEditing ? tr("Edit Customer") : tr("New Customer")}</h2>
          <div>
            <Button
              primary
              type="submit"
              isLoading={submitChanges.isLoading}
              disabled={!formik.dirty || !formik.isValid}
            >
              {tr("Save Changes")}
            </Button>
          </div>
        </STitle>
        <h3>{tr("Primary Contact")}</h3>
        <SContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Input
              label={tr("First Name")}
              required={!formik.values.company_name}
              formik={formik}
              name="first_name"
              style={{ width: "49%" }}
            />
            <Input
              required={!formik.values.company_name}
              formik={formik}
              label={tr("Last Name")}
              name="last_name"
              style={{ width: "49%" }}
            />
          </div>
          <div className="flex justify-between">
            <Input
              name="company_name" 
              formik={formik} 
              label={tr("Company Name")} 
              style={{ width: "49%" }}
            />
            <Input
              name="title" 
              formik={formik} 
              label={tr("Title")} 
              style={{ width: "49%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Input
              label={tr("Email")}
              formik={formik}
              name="email"
              style={{ width: "49%" }}
            />
            <PhoneNumberInput
              label={tr("Phone")}
              formik={formik}
              name="phone"
              style={{ width: "39%" }}
            />
            <Input
              label="Ext"
              formik={formik}
              name="extension"
              style={{ width: "9%" }}
            />
          </div>
        </SContent>
        {params.id === "new" && (
          <>
            <h3>{tr("Address")}</h3>
            <SContent>
              <Input
                name="name"
                style={{ width: "100%" }}
                formik={formik}
                label={tr("Nickname")}
              />
              <StandardizedAddressInput
                {...{ setAddressObj, addressObj }}
                label={tr("Address")}
                // required
              />
              <div className="flex justify-between">
                <Input
                  name="city"
                  style={{ width: "40%" }}
                  label={tr("City")}
                  value={
                    addressObj.selectedOption
                      ? addressObj.selectedOption.city
                      : null
                  }
                  onChange={(e: any) => {
                    setAddressObj({
                      ...addressObj,
                      isStandardizedAddress: false,
                      selectedOption: {
                        ...addressObj.selectedOption,
                        city: e.target.value,
                      },
                    });
                  }}
                />
                <Input
                  name="state"
                  style={{ width: "20%" }}
                  formik={formik}
                  label={tr("State")}
                  maxLength={2}
                  value={
                    addressObj.selectedOption
                      ? addressObj.selectedOption.state
                      : null
                  }
                  onChange={(e: any) => {
                    setAddressObj({
                      ...addressObj,
                      isStandardizedAddress: false,
                      selectedOption: {
                        ...addressObj.selectedOption,
                        state: e.target.value,
                      },
                    });
                  }}
                />
                <Input
                  name="zipcode"
                  style={{ width: "30%" }}
                  formik={formik}
                  label={tr("Zipcode")}
                  value={
                    addressObj.selectedOption
                      ? addressObj.selectedOption.zipcode
                      : null
                  }
                  onChange={(e: any) => {
                    setAddressObj({
                      ...addressObj,
                      isStandardizedAddress: false,
                      selectedOption: {
                        ...addressObj.selectedOption,
                        zipcode: e.target.value,
                      },
                    });
                  }}
                />
              </div>
            </SContent>
          </>
        )}

        <STitle>
          <h3>{tr("Additional Contact")}</h3>
          <IconButton
            onClick={() =>
              formik.setValues((v) => ({
                ...v,
                contacts: v.contacts ? [...v.contacts, contact] : [contact],
              }))
            }
          >
            +
          </IconButton>
        </STitle>
        <SContent>{populateContacts}</SContent>

        <STitle>
          <h3>{tr("Tags")}</h3>
        </STitle>
        <SContent>
          {tagsQuery.isLoading ? (
            <Spinner />
          ) : (
            <>
              {params.id === "new" ? (
                <TagManager
                  customerId={params.id}
                  addedTags={formik.values.customer_tags}
                  availableTags={tagsQuery.data}
                  handleUpdateTag={handleUpdateTag}
                />
              ) : (
                <TagManagement 
                  customerId={params.id} 
                  updateFormikTags={(updatedTags: any) =>
                    updateFormikTags(updatedTags)
                  } 
                />
              )}
            </>
          )}
        </SContent>
      </SCon>
    </SafeArea>
  );
}

const SCon = styled.form`
  padding: 1em;
  display: flex;
  flex-direction: column;
`;

const STitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SContent = styled.div`
  // background: ${theme.bg.blue};
  border-top: 1px solid ${theme.border.black};
  padding: 1em 0;
  margin: 1em 0;
`;

const SList = styled.div`
  border-bottom: 1px solid ${theme.border.light};
  padding: 1em 0;
`;
