import axios from "api/axios";
import { Avatar } from "components/avatar";
import { Button } from "components/button";
import { useSnackbarContext } from "components/snackbar";
import * as React from "react";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import styled from "styled-components";
import { theme } from "styles/theme";
import { dollarFormatter } from "utils";
import type { BidClient, Info } from "../";
import Switch from "rc-switch";
import { Customer } from "views/requests/newRequestModal/customerForm";
import { AxiosResponse } from "axios";
import Segment, { SContent, SHeader } from "components/segment";
import FlatContainer from "components/container/flat";
import DollarInputV2 from "components/input/dollarInputV2";
import { cloneDeep, set } from "lodash";
import { useTranslation } from 'react-i18next';

type Props = {
  data: Info;
  editable: boolean;
  total_amount?: number;
  id: string;
  clients: BidClient[] | null;
};

export default function FinalPriceReview(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.projects.views.project.views.quote.components.reviewFinalPrice";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [value, setValue] = React.useState<number>(),
    [singlePrice, setSinglePrice] = React.useState(true);

  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbarContext();

  const approve = useMutation(
    async (body: { value: number | BidClient[] }) =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes/${props.id}/approve
  `,
          body
        )
        .then((res) => res.data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["quote", props.id]);
        showSnackbar(tr("Quote Updated!"));
      },
    }
  );

  React.useEffect(() => {
    if (!props.editable) {
      if (props.total_amount) setValue(props.total_amount / 100);
      else setSinglePrice(false);
    }
  }, [props]);

  const editableCon = (
    <>
      {singlePrice ? (
        <>
          <span>
            {props.clients?.reduce(
              (prev, client) =>
                !client
                  ? prev
                  : `${prev && prev + ", "}${client.first_name} ${
                      client.last_name
                    }`,
              ""
            )}
          </span>
          <DollarInputV2
            value={value}
            onValueChange={(e) => setValue(e.floatValue)}
          />
          <div>
            <Button
              onClick={() =>
                value &&
                approve.mutate({
                  value: value * 100,
                })
              }
              isLoading={approve.isLoading}
              disabled={!value}
              primary
            >
              {tr("Submit Approval")}
            </Button>
          </div>
        </>
      ) : (
        <Multiple clients={props.clients} approve={approve} />
      )}
    </>
  );

  const nonEditableCon = (
    <>
      {singlePrice ? (
        <>
          <h1>{dollarFormatter(props.total_amount)}</h1>
          <span>
            {props.clients?.reduce(
              (prev, client) =>
                !client
                  ? prev
                  : `${prev && prev + ", "}${client.first_name} ${
                      client.last_name
                    }`,
              ""
            )}
          </span>
        </>
      ) : (
        <Multiple clients={props.clients} />
      )}
      <SReviewedBy>
        <span>{tr("Reviewed By")}</span>
        <Avatar showLabel>{props.data.name}</Avatar>
      </SReviewedBy>
    </>
  );

  return (
    <FlatContainer
      style={{ header: { padding: "1rem" }, content: { padding: "1rem" } }}
      title={<h2>Review</h2>}
    >
      {props.editable && (
        <div>
          <Switch
            checked={singlePrice}
            onChange={(checked) => setSinglePrice(checked)}
          />
          <label style={{ fontSize: "1rem", marginLeft: "0.5rem" }}>
            {tr("Same price for all clients")}
          </label>
        </div>
      )}

      <h3>{tr("Bid Price")}</h3>
      {props.editable ? editableCon : nonEditableCon}
    </FlatContainer>
  );

  // return (
  //   <Segment>
  //     <SHeader>
  //       <h1>Review</h1>
  //     </SHeader>
  //     <SContent>
  //       {props.editable && (
  //         <div>
  //           <Switch
  //             checked={singlePrice}
  //             onChange={(checked) => setSinglePrice(checked)}
  //           />
  //           <label style={{ fontSize: "1rem", marginLeft: "0.5rem" }}>
  //             Same price for all clients
  //           </label>
  //         </div>
  //       )}

  //       <h3>Bid Price</h3>
  //       {props.editable ? editableCon : nonEditableCon}
  //     </SContent>
  //   </Segment>
  // );
}

const Con = styled.div`
  border-top: 2px solid black;
  margin: 2rem 0;
  > div:nth-child(3) {
    display: flex;
    align-items: center;
    > div:first-child {
      margin-right: 1rem;
    }
    > div:last-child {
      flex: 1;
    }
  }
  > div:last-child {
    margin-top: 1rem;
  }
`;

const SReviewedBy = styled.div`
  > span {
    font-weight: bold;
    font-size: 0.8rem;
  }
`;

const Multiple = ({
  clients,
  approve,
}: {
  clients: BidClient[] | null;
  approve?: UseMutationResult<
    AxiosResponse<any>,
    unknown,
    {
      value: number | BidClient[];
    },
    unknown
  >;
}) => {
  const { t } = useTranslation();
  const tBase = "views.projects.views.project.views.quote.components.reviewFinalPrice";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [input, setInput] = React.useState<BidClient[] | null>(null);

  React.useEffect(() => {
    setInput(clients);
  }, [clients]);

  const populateInputs = input?.map((input, i) => {
    if (!input) return null;

    const onChange = (value?: number) => {
      setInput((input) => {
        if (!input) return input;

        if (!input[i]) return input;

        const temp = cloneDeep(input);

        temp[i].price = value;

        return temp;
      });
    };

    return (
      <div key={input.id}>
        <span>{`${input.first_name} ${input.last_name}`}</span>
        <DollarInputV2
          value={input.price}
          onValueChange={(e) => onChange(e.floatValue)}
        />
      </div>
    );
  });

  const populatePrices = clients?.map((client) => {
    if (!client || !client.price) return null;

    return (
      <div style={{ marginRight: "1rem" }} key={client.id}>
        <div>{`${client.first_name} ${client.last_name}`}</div>
        <h2>{dollarFormatter(client.price)}</h2>
      </div>
    );
  });

  const parseInput = input
    ? input.map(
        (indInput) =>
          indInput && {
            ...indInput,
            price:
              typeof indInput.price === "number" ? indInput.price * 100 : null,
          }
      )
    : [];

  if (approve) {
    return (
      <div>
        {populateInputs}
        <div>
          <Button
            onClick={() => input && approve.mutate({ value: parseInput })}
            isLoading={approve.isLoading}
            primary
            disabled={input?.findIndex((input) => !input.price) !== -1}
            style={{}}
          >
            {tr("Submit Approval")}
          </Button>
        </div>
      </div>
    );
  }

  return <div>{populatePrices}</div>;
};
