import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import axios from "axios";
import { Button } from "components/button";
import { useModalContext } from "components/modal";
import { tohrs } from "utils/tohrs";
import Table from "components/table";
import { useQuery, useMutation } from "react-query";
import twodigitmonth from "utils/twodigitmonth";
import {
  RiCloseLine,
  RiFileExcelLine,
  RiFilterLine,
  RiMenuAddLine,
  RiRefreshLine,
} from "react-icons/ri";
import Dropdown from "components/dropdown";
import { Input } from "components/input";
import timeserv from "images/TIMEServ.png";
import Tab from "components/tab";
import { Avatar } from "components/avatar";
import {
  RiNavigationLine,
  RiCheckLine,
  RiTimeLine,
  RiUser2Line,
} from "react-icons/ri";
import AddPunchModal from "./AddPunchModal";
import Punchtable from "./PunchTable";
import GroupPunchTable from "./GroupPunchTable";
import Spinner from "components/spinner";
import GroupPunchExportOptions from "./GroupPunchExportOptions";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
  margin: 10px;
  display: inline;
`;
const Row = styled.div`
  margin-top: 10px;
  border: solid;
  border-width: 1.5px;
  border-radius: 5px;
  border-color: #ff4f00;
  display: block;
  min-height: 10px;
`;
const Title = styled.div`
  text-align: left;
  margin-bottom: 1rem;
`;

const Filterlistelement = (props) => {
  const { t } = useTranslation();
  const tBase = "views.time_dashboard.index";
  const tr = (key) => t(`${tBase}.${key}`);

  return props.filterlist.map((a, i) => {
    if (a !== "") {
      return (
        <Con
          style={{
            margin: "10px",
            borderRadius: "10px",
            background: "#aaa",
            padding: "5px",
          }}
        >
          <button
            children=<RiCloseLine />
            style={{
              margin: "3px",
              width: "1rem",
              height: "1rem",
            }}
            onClick={() => {
              props.setFilterlist((prev) => {
                return [...prev.slice(0, i), ...prev.slice(i + 1)];
              });
            }}
          ></button>
          <em>{a} {tr("Filter")}</em>
        </Con>
      );
    }
    return null;
  });
};

const Filterparentelement = (props) => {
  const { t } = useTranslation();
  const tBase = "views.time_dashboard.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const [searchInput, setSearchInput] = useState("");

  const handleInputChange = (val) => {
    setSearchInput(val);
  };

  const inputBlurred = () => {
    const timer = setTimeout(() => {
      setSearchInput("");
    }, 500);

    return () => clearTimeout(timer);
  };

  if (props.dropdowntext === "Employee") {
    const namelist = { names: [], pins: [] };
    props.EmployeeList.data.forEach((a) => {
      if (
        namelist.names.includes(a.name) ||
        a.name.toLowerCase().includes(searchInput) === false
      ) {
        return;
      } else {
        namelist.names.push(a.name);
        namelist.pins.push(a.pin);
      }
    });
    return (
      <Con>
        <h4 style={{ display: "inline", margin: "5px" }}>{tr("Select Employee")}:</h4>
        <Dropdown
          handleInputChange={handleInputChange}
          inputBlurred={inputBlurred}
          employeeList
          reference={
            <button
              style={{
                margin: "5px",
                borderBottom: "solid",
                borderWidth: ".2px",
              }}
              children=<div>
                <RiNavigationLine style={{ display: "inline" }} />
                <p style={{ display: "inline" }}>{tr("Select")}</p>
              </div>
            />
          }
          data={[
            {
              button: namelist.names
                .map((a, i) => {
                  if (a.includes("----") === false) {
                    return {
                      label: (
                        <p>
                          <RiUser2Line
                            style={{
                              display: "inline",
                            }}
                          />
                          {a}
                        </p>
                      ),
                      onClick: () => {
                        props.setFilterlist((prev) => {
                          return [
                            ...prev,
                            "Employee:" + a + "(" + namelist.pins[i] + ")",
                          ];
                        });
                        props.setdropdowntext("");
                      },
                    };
                  } else {
                    return null;
                  }
                })
                .filter((a) => {
                  if (a === null) {
                    return false;
                  }
                  return true;
                }),
            },
          ]}
        />
        <Filterlistelement {...props} />
      </Con>
    );
  }
  if (props.dropdowntext === "Date Range") {
    return (
      <Con>
        <Input
          type="date"
          label={tr("Start Date") + ":"} 
          style={{
            margin: "5px",
            width: "10rem",
            display: "inline-block",
          }}
          defaultValue={props.datefilter.startD}
          onBlur={(a) => {
            const inputval = a.target.value;
            props.setdatefilter(() => {
              return {
                startD: inputval,
                endD: props.datefilter.endD,
              };
            });
          }}
        />
        <Input
          type="date"
          label={tr("End Date") + ":"} 
          style={{
            margin: "5px",
            width: "10rem",
            display: "inline-block",
          }}
          defaultValue={props.datefilter.endD}
          onBlur={(a) => {
            const inputval = a.target.value;
            props.setdatefilter(() => {
              return {
                startD: props.datefilter.startD,
                endD: inputval,
              };
            });
          }}
        />
        <button
          style={{
            borderRadius: "100%",
            border: "solid",
            borderWidth: "1.5px",
            borderColor: "#999",
            veticalAlign: "tex-bottom",
          }}
          onClick={(e) => {
            props.setFilterlist((prev) => {
              return [
                ...prev,
                "Date_startD:" +
                  props.datefilter.startD +
                  "_endD:" +
                  props.datefilter.endD +
                  "_",
              ];
            });
            props.setdropdowntext("");
          }}
        >
          <RiCheckLine style={{ height: "2rem", width: "2rem" }} />
        </button>
        <Filterlistelement {...props} />
      </Con>
    );
  }
  if (props.dropdowntext === "Missed Punch") {
    return (
      <Con>
        <p style={{ display: "inline", margin: "5px" }}>
          {tr("Show only punches with missing out/in counterparts")}
        </p>
        <button
          style={{
            borderRadius: "100%",
            border: "solid",
            borderWidth: "1.5px",
            borderColor: "#999",
            veticalAlign: "tex-bottom",
          }}
          onClick={() => {
            props.setFilterlist((prev) => {
              return [...prev, "Missing_Punches"];
            });
            props.setdropdowntext("");
          }}
        >
          <RiCheckLine style={{ height: "2rem", width: "2rem" }} />
        </button>
        <Filterlistelement {...props} />
      </Con>
    );
  }

  if (props.dropdowntext === "Single Day") {
    return (
      <Con>
        <Input
          type="date"
          label={tr("Start Date") + ":"} 
          style={{
            margin: "5px",
            width: "10rem",
            display: "inline-block",
          }}
          defaultValue={props.datefilter.startD}
          onBlur={(a) => {
            a.preventDefault();
            const inputval = a.target.value;
            a.persist();
            props.setdatefilter(() => {
              return { startD: inputval, endD: "" };
            });
          }}
        />
        <button
          style={{
            borderRadius: "100%",
            border: "solid",
            borderWidth: "1.5px",
            borderColor: "#999",
            veticalAlign: "tex-bottom",
          }}
          onClick={() => {
            props.setFilterlist((prev) => {
              return [
                ...prev,
                "Single_Day_startD:" + props.datefilter.startD + "_",
              ];
            });
            props.setdropdowntext("");
          }}
        >
          <RiCheckLine style={{ height: "2rem", width: "2rem" }} />
        </button>
        <Filterlistelement {...props} />
      </Con>
    );
  }

  return <Filterlistelement {...props} />;
};

const DisplayRecords = (props) => {
  return (
    <select
      style={{
        margin: "auto",
        display: "inline",
        width: "5rem",
        border: "1px solid #ccc",
        borderRadius: "5px",
      }}
      onChange={(e) => {
        console.log("e.target.value", e.target.value);
        props.setRecordsDisplayed(e.target.value);
      }}
      value={props.recordsDisplayed}
    >
      <option value="10">10</option>
      <option value="25">25</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>
  );
};

const Duck = () => {
  const { t } = useTranslation();
  const tBase = "views.time_dashboard.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [defaultTab, setDefaultTab] = useState();
  const [recordsDisplayed, setRecordsDisplayed] = useState(10);
  const [recordsLoading, setRecordsLoading] = useState(false);
  const [HistoryList, setHistoryList] = useState(() => {
    return [{ name: "", onClock: "" }];
  });
  const [GroupedHistory, setGroupedHistory] = useState(() => {
    return [{ name: "", onClock: "" }];
  });
  const [Totals, setTotals] = useState(() => {
    return [{ name: "", onClock: "" }];
  });
  const [filterlist, setFilterlist] = useState(() => {
    const today = new Date();
    // const todaystring = today.getFullYear()+"-"+twodigitmonth(today.getMonth()+1)+"-"+twodigitmonth(today.getDate())
    return [];
  });
  const [datefilter, setdatefilter] = useState(() => {
    const today = new Date();
    return { startD: today.toDateString, endD: today.toDateString };
  });
  const [dropdowntext, setdropdowntext] = useState();
  const [exportType, setExportType] = useState("IndividualPunches");

  const fetchHistory = async (info) => {
    // console.log("info in fetch: ", info)
    const now = new Date();
    const tz = now
      .toLocaleTimeString("en-us", { timeZoneName: "short" })
      .split(" ")[2];
    const response = await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/time_clock/h`,
        {
          params: {
            ...info,
            tz,
            limit: recordsDisplayed,
          },
        }
      )
      .then((res) => {
        return res;
      });
    const data = response.data;

    const datelist = [];
    data.h.forEach((a) => {
      let dateofentry = new Date(a.time);
      dateofentry.setHours(0, 0, 0, 0);
      dateofentry = dateofentry.toLocaleDateString();
      if (!datelist.includes(dateofentry)) {
        datelist.push(dateofentry);
      }
    });

    datelist.forEach((date) => {
      const indextoaddat = data.h.findIndex((punch) => {
        let dateofentry = new Date(punch.time);
        dateofentry.setHours(0, 0, 0, 0);
        dateofentry = dateofentry.toLocaleDateString();
        return dateofentry === date;
      });

      const newarray = data.h.slice(0, indextoaddat).concat(
        [
          {
            name: "------------",
            event_type: "------------",
            time: "----" + date + "----",
            pin: "",
            note: "------------",
            completed: "------------",
          },
        ],
        data.h.slice(indextoaddat)
      );
      data.h = newarray;
    });

    // simple fix to make sure the table is always 15 rows long, to keep pagination buttons from shifting up or down when the table is updated
    if (data.h.length < recordsDisplayed) {
      const difference = recordsDisplayed - data.h.length;
      for (let i = 0; i < difference; i++) {
        data.h.push({
          name: "------------",
          event_type: "------------",
          time: "------------",
          pin: "",
          note: "------------",
          completed: "------------",
        });
      }
    }

    setHistoryList(data.h);
    setTotals(data.t);
    setGroupedHistory(data.grouped_punches);
    setTotalPages(Math.ceil(data.page_count));
    setDefaultTab("IndividualPunches");
    setRecordsLoading(false);
  };

  useEffect(() => {
    setRecordsLoading(true);
    setCurrentPage(1);
    fetchHistory({ filterstring: "filters=" + filterlist.toString(), page: 1 });
  }, [recordsDisplayed]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const filterstring = "filters=" + filterlist.toString();
    // console.log("current page: ", pageNumber)
    fetchHistory({ filterstring: filterstring, page: pageNumber });
    // StatusEventList.refetch();
  };

  useEffect(() => {
    handlePageChange(1);
  }, [filterlist]);

  const EmployeeList = useQuery(
    "Emplist",
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/time_clock/ALLEMP`
        )
        .then((res) => {
          return res.data;
        })
  );

  const [statusEventData, setStatusEventData] = useState({
    listData: [],
    totalPages: 0,
    pageIndex: 1,
  });

  const filterstring = "filters=" + filterlist.toString();

  const handleStatusPageChange = (pageNumber) => {
    setStatusEventData({
      ...statusEventData,
      pageIndex: pageNumber,
    });
    // StatusEventList.refetch();
  };

  const StatusEventList = useQuery(
    ["stateventlist", statusEventData.pageIndex],
    async () =>
      await axios
        .get(
          `${
            process.env.REACT_APP_SERVER_URL
          }/api/v1/eserv-business/time_clock/statusevents?filters=${filterlist.toString()}`,
          {
            params: {
              page: statusEventData.pageIndex,
              // limit: displayedRecords,
              filterstring: { filterstring: filterstring },
            },
          }
        )
        .then((res) => {
          let line;

          for (let item = 0; item < res.data.resobj.length; item++) {
            if (res.data.resobj[item] !== null) {
              line = res.data.resobj[item];
              break;
            }
          }

          const data = res.data.resobj.map((line) => {
            line.time = new Date(line.rawTime).toLocaleString();
            return line;
          });

          setStatusEventData((prev) => ({
            ...prev,
            listData: data,
            totalPages: Math.ceil(res.data.page_count / 10),
          }));
        })
  );

  const Discrepencyval = (a) => {
    if (a.discrepency >= 6000) {
      return (
        <p
          style={{
            color: "#ff0000",
            display: "inline-block",
            align: "right",
            marginLeft: "10px",
          }}
        >
          {" !!Unaccounted time:" + tohrs(a.discrepency) + "!!"}
        </p>
      );
    } else {
      return null;
    }
  };

  const refreshinfo = () => {
    const filterstring = "filters=" + filterlist.toString();
    StatusEventList.refetch();
    EmployeeList.refetch();
    fetchHistory({ filterstring: filterstring, page: currentPage });
  };
  const filtertypes = ["Employee", "Missed Punch", "Date Range", "Single Day"];
  const now = new Date();
  const downloadexport = async (info, options = null) => {
    try {
      axios({
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/time_clock/export?${info.filterstring}`,
        method: "GET",
        responseType: "blob",
        params: {
          exportType: info.exportType,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          ...options,
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `TimePunches_${new Date()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      console.error(error);
    }
  };

  const Timetotals = () => {
    return (
      <div
        style={{
          width: "90%",
          margin: "auto",
        }}
      >
        <div
          style={{
            padding: ".5em",
            borderBottom: "solid",
            borderWidth: "0.5px",
            borderColor: "#ff4f00",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
          }}
        >
          <p style={{ color: "#94A3B8", display: "inline-block" }}>{tr("Name")}:</p>
          <p
            style={{
              color: "#94A3B8",
              display: "inline-block",
              float: "right",
              align: "right",
            }}
          >
            {tr("Total Time")}:
          </p>
        </div>
        {Totals?.map((a) => {
          const hrsonClock = tohrs(a.onClock);
          if (a.applist?.length > 0) {
            return (
              <div>
                <div
                  style={{
                    padding: ".5em",
                    borderWidth: "0.5px",
                    borderColor: "#ff4f00",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    backgroundColor: "#f4f4f4",
                  }}
                >
                  <div style={{ width: "50%", display: "inline-block" }}>
                    <Avatar user_id={a.userId} showLabel={true} size="small">
                      {a.name}
                    </Avatar>
                  </div>
                  <p
                    style={{
                      color: "#334155",
                      display: "inline-block",
                      float: "right",
                      align: "right",
                    }}
                  >
                    {hrsonClock}
                    <Discrepencyval discrepency={a.discrepency} />
                  </p>
                </div>
                {a.applist.map((b) => {
                  const totalApptime = tohrs(b.timetotal);
                  return (
                    <div
                      style={{
                        padding: ".5em",
                        borderWidth: "0.5px",
                        borderColor: "#ff4f00",
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      <p
                        style={{
                          marginLeft: "50px",
                          color: "#334155",
                          display: "inline-block",
                        }}
                      >
                        {b.status}
                      </p>
                      <p
                        style={{
                          color: "#334155",
                          display: "inline-block",
                          float: "right",
                          align: "right",
                        }}
                      >
                        {totalApptime}
                      </p>
                    </div>
                  );
                })}
              </div>
            );
          } else {
            return (
              <div
                style={{
                  padding: ".5em",
                  borderWidth: "0.5px",
                  borderColor: "#ff4f00",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                <p
                  style={{
                    color: "#334155",
                    display: "inline-block",
                  }}
                >
                  {a.name}
                </p>
                <p
                  style={{
                    color: "#334155",
                    display: "inline-block",
                    float: "right",
                    align: "right",
                  }}
                >
                  {hrsonClock}
                </p>
              </div>
            );
          }
        })}
      </div>
    );
  };
  const Statustable = () => {
    const statuscolumns = {
      name: {
        label: (
          <p>
            <RiUser2Line style={{ display: "inline" }} />
            {tr("Name")}:
          </p>
        ),
        cell: (row) => {
          if (row.name?.includes("----") === false) {
            return (
              <div style={{ width: "50%", display: "inline-block" }}>
                <Avatar user_id={row.userId} showLabel={true} size="small">
                  {row.name}
                </Avatar>
              </div>
            );
          } else {
            return row.name;
          }
        },
        isClickable: false,
      },
      time: {
        label: (
          <p>
            <RiTimeLine style={{ display: "inline" }} />
            {tr("Time")}:
          </p>
        ),
        isClickable: false,
      },
      status: {
        label: (
          <p>
            <RiCheckLine style={{ display: "inline" }} />
            {tr("Status")}:
          </p>
        ),
        isClickable: false,
      },
    };
    return (
      <div
        style={{
          width: "90%",
          margin: "auto",
        }}
      >
        <Table
          columns={statuscolumns}
          data={statusEventData.listData}
          isTimedashboard={true}
          pageCount={statusEventData.totalPages}
          pageIndex={statusEventData.pageIndex}
          handlePage={handleStatusPageChange}
        />
      </div>
    );
  };

  const Pagedata = {
    StatusEvents: {
      tab: tr("Status Events"),
      content: <Statustable />,
    },
    TotalTimes: {
      tab: tr("Total Times"),
      content: <Timetotals />,
    },
    IndividualPunches: {
      tab: tr("Individual Punches"),
      content: (
        <Punchtable
          HistoryList={HistoryList}
          refreshinfo={() => {
            refreshinfo();
          }}
          handlePageChange={handlePageChange}
          pageCount={totalPages}
          pageIndex={currentPage}
        />
      ),
    },
    GroupedPunches: {
      tab: tr("Grouped Punches"),
      content: (
        <GroupPunchTable
          GroupedPunches={GroupedHistory}
          recordsDisplayed={recordsDisplayed}
          refreshinfo={() => {
            refreshinfo();
		      }}
        />
      ),
    },
  };

  return (
    <Con style={{ width: "100%" }}>
      <Title>
        <img
          alt="TIMEServ.logo"
          src={timeserv}
          style={{
            verticalAlign: "bottom",
            height: "15px",
            marginTop: "9px",
            marginBottom: "9px",
            marginleft: "auto",
            display: "inline",
          }}
        ></img>
        <p
          style={{
            color: "#ff4f00",
            fontSize: "22px",
            display: "inline-block",
            verticalAlign: "bottom",
            marginBottom: "0px",
          }}
        >
          {tr("Dashboard")}
        </p>
        <Con
          style={{
            width: "20%",
            minWidth: "200px",
            margin: "0px",
            display: "inline",
            marginRight: "0",
            float: "right",
          }}
        >
          {" "}
          <Button
            primary
            style={{}}
            children=<p>
              <RiMenuAddLine
                style={{
                  height: "1.25rem",
                  verticalAlign: "top",
                  display: "inline",
                }}
              />
              {tr("Add Punch")}
            </p>
            onClick={() =>
              setModal({
                component: (
                  <AddPunchModal
                    EmployeeList={EmployeeList}
                    refreshinfo={refreshinfo}
                  />
                ),
                label: tr("Add Punch"),
                props: {
                  shouldCloseOnOverlayClick: true,
                  onAfterClose: () => {
                    let filterstring = "filters=" + filterlist.oString();
                    // fetchHistory({ filterstring: filterstring, page: currentPage });
                  },
                },
              })
            }
          />
          <Button
            primary
            style={{ float: "right" }}
            children=<p>
              <RiFileExcelLine
                style={{
                  height: "1.25rem",
                  verticalAlign: "top",
                  display: "inline",
                }}
              />
              {tr("Export")}
            </p>
            onClick={() => {
              if (exportType !== "GroupedPunches") {
                const filterstring = "filters=" + filterlist.toString();
                downloadexport({
                  filterstring: filterstring,
                  exportType: exportType,
                });
              } else {
                setModal({
                  component: (
                    <GroupPunchExportOptions
                      {...{ downloadexport, filterstring, exportType }}
                    />
                  ),
                  label: tr("Export Options"),
                  props: {
                    shouldCloseOnOverlayClick: true,
                    onAfterClose: () => {
                      let filterstring = "filters=" + filterlist.oString();
                      // fetchHistory({ filterstring: filterstring, page: currentPage });
                    },
                  },
                });
              }
            }}
          />
        </Con>

        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <div>
            <Dropdown
              style={{ display: "inline" }}
              reference={
                <button
                  style={{
                    marginLeft: "1em",
                    display: "inline",
                    padding: "1em",
                  }}
                  primary
                  children=<p>
                    <RiFilterLine
                      style={{
                        height: "1.25rem",
                        verticalAlign: "top",
                        display: "inline",
                      }}
                    />
                    {tr("Filters")}
                  </p>
                />
              }
              data={[
                {
                  button: filtertypes.map((a) => {
                    return {
                      label: tr(a),
                      onClick: () => {
                        setdropdowntext(a);
                      },
                    };
                  }),
                },
              ]}
            />
            <Filterparentelement
              style={{ marginLeft: "auto" }}
              {...{
                filterlist,
                setFilterlist,
                setdropdowntext,
                dropdowntext,
                datefilter,
                setdatefilter,
                EmployeeList,
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            {recordsLoading && <Spinner />}
            <DisplayRecords {...{ recordsDisplayed, setRecordsDisplayed }} />
            <RiRefreshLine
              style={{
                margin: "10.250px",
                top: "-1.5rem",
                padding: "5px",
                height: "2rem",
                width: "2rem",
                cursor: "pointer",
                background: "#bbb",
                borderRadius: "100%",
              }}
              onClick={() => {
                refreshinfo();
              }}
            />
          </div>
        </Row>
      </Title>
      <Row style={{ marginTop: "1rem" }}>
        <Tab
          data={Pagedata}
          defaultTab={defaultTab}
          onClick={() => {
            const filterstring = "filters=" + filterlist.toString();
            StatusEventList.refetch();
            // fetchHistory({ filterstring: filterstring, page: currentPage });
          }}
          {...{ setExportType }}
        />
      </Row>

      <p id="descriptionBubble"></p>
    </Con>
  );
};
export default Duck;
