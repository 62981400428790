import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import Spinner from "components/spinner";
import { Button } from "components/buttonV2";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useSnackbarContext } from "components/snackbar";
import { useAreYouSure } from "components/areYouSure";

// Card Icons
import visa from "../../../images/card-icons/visa.png";
import mastercard from "../../../images/card-icons/mastercard.png";
import amex from "../../../images/card-icons/america_express.png";
import discover from "../../../images/card-icons/discover.png";
import other from "../../../images/card-icons/other.png"; // Image by rawpixel.com on Freepik

// Other Icons
import {
  AiFillDelete,
  AiFillEdit,
} from "react-icons/ai";

interface PaymentMethodsProps {
  id: string;
  customer: any;
}

const PaymentMethods = ({ id, customer }: PaymentMethodsProps) => {
  const { t } = useTranslation();
  const tBase = "views.customer.containers.PaymentMethods";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [showCardForm, setShowCardForm] = useState(false);
  const [cardComplete, setCardComplete] = useState(false);
  const [cardError, setCardError] = useState<string | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [settingDefault, setSettingDefault] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const { showSnackbar } = useSnackbarContext();
  const areYouSure = useAreYouSure();

  const {
    fk_business_id: companyId,
    email,
    name,
    phone,
  } = customer;

  const cardIcon = (brand: string) => {
    switch (brand) {
      case "visa":
        return visa;
      case "mastercard":
        return mastercard;
      case "amex":
        return amex;
      case "discover":
        return discover;
      default:
        return other;
    }
  };

  const getStripeCustomerById = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/${id}/get-stripe-customer`
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching Stripe customer ID:", error);
    }
  };

  const createStripeCustomer = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/${id}/create-stripe-customer`,
        {
          companyId,
          email,
          name,
          phone,
        }
      );
    } catch (error) {
      console.error("Error creating customer:", error);
    }
  };

  const getPaymentMethods = async () => {
    return axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/get-payment-methods/${id}`
      )
      .then((res) => {
        setCards(res.data.cards);
      })
      .catch((error) => {
        console.error("Error fetching Stripe customer ID:", error);
      });
  }

  const getCustThenCards = async () => {
    setLoading(true);
    const res = await getStripeCustomerById();
    const stripeCustomerId = res?.data?.stripeCustomer?.stripe_customer_id;
    if (!stripeCustomerId) {
      await createStripeCustomer();
      await getStripeCustomerById();
      await getPaymentMethods();
    } else {
      await getPaymentMethods();
    }
    setLoading(false);
  };

  useEffect(() => {
    getCustThenCards();
  }, []);

  const addPaymentMethod = async () => {
    setLoading(true);

    if (!stripe || !elements) {
      console.error("Stripe has not loaded.");
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      console.error("CardElement is not available.");
      setLoading(false);
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: customer.first_name + " " + customer.last_name,
        email: customer.email,
      },
    });

    if (error) {
      console.error("Error creating payment method:", error);
      setLoading(false);
      return;
    }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/${id}/add-payment-method`,
        {
          paymentMethodId: paymentMethod.id,
        }
      );

      if (res.status === 200) {
        showSnackbar(tr("Payment method added successfully."));
        setShowCardForm(false);
        setCardComplete(false);
      } else {
        showSnackbar(tr("Error adding payment method."));
        setLoading(false);
        return;
      }

      // Fetch the updated payment methods
      await getPaymentMethods();
    } catch (error) {
      console.error("Error adding payment method:", error);
    }

    setLoading(false);
  };

  const deletePaymentMethod = async (paymentMethodId: string) => {
    setLoading(true);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/${id}/delete-payment-method`,
        {
          paymentMethodId,
        }
      );

      if (res.status === 200) {
        showSnackbar(tr("Payment method deleted successfully."));
      } else {
        showSnackbar(tr("Error deleting payment method."));
        setLoading(false);
        return;
      }

      // Fetch the updated payment methods
      await getPaymentMethods();
    } catch (error) {
      console.error("Error deleting payment method:", error);
    }

    setLoading(false);
  };

  const setDefaultCard = async (paymentMethodId: string) => {
    setLoading(true);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/${id}/set-default-payment-method`,
        {
          paymentMethodId,
        }
      );

      if (res.status === 200) {
        showSnackbar(tr("Default payment method set successfully."));
      } else {
        showSnackbar(tr("Error setting default payment method."));
        setLoading(false);
        return;
      }

      // Fetch the updated payment methods
      await getPaymentMethods();
    } catch (error) {
      console.error("Error setting default payment method:", error);
    }

    setLoading(false);
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const iconCircleStyle = " cursor-pointer py-2 h-10 w-10 rounded-full flex items-center justify-center hover:bg-slate-100 select-none active:bg-slate-200 transition-colors duration-300 ease-in-out";

  const cardElementOptions = {
    style: {
      base: {
        fontSize: "16px",
        color: "#000",
        "::placeholder": {
          color: "#6b7280",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };

  const cardErrors = (cardError: string) => {
    const recognizedErrors = [
      "Your card number is incomplete.",
      "Your card number is invalid.",
      "Your card's expiration year is in the past.",
      "Your card's expiration year is invalid.",
      "Your postal code is incomplete.",
      "Your card's security code is incomplete.",
      "Your card's expiration date is incomplete."
    ];
    
    const errorMessage = recognizedErrors.includes(cardError) ? tr(cardError) : cardError;
    return errorMessage;
  };

  return (
    <div>
      <div className="flex mb-1 items-center space-x-1">
        <h1>{tr("Payment Methods")}</h1>
        <div>
          {loading ? (
            <Spinner size={26} color="#6b7280" /> 
          ) : (
            cards?.length !== 0 && (
              <AiFillEdit 
                size={24}
                className={"-m-1 text-blue-500 cursor-pointer" + iconCircleStyle}
                onClick={() => setEditMode(!editMode)}
              />
            )
          )}
        </div>
      </div>
      <div className={`flex flex-col mt-3 ${cards.length > 0 ? "mb-4" : "mb-0"}`}>
        {cards?.map((card: any) => (
          <div
            key={card.id}
            className="grid grid-cols-11 border p-3 my-0.5 border-gray-200 rounded-md w-[550px] items-center gap-x-4"
          >
            <div className="col-span-2">
              <img src={cardIcon(card.brand)} alt={card.brand} className="h-10" />
            </div>

            <div className="col-span-5">
              <div>
                {capitalizeFirstLetter(card.brand)} {tr("ending in")} {card.last4}
              </div>
              <div className="text-sm text-gray-500">
                {tr("Exp. date")} {card.exp_month}/{card.exp_year}
              </div>
            </div>
            <div className="col-span-3 text-[12px] flex justify-end">
              {card.default ? (
                <div>
                  {tr("Default")}
                </div>
              ) : (!card.default && editMode) ? (
                <button 
                  className="text-blue-500 text-end"
                  onClick={() => {
                    setDefaultCard(card.id);
                    setEditMode(false);
                  }}
                >
                  {tr("Set as default")}
                </button>
              ) : null}
            </div>

            <div className="col-span-1">
              <AiFillDelete
                size={24}
                className={"text-red-500 cursor-pointer" + iconCircleStyle}
                onClick={() => areYouSure.activate(() => deletePaymentMethod(card.id))}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="flex space-x-2">
        <Button 
          type={`${!showCardForm || cardComplete ? "primary" : "secondary"}`}
          className={`w-[86px] ${cardComplete && "!p-0"}`}
          onClick={showCardForm ? 
            (cardComplete ? addPaymentMethod : () => {setShowCardForm(false); setCardError(null);}) 
            : () => setShowCardForm(true)
          } 
        >
          {!showCardForm ? tr("Add Card") : (cardComplete ? tr("Save Card") : tr("Cancel"))}
        </Button>
        {showCardForm && (
          <div className="w-[456px] border p-2 rounded">
            <CardElement 
              options={cardElementOptions} 
              onChange={(event) => {
                setCardComplete(event.complete); 
                setCardError(event.error ? event.error.message : null); 
              }} 
            />
          </div>
        )}
        {cardError && showCardForm && 
          <div className="text-red-500 text-sm mt-2">
            {cardErrors(cardError)}
          </div>
        }
      </div>

    </div>
  );
};

const PaymentMethodsWrapper = ({ id, customer }: PaymentMethodsProps) => {
  const [stripeAccountId, setStripeAccountId] = useState(null);

  const companyId = customer.fk_business_id;

  const getStripeAccountId = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/${companyId}/get-stripe-account`
      );
      return setStripeAccountId(res.data.stripeAccountId);
    } catch (error) {
      console.error("Error fetching Stripe customer ID:", error);
    }
  };

  useEffect(() => {
    getStripeAccountId();
  }, []);

  const stripePromise = stripeAccountId && loadStripe(process.env.REACT_APP_STRIPE!, {
    stripeAccount: stripeAccountId, // IMPORTANT - This is the Stripe Connect account ID (company specific)
  });
  
  return (
    <Elements stripe={stripePromise}>
      <PaymentMethods id={id} customer={customer} />
    </Elements>
  );
};

export default PaymentMethodsWrapper;
