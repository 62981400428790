import { useEffect, useState } from "react";
import getInvoiceAssignees from "./api/getInvoiceAssignees";
import AssigneeSelect from "components/react-select/assignees";
import { InvoiceProductionAssignee } from "./types/InvoiceProductionAssignee";
import updateInvoiceAssignees from "./api/updateInvoiceAssignees";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

type InvoiceProductionAllocationProps = {
  invoiceId: number;
};

const InvoiceProductionAllocation = ({
  invoiceId,
}: InvoiceProductionAllocationProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [assignees, setAssignees] = useState<InvoiceProductionAssignee[]>([]);
  const [staleAssignees, setStaleAssignees] = useState<
    InvoiceProductionAssignee[]
  >([]);
  const [invoiceTotal, setInvoiceTotal] = useState<number | null>(null);

  const COLORS = ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"];

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const onChangeAssignees = (value: InvoiceProductionAssignee[]) => {
    if (!value) return;
    console.log("Assignees:", value);

    if (value.length === 0) {
      setAssignees([]);
      setStaleAssignees([]);
      return;
    }

    const updatedAssignees = value.map((assignee) => {
      const existing = assignees.find((a) => a.value === assignee.value);
      return existing || { ...assignee, percent: 0 };
    });

    setAssignees(updatedAssignees);
  };

  const onClickSave = async () => {
    console.log("Updating assignees:", assignees);

    const sanitizedAssignees = assignees.map((assignee) => ({
      ...assignee,
      value: Number(assignee.value),
    }));

    const updateResponse = await updateInvoiceAssignees(
      invoiceId,
      sanitizedAssignees
    );
    if (updateResponse) {
      console.log("Update response:", updateResponse);
      setStaleAssignees(assignees);
    }
    closeModal();
  };

  const updatePercent = (value: number, userId: number) => {
    setAssignees((prev) =>
      prev.map((assignee) =>
        assignee.value === userId ? { ...assignee, percent: value } : assignee
      )
    );
  };

  const fetchAssignees = async () => {
    const response = await getInvoiceAssignees(invoiceId);

    if (response) {
      const convertedAssignees =
        response.data.productionRows?.map((assignee: any) => ({
          label: `${assignee.first_name} ${assignee.last_name}`,
          value: String(assignee.fk_user_id),
          percent: assignee.percent,
        })) || [];

      console.log("Converted assignees (fetch):", convertedAssignees);

      setAssignees(convertedAssignees);
      setStaleAssignees(convertedAssignees);

      const total = parseFloat(response.data.invoiceTotal) || null;
      setInvoiceTotal(total);
    }
  };

  useEffect(() => {
    if (invoiceId) {
      fetchAssignees();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);

  // Format pie chart data with dollar amounts
  const pieChartData = staleAssignees.map((assignee) => {
    const dollarAmount =
      invoiceTotal !== null
        ? ((assignee.percent / 100) * invoiceTotal).toFixed(2)
        : "0.00";

    return {
      name: assignee.label,
      percent: assignee.percent,
      amount: parseFloat(dollarAmount),
    };
  });

  return (
    <div className="space-y-4">
      <div className="flex flex-row items-center justify-between border-b py-4">
        <h2 className="text-lg font-semibold">Production Allocation</h2>
        <button
          className="px-4 py-2 border border-orange-500 text-orange-500 rounded hover:bg-orange-100"
          onClick={openModal}
        >
          Manage Users
        </button>
      </div>

      {/* Invoice Total Display */}
      {invoiceTotal !== null && (
        <div className="text-lg font-semibold text-gray-800">
          Invoice Total:{" "}
          <span className="text-blue-600">${invoiceTotal.toFixed(2)}</span>
        </div>
      )}

      <div className="flex flex-col lg:flex-row gap-6">
        {/* Pie Chart with Responsive Container */}
        <div className="w-full lg:flex-1 min-h-[350px]">
          <ResponsiveContainer width="100%" height={350}>
            <PieChart>
              <Pie
                data={pieChartData}
                dataKey="percent"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius="70%"
                fill="#8884d8"
                label={({ name, amount, percent }) =>
                  `${name}: $${amount.toFixed(2)} (${percent}%)`
                }
              >
                {pieChartData?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip
                formatter={(value, name, props) => [
                  `$${props.payload.amount.toFixed(2)} (${value}%)`,
                  name,
                ]}
              />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {/* User Percentages & Dollar Amounts */}
        <div className="w-full lg:w-1/3 space-y-2">
          <h3 className="text-lg font-semibold">User Allocations</h3>
          <ul>
            {staleAssignees?.map((assignee) => {
              const dollarAmount =
                invoiceTotal !== null
                  ? ((assignee.percent / 100) * invoiceTotal).toFixed(2)
                  : "0.00";

              return (
                <li
                  key={assignee.value}
                  className="flex justify-between border-b py-2"
                >
                  <span>{assignee.label}</span>
                  <span>
                    {assignee.percent}% -{" "}
                    <span className="font-semibold text-green-600">
                      ${dollarAmount}
                    </span>
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      {/* Modal with Reset Button */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white w-96 h-1/2 p-6 rounded shadow-lg flex flex-col">
            <h2 className="text-lg font-semibold mb-4">Manage Assignees</h2>

            {/* Assignee Select */}
            <AssigneeSelect
              value={assignees}
              onChange={(value: InvoiceProductionAssignee[]) => {
                if (value) {
                  onChangeAssignees(value as InvoiceProductionAssignee[]);
                } else {
                  setAssignees([]);
                  // setStaleAssignees([]);
                }
              }}
            />

            {/* Scrollable List */}
            <div className="overflow-y-auto flex-grow mt-4 border-t pt-4">
              {assignees?.map((assignee) => (
                <div
                  key={assignee.value}
                  className="flex items-center justify-between mb-2"
                >
                  <span className="text-gray-700">{assignee.label}</span>
                  <div>
                    <input
                      type="number"
                      min="0"
                      max="100"
                      className="w-20 p-2 border rounded"
                      value={assignee.percent}
                      onChange={(e) =>
                        updatePercent(Number(e.target.value), assignee.value)
                      }
                    />
                    <span>%</span>
                  </div>
                </div>
              ))}
            </div>

            {/* Buttons */}
            <div className="flex justify-end mt-4 space-x-4">
              {/* <button
                className="px-4 py-2 border border-gray-300 rounded hover:bg-gray-100"
                onClick={() => {
                  setAssignees([]);
                  setStaleAssignees([]);
                }}
              >
                Reset
              </button> */}
              <button
                className="px-4 py-2 border border-gray-300 rounded hover:bg-gray-100"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={onClickSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvoiceProductionAllocation;
