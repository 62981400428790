import React from "react";
import axios from "axios";
import { Button, OutlineButton } from "components/button";
import Field from "components/field";
import Select from "react-select";
import { useModalContext } from "components/modal";
import ClientSelect from "components/react-select/clients";
import TaxSelect from "components/react-select/taxes";
import { useSnackbarContext } from "components/snackbar";
import { useFormik } from "formik";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TCustomer } from "types/customer";
import { TProperty, TPropertyForm } from "types/property";
import * as Yup from "yup";
import { NewPropertyMapContainer } from "./NewPropertyMapContainer";
import { TTag } from "types/tag";
import StandardizedAddressInput from "components/standardizedAddressInput";
import { useTranslation } from 'react-i18next';

type Props = {
  customerId?: string;
  property?: TProperty;
  customerInfo?: TCustomer;
  notModal?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
  returnedPropertyId?: (property: TProperty) => void;
};
export default function NewPropertyModal(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.properties.newPropertyModal";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const tagsQuery = useQuery<Array<TTag>>("tags", async () =>
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/tags/customer&property`)
      .then((res) => res.data)
  );

  const tagOptions =
    tagsQuery.data?.map((tag) => ({ value: tag.id, label: tag.label })) || [];

  const modal = useModalContext(),
    snackbar = useSnackbarContext(),
    queryClient = useQueryClient();
  const mutation = useMutation(
    async (e: TPropertyForm) =>
      await axios
        .post(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/property/${props.property?.id}
    `,
          {
            ...e,
            address: addressObj.address,
            isStandardizedAddress: addressObj.isStandardizedAddress,
          }
        )
        .then((res) => {props.returnedPropertyId && props.returnedPropertyId(res.data); return res.data}),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries();
        !props.notModal && modal.setModal();
        snackbar.showSnackbar(
          `${tr("Property")} ${props.property ? tr("modified!") : tr("created!")}`
        );
        props.onSubmit && props.onSubmit();
        props.returnedPropertyId && props.returnedPropertyId(res);
      },
    }
  );

  // console.log(tagOptions);

  const initialValues = {
    address: props.property?.address || "",
    city: props.property?.city || "",
    state: props.property?.state || "",
    name: props.property?.name || "",
    zipcode: props.property?.zipcode || "",
    owner: props.property?.owner
      ? { ...props.property.owner, id: props.property.owner.id.toString() }
      : props.customerInfo || null,
    billed_to: props.property?.billed_to
      ? {
          ...props.property.billed_to,
          id: props.property.billed_to.id.toString(),
        }
      : props.customerInfo || null,
    default_tax: props.property?.default_tax || null,
    latitude: props.property?.latitude || null,
    longitude: props.property?.longitude || null,
    tags: props.property?.tags?.map(tag => ({
      value: tag.id,
      label: tag.label,
    })) || [],
  };

  const validationSchema = Yup.object({
    address: Yup.string().required(),
    city: Yup.string().required(),
    state: Yup.string().required().length(2),
    zipcode: Yup.string().required().length(5),
    name: Yup.string(),
    owner: Yup.object()
      .nullable()
      .required(), // Added required validation for owner
    billed_to: Yup.object()
      .nullable()
      .required(), // Added required validation for billed_to
    latitude: Yup.number().nullable(),
    longitude: Yup.number().nullable(),
    tags: Yup.array().nullable(),
  });
  

  // const onSubmit = (e: TPropertyForm) => {
  //   // console.log("submit")
  //   mutation.mutate(e);
  // };

  const onSubmit = (e: TPropertyForm) => {
    const tags = e.tags ? e.tags.map((tag) => tag.value) : [];
    mutation.mutate({ ...e, tags });
  };

  const formik = useFormik<TPropertyForm>({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const [addressObj, setAddressObj] = React.useState({
    address: formik.values.address || "",
    isStandardizedAddress: false,
    selectedOption: {
      description: formik.values.address || "",
      city: formik.values.city || "",
      state: formik.values.state || "",
      zipcode: formik.values.zipcode || "",
    },
  });

  React.useEffect(() => {
    formik.setValues((v) => ({
      ...v,
      address: addressObj.address,
      // address: addressObj.selectedOption.description,
      city: addressObj.selectedOption.city,
      state: addressObj.selectedOption.state,
      zipcode: addressObj.selectedOption.zipcode,
    }));
  }, [addressObj]);

  // const handleChange = (e: any) => {
  //   if (e && e.target.name) {
  //     setAddressObj((prev) => ({
  //       ...prev,
  //       selectedOption: {
  //         ...prev.selectedOption,
  //         [e.target.name]: e.target.value,
  //       },
  //       isStandardizedAddress: false,
  //     }));
  //   }
  // };

  return (
    <form onSubmit={formik.handleSubmit} className="space-y-4 max-w-[800px]">
      {/* Address Input */}
      <StandardizedAddressInput {...{ setAddressObj, addressObj }} label={tr("Address")} formik={formik} required />
  
      {/* City, State, Zipcode */}
      <div className="flex justify-between">
        <Field
          name="city"
          className="w-[40%]"
          required
          label={tr("City")}
          value={addressObj.selectedOption.city || ""}
          onChange={(e: any) =>
            setAddressObj({
              ...addressObj,
              isStandardizedAddress: false,
              selectedOption: { ...addressObj.selectedOption, city: e.target.value },
            })
          }
          error={formik.touched.city && formik.errors.city ? formik.errors.city : ""}
        />
        <Field
          name="state"
          className="w-[20%]"
          required
          label={tr("State")}
          value={addressObj.selectedOption.state || ""}
          onChange={(e: any) =>
            setAddressObj({
              ...addressObj,
              isStandardizedAddress: false,
              selectedOption: { ...addressObj.selectedOption, state: e.target.value },
            })
          }
          error={formik.touched.state && formik.errors.state ? formik.errors.state : ""}
        />
        <Field
          name="zipcode"
          className="w-[30%]"
          required
          label={tr("Zipcode")}
          value={addressObj.selectedOption.zipcode || ""}
          onChange={(e: any) =>
            setAddressObj({
              ...addressObj,
              isStandardizedAddress: false,
              selectedOption: { ...addressObj.selectedOption, zipcode: e.target.value },
            })
          }
          error={formik.touched.zipcode && formik.errors.zipcode ? formik.errors.zipcode : ""}
        />
      </div>
  
      {/* Nickname */}
      <Field name="name" formik={formik} label={tr("Nickname")} />
  
      {/* Owner and Billed To */}
      <div className="flex space-x-2">
        <ClientSelect
          error={formik.touched.owner && formik.errors.owner ? formik.errors.owner : ""}
          required
          isClearable
          value={
            formik.values.owner
              ? { ...formik.values.owner, value: formik.values.owner.id, label: formik.values.owner.name }
              : null
          }
          label={tr("Owner")}
          onChange={(e) => formik.setValues((v) => ({ ...v, owner: e }))}
        />
        <ClientSelect
          error={formik.touched.billed_to && formik.errors.billed_to ? formik.errors.billed_to : ""}
          required
          isClearable
          value={
            formik.values.billed_to
              ? { ...formik.values.billed_to, value: formik.values.billed_to.id, label: formik.values.billed_to.name }
              : null
          }
          label={tr("Billed To")}
          onChange={(e) => formik.setValues((v) => ({ ...v, billed_to: e }))}
        />
      </div>
  
      {/* Default Tax */}
      <div>
        <TaxSelect
          label={tr("Default Tax")}
          value={formik.values?.default_tax}
          onChange={(e: any) => formik.setFieldValue("default_tax", e.value)}
        />
      </div>
  
      {/* Tags */}
      <div>
        <div className="text-sm">{tr("Tags")}</div>
        <Select
          isMulti
          name="tags"
          options={tagOptions}
          className="basic-multi-select"
          label={tr("Tags")}
          classNamePrefix="select"
          value={formik.values.tags}
          onChange={(selectedOptions) => {
            const tags = selectedOptions
              ? selectedOptions.map((option) => ({
                  value: option.value,
                  label: option.label,
                }))
              : [];
            formik.setFieldValue("tags", tags);
          }}
          placeholder={tr("Select tags...")}
        />
      </div>
  
      {/* Map */}
      <div>
        <div className="text-sm">{tr("Map")}</div>
        <NewPropertyMapContainer formik={formik} />
      </div>
  
      {/* Buttons */}
      <div className="flex justify-end space-x-2">
        <OutlineButton
          type="button"
          onClick={() =>
            (!props.notModal && modal.setModal()) ||
            (props.onCancel && props.onCancel())
          }
        >
          {tr("Cancel")}
        </OutlineButton>
        <Button primary type="submit" disabled={!formik.isValid || !formik.dirty || mutation.isLoading}>
          {tr("Save")}
        </Button>
      </div>
    </form>
  );
}
