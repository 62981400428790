/* eslint-disable import/no-anonymous-default-export */
import { useContext } from "react";
import styled from "styled-components";
import {
  RiArrowLeftLine,
  RiArrowRightLine,
} from "react-icons/ri";
import DatePicker from "react-datepicker";
import {
  addDays,
  subDays,
  startOfWeek,
  endOfWeek,
  format,
  isToday,
} from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import {
  IconButton,
  Button,
} from "components/button";
import Filter from "components/filter";
import { AiOutlinePlus } from "react-icons/ai";
import { useModalContext } from "components/modal";
import AddBlock from "./addBlock";
import useQueryString from "hooks/useQueryString";
import Switch from "rc-switch";
import ViewByTypeContext from "./context/ViewByTypeContext";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";

const GridOption = styled.div`
  display: flex;
  flex-direction: row;
  > button {
    margin: 0 0.3rem 0 0.3rem;
    &:nth-child(${(props) => props.selectedGrid}) {
      background: blue;
      color: white;
    }
  }
`;

export default function Tab ({
  handleUnscheduledClick,
  handleDateChange,
  handleGridChange,
  date: dateProp,
  grid,
  refreshStatus,
  filterProps,
  sidebar
}) {
  const { t } = useTranslation();
  const tBase = "views.calendar.tab";
  const tr = (key) => t(`${tBase}.${key}`);
  const { i18n } = useTranslation();
  const locale = i18n.language === "es" ? es : enUS;

  const { setModal } = useModalContext(),
  queryString = useQueryString();
  const date = dateProp ? new Date(dateProp) : new Date();
  const { viewByType, setViewByType } = useContext(ViewByTypeContext);

  return (
    <div className={`
      flex flex-row align-center items-start justify-between z-10 mt-2 mx-2
      ${sidebar === "false" ? "ml-16" : ""}
    `}>
      <div className="flex">
        <IconButton
          style={{ borderRadius: "5px" }}
          onClick={() =>
            handleDateChange(subDays(date, 
              grid === "daily" ? 1 : grid === "weekly" ? 7 : 30))
          }
        >
          <RiArrowLeftLine />
        </IconButton>

        <DatePicker
          selected={date}
          onChange={(date) => handleDateChange(date)}
          customInput={<DateInput {...{ grid }} />}
          locale={locale}
        />

        <IconButton
          style={{ borderRadius: "5px" }}
          onClick={() =>
            handleDateChange(addDays(date, 
              grid === "daily" ? 1 : grid === "weekly" ? 7 : 30))
          }
        >
          <RiArrowRightLine />
        </IconButton>

        {!isToday(date) && (
          <div
            className="flex flex-row bg-[#edf1f4] p-[8.5px] rounded items-center cursor-pointer ml-2 hover:bg-gray-300  text-[#ff4f00]"
            onClick={() => handleDateChange(new Date())}
          >
            {tr("Today")}
          </div>
        )}
      </div>
      <div className="flex space-x-4">
        <div className="self-center -mr-2 xl:block hidden">
          {tr("View by Appointment Type")}
        </div>
        <Switch 
          color="primary" 
          className="self-center"
          onChange={() => setViewByType(prevState => !prevState)}
          />

        <Button
          onClick={() =>
            setModal({
              component: <AddBlock />,
              label: "Add Block",
            })
          }
        >
          <AiOutlinePlus />
          {tr("Block")}
        </Button>

        <Filter {...filterProps} />

        <GridOption selectedGrid={grid === "daily" ? 1 : grid === "weekly" ? 2 : 3}>
          <Button onClick={() => handleGridChange("daily")}>{tr("Daily")}</Button>
          <Button onClick={() => handleGridChange("weekly")}>{tr("Weekly")}</Button>
          <Button onClick={() => handleGridChange("monthly")}>{tr("Monthly")}</Button>
        </GridOption>
      </div>
    </div>
  );
}

const DateInput = ({ value, onClick, grid }) => {
  const { i18n } = useTranslation();
  const locale = i18n.language === "es" ? es : enUS;

  // const weekStartDate = format(
  //   addDays(startOfWeek(new Date(value)), 1),
  //   "MM/dd"
  // );
  // const weekEndDate = format(addDays(endOfWeek(new Date(value)), 1), "MM/dd");

  const weekStartDate = format(startOfWeek(new Date(value), { weekStartsOn: 1 }), "MM/dd"); // Monday
  const weekEndDate = format(endOfWeek(new Date(value), { weekStartsOn: 1 }), "MM/dd"); // Sunday


  let formatValue;
  
  if (grid === "daily") {
    formatValue = value;
  } else if (grid === "weekly") {
    formatValue = weekStartDate + "-" + weekEndDate;
  } else if (grid === "monthly") {
    formatValue = format(new Date(value), "MMMM yyyy", {
      locale: locale,
    });  // This will show e.g. "October 2023"
  }

  return (
    <Button style={{ margin: "0 0.5rem", width: "150px" }} {...{ onClick }}>
      {formatValue}
    </Button>
  );
};

