import React, { useState } from "react";

import { VendorItemDetails } from "types/vendor";
import { updateVendorItemQuantity } from "api/vendorsApi";
import { Button } from "components/button";
import { Input } from "components/input";
import { useTranslation } from "react-i18next";

import styles from "./VendorItemDetail.module.scss";

interface IProps {
  item: VendorItemDetails;
}

const VendorItemDetail = ({ item }: IProps) => {
  const { t } = useTranslation();
  const tBase =
    "views.vendors.VendorBody.VendorItems.VendorItemDetail.VendorItemDetail";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const {
    id,
    vendor_part_number,
    price,
    lead_time,
    notes,
    item_name,
    long_description,
    model,
    imageUrl,
    last_update,
  } = item;

  const [displayPrice, setDisplayPrice] = useState<string | null>(
    price?.toString() || null
  );

  const [newPrice, setNewPrice] = useState<string | null>(
    ((price ? price : 0) / 100)?.toString() || ""
  );

  const [error, setError] = useState(false);

  const handleChangePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPrice(e.target.value);
    if (e.target.value && parseFloat(e.target.value)) {
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleUpdateVendorItemDetail = async (
    id: number,
    newPrice: string | null
  ) => {
    if (newPrice) {
      const response = await updateVendorItemQuantity(
        id,
        parseFloat(newPrice) * 100
      );

      if (response) {
        setNewPrice("");
        setDisplayPrice(response.price);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.nameGroup}>
        <div>
          <img className={styles.image} src={imageUrl || ""} alt={item_name} />
        </div>
        <div className={styles.details}>
          <div className={styles.model}>
            {model}
            {vendor_part_number ? (
              <span className={styles.vendorPartNumber}>
                {` (vend.: ${vendor_part_number})`}
              </span>
            ) : null}
          </div>
          <div className={styles.itemName}>{item_name}</div>
          <div className={styles.longDescription}>{long_description}</div>
        </div>
      </div>
      <div className={styles.priceGroup}>
        <div className={styles.prices}>
          {displayPrice ? (
            <div className={styles.price}>
              ${(parseFloat(displayPrice) / 100).toFixed(2)}
            </div>
          ) : null}
          {last_update ? (
            <div className={styles.lastUpdate}>
              Last Updated: {new Date(last_update).toLocaleDateString()}
            </div>
          ) : null}
          {lead_time ? (
            <div className={styles.leadTime}>
              {tr("Lead Time")}: {lead_time}
            </div>
          ) : null}
          {notes ? <div className={styles.notes}>{notes}</div> : null}
        </div>
        <div className={styles.editGroup}>
          <Input
            type="$"
            className={error ? styles.errorInput : styles.input}
            value={newPrice}
            onChange={handleChangePrice}
          />
          <Button
            primary
            disabled={!error && newPrice ? false : true}
            onClick={() => handleUpdateVendorItemDetail(id, newPrice)}
          >
            {tr("Save")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VendorItemDetail;
