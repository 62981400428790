import axios from "axios";
import { InvoiceProductionAssignee } from "../types/InvoiceProductionAssignee";

const updateInvoiceAssignees = async (
  invoiceId: number,
  assignees: InvoiceProductionAssignee[]
) => {
  console.log("Updating assignees:", assignees);
  try {
    return await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/company/production/invoice-assignees`,
      {
        invoiceId,
        assignees,
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export default updateInvoiceAssignees;
