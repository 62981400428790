import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';

// Components
import TooltippedElement from "components/TooltippedElement";
import MergeModal from "./MergeModal";
import IgnoreModal from "./IgnoreModal";

// Context
import { useSnackbarContext } from "components/snackbar";
import { useModalContext } from "components/modal";

// Icons
import { MergeIcon }  from "images/icons/icons.js";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdVerifiedUser, MdDoNotDisturbAlt } from "react-icons/md";

// Types
import { IAccordionStates, ILoadingGradient } from "../types";
import { Button } from "components/buttonV2";
import Spinner from "components/spinner";

interface ICustomersProps {
  customerData: any;
  toggleAccordion: (id: string) => void;
  accordionStates: IAccordionStates;
  setCustomerData: any;
  setDuplicateCount: any;
  fetchDuplicates: () => void;
  loading: boolean;
}

const Customers = ({
  customerData,
  toggleAccordion,
  accordionStates,
  setCustomerData,
  setDuplicateCount,
  fetchDuplicates,
  loading
}: ICustomersProps
) => {
  const { t } = useTranslation();
  const tBase = "views.duplicates.customers.components.Customers";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const [loadingGradient, setLoadingGradient] = useState<ILoadingGradient>({});
  const [mergeIconHover, setMergeIconHover] = useState<{ [key: string]: boolean }>({});
  const { showSnackbar } = useSnackbarContext();
  const { setModal } = useModalContext();

  // Wait function (basically setTimeout but better syntax)
  const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
  
  // Merge Icon
  const handleMouseEnter = (id: string) => {
    setMergeIconHover(prev => ({ ...prev, [id]: true }));
  };

  const handleMouseLeave = (id: string) => {
    setMergeIconHover(prev => ({ ...prev, [id]: false }));
  };

  // Progress bar
  const calculateMatchPercentage = (whereIsDuplicate: any) => {
    const maxReasons = 5; 
    const reasonsCount = whereIsDuplicate.split('_').length; 
    return (reasonsCount / maxReasons) * 100; 
  };
  
  // Gradient 
  const setGradientTrue = (id: string) => {
    setLoadingGradient(prevState => ({
      ...prevState,
      [id]: true
    }));
  };

  const setGradientFalse = (id: string) => {
    setLoadingGradient(prevState => ({
      ...prevState,
      [id]: false
    }));
  };
  
  const mergeDuplicates = async (originalId: string, duplicateId: string, originalCustomer: any, duplicateCustomer: any, flipped: boolean) => {
    setLoadingGradient({}); // Reset all gradients
    let noMoreDuplicates;
    setGradientTrue(duplicateId);

    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/duplicates/customers/merge`, {
        originalId,
        duplicateId,
        originalCustomer,
        duplicateCustomer
      });

      setDuplicateCount((prevCount: number) => prevCount - 1);
      
      // Update customerData to exclude the merged duplicate
      setCustomerData((currentData: any) => 
        currentData.map((data: any) => {
          if (data.original.id === originalId) {
            // Filter out the merged duplicate from the duplicates array
            const updatedDuplicates = data.duplicates.filter((duplicate: any) => duplicate.id !== duplicateId);
            if (updatedDuplicates.length === 0) {
              noMoreDuplicates = true;
            }
            return {...data, duplicates: updatedDuplicates};
          }
          return data;
        })
      );

      setGradientFalse(duplicateId);

      if (flipped) {
        setCustomerData((currentData: any) => {
          const replaceOriginal = currentData.map((data: any) => {
            if (data.original.id === duplicateId) {
              const removedDuplicate = data.duplicates.filter((duplicate: any) => duplicate.id !== originalId);
              if (removedDuplicate.length === 0) {
                noMoreDuplicates = true;
              }
              return {...data, original: { ...originalCustomer}, duplicates: removedDuplicate};
            }
            return data;
          });
          return replaceOriginal;
        });
      };

      showSnackbar(tr("Customer merged successfully.")); 
      setLoadingGradient({}); // Reset all gradients

      if (noMoreDuplicates) { // remove original from customerData if no more duplicates
        setGradientTrue(originalId);
        setTimeout(() => {
          setCustomerData((currentData: any) =>
            currentData.filter((data: any) => data.original.id !== originalId)
          );
          setGradientFalse(originalId);
        }, 900); // Delay execution to allow for the gradient to finish
      };

    } catch (error) {
      console.error("Error fetching duplicates:", error);
      setGradientFalse(duplicateId);
      showSnackbar(tr("Error merging customers."));
    }
  };

  const ignoreDuplicate = async (originalId: string, duplicateId: string) => {
    setLoadingGradient({}); // Reset all gradients
    let noMoreDuplicates;
    setGradientTrue(duplicateId);
    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/duplicates/customers/ignore`, {
        duplicateId,
      });

      
      setDuplicateCount((prevCount: number) => prevCount - 1);
      
      await wait(700);
      // Update customerData to exclude the ignored duplicate
      setCustomerData((currentData: any) => 
        currentData.map((data: any) => {
          if (data.original.id === originalId) {
            // Filter out the ignored duplicate from the duplicates array
            const updatedDuplicates = data.duplicates.filter((duplicate: any) => duplicate.id !== duplicateId);
            if (updatedDuplicates.length === 0) {
              noMoreDuplicates = true;
            }
            return {...data, duplicates: updatedDuplicates};
          }
          return data;
        })
      );

      setGradientFalse(duplicateId);
      showSnackbar(tr("Customer ignored successfully.")); 
      setLoadingGradient({}); // Reset all gradients

      if (noMoreDuplicates) { // remove original from customerData if no more duplicates
        setGradientTrue(originalId);
        setTimeout(() => {
          setCustomerData((currentData: any) => 
            currentData.filter((data: any) => data.original.id !== originalId)
          );
          setGradientFalse(originalId);
        }, 900); // Delay execution to allow for the gradient to finish
      };

    } catch (error) {
      console.error("Error fetching duplicates:", error);
      setGradientFalse(duplicateId);
      showSnackbar(tr("Error merging customers."));
    }
  };

  // Ensure gradient is removed on component mount
  useEffect(() => {
    setLoadingGradient({});
  }, []);

  const checkForBadge = (customer: any, type: string) => {
    const ogCustomer = customer.original || {};
    if (type === "originalCustomer" && ogCustomer.fk_customer_id ) {
      return true;
    } else if (type === "duplicateCustomer" && customer?.fk_customer_id) {
      return true;
    }
  };

  const checkForBadgeBoth = (customer: any, duplicateCustomer: any) => {
    const ogCustomer = customer.original || {};
    if (ogCustomer.fk_customer_id && duplicateCustomer?.fk_customer_id) {
      return true;
    } 
  };

  return (
    <div className="mb-20 text-sm">
      {
        customerData?.map((customer: any, index: number) => {
          const seenDuplicateIds = new Set();
          const ogCustomer = customer.original || {};
          const duplicates = customer.duplicates || [];
          const ogAddress = ogCustomer.address ? `${ogCustomer.address},` : "";
          const ogCity = ogCustomer.city ? `${ogCustomer.city},` : "";
          const ogState = ogCustomer.state ? `${ogCustomer.state},` : "";
          const ogZipcode = ogCustomer.zipcode ? `${ogCustomer.zipcode}` : "";
          const ogFullAddress = `${ogAddress} ${ogCity} ${ogState} ${ogZipcode}`;
          
          return (
          <div key={index}>

            {/* Originals */}
            <div 
              className={`grid grid-cols-12 gap-4 hover:bg-[#f6fafe] py-2 cursor-pointer relative
              ${accordionStates[ogCustomer.id] ? "font-bold" : "font-medium "}`}
              onClick={() => toggleAccordion(ogCustomer.id)}
            >
              <div className="col-span-1 pl-2">{tr("Original")}</div>
              <div className="col-span-2">
                <div className="flex items-center"> 
                  {ogCustomer.first_name} {ogCustomer.last_name}
                  {ogCustomer.first_name && ogCustomer.last_name && " "}
                  {ogCustomer.company_name ?
                    <span className="text-gray-500"> ({ogCustomer.company_name})</span>
                    : null
                  }
                  {ogCustomer.fk_customer_id && 
                    <TooltippedElement 
                      message={tr("Verified Customer")}
                      placement="top"
                      element={
                        <MdVerifiedUser className="text-secondary text-[0.9rem] ml-1" />
                        }
                    />
                  }
                </div>
              </div>
              <div className="col-span-3">{ogCustomer.email}</div>
              <div className="col-span-3">{ogFullAddress}</div>
              <div className="col-span-2">{ogCustomer.phone}</div>
              <div className="col-span-1">
                <div className="">
                  {accordionStates[ogCustomer.id] 
                    ? 
                    <IoIosArrowUp className="text-xl font-bold" />
                  : 
                    <IoIosArrowDown className="text-xl text-gray-500" />}
                </div>
              </div>

              {/* Loading Gradient */}
              {loadingGradient[ogCustomer.id] && (
                <div className="absolute right-0 h-full bg-gradient-to-l from-[#358ad1] from-10% via-secondary via-30% to-[#a2d4ff] to-90% animate-expandLeft" />
              )}
            </div>

            {/* Accordion Duplicates */}    
            {accordionStates[ogCustomer.id] && (
              duplicates
              .filter((duplicate: any) => {
                if (seenDuplicateIds.has(duplicate.id)) {
                  return false;
                }
                seenDuplicateIds.add(duplicate.id);
                return true;
              }).map((duplicate: any, dupIndex: any) => (
                <div key={dupIndex}>
                  <div className="grid grid-cols-12 gap-4 hover:bg-[#f6fafe] py-2 border-t font-medium relative">
                    <div className="col-span-1 pl-2">
                      <div className="flex items-center h-full">
                        <div className="w-full bg-gray-200 rounded-full h-2.5">
                          <div
                            className="bg-secondary h-2.5 rounded-full"
                            style={{ width: `${calculateMatchPercentage(duplicate.whereIsDuplicate)}%` }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="flex items-center flex-wrap"> 
                        {duplicate.first_name} {duplicate.last_name}
                        {duplicate.first_name && duplicate.last_name && " "}
                        {duplicate.company_name ?
                          <span className="text-gray-500"> ({duplicate.company_name})</span>
                          : null
                        }
                        {duplicate.fk_customer_id && 
                          <TooltippedElement 
                            message={tr("Verified Customer")}
                            placement="top"
                            element={
                              <MdVerifiedUser className="text-secondary text-[0.9rem] ml-1" />
                              }
                          />
                        }
                      </div>
                    </div>
                    <div className="col-span-3">{duplicate.email}</div>
                    <div className="col-span-3">{duplicate.address}, {duplicate.city}, {duplicate.state}, {duplicate.zipcode}</div>
                    <div className="col-span-2">{duplicate.phone}</div>
                    <div className="col-span-1">
                      <div className="flex items-center justify-center space-x-2">
                      <TooltippedElement 
                        message={`${checkForBadgeBoth(customer, duplicate)  ? tr("Cannot Merge Verified Customer") : tr("Merge")}`}
                        placement={`${checkForBadgeBoth(customer, duplicate)  ? "left" : "top"}`}
                        element={
                          <button 
                            onMouseEnter={() => handleMouseEnter(duplicate.id)}
                            onMouseLeave={() => handleMouseLeave(duplicate.id)}
                            disabled={checkForBadgeBoth(customer, duplicate)}
                            className={`${checkForBadgeBoth(customer, duplicate) ? "cursor-not-allowed" : "cursor-pointer"}`}
                            onClick={() => {
                              setModal({
                                component: 
                                <>
                                  <MergeModal {...{
                                    original: ogCustomer,
                                    duplicate: duplicate,
                                    mergeDuplicates,
                                    setModal,
                                    cannotFlip: checkForBadge(customer, "originalCustomer") || checkForBadge(duplicate, "duplicateCustomer") || false,
                                  }} />
                                </>,
                                label: tr("Merge Customer"),
                              });
                            }}
                          >
                            <MergeIcon color={mergeIconHover[duplicate.id as keyof typeof mergeIconHover] ? "#ff4f00" : "#000000B3"} />
                          </button>
                        }
                      />
                      <TooltippedElement 
                        message={tr("Ignore")}
                        placement="top"
                        element={
                          <button 
                            className="text-gray-500 hover:text-red-500"
                            onClick={() => {
                              setModal({
                                component: 
                                <>
                                  <IgnoreModal {...{
                                    originalId: ogCustomer.id,
                                    duplicateId: duplicate.id,
                                    ignoreDuplicate,
                                    setModal
                                  }} />
                                </>,
                                label: tr("Ignore Customer"),
                              });
                            }}
                          >
                            <MdDoNotDisturbAlt className="text-2xl"/>
                          </button>
                        }
                      />
                      </div>
                    </div>

                    {/* Loading Gradient */}
                    {loadingGradient[duplicate.id] && (
                      <div className="absolute right-0 h-full bg-gradient-to-l from-[#358ad1] from-10% via-secondary via-30% to-[#a2d4ff] to-90% animate-expandLeft" />
                    )}

                  </div>

                </div>
              ))
            )}

            <div className="border-t border-primary "/>
          </div>
        )})
      }
    </div>
  )
}

export default Customers