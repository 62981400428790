import { Avatar } from "components/avatar";
import { SubtleButton, SubtleIconButton } from "components/button";
import { format, parseISO, isValid } from "date-fns";
import * as React from "react";
import {
  RiArrowDownSLine,
  RiArrowRightSLine,
  RiCloseLine,
  RiPencilLine,
} from "react-icons/ri";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import { theme } from "styles/theme";
import { dollarFormatter } from "utils";
import AddAppointment from "./appointment/appointmentModal";
import StatusSelect from "components/react-select/status";
import LineItemsContainer from "containers/lineItems";
import CompletionNote from "./completionNote";

import AppointmentStatuses from "./appointmentStatuses";
import ReminderDialog from "./reminderDialog";
import { parseDuration } from "utils/duration";
import { useAreYouSure } from "components/areYouSure";
import AppointmentProduction from "./appointmentProduction";
import { AppointmentChecklists } from "features/requests/components/appointment/checklists";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";
import { convertFromUTC } from "utils/utcConversion";
import { useAuthContext } from "contexts/auth";

type Props = any;

export default function Appointment({
  appointment,
  handleStatusChange,
  handleDelete,
  setModal,
  parentId,
  i,
  length,
  acceptedBid,
  type,
  isBookit = false,
  responses,
  ...props
}: Props) {
  const {
    duration,
    id: appointmentId,
    fk_job_id,
    note,
    assignee,
    completion_note,
    actual_total,
    status,
  } = appointment;
  const { t, i18n } = useTranslation();
  const tBase = "containers.appointmentCard.appointment";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const locale = i18n.language === "es" ? es : enUS;
  const { timezone } = useAuthContext();
  const [expanded, setExpanded] = React.useState(false);

  React.useEffect(() => {
    (props.expand === true || props.expand === false) &&
      setExpanded(props.expand);
  }, [props.expand]);

  const queryClient = useQueryClient(),
    areYouSure = useAreYouSure();

  const getdateintervalinfo = () => {
    if (appointment.enddate !== null && appointment.enddate !== undefined) {
      return appointment.date &&
        isValid(parseISO(appointment.date)) &&
        isValid(parseISO(appointment.enddate))
        ? format(parseISO(appointment.date), "eee, MMM dd, yyyy", {
          locale: locale,
        }) +
            " - " +
            format(parseISO(appointment.enddate), "eee, MMM dd, yyyy", {
              locale: locale,
            })
        : tr("Invalid Date");
    } else {
      return appointment.date && isValid(parseISO(appointment.date))
        ? format(parseISO(appointment.date), "eee, MMM dd, yyyy", {
          locale: locale,
        })
        : tr("Invalid Date");
    }
  };


  const start_time_utc = appointment.start_time_utc 
    ? convertFromUTC(appointment.start_time_utc, timezone) 
    : "";

  const end_time_utc = appointment.end_time_utc 
    ? convertFromUTC(appointment.end_time_utc, timezone) 
    : "";


  const date = getdateintervalinfo();

  const interval = start_time_utc;

  const populateAssignee = assignee
    ? assignee.map(({ value, label }: any) => (
        <SAssignee key={value}>
          <Avatar showLabel size="small" user_id={value}>
            {label}
          </Avatar>
        </SAssignee>
      ))
    : "";

  const intAppointmentId = parseInt(appointmentId);

  return (
    <SAppointmentCon>
      <SFirstRow className="py-2">
        <SArrow onClick={() => setExpanded((s) => !s)}>
          {expanded ? <RiArrowDownSLine /> : <RiArrowRightSLine />}
        </SArrow>
        <SApptIcon>{length - i}</SApptIcon>
        <SDetail>
          <div className="flex flex-wrap text-sm font-bold text-slate-500">
            <span>#{appointment.id}</span>
            {note && (
              <>
                <span className="mx-1">|</span>
                <span>{appointment.note}</span>
              </>
            )}

            {appointment.appointment_category && (
              <>
                <span className="mx-1">|</span>
                <span>{appointment.appointment_category}</span>
              </>
            )}
            <span className="flex items-center px-2 mx-1 text-xs font-bold capitalize rounded bg-slate-100 text-slate-700">
              {tr(appointment.type)}
            </span>
            <span>{dollarFormatter(actual_total)}</span>
          </div>

          <SFirstRow>
            <SDate>
              {date || (
                <span style={{ color: "red", fontWeight: "bold" }}>
                  {tr("No Date")}
                </span>
              )}
            </SDate>
            <div style={{ color: theme.color.subtext2, marginLeft: "0.5em" }}>
              {start_time_utc && start_time_utc}
              {start_time_utc && end_time_utc && "-"}
              {end_time_utc && end_time_utc}
              {duration
                ? " | " +
                  parseDuration(duration).hr +
                  "h " +
                  parseDuration(duration).mins +
                  "m"
                : ""}
            </div>
          </SFirstRow>
          <SSecondRow>{populateAssignee}</SSecondRow>
        </SDetail>

        <div className="flex flex-row items-center">
          <div className="mr-2">
            <SubtleButton
              onClick={() => {
                setModal({
                  component: <ReminderDialog appointmentId={appointmentId} />,
                  label: tr("Send Reminder"),
                });
              }}
            >
              {tr("Send Reminder")}
            </SubtleButton>
          </div>
          <div className="mr-10">
            <StatusSelect
              value={{
                value: status.id,
                label: tr(status.label),
                color: status.color,
              }}
              handleStatusChange={(e: any) =>
                handleStatusChange(e, appointmentId)
              }
              type="appointment"
            />
          </div>
          <SIconCon>
            <SubtleIconButton
              onClick={() =>
                setModal({
                  component: (
                    <AddAppointment
                      id={parentId}
                      closed={() => queryClient.invalidateQueries()}
                      appointmentId={appointmentId}
                      appointment={appointment}
                      type={type}
                      emailDefault={isBookit ? true : false}
                      defaultdate={Date}
                      isBookit={isBookit}
                      responses={responses}
                    />
                  ),
                  label: tr("Edit Appointment"),
                })
              }
            >
              <RiPencilLine />
            </SubtleIconButton>
            <SubtleIconButton
              style={{ color: "red" }}
              onClick={() =>
                areYouSure.activate(() => handleDelete(appointmentId))
              }
            >
              <RiCloseLine />
            </SubtleIconButton>
          </SIconCon>
        </div>
      </SFirstRow>
      {expanded && (
        <div className="p-4 border-t bg-slate-100/40">
          <AppointmentStatuses appointmentId={appointmentId} />
          <CompletionNote completion_note={completion_note} />
          <AppointmentChecklists appointmentId={intAppointmentId} />
          <LineItemsContainer
            style={{ padding: 0 }}
            type="appointment"
            id={appointmentId}
            parentId={parentId}
            parentType={type}
          />
          <AppointmentProduction appointmentId={appointmentId} />
        </div>
      )}
    </SAppointmentCon>
  );
}

const SDot = styled.div`
  margin: 0 0.25rem;
  font-size: 0.8rem;
`;

const SDate = styled.span`
  font-weight: 600;
`;

const SArrow = styled.div`
  font-size: 1.5rem;
  line-height: 1;
  color: ${theme.color.subtext3};
`;

const SAppointmentCon = styled.div`
  display: flex;
  flex-direction: column;
`;

const SIconCon = styled.div`
  display: flex;
`;

const SApptIcon = styled.div`
  background: ${theme.bg.blue};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  font-size: 1.5rem;
  padding: 0.5rem;
`;

const SDetail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const SFirstRow = styled.div`
  display: flex;

  > div:not(:last-child) {
    margin-right: 1rem;
  }
`;

const SSecondRow = styled.div`
  display: flex;
  > div:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const SAssignee = styled.div`
  display: flex;
`;