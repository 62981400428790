const EmailDashboard = () => {
  const campaigns = [
    {
      id: 1,
      name: "January Newsletter",
      subject: "Start the New Year Right!",
      sentDate: "2025-01-01",
      openRate: "45%",
      clickRate: "25%",
      status: "Completed",
    },
    {
      id: 2,
      name: "Holiday Promo",
      subject: "Exclusive Deals for You!",
      sentDate: "2024-12-20",
      openRate: "60%",
      clickRate: "30%",
      status: "Completed",
    },
  ];

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      {/* Quick Actions */}
      <div className="mb-6 flex flex-wrap gap-4 flex-row justify-between items-center">
        <h1 className="text-3xl font-bold mb-4 text-gray-800">
          Email Dashboard
        </h1>

        <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
          + New Campaign
        </button>
      </div>

      {/* Campaign List */}
      <div className="bg-white shadow rounded-lg p-4">
        <h2 className="text-xl font-semibold mb-4 text-gray-700">
          Recent Campaigns
        </h2>
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto border-collapse">
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left">Name</th>
                <th className="py-3 px-6 text-left">Subject</th>
                <th className="py-3 px-6 text-left">Sent Date</th>
                <th className="py-3 px-6 text-center">Open Rate</th>
                <th className="py-3 px-6 text-center">Click Rate</th>
                <th className="py-3 px-6 text-center">Status</th>
                <th className="py-3 px-6 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {campaigns.map((campaign) => (
                <tr
                  key={campaign.id}
                  className="border-b hover:bg-gray-100 text-gray-700"
                >
                  <td className="py-3 px-6 text-left">{campaign.name}</td>
                  <td className="py-3 px-6 text-left">{campaign.subject}</td>
                  <td className="py-3 px-6 text-left">{campaign.sentDate}</td>
                  <td className="py-3 px-6 text-center">{campaign.openRate}</td>
                  <td className="py-3 px-6 text-center">
                    {campaign.clickRate}
                  </td>
                  <td className="py-3 px-6 text-center">
                    <span
                      className={`px-2 py-1 rounded ${
                        campaign.status === "Completed"
                          ? "bg-green-100 text-green-600"
                          : "bg-yellow-100 text-yellow-600"
                      }`}
                    >
                      {campaign.status}
                    </span>
                  </td>
                  <td className="py-3 px-6 text-center">
                    <button className="text-blue-500 hover:underline">
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EmailDashboard;
