import React from "react";
import { useModalContext } from "components/modal";
import { theme } from "styles/theme";
import { dollarFormatter } from "utils";
import { ProductionAssigneeSelect } from "./components/ProductionAssigneeSelect";
import { useTranslation } from 'react-i18next';
import { TTargetProduct }  from "types/product";

interface EachProductProps extends TTargetProduct {
  priceType?: "list" | "sale" | "install";
  rateLabel: "list_price" | "unit_price" | "install_price";
  hideEmpty: boolean;
}

const EachProduct: React.FC<EachProductProps> = (props) => {
  const { t } = useTranslation();
  const tBase = "containers.lineItems.eachProduct";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext();

  if (!props.quantity && props.hideEmpty) return null;
 
  const handleClickProductionAssignee = () => {
    const targetId = parseInt(props.id);
    setModal({
      label: tr("Assign Production"),
      component: <ProductionAssigneeSelect targetId={targetId} />,
    });
  };

  return (
    <div className="py-2 px-0 flex items-center ml-2">
      <div style={{ flex: "1" }}>
        <div>
          <span>
            {props.quantity}&nbsp; &#10761; &nbsp;
            {props.label}
            <span> • </span>
            <span className="text-sm font-bold text-slate-500">
              {props.long_description}
            </span>
          </span>
          &nbsp; &nbsp;
          <span
            style={{
              flex: "1",
              color: theme.color.subtext3,
              fontSize: theme.fontSize.xsmall,
            }}
          >
            {`${tr("List")}: ${dollarFormatter(
              props.list_price
            )} | ${tr("Sale")}: ${dollarFormatter(
              props.unit_price
            )} | ${tr("Install")}: ${dollarFormatter(props.install_price)}`}
          </span>
        </div>
        <div className="flex items-center">
          <div className="text-xs text-secondary">
            {/* {dollarFormatter(props.production_value)} */}
          </div>
          <div className="flex ml-0 text-[12px] font-bold text-slate-500">
            {`${tr("Added by")} ${props.tech}`}
            <button
              className="ml-2 text-[10px] text-primary hover:text-slate-400 focus:outline-none"
              onClick={handleClickProductionAssignee}
            >
              {tr("Edit")}
            </button>
          </div>
        </div>
      </div>

      <span style={{ float: "right" }}>
        {dollarFormatter(props.quantity * +(props[props.rateLabel] || 0))}
      </span>
    </div>
  );
}

export default EachProduct;