import axios from "axios";
import BreadCrumbs from "components/breadcrumbs";
import { Button, NoStyleA, OutlineA, OutlineButton } from "components/button";
import Dropdown from "components/dropdown";
import Failed from "components/failed";
import Spinner from "components/spinner";
import Tab from "components/tab";
import { RequestStatusTag } from "components/tag/index";
import useWindowDimensions from "hooks/useWindowDimensions";
import * as React from "react";
import { RiAddFill, RiArrowDownSLine, RiArrowRightLine } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { TCustomer } from "types/customer";
import { TEstimate } from "types/estimate";
import { TResponse } from "types/response";
import { useModalContext } from "components/modal";
import RequestModal from "../requests/newRequestModal";
import LineItemsContainer from "containers/lineItems";
import RequestNotes from "./container/notes";
import RequestFilesTab from "./container/files";
import RequestActivity from "./container/activity";
import ResponsesCard from "./container/responses";
import RequestReminders from "./container/reminders";
import AppointmentCard from "../../containers/appointmentCard";
import Estimates from "containers/estimatesListV2";
import AddChildModal from "./container/addToRequest";
import InvoicesContainer from "containers/invoicesContainer";
import queryString from "query-string";
import CustomerContainer from "containers/customer";
import NewPaymentModal from "views/payment/newPaymentModal";
import Payments from "containers/payments";
import { TPayment } from "types/payment";
import { dollarFormatter } from "utils";
import ApplyDepositModal from "containers/payments/applyDepositModal";
import { useAreYouSure } from "components/areYouSure";
import CancelRequestModal from "./cancelRequestModal";
import LineItemsTab from "./lineItemsTab";
import RequestCustomFields from "./container/custom-fields";
import Assignee from "components/react-select/assignees";
import DivisionDropdown from "components/react-select/division";
import WarrantyTab from "containers/warrantyTab";
import { useQueryClient } from "react-query";
import AddAppointment from "../../containers/appointmentCard/appointment/appointmentModal";
import TimeInStatus from "components/timeinstatus";
import { isNullOrUndefined } from "util";
import TooltippedElement from "components/TooltippedElement";
import { useTranslation } from 'react-i18next';

type TRequest = {
  customer: TCustomer & { address_id: number; address: string };
  estimates: TEstimate[];
  payments: TPayment[] | null;
  fileCount: number;
  id: string;
  invoices: any;
  link: string;
  notes_count: string;
  status: string;
  responses: TResponse[];
  fk_address_id: string;
  cancel_reason: string | null;
  attention: boolean;
  manager: { value: string; label: string } | null;
  fk_division_id: string | null;
  warranty_count: number;
  propdate: Date;
  bookit: boolean;
  total_amount_due: number;
};

type Tab =
  | "overview"
  | "responses"
  | "notes"
  | "reminders"
  | "files"
  | "activity"
  | "line_items";

export default function Request() {
  const { t } = useTranslation();
  const tBase = "views.request.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { id, category, categoryId } = useParams<{
      id: string;
      category: string;
      categoryId: string;
    }>(),
    dimensions = useWindowDimensions(),
    modal = useModalContext(),
    history = useHistory(),
    areYouSure = useAreYouSure(),
    searchParams = useLocation().search;

  const [bookit, setBookit] = React.useState(false);

  const findBookitQuestion = (responseArr: any) => {
    if (!responseArr || responseArr.length === 0) return;
    let dateQuestion = null;
    dateQuestion = responseArr.find(
      (response: any) => response.type === "CALENDAR"
    );
    if (dateQuestion) setBookit(true);
  };

  let search = queryString.parse(searchParams);

  const query = useQuery<TRequest>(
    ["requestView", id],
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/request/requestView?id=${id}`
        )
        .then((res) => {
          findBookitQuestion(res.data.responses);
          return res.data;
        })
  );

  const mutate = useMutation(
    async (body: { status: string; id: string }) =>
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/request/changeStatus`,
        body
      ),
    {
      onSuccess: () => query.refetch(),
    }
  );
  const requiresAttention = useMutation(
    async () =>
      await axios
        .post(
          `
        ${process.env.REACT_APP_SERVER_URL}/api/v1/company/request/${id}/attention
        `
        )
        .then((res) => res.data),
    {
      onSuccess: () => query.refetch(),
    }
  );

  if (query.isLoading) return <Spinner />;

  if (!query.data) return <Failed />;

  const data = query.data;
  data["bookit"] = bookit;

  // console.log("data", data);

  let baseTab: any = {
    overview: {
      tab: tr("Overview"),
      content: <Overview {...data} />,
    },
    line_items: {
      tab: tr("Line Items"),
      content: <LineItemsTab id={id} />,
    },
    warranty: {
      tab: tr("Warranty"),
      count: data.warranty_count,
      content: <WarrantyTab id={id} type="request" />,
    },
  };
  let secondaryTab: any = {
    responses: {
      tab: tr("Responses"),
      content: <ResponsesCard questions={data.responses} id={id} />,
    },
    notes: {
      tab: tr("Notes"),
      count: data.notes_count,
      content: <RequestNotes id={id} type="request" />,
    },
    reminders: {
      tab: tr("Reminders"),
      content: <RequestReminders id={+id} type="request" />,
    },
    files: {
      tab: tr("Files"),
      count: data.fileCount,
      content: <RequestFilesTab id={id} type="request" />,
    },
    // timeWorked: {
    //   tab: "Time Worked",
    //   content: <TimeInStatus reqID={id} />,
    // },
    activity: {
      tab: tr("Activity"),
      content: <RequestActivity requestId={id} />,
    },
    custom_fields: {
      tab: tr("Custom Fields"),
      content: <RequestCustomFields requestId={id} />,
    },
  };
  let tabData: any = {
    ...baseTab,
    ...secondaryTab,
  };

  const tab =
    search.tab && !Array.isArray(search.tab) ? search.tab : "overview";

  const handleStatusChange = async (status: string) => {
    try {
      mutate.mutate({ status: status, id });
    } catch (e) {
      console.error(e);
    }
  };

  const customButton = data.status !== "completed" &&
    data.status !== "canceled" && (
      <Button
        className="w-full md:w-auto"
        primary
        isLoading={mutate.isLoading}
        onClick={() => handleStatusChange("completed")}
      >
        {tr("Mark as Completed")}
      </Button>
    );

  const buttons = [
    {
      button: [
        {
          label: tr("Deposit"),
          onClick: () =>
            modal.setModal({
              component: (
                <NewPaymentModal id={data.id} type="request" is_deposit customerId={data.customer.id} />
              ),
              label: tr("New Payment"),
            }),
        },
        {
          isLoading: requiresAttention.isLoading,
          onClick: () => {
            requiresAttention.mutate();
          },
          label: `${data.attention ? tr("Unmark") : tr("Mark")} ${tr("Requires Attention")}`,
        },
        {
          label: tr("Update Customer"),
          onClick: () =>
            modal.setModal({
              component: (
                <RequestModal
                  requestId={data.id}
                  customerId={data.customer.id}
                  addressId={data.customer.address_id}
                />
              ),
              label: tr("Update Request"),
            }),
        },
        data.status === "canceled"
          ? {
              label: tr("Reopen Job"),
              isLoading: mutate.isLoading,
              onClick: () => {
                handleStatusChange("pending");
              },
            }
          : null,
        data.status !== "completed" && data.status !== "canceled"
          ? {
              label: tr("Cancel Job"),
              isLoading: mutate.isLoading,
              onClick: () => {
                modal.setModal({
                  component: <CancelRequestModal id={id} />,
                  label: tr("Cancel Request"),
                });
              },
              style: { color: "red" },
            }
          : null,
      ],
    },
  ];
  return (
    <div
      className={`grow relative flex p-4 space-x-4 ${
        data.status === "canceled"
          ? "bg-red-100/10 border-red-900 border-solid border-4"
          : data.status === "completed"
          ? "bg-green-100/10 border-green-900 border-solid border-4"
          : ""
      }`}
    >
      {dimensions.width >= 1200 && (
        <div className="border p-4 rounded-md self-start flex-[0_0_25%]">
          <CustomerContainer
            data={data.customer}
            selected_address={data.fk_address_id}
          />
          <Tab
            style={{
              main: { maxWidth: 450 },
              tab: { overflowX: "scroll" },
            }}
            data={secondaryTab}
            defaultTab="responses"
          />
        </div>
      )}

      <div className="flex-auto space-y-4 overflow-hidden">
        <div className="flex flex-col justify-between space-y-4 md:flex-row md:space-y-0">
          <div>
            <BreadCrumbs link={[{ label: tr("Requests"), to: "/requests" }]} />
            <div className="space-x-1 text-baseline">
              <span className="text-3xl">{tr("Request")} #{data?.id}</span>
              <span className="text-xl font-light text-slate-600">{tr("for")}</span>
              <NoStyleA to={`/customers/${data?.customer?.id}`}>
                <span className="text-xl font-medium text-blue-800 ">
                  {data?.customer?.name}
                </span>
              </NoStyleA>
              <RequestStatusTag>{tr(data?.status)}</RequestStatusTag>
              <span className="text-slate-500">{data?.link || tr("General")}</span>
            </div>
          </div>

          <div className="flex space-x-2">
            {
              <>
                {customButton}
                <Dropdown
                  reference={
                    <Button>
                      {tr("Action")}
                      <RiArrowDownSLine />
                    </Button>
                  }
                  data={buttons}
                />
              </>
            }
          </div>
        </div>
        {data.attention && (
          <span className="p-1 px-4 text-xs font-bold text-white uppercase bg-red-600 rounded-full">
            {tr("Attention!")}
          </span>
        )}
        {data.cancel_reason && (
          <div className="p-2 text-red-900 bg-red-100 border border-red-900 border-solid rounded">
            {data.cancel_reason}
          </div>
        )}

        <Tab
          style={{
            main: {
              // position: "sticky",
              // top: theme.height.navigation,
            },
          }}
          data={dimensions.width < 1200 ? tabData : baseTab}
          defaultTab={tab}
          // @ts-ignore
          onClick={(key: Tab) =>
            history.push({
              pathname: `/requests/${id}`,
              search: queryString.stringify({
                ...search,
                tab: key,
              }),
            })
          } // setTab(key)}
        />
      </div>
    </div>
  );
}

const Overview = (props: TRequest) => {
  const { t } = useTranslation();
  const tBase = "views.request.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext();
  const queryClient = useQueryClient();
  const modal = useModalContext(),
    dimensions = useWindowDimensions();
  const responses = props.responses || [];

  const [managers, setManagers] =
      React.useState<{ value: string; label: string }[]>(),
    [division, setDivision] = React.useState<string | null>();

  const updateManager = useMutation(
    async (body: { value: string; label: string }[]) =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/request/${props.id}/manager
  `,
          body
        )
        .then((res) => res.data)
  );

  const updateDivision = useMutation(
    async (body: { value: string; label: string }) =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/request/${props.id}/division
  `,
          body
        )
        .then((res) => res.data)
  );

  const handleManager = async (e: { value: string; label: string }[]) => {
    updateManager.mutate(e);
    setManagers(e);
  };

  const handleDivision = async (e: { value: string; label: string }) => {
    updateDivision.mutate(e);
    setDivision(e.value);
  };

  React.useEffect(() => {
    setManagers(props.manager ? [props.manager] : []);
    setDivision(props.fk_division_id || null);
  }, [props]);

  const handleAddWorkOrder = (type: "estimates" | "jobs") => {
    modal.setModal({
      component: <AddChildModal id={+props.id} type={type} />,
      label: `${tr("Add")} ${type} ${tr("to request")}`,
    });
  };

  const customerCredit = useQuery<number | null>(
    ["customer credit", props.customer.id],
    async () =>
      props.customer.id
        ? await axios
            .get(
              `${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/${props.customer.id}/credit`
            )
            .then((res) => res.data)
        : null
  );

  const proposedDateexist = () => {
    if (props.propdate !== null) {
      return "block";
    }
    return "hidden";
  };

  return (
    <div className={`space-y-4 `}>
      {dimensions.width < 1200 && (
        <CustomerContainer
          data={props.customer}
          selected_address={props.fk_address_id}
        />
      )}

      <div className="divide-y">
        <div className="py-4">
          <h2>{tr("Summary")}</h2>
        </div>
        <div className="grid grid-cols-2 py-4 space-x-4">
          <Assignee
            label={tr("Manager/Designer")}
            value={managers}
            onChange={handleManager}
          />
          <div>
            <DivisionDropdown
              label={tr("Division")}
              value={division}
              onChange={handleDivision}
            />
          </div>
          {/* <div
          style={{
            display:proposedDateexist()
          }}
          >
            <p>This Request has a date proposed by the customer</p>
          </div> */}
          {/* <div
          style={{
            display:proposedDateexist()
          }}
          >
           <Button
             primary
             onClick={() =>{
              setModal({
                component: (
                  <AddAppointment
                    appointmentId={undefined} 
                    appointment={undefined}
                    defaultdate={props.propdate} 
                    {...{
                    parentId: null,
                    type: null,
                    id: 1,
                  }}
                  closed={() => {
                    queryClient.invalidateQueries();
                  }}
                  emailDefault={true}                  
                  />
                ),
                label: "Add Appointment",
              })}}
           >Auto-schedule Appointment for proposed date</Button>
          </div> */}
        </div>
      </div>

      <AppointmentCard
        id={props.id}
        type="requests"
        isBookit={props.bookit}
        responses={responses}
        requestId={props.id}
      />
      <div className="space-y-4">
        <div className="flex items-center justify-between py-4 border-b">
          <h2>{tr("Estimates")}</h2>
          <TooltippedElement
            message={tr("Add Estimate")}
            element={
              <OutlineButton
                style={{ marginLeft: "auto" }}
                onClick={() => handleAddWorkOrder("estimates")}
              >
                <RiAddFill />
              </OutlineButton>
            }
          />
        </div>
        <div>
          <Estimates id={props.id} data={props.estimates} type="requests" />
        </div>
      </div>
      <div className="space-y-4">
        <div className="flex items-center justify-between py-4 border-b">
          <h2>{tr("Payments")}</h2>
          {/* <OutlineButton
            style={{ marginLeft: "auto" }}
            onClick={() => handleAddWorkOrder("estimates")}
          >
            <RiAddFill />
            New Payment
          </OutlineButton> */}
        </div>
        <div>
          {customerCredit.data !== 0 && (
            <div className="flex justify-between p-2 text-green-900 bg-green-100 rounded">
              <div className="flex flex-col">
                <span className="text-xs">{tr("Customer Credit")}</span>
                <span className="text-lg">
                  {dollarFormatter(customerCredit.data)}
                </span>
              </div>
              <div>
                <OutlineA
                  primary
                  className="!bg-transparent"
                  to={`/customers/${props.customer.id}`}
                >
                  {tr("Apply To")} <RiArrowRightLine />
                </OutlineA>
              </div>
            </div>
          )}
        </div>
          <Payments data={props.payments} type="request" />
      </div>
      <InvoicesContainer type="request" id={props.id} />
    </div>
  );
};
