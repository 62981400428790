/**
 * Used to get the contrast text color for a given hex color either white || black depending on contrast
 * @param hexcolor 
 * @returns white || black
 */

function getContrastTextColorHEX(hexcolor: string): "white" | "black" {
  if (!hexcolor || hexcolor === "transparent") return "black";
  const r = parseInt(hexcolor.substr(1, 2), 16);
  const g = parseInt(hexcolor.substr(3, 2), 16);
  const b = parseInt(hexcolor.substr(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
}

function getContrastTextColorRGB(rgbString: string): "white" | "black" {
  // Split the string into an array and convert each element to a number
  const [r, g, b] = rgbString.split(",").map(Number);

  // Ensure RGB values are within the valid range (0-255)
  const red = Math.max(0, Math.min(255, r));
  const green = Math.max(0, Math.min(255, g));
  const blue = Math.max(0, Math.min(255, b));

  // Calculate luminance
  const yiq = (red * 299 + green * 587 + blue * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
};

function checkForColor(color: string | undefined): string {
  if (!color) {
    return "#ccc";
  } else {
    return color;
  }
};

export {
  getContrastTextColorHEX,
  getContrastTextColorRGB,
  checkForColor
};