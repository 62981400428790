import styled, { css } from "styled-components";
import { theme } from "styles/theme";

export interface Props {
    count?: Count[] | null;
    query?: Query;
    categories?: Category[];
    selected?: string;
    handleStatusClick: (status: string) => void;
    search: any;
}

export interface Tag {
    id: number;
    label: string;
}

export type WorkOrderProps = {
    id: number;
    description: string;
    entry_date: Date;
    appointment_count: number;
    completed_count: number;
    status: { label: string; color: string };
};

export interface List {
    id: number;
    entry_date: Date;
    name: string;
    address: string;
    status: string;
    tags: Tag[];
    link: string;
    children?: WorkOrderProps[];
}

export interface Count {
    status: string;
    count: number;
}

export interface Category {
    link: string;
}

export interface Prop {
    list: List[];
    count: Count[];
    categories: Category[];
}

export interface Query {
    category?: string[];
    status?: string[];
    page?: string[];
    amountMin?: string[] | null;
    amountMax?: string[] | null;
    balanceMin?: string[] | null;
    balanceMax?: string[] | null;
}

const AmountRange = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    gap: 0;
    margin: 1rem 0;
`;

const BalanceRange = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    gap: 0;
    margin: 1rem 0;
`;

const Con = styled.div`
    display: flex;
    flex-direction: column;
`;

const ListCategory = styled.h4`
    padding: 0;
    margin: 1rem 1rem 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const List = styled.div<{ clicked?: boolean }>`
    font-size: 0.9rem;
    display: flex;
    color: #2e2e2e;
    text-transform: capitalize;
    /* border-bottom: 1px solid #d6dbdf; */
    padding: 0.7rem 1rem;
    cursor: pointer;
    &:hover {
        background: #f1f4f9;
    }
    > div:last-child {
        padding: 0 0.5rem;
        border-radius: 10px;
        margin-left: auto;
    }
    ${(props) =>
        props.clicked &&
        css`
            font-weight: bold;
            background: ${theme.bg.blue};
            color: ${theme.color.main};
        `}
`;

const CheckboxRow = styled.li`
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    > input {
        margin-right: 0.5rem;
    }
`;

export {
    AmountRange,
    BalanceRange,
    Con,
    ListCategory,
    List,
    CheckboxRow
};
