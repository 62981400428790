/* Created by Hannah Green on 7/22/2021 for ES-174 */

/* This is a class because the react-to-print library does not work with 
functions very well and whatever is being printed HAS to be a class */
import React, { useRef, forwardRef } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";
import ReactToPrint from "react-to-print";
import { PrintButton, PrintCon } from "./styles";
import { useTranslation } from "react-i18next";

const UserInfo = styled.div`
  width: 100%;
  background-color: #ebedef;
  border: 1px solid #ebedef;
  border-radius: 5px;
  padding: 10px;

  > label {
    font-weight: bold;
    padding-left: 5px;
  }

  > div {
    width: 450px;
    padding-left: 10px;
  }
`;

const formatDate = (date) => {
  const oldDate = new Date(date);
  return oldDate.toLocaleDateString();
};

const CheckRowDivider = (props) => {
  return (
    <>
      <TableRow>
        <TableCell variant="head">{props.label}</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
    </>
  );
};

const CheckRow = (props) => {
  let check = false;

  if (props.value === "G") {
    check = true;
  }

  return (
    <>
      <TableRow>
        <TableCell>{props.label}</TableCell>
        {check && (
          <>
            <TableCell align="center">{<CheckIcon />}</TableCell>
            <TableCell align="center"></TableCell>
          </>
        )}

        {!check && (
          <>
            <TableCell align="center"></TableCell>
            <TableCell align="center">{<CloseIcon />}</TableCell>
          </>
        )}
      </TableRow>
    </>
  );
};

const CheckTableHeader = () => {
  const { t } = useTranslation();
  const tBase = "views.vehicle_inspection.dashboard.inspection_details_table";
  const tr = (key) => t(`${tBase}.${key}`);

  return (
    <TableHead>
      <TableRow>
        <TableCell align="center"></TableCell>
        <TableCell align="center">{tr("Good")}</TableCell>
        <TableCell align="center">{tr("Needs Checked")}</TableCell>
      </TableRow>
    </TableHead>
  );
};

const PrintTable = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const tBase = "views.vehicle_inspection.dashboard.inspection_details_table";
  const tr = (key) => t(`${tBase}.${key}`);

  return (
    <div ref={ref}>
      <UserInfo>
        <h3 align="center">{tr("Vehicle Information")}</h3>

        <label>{tr("Vehicle Number")}</label>
        <div>{props.inspection.vehicle_number}</div>

        <label>{tr("Mileage")}</label>
        <div>{props.inspection.mileage}</div>

        <label>{tr("Tech Name")}</label>
        <div>{props.inspection.tech_name}</div>

        <label>{tr("Inspection Date")}</label>
        <div>{formatDate(props.inspection.inspection_date)}</div>

        {props.inspection.description && (
          <>
            <label>{tr("Description")}</label>
            <div>{props.inspection.description}</div>
          </>
        )}

        {props.inspection.is_reviewed &&
          props.inspection.comments && (
            <>
              <label>{tr("Comments")}</label>
              <div>{props.inspection.comments}</div>
            </>
          )}

        {props.inspection.is_reviewed && props.inspection.notes && (
          <>
            <label>{tr("Maintenance Notes")}</label>
            <div>{props.inspection.notes}</div>
          </>
        )}
      </UserInfo>

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <CheckTableHeader />
          <TableBody>
            <CheckRowDivider label={tr("Maintenance")} />
            <CheckRow label={tr("Belts")} value={props.inspection.belts} />
            <CheckRow
              label={tr("Lights")}
              value={props.inspection.lights}
            />
            <CheckRow label={tr("Leaks")} value={props.inspection.leaks} />
            <CheckRow
              label={tr("Wipers")}
              value={props.inspection.wipers}
            />
            <CheckRow
              label={tr("Mirrors")}
              value={props.inspection.mirrors}
            />
            <CheckRow
              label={tr("Tire Wear")}
              value={props.inspection.tire_wear}
            />
            <CheckRowDivider label={tr("Fluid Levels")} />
            <CheckRow
              label={tr("Power Steering")}
              value={props.inspection.power_steering}
            />
            <CheckRow label={tr("Oil")} value={props.inspection.oil} />
            <CheckRow
              label={tr("Washer Fluid")}
              value={props.inspection.washer_fluid}
            />
            <CheckRow
              label={tr("Antifreeze")}
              value={props.inspection.antifreeze}
            />
            <CheckRow
              label={tr("Transmission Fluid")}
              value={props.inspection.trans_fluid}
            />
            <CheckRow
              label={tr("Brake Fluid")}
              value={props.inspection.brake_fluid}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
});

export const DetailsTable = (props) => {
  const { t } = useTranslation();
  const tBase = "views.vehicle_inspection.dashboard.inspection_details_table";
  const tr = (key) => t(`${tBase}.${key}`);

    const componentRef = useRef();
  
    return (
      <>
        <PrintCon>
          <ReactToPrint
            trigger={() => (
              <PrintButton primary>{tr("Print Details")}</PrintButton>
            )}
            content={() => componentRef.current}
          />
        </PrintCon>
  
        <PrintTable
          ref={componentRef}
          inspection={props.inspection}
        />
      </>
    );
  };
