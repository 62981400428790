import { useState } from "react";
import TimePicker from "react-time-picker";
import axios from "axios";
import { MdOpenInNew } from "react-icons/md";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from 'react-i18next';

import { Button, IconButton } from "components/button";
import { formatTime } from "utils/formatTime";

import styles from "./AppointmentRow.module.scss";
import Assignee from "components/react-select/assignees";
import Switch from "rc-switch";
import _ from "lodash";
import { Input } from "components/input";
import twodigitmonth from "utils/twodigitmonth";
import { convertFromUTC, convertToUTC } from "utils/utcConversion";
import { useAuthContext } from "contexts/auth";

function AppointmentRow({
	marker,
	markers,
	setMarkers,
	optimizeState,
	optimizeDispatch,
}) {

	const { t } = useTranslation();
	const tBase = "views.appointments.MapComponents.AppointmentRow";
	const tr = (key) => t(`${tBase}.${key}`);
	const { timezone } = useAuthContext();
	
	const start_time = marker.start_time_utc && convertFromUTC(marker.start_time_utc, timezone);
	const startStartWithoutAMPM = marker.start_time_utc && convertFromUTC(marker.start_time_utc, timezone, false);
	const end_time = marker.end_time_utc && convertFromUTC(marker.end_time_utc, timezone);

	const [timeValue, setTimeValue] = useState(startStartWithoutAMPM || null);
	// const [timeValue, setTimeValue] = useState(marker.start_time || null);

	const [assigned, setAssigned] = useState(
		marker.assignees?.map((a) => ({
			value: a.user_id,
			label: a.name,
			color: a.color,
		})) || []
	);

	const {
		assignees,
		fk_project_id: projectId,
		fk_request_id: requestId,
	} = marker;

	const updateAppointment = async (startTime, assignees) => {
		try {
			const regularDate = marker.date.split("T")[0];
			// const saveableTime = marker.start_time_utc && convertToUTC(regularDate, startTime, timezone);
			const saveableTime = convertToUTC(regularDate, startTime, timezone);
			const response = await axios.put(
				`${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment-map/updateAppointment`,
				{
					startTime: saveableTime,
					appointmentId: marker.id,
					assignees: assignees,
				}
			);

			if (response.status === 200) {
				setMarkers((prev) => {
					return [
						...prev.filter(
							(m) => parseInt(m.id) != parseInt(marker.id)
						),
						{
							...marker,
							start_time_utc: saveableTime,
							assignees: response.data,
						},
					];
				});
				setBackgroundColor(
					response.data &&
						response.data.length === 1 &&
						response.data[0] &&
						response.data[0].hasOwnProperty("color") &&
						response.data[0].color
						? response.data[0].color
						: `rgba(50, 50, 50, 0.8)`
				);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const getAddress = () => {
		let address = null;

		if (
			marker.request_details &&
			marker.request_details[0] &&
			marker.request_details[0].customer_address
		) {
			address = marker.request_details[0].customer_address;
		} else if (
			marker.project_details &&
			marker.project_details[0] &&
			marker.project_details[0].customer_address
		) {
			address = marker.project_details[0].customer_address;
		}

		return address;
	};

	const address = getAddress();

	const handleOpenAppointment = (id, type) => {
		window.open(`/${type}/${id}`, "_blank");
	};

	const [backgroundColor, setBackgroundColor] = useState(
		assignees &&
			assignees.length === 1 &&
			assignees[0] &&
			assignees[0].hasOwnProperty("color") &&
			assignees[0].color
			? assignees[0].color
			: `rgba(50, 50, 50, 0.8)`
	);
	const isSelected = _.findIndex(optimizeState, {
		address: getAddress(),
	}) > -1;
	if (!markers) return null;

	return (
		<div
			className="flex flex-row py-[10px] border border-[rgba(180,180,180)] relative"
			style={{
				borderLeftColor: backgroundColor,
				borderLeftWidth: 15,
				opacity: isSelected || optimizeState.length<1 ? 1 : 0.5,
			}}
		>
			<div className="flex flex-1 flex-row items-center justify-center text-[20px] font-[800] mx-[15px]">
				<div className="flex items-center justify-center self-center h-[25px] w-[25px] -ml-[28px]">
					{_.find(optimizeState, { address: address }) &&
					_.find(optimizeState, { address: address }).order ? (
						<div className={styles.order}>
							{_.find(optimizeState, { address: address })
								?.isStart &&
							_.find(optimizeState, { address: address })?.isEnd
								? `1,${
										_.find(optimizeState, {
											address: address,
										})?.order
								  }`
								: _.find(optimizeState, { address: address })
										?.order}
						</div>
					) : null}
				</div>
				<div
					onClick={() => {
						if (
							_.findIndex(optimizeState, { address: address }) ===
							-1
						) {
							optimizeDispatch({
								type: "ADD",
								payload: { address, center: marker.center },
							});
						} else {
							optimizeDispatch({
								type: "REMOVE",
								payload: { address },
							});
						}
					}}
					className={styles.countRow}
				>
					<Checkbox
						checked={
							_.findIndex(optimizeState, { address: address }) >
							-1
						}
					/>
				</div>
				<div className={styles.timeSwitchGroup}>
					<div className={styles.timeSwitchRow}>
						<Switch
							checked={
								_.find(optimizeState, { address: address }) &&
								_.find(optimizeState, { address: address })
									.isStart
							}
							onChange={() => {
								if (
									_.find(optimizeState, {
										address: address,
									}) &&
									_.find(optimizeState, { address: address })
										.isStart
								) {
									optimizeDispatch({
										type: "REMOVE_START",
									});
								} else {
									optimizeDispatch({
										type: "ADD_START",
										payload: {
											address,
											center: marker.center,
										},
									});
								}
							}}
						/>
						<div className={styles.timeSwitchText}>{tr("Start")}</div>
					</div>
					<div className={styles.timeSwitchRow}>
						<Switch
							checked={
								_.find(optimizeState, { address: address }) &&
								_.find(optimizeState, { address: address })
									.isEnd
							}
							onChange={() => {
								if (
									_.find(optimizeState, {
										address: address,
									}) &&
									_.find(optimizeState, { address: address })
										.isEnd
								) {
									optimizeDispatch({
										type: "REMOVE_END",
									});
								} else {
									optimizeDispatch({
										type: "ADD_END",
										payload: {
											address,
											center: marker.center,
										},
									});
								}
							}}
						/>
						<div className={styles.timeSwitchText}>{tr("End")}</div>
					</div>
				</div>
			</div>

			<div className="flex">
				<div className="flex items-center">

					<div className="flex flex-col">
						{address ? (
							<div className={styles.address}>{address}</div>
						) : (
							<div className={styles.address}>{tr("No Address")}</div>
						)}

						<div className="flex">
							{start_time ? (
								<div>{start_time}</div>
							) : null}
							{start_time && end_time ? (
								<div>{` - `}</div>
							) : null}
							{end_time ? (
								<div>{end_time}</div>
							) : null}
						</div>
						{marker.duration ? (
							<div>{`${marker.duration / 60} ${
								marker.duration / 60 === 1 ? `hour` : `hours`
							}`}</div>
						) : null}
					</div>
				</div>
				<div className="flex items-center justify-between pr-5">
					<div className="flex flex-wrap">
						<div className="text-[12px] mr-[5px] text-center self-center">
							{tr("Start")}:
						</div>
						<Input
							key={"start-" + timeValue}
							defaultValue={timeValue}
							type="time"
							onChange={(e) => setTimeValue(e.target.value)}
						/>
						{/* <TimePicker
							label="Start Time"
							clockIcon={null}
							clearIcon={null}
							onChange={(e) => {
								setTimeValue(`${e}-${timeOffset}`);
							}}
							value={timeValue}
							className={styles.startTimeWrapper}
							wrapperClassName={styles.startTimeWrapper}
						/> */}
						<Assignee
							value={assigned}
							style={{ marginTop: 5, marginRight: 10 }}
							assigneeType="appointment"
							wrapperClassName={styles.assigneePickerWrapper}
							className={styles.assigneePicker}
							onChange={setAssigned}
						/>
					</div>
					<div className="self-end">
						<Button onClick={() => { updateAppointment(timeValue, assigned) }}>
							{tr("Save")}
						</Button>
					</div>
					<div className="absolute top-1 right-1">
						<div
							onClick={() => {
								if (projectId) {
									handleOpenAppointment(
										projectId,
										"projects"
									);
								} else if (requestId) {
									handleOpenAppointment(
										requestId,
										"requests"
									);
								} else {
									return null;
								}
							}}
							className="w-7 h-7 bg-[#ecf1f3] hover:bg-[#bec1c3] rounded-full flex items-center justify-center cursor-pointer"
						>
							<MdOpenInNew className="w-4 h-4" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AppointmentRow;