import { cloneDeep } from "lodash";
import { GroupLinkage, State, Questions, Category } from "./context";
// export const dataStructure = {
//     questions: [{
//         category: 'General',
//         questions: [{
//             title: '',
//             choices: [{
//                 label: ''
//             }]
//         }]
//     }],
//     snackBarUndoStatus: false,
//     selectedQuestion: {
//         category: 'General',
//         index: -1
//     },
//     groupLinkage: {
//         questionId: false,
//         optionLinkage: [{
//             optionId: '',
//             questionId: 0
//         }]
//     }
// }

// Future implementation: this is called by default when a question is added. Turn this into a default, with default values, that can be
// used for all other question types. As of now, it contains adds much more information than is what currently needed.
export const addMultiple = ({
  questions: tempQuestions,
  selectedQuestion: tempSelectedQuestions,
  ...state
}: State) => {
  // console.log(tempQuestions);
  let questions = cloneDeep(tempQuestions);
  let selectedQuestion = tempSelectedQuestions + 1;
  let uuid = new Date().getTime();
  let question = {
    title: "Untitled Question",
    type: "SINGLE",
    required: false,
    id: "Q-" + uuid,
    choices: [{ id: "C-" + uuid, label: "Option 1", subquestion: null }],
  };
  if (questions) {
    questions.splice(tempSelectedQuestions + 1, 0, question);
  } else {
    questions = [question];
  }

  return {
    ...state,
    questions,
    selectedQuestion,
  };
};

export type ChangeQuestionTitle = {
  type: "changeQuestionTitle";
  payload: string;
};

export const changeQuestionTitle = (
  { questions: tempQuestions, selectedQuestion, ...state }: State,
  { payload }: ChangeQuestionTitle
) => {
  let questions = cloneDeep(tempQuestions);
  questions[selectedQuestion]["title"] = payload;
  return {
    ...state,
    questions,
    selectedQuestion,
  };
};

export const addOption = ({
  questions: tempQuestions,
  selectedQuestion,
  ...state
}: State) => {
  let questions = cloneDeep(tempQuestions);
  let choices = questions[selectedQuestion]["choices"];
  let id = "C-" + new Date().getTime();
  choices.push({
    id,
    label: `Option ${choices.length + 1}`,
    subquestion: null,
  });
  return {
    ...state,
    questions,
    selectedQuestion,
    option: id,
  };
};

export const isSelectionAndGroupLinkageMatch = (
  selectedQuestion: number,
  groupLinkage: GroupLinkage
) => {
  let { questionId: linkageIndex } = groupLinkage;
  return selectedQuestion === linkageIndex;
};

const findOptionIndex = (
  array: Questions[],
  questionIndex: number,
  optionId: string
) => {
  let index = array[questionIndex]["choices"].findIndex(
    ({ id }) => id === optionId
  );
  return index;
};

export type HandleOptionEdit = {
  type: "handleOptionEdit";
  payload: {
    questionIndex: number;
    value: string;
    optionId: string;
  };
};

export const handleOptionEdit = (
  {
    groupLinkage: tempGroupLinkage,
    questions: tempQuestions,
    selectedQuestion,
    ...state
  }: State,
  { payload: { value, optionId } }: HandleOptionEdit
) => {
  let groupLinkage;
  let questions = cloneDeep(tempQuestions);
  let index = findOptionIndex(questions, selectedQuestion, optionId);

  let choice = questions[selectedQuestion]["choices"][index];
  choice.label = value;
  if (isSelectionAndGroupLinkageMatch(selectedQuestion, tempGroupLinkage)) {
    groupLinkage = { ...tempGroupLinkage };
    groupLinkage["optionLinkage"][index]["optionId"] = value;
  } else {
    groupLinkage = tempGroupLinkage;
  }

  return {
    ...state,
    questions,
    selectedQuestion,
    groupLinkage,
  };
};

export type HandleLeadChange = {
  type: "handleLeadChange";
  payload: {
    leadTime: number;
  };
};

export const handleLeadChange = (
  { questions: tempQuestions, selectedQuestion, ...state }: State,
  { payload: { leadTime } }: HandleLeadChange
) => {
  let questions = cloneDeep(tempQuestions);
  // console.log(questions);
  // let questions = cloneDeep(tempQuestions);
  // if (questions[selectedQuestion].choices === null) {
  //   let choices = questions[selectedQuestion]["choices"];
  //   choices[0] = {
  //     id: "C-" + new Date().getTime(),
  //     label: new Date().getDay().toLocaleString(),
  //     subquestion:null,
  //   }
  // }
  let choices = questions[selectedQuestion]["choices"];
  let id = "C-" + new Date().getTime();

  if (!choices) {
    choices = [];
  }

  choices[0] = {
    id,
    label: String(leadTime),
    subquestion: null,
  };

  // console.log(choices)

  // questions[selectedQuestion]["choices"].splice(1)
  return {
    ...state,
    questions,
    selectedQuestion,
    // option: id,
  };
};

export type HandleOptionToSubQuestion = {
  type: "handleOptionToSubQuestion";
  payload: {
    questionId: string;
    optionId: string;
    subquestion: string | null;
  };
};

export const handleOptionToSubQuestion = (
  state: State,
  payload: HandleOptionToSubQuestion
) => {
  const questions = cloneDeep(state.questions);

  let question = questions.find(
    (q) => q.id.toString() === payload.payload.questionId
  );

  let choice = question!.choices.find(
    (choice) => choice.id === payload.payload.optionId
  );

  choice!.subquestion = payload.payload.subquestion;

  return {
    ...state,
    questions,
  };
};

export type HandleSelectedQuestionChange = {
  type: "changeSelectedQuestion";
  index: number;
};

export const handleSelectedQuestionChange = (
  { selectedQuestion, ...state }: State,
  action: HandleSelectedQuestionChange
) => {
  const { index } = action;

  return {
    ...state,
    selectedQuestion: index,
  };
};

export type DeleteQuestion = {
  index: number;
  type: "deleteQuestion";
};
export const deleteQuestion = (
  {
    questions: tempQuestions,
    groupLinkage: tempGroupLinkage,
    selectedQuestion: tempSelectedQuestions,
    ...state
  }: State,
  { index }: DeleteQuestion
) => {
  let questions = cloneDeep(tempQuestions);
  let groupLinkage = cloneDeep(tempGroupLinkage);

  if (groupLinkage.questionId === questions[index].id) {
    groupLinkage = { questionId: undefined, optionLinkage: [] };
  }

  questions.splice(index, 1);
  // questions[index].status = "deleted"

  let selectedQuestion = Math.max(
    tempSelectedQuestions < index
      ? tempSelectedQuestions
      : tempSelectedQuestions - 1,
    0
  );

  return {
    ...state,
    questions,
    selectedQuestion,
    groupLinkage,
  };
};

export type DeleteOption = {
  type: "deleteOption";
  optionIndex: string;
};

export const deleteOption = (
  {
    questions: tempQuestions,
    groupLinkage: tempGroupLinkage,
    selectedQuestion,
    ...state
  }: State,
  { optionIndex }: DeleteOption
) => {
  let questions = cloneDeep(tempQuestions);
  let index = findOptionIndex(questions, selectedQuestion, optionIndex);
  questions[selectedQuestion]["choices"].splice(index, 1);

  let groupLinkage = cloneDeep(tempGroupLinkage);

  let linkageIndex = groupLinkage.optionLinkage.findIndex(
    (link) => link.optionId === optionIndex
  );
  if (linkageIndex !== -1) {
    groupLinkage.optionLinkage.splice(linkageIndex, 1);
  }
  return {
    ...state,
    selectedQuestion,
    questions,
    groupLinkage,
  };
};

export const handleLinkage = ({
  groupLinkage: tempGroupLinkage,
  questions,
  selectedQuestion,
  isPrimary,
  ...state
}: State) => {
  let groupLinkage = { ...tempGroupLinkage };
  let selected = questions[selectedQuestion];
  if (isPrimary && selected.type === "SINGLE") {
    if (groupLinkage.questionId && groupLinkage.questionId === selected.id) {
      // groupLinkage = { questionId: undefined, optionLinkage: [] };
    } else {
      let optionLinkage = selected.choices.map((choice) => ({
        optionId: choice.id,
      }));

      groupLinkage = { questionId: [...[selected.id]], optionLinkage };
    }
  }
  
  return {
    ...state,
    questions,
    selectedQuestion,
    groupLinkage,
    isPrimary,
  };
};

export type HandleLinkageSelect = {
  type: "handleLinkageSelect";
  optionId: string;
  value?: string;
};
export const handleLinkageSelect = (
  { groupLinkage: tempGroupLinkage, ...state }: State,
  { optionId, value }: HandleLinkageSelect
) => {
  let groupLinkage = { ...tempGroupLinkage };
  let { optionLinkage } = groupLinkage;
  let index = optionLinkage.findIndex((link) => optionId === link.optionId);
  if (index === -1) {
    optionLinkage.push({ optionId, groupId: value });
  } else {
    optionLinkage[index]["groupId"] = value;
  }

  return {
    ...state,
    groupLinkage,
  };
};

export type PopulateInitial = {
  type: "initialLoad";
  payload: {
    questions: Questions[];
    division: { id: number; label: string } | null;
  };
};

export const populateInitial = (
  state: State,
  { payload }: PopulateInitial
): State => {
  return {
    ...state,
    questions: payload.questions,
    initialQuestions: payload.questions,
    division: payload.division?.id || null,
  };
};

export type ChangeQuestionType = {
  type: "changeQuestionType";
  index: number;
  value: string;
};

export const changeQuestionType = (
  state: State,
  action: ChangeQuestionType
) => {
  const { value, index } = action;
  let questions = cloneDeep(state.questions);
  let question = questions[index];

  if (question.id !== state.groupLinkage.questionId) {
    question["type"] = value;
    if (value === "TEXT" || value === "IMAGE") {
      question["choices"] = [];
    }
  }

  return { ...state, questions };
};

export type HandleRequired = {
  type: "handleRequired";
  index: number;
};

export const handleRequired = (state: State, action: HandleRequired) => {
  const { index } = action;
  let questions = cloneDeep(state.questions);
  let required = questions[index]["required"];
  questions[index]["required"] = !required;
  return { ...state, questions };
};

export type HandleClick = {
  type: "handleClick";
  option: string;
};

export const handleClick = (state: State, action: HandleClick) => {
  const { option } = action;
  return { ...state, option };
};

export type SetCategory = {
  type: "setCategory";
  data: Category[] | null;
};

export const setCategory = (state: State, { data }: SetCategory) => {
  let category =
    data
      ?.filter((category) => !category.is_primary)
      ?.map((category) => {
        return {
          id: category.id,
          value: category.id,
          label: category.label,
        };
      }) || null;
  return { ...state, category };
};

export type SetSubquestions = {
  type: "setSubquestions";
  data: Category[] | null;
};

export const setSubquestions = (state: State, { data }: SetSubquestions) => {
  let subquestions =
    data?.map((category) => {
      return {
        id: category.id,
        value: category.id,
        label: category.label,
      };
    }) || null;
  return { ...state, subquestions };
};

export type SetIsPrimary = {
  type: "setIsPrimary";
  payload: boolean;
};

export const setIsPrimary = (state: State, action: SetIsPrimary) => {
  return { ...state, isPrimary: action.payload };
};

export type SetGroupLinkage = {
  type: "setGroupLinkage";
  payload?: {
    questionId: number;
    groupId: string | null;
    optionid: string;
  }[];
};

export const setGroupLinkage = (state: State, { payload }: SetGroupLinkage) => {
  let groupLinkage = {
    questionId:
    payload && payload.length > 0 ? 
      // each unique questionId should be added to the array
      [...new Set(payload.map(({ questionId }) => questionId))] : undefined,
    optionLinkage: payload?.map(({ questionId, ...option }) => option) || [],
  };
  return { ...state, groupLinkage };
};

export type ChangeDivision = {
  type: "changeDivision";
  payload: string;
};

export const changeDivision = (state: State, payload: ChangeDivision) => {
  return { ...state, division: +payload.payload };
};
