import Spinner from "components/spinner";
import { FormikProps } from "formik";
import { get } from "lodash";
import * as React from "react";
import { StyledComponent, StyledComponentProps } from "styled-components";
import { Size } from "types/theme";
import { InputProps } from ".";
import { InputCon, StyledInput } from "./style";

// export type TInput = React.HTMLProps<HTMLInputElement>;

export type TInput = Omit<
  StyledComponentProps<
    "input",
    any,
    {
      size?: string | undefined;
      error?: string | undefined;
      search?: boolean | undefined;
      $size?: Size;
    },
    never
  >,
  ""
> & {
  //   search?: boolean;
  isLoading?: boolean;
  label?: string;
  error?: string;
  size?: string;
  horizontal?: boolean;
  type?: string;
  value?: string | null | number;
  name?: string;
  formik?: FormikProps<any>;
  advancedNumericalInputHandling?: boolean;
  allowLeaders?: boolean;
  setValue?: any;
  backgroundColorFocus?: string;
};

const InputV2 = (
  props: TInput & { forwardedRef: React.ForwardedRef<HTMLInputElement> }
) => {
  const value =
    props.value ??
    (props.name && get(props.formik?.values, props.name)) ??
    (props.formik && "") ??
    undefined;

  // allows only one decimal point, and only digits, forbids leading decimal point and leading 0
  // EXCEPTION: allowLeaders permits one leading 0 or one leading decimal point
  if (props.advancedNumericalInputHandling) {
    return (
      <StyledInput
        backgroundColorFocus={props.backgroundColorFocus}
        onBlur={props.onBlur}
        style={props.style}
        placeholder={props.placeholder}
        value={props.value}
        maxLength={13} // 10 digits before decimal + 1 for the decimal point + 2 after decimal
        onChange={(e: any) => {
          let value = e.target.value;
          // allow only numbers and decimal point
          let updatedValue = value.replace(/[^0-9.]/g, "");

          if (!props.allowLeaders) {
            // prevent the value from starting with a decimal point
            if (updatedValue.startsWith(".")) {
              updatedValue = updatedValue.substring(1);
            }

            // prevent the value from beginning with 0
            if (updatedValue.startsWith("0") && updatedValue.length > 0) {
              updatedValue = updatedValue.substring(1);
            }
          }

          const parts = updatedValue.split(".");

          // no more than 10 digits before the decimal
          if (parts[0].length > 10) {
            parts[0] = parts[0].slice(0, 10);
          }

          // no more than 2 digits after the decimal
          if (parts.length > 1 && parts[1].length > 2) {
            parts[1] = parts[1].slice(0, 2);
          }

          // update the value based on the parts
          props.setValue(parts.length > 1 ? `${parts[0]}.${parts[1]}` : parts[0]);
        }}
      />
    )
  }

  return (
    <InputCon hasLogo={props.search}>
      <StyledInput
        onChange={props.formik?.handleChange}
        onBlur={props.formik?.handleBlur}
        value={value}
        $error={props.error}
        ref={props.forwardedRef}
        $size={props.$size || "regular"}
        {...props}
        className="bg-black"
      />
      {props.isLoading && <Spinner />}
    </InputCon>
  );
};

export default InputV2;
