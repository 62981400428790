import axios from "axios";
import { Button } from "components/button";
import Field from "components/field";
import { useModalContext } from "components/modal";
import Assignee from "components/react-select/assignees";
import DivisionDropdown from "components/react-select/division";
import { useFormik } from "formik";
import * as React from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { Checkbox } from "@mui/material";

type TReport = {
  assignee: string[] | null;
  division: string[] | null;
  start_date: string | null;
  end_date: string | null;
  only_canceled: boolean | undefined;
};

type Props = {
  type: "aging" | "invoices" | "sales" | "inventoryUsage" | "customerList" | "requestList" | "propertyList" | "invoiceList" | "appointmentList";
};

export default function NewReportModal(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.reports.newReportModal";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const excludeAssigneeAndDivision = ["customerList", "requestList", "propertyList", "invoiceList", "appointmentList"];

  const modal = useModalContext();

  const mutation = useMutation(
    async (e: TReport) =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/reports/${props.type}`,
          {
            params: {
              ...e,
              start_date: new Date(e.start_date + " 0:00").toISOString(),
              end_date: new Date(e.end_date + " 0:00").toISOString(),
            },
          }
        )
        .then((res) => res.data),
    {
      onSuccess: (url) => {
        modal.setModal();
        window.location.assign(url);
      },
    }
  );

  const initialValues = {
    assignee: null,
    division: null,
    start_date: null,
    end_date: null,
    only_canceled: false,
  };

  const onSubmit = (e: TReport) => {
    mutation.mutate(e);
  };

  const validationSchema = Yup.object({
    assignee: Yup.array(Yup.string()).nullable(),
    division: Yup.array(Yup.string()).nullable(),
    start_date: Yup.date().required(),
    end_date: Yup.date().required(),
    onlyCanceled: Yup.boolean(),
  });

  const formik = useFormik<TReport>({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <div className="w-[500px] flex flex-col">
      <form
        onSubmit={formik.handleSubmit}
        className="flex-1 flex flex-col space-y-4"
      >
        <div className="flex justify-between">
          <Field
            type="date"
            label={tr("Start Date")}
            name="start_date"
            className={"w-[48%]"}
            formik={formik}
          />
          <Field
            type="date"
            label={tr("End Date")}
            name="end_date"
            className={"w-[48%]"}
            formik={formik}
          />
        </div>
        { 
          props.type === "requestList" && (
            <div style={{display: "flex", alignItems: "center"}}>
              <div>
                <Checkbox
                  checked={formik.values.only_canceled}
                  onChange={(e) => formik.setFieldValue("only_canceled", e.target.checked)}
                />
              </div>
              <p>{tr("Retrieve only canceled requests")}</p>
            </div>
          )  
        }
        { !excludeAssigneeAndDivision.includes(props.type) && (
          <>
            <div>
              <Assignee
                label={tr("Assignees")}
                placeholder={tr("All Assignee")}
                onChange={(e: any) =>
                  formik.setFieldValue(
                    "assignee",
                    e?.map((assignee: any) => assignee.value) || null
                  )
                }
              />
            </div>
            <div>
              <DivisionDropdown
                label={tr("Divisions")}
                placeholder={tr("All Division")}
                isMulti
                onChange={(e: any) =>
                  formik.setFieldValue(
                    "division",
                    e?.map((div: any) => div.id) || null
                  )
                }
              />
            </div>
          </>
        )}
        <div className="mt-auto flex justify-end">
          <Button primary type="submit" isLoading={mutation.isLoading}>
            {tr ("Download Report")}
          </Button>
        </div>
      </form>
    </div>
  );
}
