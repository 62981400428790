import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TInvoice } from "types/invoice";
import StatusTag from "components/tag/indexv2";
import { useSnackbarContext } from "components/snackbar";
import Spinner from "components/spinner";
import { Button, OutlineButton } from "components/button";
import axios from "axios";
import { useModalContext } from "components/modal";
import { TPayment } from "types/payment";
import { dollarFormatter } from "utils";
import Placeholder from "views/request/component/placeholder";
import { cloneDeep } from "lodash";
import { RiCheckboxBlankLine, RiCheckboxFill } from "react-icons/ri";
import DollarInputV2 from "components/input/dollarInputV2";
import { useTranslation } from 'react-i18next';

const ApplyDepositModal = ({
  data,
  type,
}: {
  data: TPayment;
  type: "request" | "project" | "customer";
}) => {
  const { t } = useTranslation();
  const tBase = "containers.payments.applyDepositModal";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const modal = useModalContext(),
    snackbar = useSnackbarContext(),
    queryClient = useQueryClient();

  const [selected, setSelected] = React.useState<
    { id: string; amount: number | undefined }[] | null
  >();
  const parentId =
    type === "request"
      ? data.fk_request_id
      : type === "customer"
      ? data.fk_bcustomer_id
      : data.fk_project_id;

  const query = useQuery<TInvoice[] | null>(
    ["invoices", "request", parentId, type],
    async () =>
      await axios
        .get(
          `
        ${process.env.REACT_APP_SERVER_URL}/api/v1/company/invoices/deposit?type=${type}&id=${parentId}
        `
        )
        .then((res) => res.data)
  );

  const mutation = useMutation(
    async () =>
      await axios
        .post(
          `
        ${process.env.REACT_APP_SERVER_URL}/api/v1/company/payments/apply/${data.id}
        `,
          {
            selected,
          }
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        const invoiceNumbers = selected?.map((s) => `#${s.id}`).join(", ");
        const message = selected?.length === 1
            ? `${tr("Payment applied to invoice")} ${invoiceNumbers}`
            : `${tr("Payment applied to invoices")} ${invoiceNumbers}`;
            
        modal.setModal();
        snackbar.showSnackbar(message);
        queryClient.invalidateQueries();
      },
    }
  );

  if (query.isLoading) return <Spinner />;

  let validAmt = true;

  const populateInvoices = query.data?.map((invoice) => {
    const index = selected
      ? selected.findIndex((s) => s.id === invoice.id)
      : -1;
    const isSelected = index !== -1;

    if (
      isSelected &&
      selected &&
      invoice.amount_due / 100 - (selected[index].amount || 0) < 0
    ) {
      validAmt = false;
    }

    return (
      <OutlineButton
        key={invoice.id}
        onClick={() =>
          setSelected((s) => {
            let temp = cloneDeep(s);

            if (!temp) return [{ id: invoice.id, amount: undefined }];

            let index = temp.findIndex((s) => s.id === invoice.id);

            if (index === -1) {
              temp.push({ id: invoice.id, amount: undefined });
              return temp;
            } else {
              temp.splice(index, 1);
              if (temp.length === 0) return null;
              return temp;
            }
          })
        }
        className={`!relative !flex !flex-col !items-stretch !h-auto !p-2 ${
          isSelected && "!border-primary"
        }`}
      >
        <div className="space-x-2">
          <span>{tr("Invoice")} #{invoice.id}</span>
          <StatusTag color={invoice.status.color} size="xxsmall">
            {tr(invoice.status.label)}
          </StatusTag>
        </div>
        <span className="text-xl">{dollarFormatter(invoice.amount_due)}</span>
        {isSelected && (
          <DollarInputV2
            autoFocus
            $size="small"
            onClick={(e) => e.stopPropagation()}
            value={selected ? selected[index].amount : ""}
            onValueChange={(e) => {
              setSelected((s) => {
                if (s) {
                  let temp = cloneDeep(s);
                  temp[index].amount = e.floatValue;
                  return temp;
                }
                return s;
              });
            }}
            className=""
            placeholder={tr("Charge Amt($)")}
          />
        )}
        <div
          className={`absolute top-0 right-0 m-2 text-lg text-slate-500 ${
            isSelected && "text-[#ff4f00]"
          }`}
        >
          {isSelected ? <RiCheckboxFill /> : <RiCheckboxBlankLine />}
        </div>
      </OutlineButton>
    );
  });

  let remaining =
    data.remaining_total -
    (selected?.reduce((sum, curr) => {
      if (!curr.amount) {
        validAmt = false;
        return sum;
      }
      return (sum += curr.amount * 100);
    }, 0) || 0);

  return (
    <div className="space-y-4">
      <h2>
        {tr("Apply")} <span className="underline">{dollarFormatter(remaining)}</span>{" "}
        {tr("to")}:
      </h2>
      {query.data ? (
        <div className="space-y-4">
          <div className="flex flex-col space-y-2">{populateInvoices}</div>
          <div className="flex justify-end space-x-4">
            <Button
              primary
              disabled={!selected || remaining < 0 || !validAmt}
              isLoading={mutation.isLoading}
              onClick={() => mutation.mutate()}
            >
              {tr("Proceed")}
            </Button>
            <OutlineButton onClick={() => modal.setModal()}>
              {tr("Cancel")}
            </OutlineButton>
          </div>
        </div>
      ) : (
        <Placeholder
          label={`${tr("No invoices available in this")} ${type}.`}
          header={tr("No Invoices")}
          type="invoice"
        />
      )}
    </div>
  );
};

export default ApplyDepositModal;
