import { Cell } from "./weeklyContainer";
import * as React from "react";
import { useDrop } from "react-dnd";
import { theme } from "styles/theme";

type Props = {
  newDate: string;
  crewId: string;
  children: React.ReactNode;
  appointmentMoved: any;
  className?: any;
};
export default function DroppableCell({
  newDate,
  crewId,
  children,
  appointmentMoved,
  className,
}: Props) {
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: "BOX",
      drop: ({ id, date: prevDate, prevUserId }: any) => {
        // console.log('Dropped item:', { id, prevDate, prevUserId, newDate, crewId });
        appointmentMoved.mutate({
          prevDate,
          date: newDate,
          id,
          prevUserId,
          userId: crewId,
        });
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [newDate]
  );

  return (
    <Cell
      ref={drop}
      style={{ background: isOver ? theme.bg.blue : "transparent" }}
      className={className}
    >
      {children}
    </Cell>
  );
}
