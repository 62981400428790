import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { Button } from "components/button";
import DollarInputV2 from "components/input/dollarInputV2";
import { loadStripe } from "@stripe/stripe-js";
import { useMutation } from "react-query";
import axios from "axios";
import StripeCheckout from "./StripeCheckout";
import { useTranslation } from 'react-i18next';
import { FaCheck, FaStar } from "react-icons/fa";
import { useModalContext } from "components/modal";
import { useSnackbarContext } from "components/snackbar";
import TooltippedElement from "components/TooltippedElement";

// Card Icons
import visa from "../../../images/card-icons/visa.png";
import mastercard from "../../../images/card-icons/mastercard.png";
import amex from "../../../images/card-icons/america_express.png";
import discover from "../../../images/card-icons/discover.png";
import other from "../../../images/card-icons/other.png"; // Image by rawpixel.com on Freepik
import Spinner from "components/spinner";

import { MdClose } from "react-icons/md";

type Props = {
  id: string;
  type: "request" | "project" | "invoice" | "customer";
  is_deposit: boolean;
  stripeAccountId: null | string;
  amountDue?: number;
  customerId?: string;
};

const StripeContainer = ({ id, type, is_deposit, stripeAccountId, amountDue, customerId }: Props) => {
  const { t } = useTranslation();
  const tBase = "views.payment.components.StripeContainer";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext();
  const { showSnackbar } = useSnackbarContext();

  const [total, setTotal] = useState<number | null>(null);
  const [secret, setSecret] = useState(null);
  const [billingDetails, setBillingDetails] = useState(null);
  const [isDirect, setIsDirect] = useState(true);
  const [cards, setCards] = useState([]);
  const [stripeCustomerId, setStripeCustomerId] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [defaultCard, setDefaultCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stripeLoadError, setStripeLoadError] = useState(false);

  const cardIcon = (brand: string) => {
    switch (brand) {
      case "visa":
        return visa;
      case "mastercard":
        return mastercard;
      case "amex":
        return amex;
      case "discover":
        return discover;
      default:
        return other;
    }
  };

  const getPaymentMethods = async () => {
    setLoading(true);
    return axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/get-payment-methods/${customerId}`
      )
      .then((res) => {
        setCards(res.data.cards);
        res.data.cards.forEach((card: any) => {
          if (card.default) {
            setDefaultCard(card.id);
          }
        });
        setLoading(false);
        setStripeCustomerId(res.data.stripeCustomerId);
        return res.data.stripeCustomerId;
      })
      .catch((error) => {
        console.error("Error fetching Stripe customer ID:", error);
        setLoading(false);
        setStripeLoadError(true);
      });
  };

  const getCustomerById = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/${customerId}/basic-info`);
      return res.data.customer;
    } catch (error) {
      console.error("Error creating customer:", error);
    }
  };

  const createStripeCustomer = async (customer: any) => {
    const { 
      email, 
      first_name,
      last_name,
      company_name, 
      phone,
      fk_business_id
    } = customer;
    const companyName = company_name ? `(${company_name})` : "";
    const name = `${first_name} ${last_name} ${companyName}`;
    const companyId = fk_business_id;
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/${customerId}/create-stripe-customer`,
        {
          companyId,
          email,
          name,
          phone,
        }
      );

      return res.data.stripeCustomer.id;
    } catch (error) {
      console.error("Error creating customer:", error);
    }
  };
  
  useEffect(() => {
    getPaymentMethods();
  }, []);

  const createPaymentIntent = useMutation(
    async (payload: any) => {
      return await axios
        .post(
          `
            ${process.env.REACT_APP_SERVER_URL}/api/v1/company/payments/stripe_checkout${
            isDirect ? `_direct` : ``
          }/${id}
          `,
          payload
        )
        .then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        if (isDirect) {
          setBillingDetails(data.billingDetails);
          setSecret(data.secret.clientSecret);
        } else {
          setSecret(data);
        }
        if (selectedCard) {
          setModal();
          showSnackbar(tr("Your payment has been processed successfully"));
        } 
      },
      onError: (e) => {
        console.error("Payment Error:", e);
        if (selectedCard) {
          setModal();
          showSnackbar(tr("Payment failed"));
        }
      },
    }
  );

  const handleClickCheckout = () => {
    const payload = { 
      type, 
      total: total && total * 100, 
      is_deposit,
      amountDue,
      payment_method: selectedCard,
      stripeCustomerId,
    };
    createPaymentIntent.mutate(payload);
  };

  // Uses previous code to create a new payment intent with a new card
  const handleClickCheckoutWithNewCard = async () => {
    let stripeCustId = stripeCustomerId;
    if (!stripeCustId && !stripeLoadError) {
      const customer = await getCustomerById();
      if (customer) {
        stripeCustId = await createStripeCustomer(customer);
        setStripeCustomerId(stripeCustId);
      }
    }
    setSelectedCard(null);
    setTimeout(() => { // set timeout to allow selectedCard to be set to null (a bit of a janky workaround)
      const payload = {
        type,
        total: total && total * 100,
        is_deposit,
        amountDue,
        stripeCustomerId: stripeCustId,
      };
      createPaymentIntent.mutate(payload);
    }, 0);
  };

  const handleChangeIsDirect = () => {
    setIsDirect((prev) => !prev);
  };
  
  if(createPaymentIntent.isLoading) return <p>{tr("Loading")}...</p>
  if (!stripeAccountId) return null;

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE!);

  const stripePromiseDirect = loadStripe(process.env.REACT_APP_STRIPE || "", {
    stripeAccount: stripeAccountId,
  });
  
  if (!secret) {
    return (
      <div className="space-y-6 mt-1">
        <div className="-my-2 h-[90px]">
          <div className="flex mb-1 items-center space-x-1.5">
            <div>{tr("Saved Cards")}</div>
            {loading && (<Spinner size={18} color="#6b7280" /> )}
          </div>
          <div className="flex -space-x-1 -ml-1 mt-2.5">
            {stripeLoadError ? (
              <div className="ml-1 text-red-400">
                <div className="ml-1 h-[40px] w-[60px] border bg-gray-200 mb-[22.5px] rounded-sm" />
                <div className="-mt-[18px]">
                  {tr("Failed to load saved cards")}
                </div>
              </div>
            ) :
            loading ? (
              <div className="ml-2">
                  <div className="animate-pulse flex space-x-3 mb-0.5">
                    <div className="h-[40px] w-[60px] border bg-gray-200 mb-[22.5px] rounded-sm" />
                    <div className="h-[40px] w-[60px] border bg-gray-200 mb-[22.5px] rounded-sm" />
                    <div className="h-[40px] w-[60px] border bg-gray-200 mb-[22.5px] rounded-sm" />
                  </div>
              </div>
            ) : (
              cards.length > 0 ? (
                cards.map((card: any) => (
                  <div key={card.id} className="z-[999]">
                    <button 
                      className={`
                        relative rounded-sm group 
                        ${selectedCard === card.id ? 
                          "border border-1 border-gray-200 bg-gray-200 shadow-md"
                          : "border border-1 border-white hover:border-gray-200 bg-white hover:bg-gray-200 hover:shadow-md" 
                        }
                      `}
                      onClick={() => {
                        setSelectedCard((prevSelectedCard) => 
                          prevSelectedCard === card.id ? null : card.id
                        );
                      }}                      
                    >
                      <img 
                        src={cardIcon(card.brand)} 
                        alt={card.brand} 
                        width={60} 
                        className={`group-hover:scale-[105%] ${selectedCard === card.id ? "scale-[105%]" : ""}`}
                      />
                      <div>{card.last4}</div>
                      {selectedCard === card.id && (
                        <div className="absolute -top-[5px] -right-[5px] bg-white rounded-full">
                          <FaCheck className="text-green-500 translate-x-[2px]" />
                        </div>
                      )}
                      {defaultCard === card.id && (
                        <div className="absolute -top-[5px] -left-[5px] bg-white rounded-full z-10">
                          <TooltippedElement 
                            message={tr("Default")}
                            element={
                              <FaStar className="text-blue-500 -translate-x-[1px] -translate-y-[1px]" />
                            }
                          />
                        </div>
                      )}
                    </button>
                  </div>
                ))
              ) : (
                <div className="ml-2 h-[60px] flex flex-col justify-start items-start text-[12px] text-gray-400 italic">
                  <div className="relative">
                    <img 
                      src={other} 
                      alt={"new card"} 
                      width={60} 
                      className=" opacity-50"
                    />
                    <MdClose className="absolute -top-2 left-0 w-[30px] h-[30px] translate-x-[50%] translate-y-[50%] text-red-500" size={40} />
                  </div>
                  <div className="pt-2">
                    ({tr("No cards available")})
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        <div className="mt-4 !-mb-4">
          {tr("Total Amount")}
        </div>
        <DollarInputV2
          value={total}
          onValueChange={(e) => e.floatValue && setTotal(e.floatValue)}
        />
        {!stripeCustomerId || cards.length === 0 ? (
          <div>
            <Button onClick={handleClickCheckoutWithNewCard} primary disabled={!total}>
              {tr("Checkout")}
            </Button>
          </div>
        ) : (
          <div>
            <Button onClick={handleClickCheckout} primary disabled={!total || !selectedCard}>
              {tr("Checkout")}
            </Button>
            <button 
              onClick={handleClickCheckoutWithNewCard} 
              className={`text-[12px] p-4 ${total ? "text-blue-500 hover:underline" : "text-gray-400"}`}
              disabled={!total}
            >
              {tr("Checkout with New Payment Method")}
            </button>
          </div>
        )}
      </div>
    );
  }

  const options = {
    clientSecret: secret,
  };

  if (!selectedCard && secret) {
    return (
      <Elements
        stripe={isDirect ? stripePromiseDirect : stripePromise}
        options={options}
      >
        <StripeCheckout
          id={id}
          type={type}
          billingDetails={billingDetails}
          isDirect={isDirect}
          stripeLoadError={stripeLoadError}
        />
      </Elements>
    );
  } else {
    return null
  }
};

export { StripeContainer };
