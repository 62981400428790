import * as React from "react";
import { useState, useEffect } from "react";
import { Button, OutlineButton } from "components/button";
import GroupedButton from "components/button/grouped";
import DataLabel from "components/dataLabel";
import Field from "components/field";
import DollarInputV2 from "components/input/dollarInputV2";
import { useModalContext } from "components/modal";
import { useFormik } from "formik";
import { TPaymentManualForm } from "types/payment";
import * as Yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { useSnackbarContext } from "components/snackbar";
import { dollarFormatter } from "utils";
import { StripeContainer } from "./components/StripeContainer";
import { getStripeAccountId } from "./api/getStripeAccountId";
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
  type: "request" | "project" | "invoice" | "customer";
  is_deposit: boolean;
  amount_due?: number;
  customerId?: string;
};

export default function NewPaymentModal(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.payment.newPaymentModal";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [isAuto, setIsAuto] = React.useState(false);
  const [stripeAccountId, setStripeAccountId] = useState<null | string>(null);

  useEffect(() => {
    getStripeAccountId().then((data: string) => {
      setStripeAccountId(data);
    });
  }, []);

  return (
    <div className="w-[600px] flex flex-col space-y-4">
      {props.amount_due && (
        <div className="text-green-600 border  border-green-600 bg-green-100/50 p-2 rounded">
          <span>{tr("Amount Due")}: {dollarFormatter(props.amount_due)} </span>
        </div>
      )}
      <div>
        <GroupedButton>
          <OutlineButton
            size="small"
            onClick={() => setIsAuto(true)}
            $selected={isAuto}
          >
            Stripe
          </OutlineButton>
          <OutlineButton
            size="small"
            onClick={() => setIsAuto(false)}
            $selected={!isAuto}
          >
            {tr("Manual")}
          </OutlineButton>
        </GroupedButton>
      </div>
      {isAuto ? (
        <StripeContainer stripeAccountId={stripeAccountId} amountDue={props.amount_due} {...props} customerId={props.customerId} />
      ) : (
        <ManualContainer {...props} />
      )}
    </div>
  );
}

const ManualContainer = (props: Props) => {
  const modal = useModalContext(),
    queryClient = useQueryClient(), 
    snackbar = useSnackbarContext();

  const { t } = useTranslation();
  const tBase = "views.payment.newPaymentModal";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const paymentOptions = [
    { value: "cash", label: tr("Cash") },
    { value: "check", label: tr("Check") },
    { value: "card", label: tr("Credit card") },
    { value: "donation", label: tr("Donation") },
    { value: "financing", label: tr("Financing") },
    { value: "other", label: tr("Other") },
  ];

  const mutation = useMutation(
    async (e: TPaymentManualForm) =>
      await axios
        .post(
          `
            ${process.env.REACT_APP_SERVER_URL}/api/v1/company/payments?type=${props.type}&id=${props.id}
          `,
          {
            ...e,
            is_deposit: props.is_deposit,
          }
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        snackbar.showSnackbar(tr("Payment created!"));
        modal.setModal();
        queryClient.invalidateQueries();
      },
    }
  );

  const initialValues: TPaymentManualForm = {
    ref_number: null,
    received_by: "",
    received_on: "",
    memo: "",
    payment_type: "card",
    total: null,
    is_refund: false,
  };

  const onSubmit = (e: TPaymentManualForm) => {
    mutation.mutate(e);
  };

  const validationSchema = Yup.object({
    ref_number: Yup.string().required(),
    received_by: Yup.string().required(),
    received_on: Yup.string().required(),
    memo: Yup.string(),
    payment_type: Yup.string().required(),
    total: Yup.number().required(),
    is_refund: Yup.boolean().required(),
  });

  const formik = useFormik<TPaymentManualForm>({
    initialValues,
    onSubmit,
    validationSchema,
  });
  if (mutation.isLoading) {
    return <div>{tr("Loading")}...</div>;
  }
  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col space-y-4">
      <Field
        type="select"
        label={tr("Payment Method")}
        required
        className="max-w-[300px]"
        value={paymentOptions.find(
          (v) => v.value === formik.values.payment_type
        )}
        formik={formik}
        options={paymentOptions}
        onChange={(e: any) =>
          formik.setValues((v) => ({ ...v, payment_type: e.value }))
        }
      />
      <Field
        name="ref_number"
        required
        label={tr("Check/Reference #")}
        className="max-w-[300px]"
        placeholder="123"
        formik={formik}
      />
      <Field
        name="received_by"
        required
        placeholder="John Doe"
        className="max-w-[300px]"
        label={tr("Received by")}
        formik={formik}
      />
      <Field
        name="received_on"
        required
        className="max-w-[300px]"
        type="date"
        label={tr("Received On")}
        formik={formik}
      />
      <Field name="memo" label={tr("Payment Memo")} formik={formik} />

      <DataLabel
        title={tr("Transaction Type")}
        classNames={{
          children: "flex items-center space-x-8",
          title: "text-black",
        }}
      >
        <div className="flex items-center space-x-2">
          <input
            type="radio"
            checked={!formik.values.is_refund}
            onClick={() =>
              formik.setValues((v) => ({ ...v, is_refund: false }))
            }
          />
          <label>{tr("Payment")}</label>
        </div>
        <div className="flex items-center space-x-2">
          <input
            type="radio"
            checked={formik.values.is_refund}
            onClick={() => formik.setValues((v) => ({ ...v, is_refund: true }))}
          />
          <label>{tr("Refund")}</label>
        </div>
      </DataLabel>
      <DollarInputV2
        required
        formik={formik}
        name="total"
        label={tr("Total Amount")}
      />
      <div className="flex justify-end space-x-4">
        <Button
          type="submit"
          primary
          disabled={!formik.isValid || !formik.dirty}
          isLoading={mutation.isLoading}
        >
          {tr("Submit Transaction")}
        </Button>
        <OutlineButton type="button" onClick={() => modal.setModal()}>
          {tr("Cancel")}
        </OutlineButton>
      </div>
    </form>
  );
};
