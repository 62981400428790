import axios from "axios";

const getInvoiceAssignees = async (invoiceId: number) => {
  try {
    return await axios.get(
      `${
        process.env.REACT_APP_SERVER_URL
      }/api/v1/company/production/invoice-assignees/${invoiceId.toString()}`
    );
  } catch (error) {
    console.error(error);
  }
};

export default getInvoiceAssignees;
