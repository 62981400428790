import { cloneDeep } from "lodash";
import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { Avatar } from "components/avatar";
import { Button } from "components/button";
import { updatedDiff } from "deep-object-diff";
import { useSnackbarContext } from "components/snackbar";
import { useTranslation } from 'react-i18next';
import { FaChevronUp } from "react-icons/fa";
import { Switch } from "@mui/material";

type Category = {
  id: number;
  label: string;
  status: boolean;
};

type Data = {
  name: string;
  user_id: string;
  category: Category[];
}[];

export default function Notifications() {
  const [data, setData] = useState<Data | null>(null);
  const [updated, setUpdated] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [showInactiveUsers, setShowInactiveUsers] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const tBase = "views.admin.notifications.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { showSnackbar } = useSnackbarContext();

  // show scroll button on scroll
  window.onscroll = () => {
    if (window.scrollY > 100) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  }

  const fetchData = useQuery(
    ["requestEmailList", showInactiveUsers],
    async ({ queryKey }) => {
      const [_key, showInactive] = queryKey;
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/admin/email?showInactiveUsers=${showInactive}`
      );
      return response.data;
    },
    {
      keepPreviousData: false,
      onSuccess: (data) => {
        setData(data);
        setIsLoading(false);
      },
      onError: (error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      },
    }
  );
  
  

  const updateData = useMutation(
    async (body) =>
      await axios.post(
        `
        ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/admin/email
        `,
        body
      ),
    {
      onSuccess: () => {
        setUpdated(false);
        queryClient.invalidateQueries("requestEmailList");
        showSnackbar(tr("Notifications updated"));
      },
    }
  );

  const handleChange = (e: any, personId: string) => {
    const { name, checked } = e.target;
    setData((data) => {
      let tempData = cloneDeep(data);
      let index = data
        ? data.findIndex(({ user_id }) => user_id === personId)
        : -1;

      let categoryIndex =
        data && index !== -1
          ? data[index].category.findIndex((cat) => cat.id === +name)
          : -1;

      if (tempData && categoryIndex !== -1 && index !== -1) {
        tempData[index]["category"][categoryIndex].status = checked;
      }

      return tempData;
    });
    setUpdated(true);
  };

  const handleSubmit = () => {
    if (data) {
      let changes = updatedDiff(fetchData.data, data);
      let updates = Object.entries(changes).reduce<any>(
        (prev, [key, value]) => {
          let temp = cloneDeep(prev);
          Object.entries(value.category).forEach(
            ([keys, values]: [string, any]) => {
              if (values) {
                let change = {
                  user: data[+key].user_id,
                  category: data[+key].category[+keys].id,
                };
                if (values.status) {
                  temp["added"]["user"].push(change.user);
                  temp["added"]["category"].push(change.category);
                } else {
                  temp["deleted"]["user"].push(change.user);
                  temp["deleted"]["category"].push(change.category);
                }
              }
            }
          );
          return temp;
        },
        {
          deleted: { user: [], category: [] },
          added: { user: [], category: [] },
        }
      );

      updateData.mutate(updates);
    }
  };

  // if (fetchData.isLoading || !data || !data[0].category || data[0].category.length === 0) {
  //   return <div>Loading...</div>;
  // }
  let categoryCounts = 0;
  if (data) {
    categoryCounts = data[0].category.length;
  }

  let gridStyle = {};

  if (categoryCounts <= 2) {
    gridStyle = {
      display: "grid",
      gridTemplateColumns: `200px repeat(${categoryCounts}, minmax(60px, 200px))`,
    };
  } else {
    gridStyle = {
      display: "grid",
      gridTemplateColumns: `200px repeat(${categoryCounts}, minmax(60px, 1fr))`, 
    };
  }

  return (
    <div className="h-full overflow-auto relative">
      <div className="fixed top-[50px] h-[17px] w-[calc(100%-275px)] bg-white z-[2] -ml-1" />
      <div className="z-[2] bg-white">
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <h1>{tr("Notifications")} </h1>
          <span className="text-gray-500 ml-4">
            {tr("Set who is to receive notifications")}
          </span>
          <div style={{ marginLeft: "2rem" }}>
            Show Inactive Users
          </div>
          <Switch
            checked={showInactiveUsers}
            onChange={() => {
              setShowInactiveUsers((prev) => !prev);
            }}
          />
          <Button
            style={{ marginLeft: "auto" }}
            onClick={handleSubmit}
            size="large"
            primary
            disabled={!updated}
          >
            {tr("Save Changes")}
          </Button>
        </div>
      </div>


      {isLoading ? (
        <div className="text-3xl text-center pt-20">Loading...</div>
      ) : (
        // Show content when data is loaded
        <div
          className="mt-[25px] [&>*]:pl-2"
          style={{ ...gridStyle, borderCollapse: "collapse" }}
        >
          {/* Column Headers */}
          <div
            className="border-b border-gray-300"
            style={{
              gridColumn: `span ${categoryCounts + 1}`,
              display: "grid",
              gridTemplateColumns: `200px repeat(${categoryCounts}, minmax(60px, 1fr))`,
            }}
          >
            <div className="ml-2">{tr("Name")}</div>
            {data &&
              data[0]?.category?.map((cat) => (
                <div
                  key={cat.id}
                  className="truncate hover:overflow-visible hover:whitespace-normal text-center"
                >
                  {cat.label}
                </div>
              ))}
          </div>

          {/* Rows */}
          {data?.map((person) => (
            <React.Fragment key={person.user_id}>
              <div className="border-r border-b truncate hover:overflow-visible hover:whitespace-normal h-12">
                <Avatar user_id={person.user_id} showLabel>
                  {person.name}
                </Avatar>
              </div>
              {person.category?.map((cat) => (
                <div
                  key={cat.id}
                  className="flex items-center border-b w-full justify-center border-r"
                >
                  <input
                    className="w-4 h-4"
                    name={cat.id + ""}
                    type="checkbox"
                    checked={cat.status}
                    onChange={(e) => handleChange(e, person.user_id)}
                  />
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      )}

      {/* make scroll to top */}
      {showScrollButton && (
        <div className="fixed bottom-4 right-4">
          <button
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            className="bg-primary p-2 rounded-full shadow-md text-white hover:bg-primary-dark"
          >
            <FaChevronUp />
          </button>
        </div>
      )}


    </div>
  );
}
