import React from "react";
import { Button } from "components/button";
import { useModalContext } from "components/modal";
import Select from "react-select";
import axios from "axios";
import { useSnackbarContext } from "components/snackbar";
import { useQueryClient } from "react-query";
import { useTranslation } from 'react-i18next';

type Category = {
  value: number;
  label: string;
};

type Props = {
  categories: Category[];
  subCategoryId: number;
  onSave?: () => void;
};

const MoveCategory = ({ categories, subCategoryId, onSave }: Props) => {
  const { t } = useTranslation();
  const tBase = "views.inventories.categories.MoveCategory";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const [selectedCategoryId, setSelectedCategoryId] = React.useState<
    number | null
  >(null);
  const { setModal } = useModalContext();
  const snackbar = useSnackbarContext();
  const queryClient = useQueryClient();

  const moveCategory = async (
    movingCategoryId: number,
    toCategoryId: number
  ) => {
    const response = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/company/products/move-subcategory`,
      {
        subCatId: movingCategoryId,
        toParentId: toCategoryId,
      }
    );

    // console.log("moveCategory response:", response);

    if (response.status !== 200) {
      console.error("Failed to move category");
      snackbar.showSnackbar(tr("Failed to move category"));
      return;
    } else {
      snackbar.showSnackbar(tr("Category moved successfully"));
      queryClient.invalidateQueries("categories");
      if (onSave) onSave();
    }
  };

  // console.log("Categories:", categories);
  // console.log("Selected Category:", selectedCategoryId);

  return (
    <div
      className="flex flex-col justify-between min-h-[400px]"
      key={selectedCategoryId}
    >
      <div>
        <div>
          {tr("Moving Sub-Category")}{" "}
          <span className="font-extrabold">
            {
              categories.find(
                (cat) => cat.value.toString() === subCategoryId.toString()
              )?.label
            }
          </span>{" "}
          {tr("To")}:
        </div>

        <Select
          value={categories.find((cat) => cat.value === selectedCategoryId)}
          onChange={(option) => {
            if (!option) return;
            setSelectedCategoryId(option?.value);
          }}
          options={categories}
          placeholder={tr("Select Category")}
          isClearable
        />
      </div>

      <Button
        primary={selectedCategoryId ? true : false}
        className="align-self-end"
        onClick={() => {
          // console.log("Selected Category:", selectedCategoryId);
          if (!selectedCategoryId) return;
          moveCategory(subCategoryId, selectedCategoryId);
          setModal();
        }}
        disabled={!selectedCategoryId}
      >
        {tr("Move Category")}
      </Button>
    </div>
  );
};

export default MoveCategory;
