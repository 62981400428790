const YtdDetails = () => {
  const yearToDate = 2366192.75;
  const lastYearToDate = 2105574.49;
  const average = 12790;
  const median = 14070;
  const difference = yearToDate - lastYearToDate;
  const percentageChange = ((difference / lastYearToDate) * 100).toFixed(1);

  return (
    <div className="flex flex-col space-y-6">
      <div>
        <h2 className="text-lg font-bold text-green-400">
          Year to Date | 2024
        </h2>
        <div className="text-2xl font-bold text-white">
          ${Math.round(yearToDate).toLocaleString()}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 text-sm">
        <div className="flex justify-between">
          <span className="text-gray-400">Average</span>
          <span className="text-white font-bold">
            ${Math.round(average).toLocaleString()}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-400">Median</span>
          <span className="text-white font-bold">
            ${Math.round(median).toLocaleString()}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-400">Compare to Last Year</span>
          <span
            className={`font-bold ${
              difference > 0 ? "text-green-500" : "text-red-500"
            }`}
          >
            {difference > 0 ? "+" : ""}
            {percentageChange}%
          </span>
        </div>
      </div>
    </div>
  );
};

export default YtdDetails;
