import React, { useEffect, useState, useRef } from "react";
import { useUpload } from "../context/UploadContext";
import axios from "axios";
import { useDrag, useDrop } from "react-dnd";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaArrowRight, FaArrowLeft, FaPlus } from "react-icons/fa";
import Field from "components/field";
import { Button } from "components/buttonV2";
import { useModalContext } from "components/modal";
import Preview from "./Preview";
import { useTranslation } from 'react-i18next';
import { 
  DraggableHeaderProps, 
  DroppableColumnProps,
  IMatchedColumns,
  DropResult
} from "../interface";
import i18n from "i18n";
import { useAuthContext } from "contexts/auth";
import { initializeProgressListener } from "./ProgressBar";
import TooltippedElement from "components/TooltippedElement";
import timButton from "../utils/timButton";

const ItemType = { HEADER: "header"};

const DraggableHeader: React.FC<DraggableHeaderProps> = ({ header }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemType.HEADER,
    item: { header },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }), [header]);

  return (
    <div ref={drag} className={`bg-white border p-2 my-1.5 shadow-md rounded-[4px] hover:cursor-grab ${isDragging ? "opacity-50" : "opacity-100"}`}>
      {header}
    </div>
  );
};

const DroppableColumn: React.FC<DroppableColumnProps> = ({ column, onDrop, matchedHeader, setMatchedColumns, i }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemType.HEADER,
    drop: (item: DropResult) => onDrop(item.header, column),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const [, drag] = useDrag({
    type: ItemType.HEADER,
    item: { header: matchedHeader },
    canDrag: !!matchedHeader,
    end: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (!didDrop && matchedHeader) {
        onDrop(matchedHeader, ''); // Remove from column if not dropped to a new column
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleRemoveHeader = (i: number) => {
    setMatchedColumns(prev => {
      const newMatches = { ...prev };
      delete newMatches[i];
      return newMatches;
    });
  };

  return (
    <div ref={drop} className={`border my-1.5 h-10 overflow-hidden rounded-[4px] ${matchedHeader ? "hover:cursor-grab" : ""} ${isOver ? "bg-blue-200" : "bg-white"}`}>
      <div ref={drag} className="relative rounded-[4px] p-2">
        {matchedHeader}
        {matchedHeader && 
          <button 
            onClick={() => handleRemoveHeader(i)}
            className="absolute top-0 right-0 text-2xl m-2 bg-white text-[#ff0000] hover:text-white rounded-full hover:cursor-pointer hover:bg-[#ff0000]"
          >
            <AiOutlineCloseCircle />
          </button>
          }
      </div>
    </div>
  );
};

const ColumnMatcher = () => {
  const [matchedColumns, setMatchedColumns] = useState<IMatchedColumns>({});
  const [dynamicFields, setDynamicFields] = useState([] as any[]);
  const [selectedList, setSelectedList] = useState<string[]>([]);
  const [additionalList, setAdditionalList] = useState<string[]>([]);
  const [initialCount, setInitialCount] = useState(0); // used to safe guard deletion of non new fields
  const [useAdditionalOptions, setUseAdditionalOptions] = useState(false); // used to determine if additional options are needed
  const [previewData, setPreviewData] = useState([] as any[]); // used to store preview data
  const [availableHeaders, setAvailableHeaders] = useState([] as string[]); // used to store available headers
  const [addressAmount, setAddressAmount] = useState(0); // used to store the amount of additional addresses
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false);

  const eventSourceRef = useRef<EventSource | null>(null);
  const lastItemRef = useRef<HTMLDivElement | null>(null);

  const { 
    selected, 
    headers, 
    selectedFileType, 
    setTriggerCurtainsClose, 
    setPauseCurtains,
    setResults,
    setUploadProgress,
    origFileName,
    inventory,
    services,
    customers
  } = useUpload();
  const { setModal } = useModalContext();
  const { user_id: userId } = useAuthContext();

  const { t } = useTranslation();
  const tBase = "views.admin.uploadV2.components.ColumnMatcher";
  const tr = (key: string) => t(`${tBase}.${key}`);

  useEffect(() => {
    switch (selected) {
      case "inventory":
        setSelectedList(inventory);
        setInitialCount(inventory.length);
        setAdditionalList(addOptions[0]?.inventory || []);
        setUseAdditionalOptions(addOptions[0]?.status === "on");
        break;
      case "services":
        setSelectedList(services);
        setInitialCount(services.length);
        setAdditionalList(addOptions[1]?.services || []);
        setUseAdditionalOptions(addOptions[1]?.status === "on");
        break;
      case "customers":
        setSelectedList(customers);
        setInitialCount(customers.length);
        setAdditionalList(addOptions[2]?.customers || []);
        setUseAdditionalOptions(addOptions[2]?.status === "on");
        break;
      default:
        setSelectedList([]);
    }
  }, [selected]);

  const handleDrop = (header: string, column: string, i: number): void => {
    setMatchedColumns(prev => {
      const newMatches = { ...prev };

      Object.entries(newMatches).forEach(([key, value]) => {
        if (value === header) {
          delete newMatches[key];
        }
      });
  
      // Assign the header to the new column, if column is not empty
      if (column) {
        newMatches[i] = header;
      }

      return newMatches;
    });
  };

  const filteredHeaders = headers.filter(header => header === "" || header !== null);
  useEffect(() => {
    // Recompute available headers every time headers or matchedColumns changes
    const matchedHeaders = new Set(Object.values(matchedColumns));
    const updatedAvailableHeaders = filteredHeaders.filter(header => !matchedHeaders.has(header));
    setAvailableHeaders(updatedAvailableHeaders);
  }, [headers, matchedColumns]);

  const removeAllHeaders = () => {
    setMatchedColumns({});
  };

  // Change status to "on" to use additional options
  const addOptions = [
    { 
      inventory: [
        "Product Tag",
        "All Product Tags"
      ],
      status: "on",
    },
    { 
      services: [
        "Category",
        "Subcategory"
      ],
      status: "off"
    },
    { 
      customers: [
        "First and Last Name",
        "Last Name, First Name",
        "Phone and Extension",
        "Custom Field (Property)",
        // "Tag (...)",
        "Tag (Customer)",
        "Tag (Property)",
        "Customer Notes",
        "Additional Address (Group)",
        "Additional Contact (Group)",
      ],
      status: "on"
    },
  ];

  const additionalAddress = [
    "Address (Address)",
    "City (Address)",
    "State (Address)",
    "Zip (Address)",
    "City, State, Zip (Address)",
  ];

  const additionalContact = [
    "First Name (Contact)",
    "Last Name (Contact)",
    "First and Last Name (Contact)",
    "Title (Contact)",
    "Phone (Contact)",
    "Extension (Contact)",
    "Email (Contact)",
  ];

  
  const handleFieldChange = (label: string, i: number) => {
    setSelectedList(prev => {
      // Remove after being used and DON'T add the (n) to the label
      if (label === "First and Last Name" || label === "Last Name, First Name" || label === "Phone and Extension"  || label === "Customer Notes" || (selected === "inventory" && label === "All Product Tags")) {
        setAdditionalList(prev => {
          const newAdditionalList = prev.filter(item => item !== label);
          return newAdditionalList;
        });
        return [...prev, label];
      } else if (label.includes("(Group)") || label.includes("Custom Field") || label.includes("Tag")) {
        // Determine the specific group and its related fields
        let groupFields = [] as any[];
        if (selected === "customers") {
          if (label === "Additional Address (Group)") groupFields = additionalAddress;
          if (label === "Additional Contact (Group)") groupFields = additionalContact;
          if (label === "Custom Field (Property)") groupFields = ["Custom Field"];
          if (label === "Tag (Customer)") groupFields = ["Customer Tag"];
          if (label === "Tag (Property)") groupFields = ["Property Tag"];
          // if (label === "Customer Notes") groupFields = ["Notes"];
        } else { // inventory
          if (label.includes("Product Tag") && !label.includes("All")) groupFields = ["Tag"];
        }

        // Calculate the suffix for each field in the group & add (n) to selectedList
        const newFields = groupFields.map((field) => {
          const escapedField = field.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
          const regex = new RegExp(`^${escapedField}( \\((\\d+)\\))?$`);
          const counts = prev
            .map((item) => {
              const match = item.match(regex);
              return match ? parseInt(match[2], 10) : null;
            })
            .filter(Number.isInteger) as number[];

          const nextCount = counts.length > 0 ? Math.max(...counts) + 1 : 1;
          return `${field} (${nextCount})`;
        });

        // Update additionalList to prevent re-adding the same group
        setAdditionalList(prevAdditional => prevAdditional.filter(item => !groupFields.includes(item.replace(/ \(\d+\)$/, ''))));

        return [...prev, ...newFields];
      } else {
        return [...prev, label];
      }
    });
  };  

  const initialPositions: { [key: string]: number } = 
  selected === "customers" ? {
    "First and Last Name": 0,
    "Last Name, First Name": 1,
    "Phone and Extension": 2,
    "Customer Notes": 3,
  } : {
    "All Product Tags": 0,
  };

  const removeField = (i: number) => {
    if (selectedList.length > initialCount) {
      setSelectedList(prevSelected => {
      const indexToRemove = i !== 999 ? i : prevSelected.length - 1;
      const newSelected = [...prevSelected.slice(0, indexToRemove), ...prevSelected.slice(indexToRemove + 1)];

      setMatchedColumns(prevMatched => {
        const newMatched: { [key: string]: any } = {}; // Add index signature
        Object.keys(prevMatched).forEach(key => {
          const adjustedKey = parseInt(key);
          if (adjustedKey < indexToRemove) {
            newMatched[key] = prevMatched[key];
          } else if (adjustedKey > indexToRemove) {
            // Shift down the key to fill the gap
            newMatched[adjustedKey - 1] = prevMatched[key];
          }
          // If adjustedKey === indexToRemove, do nothing (it's being deleted)
        });
        return newMatched;
      });

        const itemToRemove = prevSelected[indexToRemove];
        if (selected === "customers") {
          const first = "First and Last Name";
          const second = "Last Name, First Name";
          const third = "Phone and Extension";
          const fourth = "Customer Notes";
      
          const specialItems = ["First and Last Name", "Last Name, First Name", "Phone and Extension", "Customer Notes"];
          if ([first, second, third, fourth].includes(itemToRemove)) {
            setAdditionalList(prevAdditional => {
            let updatedList = prevAdditional.filter(item => item !== itemToRemove);
            updatedList.push(itemToRemove);
            // Sort based on the initial index positions of only the relevant items
            updatedList.forEach(item => {
              let specialFiltered = updatedList.filter(item => specialItems.includes(item));
              updatedList = [...specialFiltered.sort((a, b) => initialPositions[a] - initialPositions[b]), ...updatedList.filter(item => !specialItems.includes(item))];
            });
            return updatedList;
            });
          }
        } else {
          const first = "All Product Tags";
          const specialItems = ["All Product Tags"];
          
          if ([first].includes(itemToRemove)) {
            setAdditionalList(prevAdditional => {
            let updatedList = prevAdditional.filter(item => item !== itemToRemove);
            updatedList.push(itemToRemove);
            // Sort based on the initial index positions of only the relevant items
            updatedList.forEach(item => {
              let specialFiltered = updatedList.filter(item => specialItems.includes(item));
              updatedList = [...specialFiltered.sort((a, b) => initialPositions[a] - initialPositions[b]), ...updatedList.filter(item => !specialItems.includes(item))];
            });
            return updatedList;
            });
          }
        }

  
        return newSelected;
      });
    }
  };
  
  // Finish import with matched columns to headers
  const sendMatchedColumns = async () => {
    // Get value from index position of selectedList
    const dbMappedCols = Object.fromEntries(
      Object.entries(matchedColumns).map(([index, header]) => {
        const columnValue = selectedList[Number(index)];
  
        return [columnValue, header]; 
      })
    );

    try {
      // TODO: UNCOMMENT WHEN READY
      setTriggerCurtainsClose(true);
      setPauseCurtains(true);
      initializeProgressListener({ // Initialize SSE for progress updates
        setResults,
        setUploadProgress,
        eventSourceRef,
        selected: selected,
      });
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/import/finishImportWithMatched`, {
        matchedColumns: dbMappedCols,
        fileType: selectedFileType,
        selected: selected,
        fileName: origFileName,
        userId: userId,
      });
    } catch {
      console.error("Error sending matched columns");
    }
  };  

  // Preview the first few rows of the matched columns
  const previewMatchedColumns = async () => {
    // Get value from index position of selectedList
    const dbMappedCols = Object.fromEntries(
      Object.entries(matchedColumns).map(([index, header]) => {
        const columnValue = selectedList[Number(index)];
  
        return [columnValue, header]; 
      })
    );

    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/import/previewMatched`, {
        matchedColumns: dbMappedCols,
        fileType: selectedFileType,
        selected: selected,
        fileName: origFileName,
      });
      setPreviewData(res.data);
      return res.data;
    } catch {
      console.error("Error sending matched columns");
    }
  };

  // Cleanup EventSource when component unmounts
  useEffect(() => {
    const currentEventSource = eventSourceRef.current;
    return () => {
      // Use the captured value for cleanup
      if (currentEventSource && currentEventSource.readyState === 1) {
        currentEventSource.close();
        console.log("EventSource closed on cleanup");
      }
    };
  }, []);

  const previewModal = async () => {
    const previewData = await previewMatchedColumns();
    setModal({
      label: tr("Preview"),
      component: <Preview data={previewData} />,
    });
  };

  // set the count of the additional address used in Tag selection
  useEffect(() => {
    setAddressAmount(selectedList.filter(item => item.includes("Address (Address)")).length);
  }, [selectedList]);

  const addValues = (item: any) => {
    if (selectedList.includes(item)) return;
    // setSelectedList(prevSelected => {
    //   const newSelected = [...prevSelected, item];
    //   return newSelected;
    // })
    handleFieldChange(item, selectedList.length);

    // Move the added item to the end of the list
    setTimeout(() => {
      if (lastItemRef.current) {
        lastItemRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }, 0);
  };

  return (
    <div>
      <div className="-mt-5 mb-5 text-gray-600 text-center">
        {tr("Please match the excel headers from the imported file to the EServ values.")}
      </div>
      {/* Buttons */}
      <div className={`grid ${showAdditionalOptions ? "grid-cols-4" : "grid-cols-3"} mb-2`}>
        <div className="mx-auto text-gray-700">
          {tr("Excel Headers")}
        </div>
        <button 
          onClick={() => removeAllHeaders()}
          className={`
            ${Object.keys(matchedColumns).length > 0 ? "text-primary hover:text-primary-dark hover:cursor-pointer" : "text-gray-400 hover:cursor-default"}  
            ${i18n.language === "es" ? "w-18" : "w-16"}
            mx-auto
          `}
        >
          {tr("Clear All")}
        </button>
        <div className="mx-auto text-gray-700 flex items-bottom">
          {tr("EServ Values")}
          {/* TODO: Remove Tim Button */}
          {/* <button 
            onClick={timButton}
            className="absolute ml-3 -mt-0.5 border rounded-md px-1 py-0.5 text-white bg-secondary hover:bg-secondary-dark">
            Tim Button
          </button> */}
          <div className="ml-2">
            <TooltippedElement 
              message={showAdditionalOptions ? tr("Close") : tr("Additional Values")}
              placement="top"
              element={
                <button onClick={() => setShowAdditionalOptions((prev) => !prev)}>
                  <FaPlus 
                    className={`
                      border p-1 h-6 w-6 rounded-full transition-transform duration-100 hover:bg-gray-100
                      ${showAdditionalOptions 
                        ? "rotate-45 text-gray-500" 
                        : "rotate-0 text-primary"}
                    `} 
                  />
                </button>
              }
            />
          </div>
        </div>
        {showAdditionalOptions && (
          <div className="mx-auto text-gray-700">
            {tr("Additional Values")}
          </div>
        )}
      </div>
      {/* Content */}
      <div className={`grid ${showAdditionalOptions ? "grid-cols-12" : "grid-cols-9"} gap-x-4 rounded-md [&>*]:max-h-[53vh]`}>
        {/* Excel Headers */}
        <div className={`col-span-3 border rounded-[3px] overflow-y-scroll px-2`}>
          {availableHeaders ? (
            availableHeaders.map((header: string, i: number) => (
              <DraggableHeader key={i} header={header} />
            ))
            ) : (
              <div>
                {tr("None found")}
              </div>
            )
          }
        </div>
        <div className="col-span-6 grid grid-cols-2 gap-x-4 border overflow-y-scroll px-2 pb-4 rounded-[3px]">
          {/* Matched Columns */}
          <div>
            {selectedList && (
              <div>
                {selectedList.map((column: string, i: number) => (
                  <DroppableColumn 
                    key={i}
                    column={column}
                    i={i}
                    onDrop={(header) => {
                      handleDrop(header, column, i)
                    }}
                    matchedHeader={matchedColumns[i]}
                    setMatchedColumns={setMatchedColumns}
                  />
                ))}
                </div>
              )}
          </div>
          {/* EServ Values */}
          <div>
            {selectedList && (
              <div>
                {selectedList.map((column: string, i: number) => (
                  <div 
                    key={i} 
                    className="border p-2 my-1.5 h-10 rounded-[4px] relative truncate"
                    ref={i === selectedList.length - 1 ? lastItemRef : null} // Add ref only to the last item
                  >
                    {column.includes("(") ? 
                      (
                        <span>
                          {column.split("(")[0]}
                          <span className="text-gray-400 text-[12px]">
                            {/* Adds () conditionally*/}
                            ({column.split("(")[1]} {column.split("(")[2] ? "(" + column.split("(")[2] : ""} 
                          </span>
                        </span>
                      ) : column
                    }
                    {i > initialCount - 1 && (
                      <button 
                        onClick={() => removeField(i)}
                        className="absolute top-0 right-0 text-2xl m-2 bg-white text-[#ff0000] hover:text-white rounded-full hover:cursor-pointer hover:bg-[#ff0000]"
                      >
                        <AiOutlineCloseCircle />
                      </button>
                    )}
                    {/* {column.includes("Tag") && (
                      <select 
                        className="absolute top-0 right-10 h-[38px] w-[140px] border-x"
                      >
                        <optgroup label="Customer">
                          <option value="owner-customer">Owner (C)</option>
                          <option value="billing-customer">Billing (C)</option>
                        </optgroup>
                        <optgroup label="Address">
                          <option value="owner-address">Owner (A)</option>
                          <option value="billing-address">Billing (A)</option>
                        </optgroup>
                        {addressAmount > 0 && (
                          <optgroup label="Additional Address">
                            {Array.from({ length: addressAmount }).map((_, index) => (
                              <option key={index} value={`additional-address-${index + 1}`}>
                                Additional Address {index + 1} (AA)
                              </option>
                            ))}
                          </optgroup>
                        )}
                      </select>
                    )} */}
                  </div>
                ))}
                <div>

                {/* {dynamicFields.map((field, i) => (
                  <Field
                    key={i}
                    type="select"
                    options={additionalList.map((item) => ({ label: item, value: item }))}
                    value={field}
                    onChange={(e: any) => handleFieldChange(e.value, i)}
                    className="mb-1 text-sm"
                  />
                ))} */}
                {/* {useAdditionalOptions && (
                  <div className="grid grid-cols-3 space-x-2 -mb-2">
                    <button onClick={addDynamicField} className="bg-secondary hover:bg-secondary-dark text-white rounded p-1">
                      {tr("Add")}
                    </button>
                    <button 
                      onClick={() => removeField(999)} // 999 is a dummy value
                      className={`text-white rounded py-1 px-2
                        ${selectedList.length > initialCount || dynamicFields.length > 0 
                          ? "hover:cursor-pointer bg-red-500 hover:bg-red-600" 
                          : "hover:cursor-default bg-gray-400"
                        }
                      `}
                    >
                      {tr("Remove")}
                    </button>
                  </div>
                )}  */}
              </div>
              </div>
            )}
          </div>
        </div>
        {/* Additional Values */}
        <div className={`
          border overflow-y-scroll px-2 pb-4 rounded-[3px] 
          ${showAdditionalOptions ? "col-span-3" : "hidden"}
        `}>
          <div className="grid">
            <div>
              {additionalList.map((item, i) => (
                <div 
                  key={i} 
                  // className={`
                  //   border p-2 my-1.5 h-10 rounded-[4px] select-none relative truncate
                  //   ${selectedList.includes(item) ? "bg-gray-200 cursor-disabled" : "hover:cursor-pointer hover:bg-gray-100"}
                  // `}
                  className="border p-2 my-1.5 h-10 rounded-[4px] select-none cursor-pointer relative truncate hover:bg-gray-100 active:bg-gray-200"
                  onClick={() => addValues(item)}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>  
      </div>
      <div className="flex justify-end space-x-3 mt-2">
        <Button 
          type="secondary" 
          onClick={previewModal}
          className="text-gray-700"
        >
          {tr("Preview")}
        </Button>
        <Button onClick={sendMatchedColumns}>
          {tr("Continue")}
        </Button>
      </div>

    </div>
  )
}

export default ColumnMatcher;