import React, { useEffect, useReducer, useRef, useState } from "react";
import { Modal } from "@material-ui/core";
import { Button } from "components/button";
import DatePicker from "react-datepicker";
import Select from "react-select";
import axios from "axios";
import _ from "lodash";
import polyline from "@mapbox/polyline";
import "react-confirm-alert/src/react-confirm-alert.css";
import AssigneeSelect from "components/react-select/assignees";
import MapComponent from "./MapComponents/MapComponent";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import styles from "./MapModal.module.scss";
import AppointmentRow from "./MapComponents/AppointmentRow";
import HomeBlock from "./MapComponents/HomeBlock";
import UserDisplayControl from "./MapComponents/UserDisplayControl";
import { useModalContext } from "components/modal";
import { optimizeReducer } from "reducers/map-reducers/optimization-reducer";

import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { Appointment } from "./types/Appointment";
import { User } from "./types/User";
import { Division } from "./types/Division";
import { Assignee } from "./types/Assignee";
import { LineString } from "@turf/turf";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";
import { useAuthContext } from "contexts/auth";
import { convertFromUTC, convertToUTC } from "utils/utcConversion";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass =
  // @ts-ignore
  // eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

interface HomeType {
  location: {
    latitude: number;
    longitude: number;
  };
}

const MapModal = () => {
  const mapRef = React.useRef<any | null>(null);
  const drawRef = useRef();

  const [mapModalOpen, setMapModalOpen] = useState(false);
  const [selectedBasemapId] = useState(1);
  const [home, setHome] = useState<HomeType | null>(null);
  const [markers, setMarkers] = useState<Appointment[] | null>(null);
  const [AssignedTo, setAssignedTo] = useState("");
  const [scheduledDate, setScheduledDate] = useState<string>("");
  const [viewState, setViewState] = useState({
    latitude: 41.18211,
    longitude: -96.12133,
    zoom: 8,
  });
  const [date, setDate] = useState(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  });
  const [users, setUsers] = useState<User[] | null>(null);
  const [filterAssignee, setFilterAssignee] = useState(null);
  const [trip, setTrip] = useState<LineString | null>(null);
  const [showMarkerDisplayList, setShowMarkerDisplayList] = useState<[]>([]);
  const [showAddresses, setShowAddresses] = useState(false);
  const [showTimes, setShowTimes] = useState(true);
  const [showUnassigned, setShowUnassigned] = useState(true);

  const { t, i18n } = useTranslation();
  const tBase = "views.appointments.MapModal";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const locale = i18n.language === "es" ? es : enUS;
  const { timezone } = useAuthContext();

  interface Action {
    type: string;
    payload: {
      address: string;
      order: number;
      center?: string[];
      isStart?: boolean;
      isEnd?: boolean;
    };
  }

  interface OptimizeState {
    address: string;
    center: string[];
    order: number | null;
    isStart: boolean;
    isEnd: boolean;
  }

  const [optimizeState, optimizeDispatch] = useReducer<
    React.Reducer<OptimizeState[], Action>
  >(optimizeReducer, []);

  const { setModal } = useModalContext();
  const [divisions, setDivisions] = useState<Division[]>([]);
  const [division, setDivision] = useState(null);
  const [newStartTime, setNewStartTime] = useState<string | null>(null);
  const [newEndTime, setNewEndTime] = useState<string | null>(null);

  const openMapModal = () => {
    setMapModalOpen(true);
  };

  const closeMapModal = () => {
    setMapModalOpen(false);
  };

  const getAppointmentsByDate = async () => {
    try {
      // console.log(markers);
      const response = await axios.get(
        `${
          process.env.REACT_APP_SERVER_URL
        }/api/v1/company/appointment-map/appointments/${date.toISOString()}`
      );

      // console.log("response", response.data.appointments);

      let assignees = [];
      let divisionList = [];

      for (let i = 0; i < response.data?.appointments.length; i++) {
        const element = response.data.appointments[i];

        if (
          element.hasOwnProperty("request_details") &&
          element.request_details &&
          element.request_details.length > 0 &&
          element.request_details[0] &&
          element.request_details[0].hasOwnProperty("division") &&
          element.request_details[0].division
        ) {
          divisionList.push({
            value: element.request_details[0].division,
            label: element.request_details[0].division,
          });
        } else if (
          element.hasOwnProperty("project_details") &&
          element.project_details &&
          element.project_details.length > 0 &&
          element.project_details[0] &&
          element.project_details[0].hasOwnProperty("division") &&
          element.project_details[0].division
        ) {
          divisionList.push({
            value: element.project_details[0].division,
            label: element.project_details[0].division,
          });
        }

        if (element.assignees && element.assignees.length > 0) {
          for (let j = 0; j < element.assignees.length; j++) {
            const assignee = element.assignees[j];
            assignees.push({
              value: assignee.user_id,
              label: assignee.name,
              color:
                assignee.color ||
                `#${Math.floor(Math.random() * 16777215).toString(16)}`,
            });
          }
        }
      }
      let markerswithUTCtime = response.data.appointments.filter(
        (m: Appointment) => m.fk_status_id != 35
      );

      assignees = _.uniqBy(assignees, "value");
      divisionList = _.uniqBy(divisionList, "value");
      setUsers(_.sortBy(assignees, "label"));
      setDivisions(divisionList);
      // @ts-ignore
      setShowMarkerDisplayList(assignees.map((a) => a.value));
      setMarkers(markerswithUTCtime);
      // console.log(markers);
      setHome(response.data.home);
    } catch (error) {
      console.error(error);
    }
  };

  const handleShowErrorDialog = (message: string[]) => {
    setModal({
      component: (
        <div style={{ zIndex: 9 }}>
          <div style={{ marginBottom: 20 }}>
            {message &&
              message.length > 0 &&
              message?.map((e) => <div style={{ marginBottom: 10 }}>{e}</div>)}
          </div>
          <Button
            primary
            onClick={() => {
              setModal();
            }}
          >
            Ok
          </Button>
        </div>
      ),
      label: "Error",
    });
  };

  interface OptimizeStateElement {
    center?: string[];
    address?: string;
    isStart?: boolean;
    isEnd?: boolean;
    // add other properties as needed
  }

  const optimizeList = async (optimizeState: OptimizeStateElement[]) => {
    try {
      let addresses = [];
      let locations = [];
      let error = [];
      let start = null;
      let addStart = null;
      let end = null;
      let addEnd = null;

      if (optimizeState && optimizeState.length > 0) {
        for (let i = 0; i < optimizeState.length; i++) {
          const element = optimizeState[i];

          if (
            element.hasOwnProperty("center") &&
            element.center &&
            element.center.length === 2
          ) {
            if (!element.isStart && !element.isEnd) {
              locations.push(element.center.join(","));
              addresses.push(element.address);
            } else {
              if (element.isStart) {
                start = element.center.join(",");
                addStart = element.address;
              }
              if (element.isEnd) {
                end = element.center.join(",");
                addEnd = element.address;
              }
            }
          }
        }

        if (start) {
          locations.unshift(start);
          addresses.unshift(addStart);
        }

        if (end) {
          locations.push(end);
          addresses.push(addEnd);
        }

        if (!start) error.push("Start Point Required");
        if (!end) error.push("End Point Required");
        if (optimizeState.length < 2)
          error.push("Two or More Appointments Required");
      } else {
        error.push("Start Point Required");
        error.push("End Point Required");
        error.push("Two or More Appointments Required");
      }

      if (error && error.length > 0) {
        handleShowErrorDialog(error);
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment-map/optimize`,
          {
            locations: locations.join(";"),
          }
        );

        if (response.data.waypoints && response.data.waypoints.length > 0) {
          let ways = response.data.waypoints;

          addresses.forEach((a, i) => {
            let addressValue = a;
            if (addressValue === null || addressValue === undefined) {
              addressValue = "";
            }

            optimizeDispatch({
              type: "UPDATE_ORDER",
              payload: {
                address: addressValue,
                order: ways[i].waypoint_index + 1,
              },
            });

            return addressValue;
          });
        }

        if (
          response &&
          response.data &&
          response.data.trips &&
          response.data.trips[0] &&
          response.data.trips.length > 0
        ) {
          setTrip(polyline.toGeoJSON(response.data.trips[0].geometry));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  interface IMarker {
    center: string[];
  }

  const recalculateCenter = (filteredMarkers: IMarker[]) => {
    if (
      home &&
      home.location &&
      home.location.latitude &&
      home.location.longitude &&
      filteredMarkers &&
      filteredMarkers.length > 0
    ) {
      let latitudes = [];
      let longitudes = [];

      latitudes.push(home.location.latitude);
      longitudes.push(home.location.longitude);

      for (let index = 0; index < filteredMarkers.length; index++) {
        const marker: IMarker = filteredMarkers[index];
        if (
          marker &&
          marker.hasOwnProperty("center") &&
          marker.center &&
          marker.center.length > 0 &&
          marker.center[0] &&
          marker.center[1] &&
          parseFloat(marker.center[0]) &&
          parseFloat(marker.center[1])
        ) {
          latitudes.push(marker.center[1]);
          longitudes.push(marker.center[0]);
        }
      }

      const minLatitude = Math.min(...latitudes.map(Number));
      const maxLatitude = Math.max(...latitudes.map(Number));
      const minLongitude = Math.min(...longitudes.map(Number));
      const maxLongitude = Math.max(...longitudes.map(Number));

      if (
        mapModalOpen &&
        mapRef &&
        mapRef.current &&
        minLatitude &&
        minLongitude &&
        maxLatitude &&
        maxLongitude
      ) {
        const coords = [
          [minLongitude, minLatitude],
          [maxLongitude, maxLatitude],
        ];
        const mapGL = mapRef?.current?.getMap();
        
        if (mapGL) {
          mapGL.fitBounds(coords, {
            padding: { top: 140, bottom: 140, left: 140, right: 140 },
          });
        }
      }
    }
  };

  const handleUpdateDisplayList = (userId: number, type: string) => {
    switch (type) {
      case "add":
        // @ts-ignore
        setShowMarkerDisplayList((prev) => [...prev, userId]);
        break;
      case "remove":
        // @ts-ignore
        setShowMarkerDisplayList((prev) => [
          ...prev.filter((i) => i != userId),
        ]);
        break;
      default:
        break;
    }
  };

  const handleChangeAllMarkerDisplay = (type: string) => {
    switch (type) {
      case "all":
        // @ts-ignore
        setShowMarkerDisplayList(users?.map((a) => a.value));
        setShowUnassigned(true);
        break;
      case "none":
        setShowMarkerDisplayList([]);
        setShowUnassigned(false);
        break;
      default:
        break;
    }
  };

  const handleChangeShowUnassigned = () => {
    setShowUnassigned((prev) => !prev);
  };

  useEffect(() => {
    getAppointmentsByDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    if (markers && markers.length > 0) {
      const filteredMarkers = markers.map((m) => {
        let display = false;
        if (m && m.hasOwnProperty("assignees") && m.assignees) {
          m.assignees.map((a) => {
            // @ts-ignore
            if (showMarkerDisplayList.includes(parseInt(a.user_id))) {
              display = true;
              return a;
            } else {
              return a;
            }
          });
        }

        if (m.hasOwnProperty("assignees") && !m.assignees && showUnassigned) {
          display = true;
        }

        if (display) {
          return m;
        } else {
          return null;
        }
      });

      // @ts-ignore
      recalculateCenter(filteredMarkers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMarkerDisplayList, showUnassigned, date, markers]);

  const handleBulkEdit = async () => {
    // @ts-ignore
    const selected = markers.filter((marker) => {
      if (
        marker &&
        marker.request_details &&
        marker.request_details.length > 0 &&
        marker.request_details[0] &&
        marker.request_details[0].customer_address &&
        optimizeState
          .map((a) => {
            return a.address;
          })
          .includes(marker.request_details[0].customer_address)
      ) {
        return true;
      }
      return false;
    });

    const formattedDate = date.toISOString().split("T")[0];
    const startTime = newStartTime ? convertToUTC(formattedDate, newStartTime, timezone) : null;
    const endTime = newEndTime ? convertToUTC(formattedDate, newEndTime, timezone) : null;

    const data = {
      appointments: selected,
      assignee: AssignedTo,
      date: scheduledDate,
      startTime: startTime,
      endTime: endTime,
      // time: newTime + " " + offsetSign + localoffset.toString() + ":00",
    };
    try {
      await axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment-map/bulk-edit`,
          data
        )
        .then(async (res) => {
          await getAppointmentsByDate().then(() => {
            // @ts-ignore
            optimizeDispatch({ type: "CLEAR" });
            setAssignedTo("");
            // @ts-ignore
            setScheduledDate("");
            setNewStartTime(null);
            setNewEndTime(null);
            setTrip(null);
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
  // @ts-ignore
  const selectedsort = (markers) => {
    if (markers && markers.length > 0) {
      // @ts-ignore
      let selected = markers.filter((marker) => {
        if (
          marker &&
          marker.request_details &&
          marker.request_details.length > 0 &&
          marker.request_details[0] &&
          marker.request_details[0].customer_address &&
          optimizeState
            .map((a) => {
              return a.address;
            })
            .includes(marker.request_details[0].customer_address)
        ) {
          return true;
        }
        return false;
      });
      // @ts-ignore
      let unselected = markers.filter((marker) => {
        if (
          marker &&
          marker.request_details &&
          marker.request_details.length > 0 &&
          marker.request_details[0] &&
          marker.request_details[0].customer_address &&
          optimizeState
            .map((a) => {
              return a.address;
            })
            .includes(marker.request_details[0].customer_address)
        ) {
          return false;
        }
        return true;
      });
      return [...selected, ...unselected];
    } else {
      return markers;
    }
  };

  return (
    <>
      <Button
        size="small"
        style={{ marginLeft: 10 }}
        onClick={() => {
          openMapModal();
        }}
      >
        {tr("Map")}
      </Button>
      <Modal
        open={mapModalOpen}
        // @ts-ignore
        onRequestClose={closeMapModal}
        contentLabel={tr("Map")}
        style={{ zIndex: 10 }}
      >
        <div className={styles.outerModalContainer}>
          <div className={styles.innerModalContainer}>
            <UserDisplayControl
              users={users}
              showMarkerDisplayList={showMarkerDisplayList}
              handleUpdateDisplayList={handleUpdateDisplayList}
              handleChangeAllMarkerDisplay={handleChangeAllMarkerDisplay}
              showUnassigned={showUnassigned}
              handleChangeShowUnassigned={handleChangeShowUnassigned}
            />

            <MapComponent
              markers={markers}
              viewState={viewState}
              setViewState={setViewState}
              mapRef={mapRef}
              drawRef={drawRef}
              trip={trip}
              optimizeState={optimizeState}
              optimizeDispatch={optimizeDispatch}
              home={home}
              showAddresses={showAddresses}
              showTimes={showTimes}
              showMarkerDisplayList={showMarkerDisplayList}
              setTrip={setTrip}
              setHome={setHome}
              setFilterAssignee={setFilterAssignee}
              setDivision={setDivision}
              setDivisions={setDivisions}
              setUsers={setUsers}
              setShowAddresses={setShowAddresses}
              setShowTimes={setShowTimes}
              setShowMarkerDisplayList={setShowMarkerDisplayList}
              setShowUnassigned={setShowUnassigned}
              setDate={setDate}
              users={users}
              showUnassigned={showUnassigned}
              selectedBasemapId={selectedBasemapId}
            />
            <div className={styles.appointmentsContainer}>
              <div className={styles.appointmentsHeader}>
                <>
                  {markers && markers.length > 0 ? (
                    <>
                      {/*@ts-ignore */}
                      <Select
                        placeholder={tr("User")}
                        isClearable={true}
                        clearValue={null}
                        value={filterAssignee}
                        onChange={setFilterAssignee}
                        className={styles.divisionSelectWrapper}
                        options={users}
                        style={{ marginRight: 10 }}
                      />
                      <Select
                        placeholder={tr("Division")}
                        isClearable={true}
                        clearValue={null}
                        value={division}
                        // @ts-ignore
                        onChange={setDivision}
                        className={styles.divisionSelectWrapper}
                        options={divisions}
                        style={{ marginRight: 10 }}
                      />
                      <Button
                        style={{ marginRight: 10 }}
                        onClick={() => {
                          setTrip(null);
                          // @ts-ignore
                          optimizeDispatch({ type: "CLEAR" });
                        }}
                      >
                        {tr("Clear")}
                      </Button>
                      <Button
                        style={{ marginRight: 10 }}
                        onClick={() => {
                          optimizeList(optimizeState);
                        }}
                      >
                        {tr("Optimize")}
                      </Button>
                    </>
                  ) : null}
                </>
                <DatePicker
                  selected={date}
                  onChange={(date) => {
                    // @ts-ignore
                    let d = new Date(date);
                    d.setHours(0, 0, 0, 0);
                    setDate(d);
                  }}
                  className={styles.mapDatePicker}
                  wrapperClassName={styles.mapDatePickerWrapper}
                  locale={locale}
                  popperPlacement="bottom"
                />

                <Button onClick={closeMapModal}>{tr("Close")}</Button>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  width: "100%",
                  flexDirection: "column",
                  padding: 5,
                  overflowY: "scroll",
                }}
              >
                <div
                  className={styles.appointmentRow}
                  style={{
                    borderLeftColor: "lightgray",
                    borderLeftWidth: 15,
                    padding: "10px",
                    marginBottom: "10px",
                    borderBottomWidth: "1px",
                    borderBottomColor: "RGB(180,180,180)",
                  }}
                >
                  <h3 className="-mt-1">{tr("Edit Selected Appointments")}</h3>
                  <hr className="my-1" />
                  <div className="
                    flex flex-row w-100 space-x-3
                    [&>*]:flex [&>*]:flex-col [&>*]:justify-between
                  ">
                    <div className="min-w-[100px] w-[150px] flex-1">
                      <p>{tr("Reassign To")}:</p>
                      <AssigneeSelect
                        onChange={(e: React.SetStateAction<string>) => {
                          setAssignedTo(e);
                        }}
                        name="assignee"
                        assigneeType="appointment"
                        value={AssignedTo}
                      />
                    </div>
                    <div className="w-[130px]">
                      <p>{tr("Reschedule for")}:</p>
                      <input
                        type="date"
                        className="h-[35px] border-[1px] rounded-[5px] p-[5px]"
                        value={scheduledDate}
                        onChange={(e) => {
                          setScheduledDate(e.target.value);
                        }}
                      />
                    </div>
                    <div className="w-[120px]">
                      <p>{tr("New Start Time")}:</p>
                      <input
                        type="time"
                        className="h-[35px] border-[1px] rounded-[5px] p-[5px]"
                        value={newStartTime || ""}
                        onChange={(e) => {
                          setNewStartTime(e.target.value);
                        }}
                      />
                    </div>
                    <div className="w-[110px]">
                      <p>{tr("New End Time")}:</p>
                      <input
                        type="time"
                        className="h-[35px] border-[1px] rounded-[5px] p-[5px]"
                        value={newEndTime || ""}
                        onChange={(e) => {
                          setNewEndTime(e.target.value);
                        }}
                      />
                    </div>
                    <div className="self-end  !max-w-10">
                      <Button onClick={handleBulkEdit} className="!h-[36px]">
                        {tr("Save")}
                      </Button>
                    </div>
                  </div>
                </div>
                {home ? (
                  <HomeBlock
                    key="home-start"
                    home={home}
                    optimizeState={optimizeState}
                    optimizeDispatch={optimizeDispatch}
                  />
                ) : null}
                {markers &&
                  markers.length > 0 &&
                  // @ts-ignore
                  selectedsort(_.sortBy(markers, "start_time_utc"))?.map((m) => {
                    let pinDisplay = false;

                    if (m && m.hasOwnProperty("assignees") && m.assignees) {
                      // @ts-ignore
                      m.assignees.map((a) => {
                        if (
                          // @ts-ignore
                          showMarkerDisplayList.includes(parseInt(a.user_id))
                        ) {
                          pinDisplay = true;
                          return a;
                        } else {
                          return a;
                        }
                      });
                    }

                    if (
                      m.hasOwnProperty("assignees") &&
                      !m.assignees &&
                      showUnassigned
                    ) {
                      pinDisplay = true;
                    }

                    let filterUserDisplay = true;

                    if (filterAssignee) {
                      filterUserDisplay = false;
                      if (m && m.hasOwnProperty("assignees") && m.assignees) {
                        // @ts-ignore
                        m.assignees.map((a) => {
                          // @ts-ignore
                          if (a.name === filterAssignee.label) {
                            filterUserDisplay = true;
                          }
                          return a;
                        });
                      }
                    }

                    let filterDivisionDisplay = true;
                    if (division) {
                      filterDivisionDisplay = false;

                      if (
                        m.hasOwnProperty("request_details") &&
                        m.request_details &&
                        m.request_details.length > 0 &&
                        m.request_details[0] &&
                        m.request_details[0].hasOwnProperty("division") &&
                        m.request_details[0].division &&
                        // @ts-ignore
                        m.request_details[0].division === division.value
                      ) {
                        filterDivisionDisplay = true;
                      }

                      if (
                        m.hasOwnProperty("project_details") &&
                        m.project_details &&
                        m.project_details.length > 0 &&
                        m.project_details[0] &&
                        m.project_details[0].hasOwnProperty("division") &&
                        m.project_details[0].division &&
                        // @ts-ignore
                        m.project_details[0].division === division.value
                      ) {
                        filterDivisionDisplay = true;
                      }
                    }

                    if (
                      m.hasOwnProperty("center") &&
                      m.center &&
                      pinDisplay &&
                      filterDivisionDisplay &&
                      filterUserDisplay
                    ) {
                      return (
                        <AppointmentRow
                          key={m.id + AssignedTo + scheduledDate + newStartTime}
                          marker={m}
                          markers={markers}
                          setMarkers={setMarkers}
                          optimizeState={optimizeState}
                          optimizeDispatch={optimizeDispatch}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MapModal;