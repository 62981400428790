import InputV2, { TInput } from "components/input/inputv2";
import { Asterick, Error, StyledLabel } from "components/input/style";
import SelectV2, { TSelectProps } from "components/react-selectv2";
import TextArea, { TTextAreaProps } from "components/textarea";
import { FormikProps } from "formik";
import { get } from "lodash";
import * as React from "react";
import { TextareaAutosizeProps } from "react-textarea-autosize";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

type GeneralProps = {
  label?: string;
  required?: boolean;
  error?: string;
  formik?: FormikProps<any>;
  name?: string;
  className?: string;
  value?: any;
  setValue?: any;
};

export type FieldProps = GeneralProps &
  (
    | ({
        type?: "input";
      } & TInput)
    | ({
        type: "select";
      } & TSelectProps)
    | ({
        type: "textarea";
      } & TTextAreaProps)
  );

const Field = React.forwardRef(
  (props: FieldProps, ref: React.ForwardedRef<any>) => {
    const { t } = useTranslation();
    const tBase = "components.field";
    const tr = (key: string) => t(`${tBase}.${key}`);

    let error =
      props.error ||
      (props.name &&
        get(props.formik?.touched, props.name) &&
        get(props.formik?.errors, props.name)) ||
      "";

    error = typeof error === "string" ? error : "";

    const value =
      props.value ??
      (props.name && get(props.formik?.values, props.name)) ??
      (props.formik && "") ??
      undefined;

    const component =
      props.type === "select" ? (
        <SelectV2 {...props} value={value} forwardedRef={ref} error={error} />
      ) : props.type === "textarea" ? (
        <TextArea {...props} value={value} error={error} />
      ) : (
        <InputV2 {...props} value={value} forwardedRef={ref} error={error} />
      );

    return (
      <Con style={props.style} className={props.className}>
        {props.label && (
          <StyledLabel>
            {props.label}
            {props.required && (
              <Asterick className="text-[0.6rem] ml-1">{tr("Required")}</Asterick>
            )}
          </StyledLabel>
        )}
        {component}
        {error && <Error>{error}</Error>}
      </Con>
    );
  }
);

const Con = styled.div`
  display: flex;
  flex-direction: column;
`;
export default Field;
