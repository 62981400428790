import { useEffect, useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Button } from "components/button";
import { useSnackbarContext } from "components/snackbar";
import axios from "axios";
import { useTranslation } from 'react-i18next';

type Props = {
  id?: string;
  type?: "request" | "project" | "invoice" | "customer";
  billingDetails?: {
    address: {
      line1: string;
      city: string;
      state: string;
      postal_code: string;
    };
    name: string;
    phone: string;
    email: string;
  } | null;
  isDirect?: boolean;
  isSubscription?: boolean;
  subscriptionData?: object | null;
  stripeLoadError?: boolean;
};

const StripeCheckout = ({ id, type, billingDetails, isDirect, isSubscription, subscriptionData, stripeLoadError}: Props) => {
  const { t } = useTranslation();
  const tBase = "views.payment.components.StripeCheckout";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const elements = useElements();
  const stripe = useStripe();
  const { showSnackbar } = useSnackbarContext();
  const [saveCard, setSaveCard] = useState(true);

  useEffect(() => {
    if (stripeLoadError) {
      setSaveCard(false);
    }
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null | undefined>(null);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const returnUrl =
      process.env.NODE_ENV === "development"
        ? `http://localhost:3000/${type}s/${id}`
        : `https://pro.eserv.com/${type}s/${id}`;
    
    const subscriptionReturnUrl = 
      process.env.NODE_ENV === "development"
        ? `${process.env.REACT_APP_LOCAL}/admin/subscription/`
        : `https://pro.eserv.com/admin/subscription/`;
    
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: isDirect
        ? {
            return_url: returnUrl,
            payment_method_data: {
              billing_details: {
                address: {
                  line1: billingDetails?.address?.line1 || "",
                  city: billingDetails?.address?.city || "",
                  state: billingDetails?.address?.state || "",
                  postal_code: billingDetails?.address?.postal_code || "",
                },
                name: billingDetails?.name || "",
                phone: billingDetails?.phone || "",
                email: billingDetails?.email || "",
              },
            },
            save_payment_method: saveCard,
            // setup_future_usage: saveCard ? "off_session" : undefined,
          }

        : isSubscription ? 
          {
            return_url: subscriptionReturnUrl,
          }

        : {
            return_url: returnUrl,
          },
    })

    if (result.error) {
      setError(result.error.message);
      // Show error to your customer (for example, payment details incomplete)
      setIsLoading(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  

  return (
    <div className="flex flex-col space-y-8">
      <PaymentElement className="w-[99.1%]" />
      {!stripeLoadError && (
        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            id="save-card"
            checked={saveCard}
            onChange={(e) => setSaveCard && setSaveCard(e.target.checked)}
          />
          <label htmlFor="save-card">{tr("Save Card for Future Payments")}</label>
        </div>
      )}
      <Button disabled={!stripe} onClick={handleSubmit} isLoading={isLoading} primary>
        {tr("Submit")}
      </Button>
      {error && <span className="text-red-600">{error}</span>}
    </div>
  );
};


export default StripeCheckout;
